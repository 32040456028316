import GdLazyImage from 'app/components/lazyimage';
import { AUTH_LOGIN, CALENDAR } from 'app/const/Route';
import { LINK_CDN_IMG } from 'app/const/URL';
import { signupSocialCompany } from 'common/redux/actions/authAction';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

import 'app/modules/auth/changePassword/css/index.scss';
import { handleTrackingEvent } from 'common/utils/MixpanelUtils';
import { SUBMIT_SIGN_UP_FORM } from 'app/const/Mixpanel';
import { mixpanelSignup } from 'app/modules/mixpanel/MixpanelSignup';
import { addBranchPath } from 'app/const/Branch';
import FreeTrial from '../signup/components/freeTrial';
import { AuthBanner } from '../components/AuthBanner';
import { capitalizeEachWord } from 'common/utils/StringUtils';
import { restoreOptionJobCalendar } from 'app/modules/calendar/ultil/Calendar';

const SignUpSocial = ({ isPublic = false }) => {
    const { t } = useTranslation(['auth']);
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const dataUser = location?.state?.dataUser;

    const handleSignupSuccess = ({ id, email, company = {}, profile = {} }) => {
        handleTrackingEvent(
            mixpanelSignup({
                profile: {
                    ...profile,
                    id,
                    name: `${capitalizeEachWord(profile.first_name + ' ' + profile.last_name)}`,
                    email
                },
                company: {
                    ...company,
                    name: capitalizeEachWord(profile.company_name)
                },
                event: SUBMIT_SIGN_UP_FORM
            })
        );
        restoreOptionJobCalendar();
        history.replace(addBranchPath(CALENDAR), { isSignupSuccess: true });
    };

    const handleSubmitForm = (data) => {
        const postData = {
            ...dataUser,
            ...data
        };

        postData.company.email = dataUser.email;
        postData.profile.email = dataUser.email;

        dispatch(
            signupSocialCompany(
                postData,
                () => handleSignupSuccess(postData),
                () => {}
            )
        );
    };

    if (!dataUser) {
        return <Redirect to={AUTH_LOGIN} />;
    }

    return !isPublic ? (
        <FreeTrial submitSignup={handleSubmitForm} />
    ) : (
        <div className="gorilladesk auth-page">
            <div className="wrapper auth-container">
                <AuthBanner />
                <div className="main-login mt-10">
                    <FreeTrial submitSignup={handleSubmitForm} userId={dataUser?.id} />
                    <p className="mascot">
                        <GdLazyImage
                            src={`${LINK_CDN_IMG}half-gorilla.png`}
                            width={165}
                            height={150}
                            alt={t('common:gorilladesk')}
                        />
                    </p>
                </div>
            </div>
        </div>
    );
};

export default SignUpSocial;
