import classNames from 'classnames';
import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import GdGridView from 'app/components/grid/GdGridView';
import GDModalWarning from 'app/components/modal/ModalWarning';
import { batchActionChatbot } from 'app/const/api/V2';
import { DEFAULT_ALL, KEY_REPORT_LOCAL_STORAGE, TYPE_BUTTON_ACTIONS } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { CONVERSATION_TAGS } from 'app/const/report/ReportFilter';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import { handleAbortController } from 'app/modules/customer/utils';
import CheckBoxHeader from 'app/modules/report/components/CheckBoxHeader';
import IconArrowDown from 'assets/icon/IconArrowDown';
import IconTrash from 'assets/icon/IconTrash';
import { clientQuery } from 'common/utils/ApiUtils';
import { handleActionHeaderReport } from 'common/utils/GridViewUtils';
import { getLocalStorage } from 'common/utils/LocalStorageUtils';
import { convertValuesObjectToString } from 'common/utils/ObjectUtils';
import { convertParamFields, getLocalParamsReport } from 'common/utils/ReportUtils';
import { ACTION_BUTTON, BATCH_ACTION, DATA_WARNING_TYPE, MARK_AS_OPTIONS, TYPE_DATA_CHATBOT } from '../constants';
import HeaderFilterChatbot from './HeaderFilterChatbot';

const TableChatbot = (
    {
        reportType = '',
        endpoint = '',
        filters = [],
        isShowDateRange = false,
        batchActionKey = '',
        isShowCheckbox = true,
        isShowHeaderFilter = true,
        isShowHeaderBottom = true,
        classTable = 'scrolls-x table-multi-column has-text-ellipsis',
        isMapDropdownTagByName = false,
        gridColumnReport = () => {},
        onClickRow = () => {},
        onShowAlert = () => {},
        onGetData = () => {},
        onHandleSaveTags = () => {}
    },
    ref
) => {
    const { t } = useTranslation();

    const keyLocalChatbot = KEY_REPORT_LOCAL_STORAGE.concat('_', reportType);
    const paramsReport = getLocalParamsReport(keyLocalChatbot, reportType);
    const paramsReportStatus = paramsReport?.status;

    const refWarning = useRef(null);
    const refDropdown = useRef(null);
    const abortController = useRef(null);

    const checkedItems = { is_check_all: false, ids: [] };
    const [state, dispatchState] = useReducer(reducer, {
        checkedItems,
        total: 0,
        data: [],
        isLoading: true,
        isLoadMore: false,
        cursor: null,
        show_more: false,
        keyword: '',
        forceRerender: 0
    });

    const {
        checkedItems: finalCheckedItems,
        total: finalTotal,
        isLoading: finalIsLoading,
        isLoadMore: finalIsLoadMore,
        data: finalData,
        cursor,
        show_more: showMore,
        keyword: finalKeyword,
        forceRerender: finalForceRerender
    } = state;
    const ids = finalCheckedItems.ids || [];

    useImperativeHandle(ref, () => ({
        getData: getListChatBot,
        getFilter: () => getLocalStorage(keyLocalChatbot),
        updateDataByType: _handleUpdateDataByType
    }));

    useEffect(() => {
        getListChatBot();

        return () => {
            handleAbortController(abortController);
        };
    }, []);

    const _handleUpdateDataByType = ({ type, data, isForceRerender = false }) => {
        let finalType = type;
        let finalData = data;
        const typeChatbotDelete = type === TYPE_DATA_CHATBOT.DELETE;
        let isCalculateTotal = false;

        if (
            !typeChatbotDelete &&
            paramsReportStatus !== DEFAULT_ALL &&
            paramsReportStatus?.length &&
            !paramsReportStatus.includes(finalData?.status)
        ) {
            finalType = TYPE_DATA_CHATBOT.DELETE;
            finalData = data?.id || '';
            isCalculateTotal = true;
        }

        switch (finalType) {
            case TYPE_DATA_CHATBOT.UPDATE:
                _handleUpdateData({ dataUpdate: finalData, isForceRerender });
                break;
            case TYPE_DATA_CHATBOT.DELETE:
                _handleDeleteItems([finalData], typeChatbotDelete || isCalculateTotal);
                break;
            default:
                break;
        }
    };

    const getListChatBot = ({ shouldLoadMore = false, params: customParams = {}, shouldLoading = true } = {}) => {
        handleAbortController(abortController);
        abortController.current = new AbortController();

        dispatchState((prev) => {
            return {
                ...prev,
                isLoadMore: shouldLoadMore,
                data: !shouldLoadMore ? [] : prev.data,
                isLoading: shouldLoading,
                checkedItems: !shouldLoadMore ? checkedItems : prev.checkedItems,
                ...customParams
            };
        });

        const _handleSuccess = (res) => {
            const { data = [], total, cursor, show_more } = res || {};
            dispatchState((prev) => {
                const { total: prevTotal, data: prevData = [], checkedItems, isLoadMore } = prev;
                const newData = isLoadMore ? [...prevData, ...data] : data;

                return {
                    ...prev,
                    isLoading: false,
                    isLoadMore: false,
                    data: newData,
                    cursor,
                    show_more,
                    total: isLoadMore ? prevTotal : total,
                    checkedItems: {
                        ...checkedItems,
                        ids: checkedItems.is_check_all ? newData.map((e) => e.id) : checkedItems.ids
                    }
                };
            });
        };

        const _handleFail = (err) => {
            if (err.isAborted) return;
            dispatchState((prev) => ({ ...prev, isLoading: false, isLoadMore: false }));
            onShowAlert(err);
        };

        const params = convertValuesObjectToString({ ...getLocalStorage(keyLocalChatbot), ...customParams });
        const paramStatus = params?.status;
        const finalParams = {
            keyword: finalKeyword,
            ...convertParamFields({ params, isEmptyFields: shouldLoadMore }),
            cursor: shouldLoadMore ? cursor : null
        };

        // eslint-disable-next-line no-prototype-builtins
        if (Object(params).hasOwnProperty('status')) {
            finalParams.status = !paramStatus ? DEFAULT_ALL : paramStatus;
        }
        // eslint-disable-next-line no-prototype-builtins
        if (Object(params).hasOwnProperty(CONVERSATION_TAGS)) {
            finalParams.tags = finalParams[CONVERSATION_TAGS];
            delete finalParams[CONVERSATION_TAGS];
        }

        delete finalParams?.currentPage;
        clientQuery(
            endpoint,
            { method: 'GET', data: finalParams, abortController: abortController.current },
            _handleSuccess,
            _handleFail
        );
    };

    const _handleClickButton = (value) => {
        const idsLength = ids.length;
        const action = t(`addons:${ACTION_BUTTON[value].label}`);
        refWarning.current?._open({
            dataConfirm: { ids, typeAction: value, paramsReportStatus, batchActionKey },
            title: t('addons:action_confirmation', { action }),
            description: t('addons:action_chatbot_description', {
                number: idsLength,
                prefix: idsLength > 1 ? 's' : '',
                type: t(`addons:${DATA_WARNING_TYPE[batchActionKey].toString().toLowerCase()}`),
                action
            })
        });
        refDropdown.current?._closeDropdown();
    };

    const renderListActionButton = () => {
        return (
            <Fragment>
                <div
                    className="v2-btn-default has-icon header-items btn-delete has-bg-red"
                    onClick={() => _handleClickButton(TYPE_BUTTON_ACTIONS.DELETE)}
                >
                    <IconTrash />
                    {t('delete')}
                </div>
                <CalendarDropdown
                    id="mark_as_chatbot"
                    ref={refDropdown}
                    buttonClassName="dropbtn items"
                    wrapperListClass="v2-dropdown__menu content-full scrolls"
                    options={MARK_AS_OPTIONS}
                    onSelect={_handleClickButton}
                    keyGetValue="id"
                    customDropButton={() => (
                        <Fragment>
                            <div className="txt-ellipsis mr-1">{t('common:mark_as')}</div>
                            <div className="arrow">
                                <IconArrowDown />
                            </div>
                        </Fragment>
                    )}
                    wrapperClassName="header-items"
                />
            </Fragment>
        );
    };

    const _renderHeaderBottom = () => {
        if (!isShowCheckbox) return null;
        const idsSelected = ids?.length || 0;
        return (
            <div className={classNames('header --filter', { 'is-disable': !idsSelected })}>
                <CheckBoxHeader isShowTotal total={finalTotal} checkedItems={finalCheckedItems} />
                {batchActionKey ? <div className="flexcenter gap-4">{renderListActionButton()}</div> : null}
            </div>
        );
    };

    const _handleActionHeader = ({ actionType, columnsTarget, currentValue }) => {
        handleActionHeaderReport({
            actionType: actionType,
            reportType,
            columnsTarget,
            currentValue,
            paramsReport,
            callBack: getListChatBot
        });
    };

    const _handleLoadMore = () => {
        if (showMore && !finalIsLoadMore) {
            getListChatBot({ shouldLoadMore: true, shouldLoading: false });
        }
    };

    const _handleConfirmWarning = (_, dataConfirm) => {
        const { ids = [], typeAction, paramsReportStatus, batchActionKey } = dataConfirm || {};
        const typeBatchAction = BATCH_ACTION[batchActionKey];
        if (!typeBatchAction) return;
        refWarning.current?._clearStatusAlert();

        const _handleSuccess = ({ message }) => {
            refWarning.current?._close();
            onShowAlert({ message, success: true });
            if (
                (typeAction !== TYPE_BUTTON_ACTIONS.DELETE && paramsReportStatus === DEFAULT_ALL) ||
                (Array.isArray(paramsReportStatus) && !paramsReportStatus?.length)
            ) {
                _handleUpdateMultiData({ ids, typeAction });
            } else {
                _handleDeleteItems(ids, true);
            }
        };

        const _handleFail = ({ message }) => {
            refWarning.current?._setStatusAlert(message);
        };

        clientQuery(
            batchActionChatbot(typeBatchAction),
            {
                data: { ids: ids?.toString() || '', type: ACTION_BUTTON[typeAction].type },
                method: 'PUT',
                toFormData: false
            },
            _handleSuccess,
            _handleFail
        );
    };

    const onChangeStateCheckedItems = (stateCheckedItems) => {
        dispatchState((prev) => ({ ...prev, checkedItems: stateCheckedItems }));
    };

    const _handleUpdateData = ({ dataUpdate = {}, isForceRerender = false }) => {
        dispatchState((prev) => {
            const { data: prevData = [], total: prevTotal } = prev || {};
            const finalData = [...prevData];
            let finalTotal = prevTotal;
            const indexItem = finalData.findIndex((item) => item.id === dataUpdate.id);
            if (indexItem > -1) finalData[indexItem] = { ...(finalData[indexItem] || {}), ...dataUpdate };
            else {
                finalData.push(dataUpdate);
                finalTotal = finalTotal + 1;
            }

            return {
                ...prev,
                data: finalData,
                total: finalTotal,
                checkedItems,
                forceRerender: isForceRerender ? prev.forceRerender + 1 : prev.forceRerender
            };
        });
    };

    const _handleUpdateMultiData = ({ ids = [], typeAction }) => {
        dispatchState((prev) => {
            let finalData = [...prev.data];
            finalData = finalData.map((item) => {
                if (ids.includes(item.id)) {
                    return { ...item, status: ACTION_BUTTON[typeAction].status };
                }

                return item;
            });

            return { ...prev, data: finalData, checkedItems };
        });
    };

    const _handleDeleteItems = (ids = [], isCalculateTotal = false) => {
        dispatchState((prev) => {
            const { data: prevData = [], total: prevTotal } = prev || {};

            let finalData = [...prevData];
            finalData = finalData.filter((item) => !ids.includes(item.id));

            return {
                ...prev,
                data: finalData,
                total: isCalculateTotal ? prevTotal - ids.length : prevTotal,
                checkedItems
            };
        });
    };

    return (
        <Fragment>
            {isShowHeaderFilter ? (
                <HeaderFilterChatbot
                    typeReport={reportType}
                    filters={filters}
                    isShowDateRange={isShowDateRange}
                    isMapDropdownTagByName={isMapDropdownTagByName}
                    forceRerender={finalForceRerender}
                    handleGetData={onGetData}
                />
            ) : null}
            <div className="wrap-tables flex-column relative">
                {isShowHeaderBottom ? _renderHeaderBottom() : null}
                <GdGridView
                    isEmptyFlat
                    isLoading={finalIsLoading}
                    classTable={classNames(classTable, { 'has-checkbox': isShowCheckbox })}
                    classTableContent=""
                    content={finalData}
                    showCheckBox={isShowCheckbox}
                    fileTranslation={'report'}
                    handleClick={onClickRow}
                    handleClickHeader={_handleActionHeader}
                    checkedItems={finalCheckedItems}
                    onChangeStateCheckedItems={onChangeStateCheckedItems}
                    {...gridColumnReport({ actionsStatus: paramsReport?.order })}
                    isScroll
                    isLoadmore={finalIsLoadMore}
                    onScrollToEnd={_handleLoadMore}
                    onHandleSaveTags={onHandleSaveTags}
                />
                <GDModalWarning
                    ref={refWarning}
                    isLargeContent={false}
                    buttonSave
                    titleButtonConfirm={t('addons:yes')}
                    onConfirm={_handleConfirmWarning}
                />
            </div>
        </Fragment>
    );
};

export default forwardRef(TableChatbot);
