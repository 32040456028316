import GdAvatar from 'app/components/avatar';
import Checkbox from 'app/components/checkbox';
import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import GdPhoneItem from 'app/components/phone/ItemPhone';
import GdPlaces from 'app/components/places';
import GdTags from 'app/components/tags';
import { getUrlSubscriptionAssign } from 'app/const/Api';
import {
    ACCOUNT_ROLE,
    ADDED_BY_SPECIAL_LIST,
    COMMON,
    CURRENT_MONTH,
    DATE_FORMAT,
    ENTER_KEY_CODE,
    KEY_CODE_ESCAPE,
    KEY_CURRENCY_COLUMNS,
    METHOD_API,
    METHOD_CONVERT,
    REGEX_URL,
    TYPE_BUTTON_ACTIONS,
    YEAR_CURRENT
} from 'app/const/App';
import { LIST_COMMISSION_SETTING, LIST_COMMISSION_TYPE } from 'app/const/Commissions';
import { PAYMENT_CARD_CUSTOMER, PAYMENT_STATUS_FAIL, TYPE_PAYMENT_FOR, TYPE_TAGS } from 'app/const/Customers';
import { reducer } from 'app/const/Reducer';
import { REPORT_LIMIT_HEIGHT_COLUMN, WON_STATUS } from 'app/const/Reports';
import { ARRAY_STAR } from 'app/const/Reviews';
import { CUSTOMERS } from 'app/const/Route';
import { KEY_COLOR_SERVICE } from 'app/const/Settings';
import { updateColorSettingService, urlOpenSquare, urlOpenStripe } from 'app/const/api/V2';
import { JOB_STATUS_OPTION_TAB } from 'app/const/customer/CustomerJobs';
import { ActiveUsersContext } from 'app/modules/addons/voip/components/activeUsers/ActiveUsersProvider';
import { ActiveVoipUsersEdit } from 'app/modules/addons/voip/components/activeUsers/ActiveVoipUsersEdit';
import { DropdownPersonalNumber } from 'app/modules/addons/voip/components/activeUsers/DropdownPersonalNumber';
import { DigitsAutoAttendant } from 'app/modules/addons/voip/components/autoAttendant/DigitsAutoAttendant';
import { EditAutoAttendant } from 'app/modules/addons/voip/components/autoAttendant/EditAutoAttendant';
import { ForwardAutoAttendant } from 'app/modules/addons/voip/components/autoAttendant/ForwardAutoAttendant';
import TagsDisplay from 'app/modules/customer/detail/accounts/components/TagsDisplay';
import { displayLocation } from 'app/modules/customer/detail/locations/utils';
import { PAYMENT_METHODS } from 'app/modules/jobdetail/const/Invoice';
import { handleCreatePopperAttach } from 'app/modules/jobdetail/const/Utils';
import IconArrowDown from 'assets/icon/IconArrowDown';
import IconCall from 'assets/icon/IconCall';
import IconDeviceSession from 'assets/icon/IconDeviceSession';
import IconDropUpDown from 'assets/icon/IconDropUpDown';
import IconLink from 'assets/icon/IconLink';
import IconLocation from 'assets/icon/IconLocation';
import IconLogout from 'assets/icon/IconLogout';
import IconPlus from 'assets/icon/IconPlus';
import IconSignedOut from 'assets/icon/IconSignedOut';
import IconStar from 'assets/icon/IconStar';
import IconThreeDots from 'assets/icon/IconThreeDots';
import IconTooltip from 'assets/icon/IconTooltip';
import IconUpgrade from 'assets/icon/IconUpgrade';
import classNames from 'classnames';
import { clientQuery } from 'common/utils/ApiUtils';
import { changeStyleRowsDropdown } from 'common/utils/GridViewUtils';
import { transformToCurrency } from 'common/utils/NumberUtils.js';
import { resolveValueObject } from 'common/utils/ObjectUtils';
import { _formatAddressQBReport, combineString } from 'common/utils/ReportUtils';
import { caculateTimeAgo, formatUnixToTime, formatTimeUnix, formatTimeToHMS } from 'common/utils/TimeUtils';
import { getUrlCustomerPage } from 'common/utils/UrlUtils';
import moment from 'moment';
import React, { Fragment, useContext, useEffect, useReducer, useRef, useState } from 'react';
import { SketchPicker } from 'react-color';
import { useTranslation } from 'react-i18next';
import { Manager, Popper, Reference } from 'react-popper';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import ButtonSave from '../button/ButtonSave';
import SwitchCheckbox from '../checkbox/SwitchCheckbox';
import GridAssignBooking from './components/GridAssignBooking';
import { formatPhoneNumberVoip } from 'common/utils/PhoneUtils';
import { addBranchPath, getAvtColorBranch } from 'app/const/Branch';
import DropdownAssignedUsers from 'app/modules/addons/multipleBranch/manage/DropdownAssignedUsers';
import { MultipleBranchEdit } from 'app/modules/addons/multipleBranch/manage/MultipleBranchEdit';
import { STATUS_MULTI_BRANCH } from 'app/modules/addons/multipleBranch/const';
import { getAvatarCustomer, convertLineBreaks } from 'common/utils/StringUtils';
import { LIST_STATUS_PIPELINES } from 'app/const/setting/SettingPipelines';
import CardDate from '../payment/components/CardDate';
import { PAYMENT_MERCHANT_KEYWORD } from 'app/const/customer/CustomerPayment';
import ItemJobStatus from '../job/ItemJobStatus';
import i18n from 'assets/i18n';

const linkStyle = { cursor: 'pointer' };

const _renderAvatar = (link = '', userName = '') => {
    const isLinkAvailable = REGEX_URL.test(link);

    if (isLinkAvailable) {
        return <img src={link} alt={userName.concat(' avatar')} width={24} height={24}></img>;
    } else {
        return link;
    }
};

const ItemLabel = ({ label = '', defaultClassLabel = 'col-label' }) => {
    return (
        <div className={defaultClassLabel} title={label}>
            {label}
        </div>
    );
};

export const ColumnCustomerInfo = ({
    style,
    item,
    urlConfig,
    onClick,
    row,
    isColumnTeammate,
    isLink = true,
    id,
    isAccountNumber = false
}) => {
    const { teammate, customer } = item;
    const isCustomerId = !!customer.id;

    const renderCustomerCell = () => {
        const fullName = isColumnTeammate ? teammate?.full_name || '' : customer?.full_name || '';
        const accountNumber = isAccountNumber ? item.account_number : fullName;

        return isColumnTeammate ? (
            !!teammate && (
                <p className="name" title={accountNumber}>
                    {accountNumber}
                </p>
            )
        ) : (
            <p className={classNames({ 'col-label': !isLink, name: isLink && isCustomerId })} title={accountNumber}>
                {accountNumber}
            </p>
        );
    };

    if (urlConfig?.isUseOnClickFn) {
        return (
            <div className={style} onClick={() => onClick({ key: id, row })}>
                {renderCustomerCell()}
            </div>
        );
    }

    const { url, isOpenNewTab } = getUrlCustomerPage({
        baseUrl: urlConfig?.baseUrl,
        isOpenNewTab: urlConfig?.isOpenNewTab,
        customerId: item?.customer.id || ''
    });

    return isCustomerId ? (
        <Link
            to={addBranchPath(url)}
            className={classNames(style, { '--nodata': !teammate && isColumnTeammate })}
            target={isOpenNewTab ? '_blank' : ''}
        >
            {!item ? null : renderCustomerCell()}
        </Link>
    ) : (
        <div className={style}>{!item ? null : renderCustomerCell()}</div>
    );
};

export const ColumnCustomer = ({ style, value, item, key, handleClick, isLink }) => {
    let finalCharacter = item?.avatar_char;
    if (!finalCharacter && item.customer_name) {
        finalCharacter = item.customer_name.match(/\b(\w)/g);
        finalCharacter = finalCharacter.join('');
        finalCharacter = finalCharacter.substring(0, 2).toUpperCase();
    }

    if (isLink) {
        return (
            <div
                className={style}
                style={linkStyle}
                onClick={() => {
                    handleClick({ [key]: value });
                }}
            >
                <p className="col-label" title={value}>
                    {value}
                </p>
            </div>
        );
    }

    return (
        <div className={style}>
            <p className="name" title={value}>
                {value}
            </p>
        </div>
    );
};

export const ColumnLink = ({
    key,
    style,
    value,
    handleClick,
    row,
    styleText = '',
    defaultValue,
    isShowTagPdf = false
}) => {
    const { t } = useTranslation();
    const finalValue = Array.isArray(value) ? value[0] : value;

    return (
        <div className={style}>
            {defaultValue && !value ? (
                <p className="col-label">{defaultValue}</p>
            ) : !!styleText ? (
                <Fragment>
                    <p
                        className={styleText}
                        title={finalValue}
                        style={linkStyle}
                        onClick={() => handleClick({ key, row })}
                    >
                        {finalValue}
                    </p>
                    {isShowTagPdf ? <div className="budget --black-dark">{t('common:pdf')}</div> : null}
                </Fragment>
            ) : (
                <p>{value}</p>
            )}
        </div>
    );
};

export const ColumnPaymentFor = ({ style, value, key, handleClick, row, styleText }) => {
    const { t } = useTranslation(['report']);
    return (
        <>
            {value ? (
                <div
                    className={style}
                    style={linkStyle}
                    onClick={() => {
                        handleClick({ [key]: value, row: row });
                    }}
                >
                    <p className={styleText} title={value}>
                        {t('report:invoice').concat(' ', value)}
                    </p>
                </div>
            ) : (
                <div className={style}>{t('report:credit')}</div>
            )}
        </>
    );
};

export const ColumnAllPaymentFor = ({ style, item, handleClick, value }) => {
    const invoiceNumber = item.invoice_number;

    const _handleClickInvoice = () => {
        if (!invoiceNumber) return;
        handleClick(item);
    };

    return (
        <div className={style} style={invoiceNumber ? linkStyle : null} onClick={_handleClickInvoice}>
            <span className="col-label mr-1">{value}</span>
            {invoiceNumber ? <span className="name flex-1">{invoiceNumber}</span> : null}
        </div>
    );
};

export const ColumnList = ({ style, value, key, handleClick, row }) => {
    return (
        <div className={style}>
            {value.map((item, index) => {
                if (key === COMMON.SERVICE_NAME && !index) {
                    return (
                        <div
                            key={index.toString()}
                            className="col-label name mb-1"
                            onClick={() => handleClick({ key, row })}
                        >
                            {item}
                        </div>
                    );
                }
                return (
                    <div key={index.toString()} className={classNames('col-label', { 'mb-1': !index })}>
                        &nbsp;{item}
                    </div>
                );
            })}
        </div>
    );
};

export const ColumnListContacts = ({ style, value }) => {
    const { t } = useTranslation(['common']);

    const _renderListContacts = (listContacts = []) => {
        return listContacts?.map((item) => {
            const {
                first_phone: firstPhone,
                second_phone: secondPhone,
                first_name = '',
                last_name = '',
                email = ''
            } = item;
            const phoneAddress = formatPhoneNumberVoip(typeof firstPhone === 'string' ? firstPhone : firstPhone?.phone);
            const phoneBilling = formatPhoneNumberVoip(
                typeof secondPhone === 'string' ? secondPhone : secondPhone?.phone
            );

            return (
                <>
                    <p className="col-label">
                        <b>{t(`first_name`)}</b>: <span title={first_name}>{first_name}</span>
                    </p>
                    <p className="col-label">
                        <b>{t(`last_name`)}</b>: <span title={last_name}>{last_name}</span>
                    </p>
                    <p className="col-label">
                        <b>{t(`email`)}</b>: <span title={email}>{email}</span>
                    </p>
                    <p className="col-label">
                        <b>{t(`first_phone`)}</b>: <span title={phoneAddress || ''}>{phoneAddress || ''}</span>
                    </p>
                    <p className="col-label">
                        <b>{t(`second_phone`)}</b>: <span title={phoneBilling || ''}>{phoneBilling || ''}</span>
                    </p>
                </>
            );
        });
    };

    return <div className={style}>{_renderListContacts(value)}</div>;
};

export const ColumnListLocations = ({ style, value }) => {
    const _renderListLocations = (listLocations = []) => {
        return listLocations?.map((item, index) => {
            const { address_to = '', street_1 = '', street_2 = '', city = '', state = '', zip = '' } = item || {};
            let streetCity = '';
            let stateZip = '';

            if (address_to) streetCity += `${address_to} ${street_1} ${street_2} ${city},`;
            if (state) stateZip += `${state}`;
            if (zip) stateZip += ` ${zip}`;

            return (
                <p key={index.toString()} className="col-label" title={`${streetCity} ${stateZip}`}>
                    {`${streetCity} ${stateZip}`}
                </p>
            );
        });
    };

    return <div className={style}>{_renderListLocations(value)}</div>;
};

export const ColumnListActiveJobs = ({ style, value }) => {
    const _renderListActiveJobs = (listActiveJobs) => {
        return listActiveJobs?.map((item, index) => (
            <p key={index.toString()} className="word-break">
                {item}
            </p>
        ));
    };

    return <div className={style}>{_renderListActiveJobs(value)}</div>;
};

export const ColumnRating = (style, value, item) => {
    const rating_name = item?.rating_name;

    const _renderStars = (value) => {
        const number = parseInt(value);
        return ARRAY_STAR.map((item) => {
            return <i key={item.toString()} className={`icon outline-star ${item <= number && 'active'}`}></i>;
        });
    };

    if (rating_name) {
        return (
            <div className={style}>
                <div>
                    {_renderStars(value)}
                    <span className="orange-default">{rating_name || ''}</span>
                </div>
            </div>
        );
    }

    return <div className={style}>{value}</div>;
};

export const ColumnRatingCounting = (style, value) => {
    const ratingCount = parseInt(value).toFixed(1);

    const _renderStars = (value) => (
        <div className="rate not-effect">
            {ARRAY_STAR.map((item, index) => (
                <div key={index} className={`rate__items${item <= parseInt(value) ? ' active' : ''}`}>
                    <IconStar color="#FF871E" />
                </div>
            ))}
        </div>
    );

    return (
        <div className={style}>
            <p className="label orange-default">{ratingCount}</p>
            {_renderStars(value)}
        </div>
    );
};

export const ColumnRatingCountingText = (style, value) => {
    const { t } = useTranslation();
    const ratingCount = parseInt(value);

    return (
        <div className={style}>
            <p className="label">
                {ratingCount} {ratingCount === 0 && `(${t('not_likely')})`}{' '}
                {ratingCount === 10 && `(${t('very_likely')})`}
            </p>
        </div>
    );
};

export const ColumnEllipsis = (style, value, key, handleClick, isLink, list) => {
    const renderListSubValue = () => {
        if (list) {
            return list.map((item, index) => {
                return (
                    <div key={index.toString()} className="txt-ellipsis" title={value}>
                        {item}
                    </div>
                );
            });
        }
        return null;
    };

    if (isLink) {
        return (
            <div
                className={style}
                style={linkStyle}
                onClick={() => {
                    handleClick({ [key]: value });
                }}
            >
                <div className="txt-ellipsis pb-1 violet-name" title={value}>
                    {value}
                </div>
                {renderListSubValue()}
            </div>
        );
    }

    return (
        <div className={style}>
            <div className="txt-ellipsis pb-1" title={value}>
                {value}
            </div>
            {renderListSubValue()}
        </div>
    );
};

export const ColumnDescription = (style, value, fileTranslation = 'report') => {
    const { t } = useTranslation([fileTranslation]);
    const containerDescRef = useRef(null);
    const contentRef = useRef(null);
    const showMoreRef = useRef(null);

    useEffect(() => {
        const contentHeight = contentRef.current?.clientHeight;
        const showMoreText = showMoreRef.current;

        if (contentHeight && showMoreText) {
            showMoreText.style.display = contentHeight >= REPORT_LIMIT_HEIGHT_COLUMN ? 'inline' : 'none';
        }
    }, [value]);

    const _toggleIsMore = () => {
        containerDescRef.current && containerDescRef.current.classList.toggle('is-more');
        const showMoreText = showMoreRef.current;

        if (showMoreText) {
            showMoreText.innerHTML = t(`${fileTranslation}:${_checkContainClassIsMore('show_less', 'show_more')}`);
        }
    };

    const _checkContainClassIsMore = (correctText = '', wrongText = '') => {
        return containerDescRef.current && containerDescRef.current.classList.contains('is-more')
            ? correctText
            : wrongText;
    };

    return (
        <div className={style} ref={containerDescRef}>
            <div
                ref={contentRef}
                className={classNames('col-label content', _checkContainClassIsMore('is-more', ''))}
                title={value}
            >
                {value}
            </div>

            <span
                ref={showMoreRef}
                className="purple-default is-link"
                style={{ display: 'none' }}
                onClick={_toggleIsMore}
            >
                {t(`report:${_checkContainClassIsMore('show_less', 'show_more')}`)}
            </span>
        </div>
    );
};

export const ColumnEmailStatus = (style, value, item = {}) => {
    const { t } = useTranslation(['report']);
    const deliveryMessage = item.delivery_message;
    const isRejected = !!deliveryMessage;
    const itemId = item.id;

    const _createTolltip = (id) => {
        handleCreatePopperAttach(`status-tooltip-${id}`, `tooltip-item-${id}`);
    };

    return (
        <div className={style}>
            <div
                className={classNames('status-btn', {
                    [value === COMMON.N_A ? 'na' : value?.replace('_', '-').toLowerCase()]: value,
                    tooltip: isRejected
                })}
                id={`status-tooltip-${itemId}`}
                onMouseOver={() => _createTolltip(itemId)}
            >
                {value === COMMON.PAST_DUE ? t('report:past_due') : t(`report:${value}`)}
                {isRejected && (
                    <p className="tooltiptext" id={`tooltip-item-${itemId}`}>
                        {deliveryMessage}
                    </p>
                )}
            </div>
        </div>
    );
};

export const ColumnDefault = (style, value, defaultValue, additionalStyle = '') => {
    const valueDisplay = defaultValue && !value ? defaultValue : value;
    return (
        <div className={style}>
            {valueDisplay || typeof valueDisplay === 'number' ? (
                <p className={additionalStyle || 'col-label'} title={typeof value === 'string' ? value : ''}>
                    {valueDisplay}
                </p>
            ) : null}
        </div>
    );
};

export const ColumnCheckBox = (
    style,
    value,
    checked,
    handleClick,
    isDisableCheckBox = false,
    isShowCheckBox = true
) => {
    return (
        <div className={classNames(style, { 'cursor-not-allowed': isDisableCheckBox })}>
            {!isShowCheckBox ? null : isDisableCheckBox ? (
                <Checkbox checked={false} onChangeValue={() => {}} value={value} disabled={isDisableCheckBox} />
            ) : (
                <Checkbox checked={checked} onChangeValue={handleClick} value={value} />
            )}
        </div>
    );
};

export const ColumnCheckBoxSingle = (style, value, checked, handleClick) => {
    return (
        <div className={style}>
            <Checkbox checked={checked} onChangeValue={handleClick} value={value} />
        </div>
    );
};

export const ColumnSwitch = ({ item, style, handleClick }) => {
    return (
        <div className={style}>
            <div className="wrap-type-commission">
                <div className="btn-item">
                    <div
                        onClick={() => {
                            handleClick(
                                {
                                    ...item,
                                    type: LIST_COMMISSION_SETTING.type.id,
                                    value: item.commission_value
                                },
                                LIST_COMMISSION_TYPE[1]
                            );
                        }}
                        className={`items ${item?.commission_type === LIST_COMMISSION_TYPE[1] && 'active'}`}
                    >
                        %
                    </div>
                    <div
                        onClick={() => {
                            handleClick(
                                {
                                    ...item,
                                    type: LIST_COMMISSION_SETTING.type.id,
                                    value: item.commission_value
                                },
                                LIST_COMMISSION_TYPE[0]
                            );
                        }}
                        className={`items ${item?.commission_type === LIST_COMMISSION_TYPE[0] && 'active'}`}
                    >
                        $
                    </div>
                </div>
            </div>
        </div>
    );
};

export const ColumnInput = ({ item, style, onSubmit }) => {
    const [currentValue, setCurrentValue] = useState(item.commission_value);

    const handleChangeValue = (value) => {
        setCurrentValue(value);
    };

    const handlePress = (e) => {
        if (e.charCode === ENTER_KEY_CODE) {
            handleSubmit();
        }
    };

    const handleBlur = () => {
        if (currentValue !== item.commission_value) {
            handleSubmit();
        }
    };

    const handleSubmit = () => {
        onSubmit(
            {
                ...item,
                type: LIST_COMMISSION_SETTING.value.id,
                value: currentValue
            },
            item.commission_type
        );
    };
    return (
        <div className={style}>
            <div className="value-commissions">
                <input
                    type="number"
                    name="value-commissions"
                    value={currentValue}
                    onChange={(e) => handleChangeValue(e.target.value)}
                    onKeyPress={handlePress}
                    onBlur={handleBlur}
                    spellCheck
                />
                <span>{item?.commission_type}</span>
            </div>
        </div>
    );
};

export const ColumnListPhone = ({
    style,
    value,
    isGetFirstPhone = false,
    customer,
    typeOpen,
    isUsePopup = false,
    customerId
}) => {
    if (!value) return <div className={style}></div>;
    if (isGetFirstPhone)
        return (
            <div className={style}>
                <GdPhoneItem
                    isValid={false}
                    phoneType={''}
                    phone={value?.[0]?.phone || ''}
                    customer={customer}
                    typeOpen={typeOpen}
                    isUsePopup={isUsePopup}
                    contactId={value[0]?.contactId || ''}
                />
            </div>
        );
    return !Array.isArray(value) || isGetFirstPhone ? (
        <div className={style}>
            <GdPhoneItem
                isValid={value?.is_valid ?? false}
                phoneType={value?.phone_type ?? ''}
                phone={value?.phone_number ?? value}
                customer={customer}
                typeOpen={typeOpen}
                isUsePopup={isUsePopup}
                contactId={value.contact_id}
            />
        </div>
    ) : (
        <div className={style}>
            {value.map((item, index) => {
                const phoneValue = item.phone || item.number || '';
                const phoneType = item.type || item.phone_type || '';
                const phoneContact = item.contact_id || item.contactId || '';
                if (phoneValue)
                    return (
                        <GdPhoneItem
                            key={item.id || index.toString()}
                            isValid={false}
                            phoneType={phoneType}
                            phone={phoneValue}
                            customer={customer}
                            typeOpen={typeOpen}
                            isUsePopup={isUsePopup}
                            contactId={phoneContact}
                            customerId={customerId}
                        />
                    );
                return null;
            })}
        </div>
    );
};

// use for Report Recurring Invoices
export const ColumnListNextInvoice = ({ style, handleClick, invoiceInfo, invoiceNumber, invoiceId }) => {
    return (
        <div className={style}>
            <span
                className="name mr-1"
                onClick={() => {
                    handleClick(invoiceId);
                }}
            >
                {invoiceNumber}
            </span>
            <span className="col-label">{invoiceInfo}</span>
        </div>
    );
};

export const ColumnPaymentMethod = ({ style, item }) => {
    const payment_card = item?.payment_card;
    return (
        <div className={style}>
            {!Array.isArray(payment_card) && typeof payment_card === 'object' ? (
                <div className="info-card">
                    <div className="card-number">
                        <p className="card-number__label">{payment_card?.merchant}</p>
                        <div className="number">
                            <p className="number__digit txt-ellipsis">{payment_card?.account_number}</p>
                        </div>
                        <CardDate {...payment_card} />
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export const ColumnListTags = ({ style, item }) => {
    if (item) {
        const { location, customer } = item;
        return (
            <div className={style}>
                {!!location.length && (
                    <div className="wrap-tag">
                        <span className="wrap-tag__label wrap-normal">Location: </span>
                        {location.map((tag, index) => {
                            return (
                                <p key={index.toString()} className="tag-label wrap-normal">
                                    {tag}
                                </p>
                            );
                        })}
                    </div>
                )}
                {!!customer.length && (
                    <div className="wrap-tag">
                        <span className="wrap-tag__label">Customer: </span>
                        {customer.map((tag, index) => {
                            return (
                                <p key={index.toString()} className="tag-label wrap-normal">
                                    {tag}
                                </p>
                            );
                        })}
                    </div>
                )}
            </div>
        );
    }
    return <div className={style}></div>;
};

export const ColumnListEmail = ({ style, item }) => {
    const billingEmails = item.billing_email;
    const email = item.email || '';

    return Array.isArray(billingEmails) ? (
        <div className={style}>
            {billingEmails.map((billingEmail, index) => {
                return (
                    <p className="col-label" key={index.toString()} title={billingEmail}>
                        {billingEmail}
                    </p>
                );
            })}
        </div>
    ) : (
        <div className={style}>
            <p className="col-label" title={email}>
                {email}
            </p>
        </div>
    );
};

export const ColumnListEmailArray = ({ style, item }) => {
    const { billing_emails: billingEmails = [], emails = [] } = item;

    if (!billingEmails.length && !emails.length) {
        return <div className={style} />;
    }

    return billingEmails.length !== 0 ? (
        <div className={style}>
            {billingEmails.map((billingEmail) => {
                return (
                    <p className="txt-ellipsis" key={billingEmail.id} title={billingEmail.email}>
                        {billingEmail.email}
                    </p>
                );
            })}
        </div>
    ) : (
        emails.map((currentEmail) => {
            return (
                <p className="txt-ellipsis" key={currentEmail.id} title={currentEmail.email}>
                    {currentEmail.email}
                </p>
            );
        })
    );
};

export const ColumnCurrency = ({ style, value, handleClick = () => {}, idRow, idCol, currency }) => {
    const cellId = `${idRow}_${idCol}`;
    const [isOpen, setIsOpen] = useState(sessionStorage.getItem(KEY_CURRENCY_COLUMNS) === cellId);
    const refDropDown = useRef(null);

    useEffect(() => {
        if (isOpen) {
            _updateSessionCurrency(true);
            document.addEventListener('click', _handleClickOutside, true);
            document.addEventListener('keydown', _handleHideDropdown, true);
        } else {
            document.removeEventListener('click', _handleClickOutside, true);
            document.removeEventListener('keydown', _handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', _handleClickOutside, true);
            document.removeEventListener('keydown', _handleHideDropdown, true);
        };
    }, [isOpen]);

    const _updateSessionCurrency = (isSet = false) => {
        changeStyleRowsDropdown(idRow, isSet);
        isSet ? sessionStorage.setItem(KEY_CURRENCY_COLUMNS, cellId) : sessionStorage.removeItem(KEY_CURRENCY_COLUMNS);
    };

    const _handleHideDropdown = (event) => {
        if (event.keyCode === KEY_CODE_ESCAPE) {
            _closeDropdown();
        }
    };

    const _handleClickOutside = (event) => {
        if (refDropDown.current && !refDropDown.current.contains(event.target)) {
            _closeDropdown();
        }
    };

    const _closeDropdown = () => {
        if (isOpen) {
            setIsOpen(false);
            _updateSessionCurrency();
        }
    };

    const _handleToggleDropDown = () => {
        setIsOpen(!isOpen);
        isOpen && _updateSessionCurrency();
    };

    const _handleOnClick = (e, value) => {
        e && e.stopPropagation();
        setIsOpen(!isOpen);
        handleClick(value);
    };

    const numericValue = parseFloat(value?.total || 0);
    const finalValue = transformToCurrency(numericValue, currency);

    const _renderListInvoice = (listInvoice) => {
        if (!Array.isArray(listInvoice) || !listInvoice?.length) return null;

        return (
            <Popper
                placement={'bottom-end'}
                strategy="absolute"
                modifiers={[{ name: 'offset', options: { offset: [0, 5] } }]}
            >
                {({ ref, style, placement }) => (
                    <div className="currency__list scrolls" ref={ref} style={style} data-placement={placement}>
                        {listInvoice.map((invoice) => {
                            const invoiceNumber = `#${invoice?.number}`;
                            return (
                                <div
                                    onClick={(e) => {
                                        _handleOnClick(e, invoice?.id);
                                    }}
                                    key={invoice?.id}
                                    className="items"
                                    title={invoiceNumber}
                                >
                                    <p className="txt-ellipsis"> {invoiceNumber}</p>
                                </div>
                            );
                        })}
                    </div>
                )}
            </Popper>
        );
    };

    return (
        <Manager>
            <div className={classNames(style, { '--empty': !numericValue })}>
                <div
                    ref={refDropDown}
                    onClick={_handleToggleDropDown}
                    className={classNames('--dayfield', { active: isOpen, currency: numericValue })}
                >
                    <Reference>
                        {({ ref }) => (
                            <div
                                className="currency__items svg-chevron purple"
                                ref={ref}
                                onClick={(e) => e.preventDefault()}
                            >
                                <p className="txt-ellipsis" title={finalValue}>
                                    {finalValue}
                                </p>
                                {numericValue ? <IconArrowDown /> : ''}
                            </div>
                        )}
                    </Reference>
                    {isOpen && _renderListInvoice(value?.invoice)}
                </div>
            </div>
        </Manager>
    );
};

const PaymentCard = ({ item }) => {
    const { type, number, exp_month = '', exp_year = '', name: nameCard } = item;
    let isExpiry = exp_year < YEAR_CURRENT;
    if (exp_year == YEAR_CURRENT) {
        isExpiry = exp_month < CURRENT_MONTH;
    }
    const finalType = type === PAYMENT_MERCHANT_KEYWORD.BANK_ACCOUNT ? nameCard : type;

    return (
        <div className="info-card">
            <div className="card-number">
                <p className="card-number__label txt-ellipsis" title={finalType}>
                    {finalType}
                </p>
                <div className="number">
                    <p className="number__dots">••••</p>
                    <p className="number__digit txt-ellipsis">{number}</p>
                </div>
                <CardDate className={classNames('date', { '--expired': isExpiry })} {...item} />
            </div>
        </div>
    );
};

const renderPaymentCard = (list) => {
    if (Array.isArray(list)) return list.map((item, index) => <PaymentCard key={index.toString()} item={item} />);
    return false;
};

export const ColumnPaymentCard = ({ style, item }) => {
    return <div className={style}>{renderPaymentCard(item?.payment_card)}</div>;
};

export const ColumnPaymentCardWithAction = ({ style, item }) => {
    const paymentCard = item?.payment_card;
    return (
        <div className={style}>
            {paymentCard && <PaymentCard item={paymentCard} />}
            <div className="col-label">{item?.action}</div>
        </div>
    );
};

export const ColumnEstimateStatus = ({ style, item, onOpenInvoice }) => {
    const { t } = useTranslation(['report']);
    const invoiceNumber = item.invoice_number || item?.invoice?.number || '';
    const status = item.status || '';

    return (
        <div className={style}>
            <div className={`status-btn ${status && status.replace('_', '-').toLowerCase()}`}>
                {status === WON_STATUS && invoiceNumber ? (
                    <>
                        {`${status} / `}
                        <span
                            title={`${t('report:invoiced')} # ${invoiceNumber}`}
                            className="cursor-pointer"
                            onClick={() => {
                                onOpenInvoice(item);
                            }}
                        >
                            {`${t('report:invoiced')} # ${invoiceNumber}`}
                        </span>
                    </>
                ) : (
                    status
                )}
            </div>
        </div>
    );
};

export const ColumnCreatedBy = ({ style, item, isEmpty = false, companyUsers }) => {
    if (isEmpty) return <div className={style}></div>;
    let createdBy = null;
    if (typeof item === 'object') {
        createdBy = item;
    } else {
        createdBy = ADDED_BY_SPECIAL_LIST.find((addedBy) => addedBy.id === item);
        if (!createdBy) {
            createdBy = companyUsers.find((user) => user.id === item);
        }
    }
    const fullName = createdBy?.full_name ?? i18n.t('report:user_deleted');

    return (
        <div className={style}>
            <div className="col__customer">
                <div className="avt-img">
                    {createdBy?.avatar ? (
                        <GdAvatar src={createdBy?.avatar} alt={fullName} width="24" height="24" />
                    ) : (
                        <div className="avt">{fullName.substring(0, 2)}</div>
                    )}
                </div>
                <span className="col-label" title={fullName}>
                    {fullName}
                </span>
            </div>
        </div>
    );
};

export const ColumnJobsStatus = ({ style, item }) => {
    const { t } = useTranslation(['report']);
    const finalJobStatusId = (item?.job_status_id ?? item?.job_status ?? item?.job?.status ?? item?.status)?.toString();

    return (
        <div className={style}>
            {!!finalJobStatusId ? (
                <ItemJobStatus idStatus={finalJobStatusId} excludeWrapper />
            ) : (
                <div className="status-btn na">{t('report:na')}</div>
            )}
            {!!item.is_workpool ? <span>({t('report:work_pool')})</span> : null}
        </div>
    );
};

export const ColumnCustomerStatus = ({ style, item }) => {
    const status = item.status || item.invoice_status;
    return (
        <div className={style}>
            <div className={`status-btn ${status ? status.toLowerCase() : 'na'}`}>{status || 'n/a'}</div>
        </div>
    );
};

export const ColumnCreditStatus = ({ style, item }) => {
    const creditStatus = item.status;
    return (
        <div className={style}>
            {Array.isArray(creditStatus)
                ? creditStatus.map((item, index) => {
                      return (
                          <div
                              key={index}
                              className={classNames('status-btn', {
                                  'available white': !item.applied,
                                  'available-other': !!item.applied
                              })}
                          >
                              {item.description}
                          </div>
                      );
                  })
                : null}
        </div>
    );
};

export const ColumnCustomerCredit = ({ style, item, handleClick = () => {} }) => {
    return (
        <div className={style}>
            <p className="col-label" title={item.method} onClick={() => handleClick(item)}>
                {item.method}
            </p>
        </div>
    );
};

export const ColumnQuickBookId = ({ style, item, isEmpty = false, onClick = () => {} }) => {
    const _handleClick = () => {
        onClick(item);
    };
    return (
        <div className={style}>
            {isEmpty ? null : item.qb_id ? (
                <span className="col-label has-sync">{item.qb_id}</span>
            ) : (
                <div className="v2-btn-default --purple ml-n1 px-1" onClick={_handleClick}>
                    Find
                </div>
            )}
        </div>
    );
};

export const ColumnCreditCard = ({ cards = [] }) => {
    return (
        <div className="col --card">
            {cards.map((card, index) => (
                <PaymentCard item={card} key={index.toString()} />
            ))}
        </div>
    );
};

export const ColumnBalance = ({
    style,
    value,
    styleText,
    isLink,
    handleClick,
    key,
    row,
    currency,
    isMethod = false,
    item
}) => {
    const numericValue = parseFloat(value);
    const finalValue = transformToCurrency(numericValue, currency);
    const methodValue = isMethod ? `${finalValue} ${item?.method || ''}` : finalValue;
    const refText = useRef(null);

    const onHandleClick = () => {
        if (numericValue && isLink) {
            handleClick({ key, row });
            refText.current.className = 'class-balance-selected price-btn selected';
        }
    };

    const _renderLinkText = () => {
        return (
            <p
                ref={refText}
                className={`${numericValue ? 'class-balance-selected price-btn txt-ellipsis' : 'col-label'}`}
                title={methodValue}
                onClick={onHandleClick}
            >
                {methodValue}
            </p>
        );
    };
    const _renderText = () => {
        return (
            <p className={`${styleText || 'col-label'}`} title={methodValue}>
                {methodValue}
            </p>
        );
    };

    return (
        <div className={`${style} ${numericValue ? '' : '--empty'}`}>{isLink ? _renderLinkText() : _renderText()}</div>
    );
};

export const ColumnTags = ({ style, tags = [] }) => {
    const _renderTagList = () => {
        return tags.map((tag) => {
            return (
                <p key={tag} className="tag-label">
                    {tag}
                </p>
            );
        });
    };

    return <div className={style}>{_renderTagList()}</div>;
};

export const ColumnLinkPaymentMethod = ({ style, value, key, handleClick, row, styleText }) => {
    const canEdit = row.is_default === 1 ? true : false;
    const newClassName = canEdit ? style.concat(' --name') : style;

    const _handleClick = () => {
        canEdit && handleClick({ [key]: value, row: row });
    };

    return (
        <div className={newClassName} style={canEdit ? linkStyle : null} onClick={_handleClick}>
            <p className={styleText} title={value}>
                {value}
            </p>
        </div>
    );
};

export const ColumnParseHTML = ({ value, style }) => {
    return <div className={style} dangerouslySetInnerHTML={{ __html: value }}></div>;
};

export const ColumnTaxes = ({ value, style }) => {
    return (
        <div className={style}>
            <div className="col-label"> {value?.name}</div>
        </div>
    );
};
export const ColumnEmailCustomer = ({ item, style }) => {
    const { emails } = item;
    return (
        <div className={style}>
            {Array.isArray(emails) &&
                emails.map((data, index) => {
                    const { email } = data;
                    return (
                        <div
                            key={index.toString()}
                            className={classNames('txt-ellipsis', { 'red-active': data.is_blacklisted })}
                            title={email}
                        >
                            {email}
                        </div>
                    );
                })}
        </div>
    );
};
export const ColumnCustomerEdit = ({ item, style, onEdit, isEmpty = false, isLink = false, title }) => {
    const { t } = useTranslation(['report']);
    const { method_name, invoice_id } = item;

    const _handleClick = (e) => {
        if (onEdit) {
            e.preventDefault();
            onEdit(item);
        }
    };

    /**
     * in Customer edit will not show if !!invoice_id && method_name === PAYMENT_METHODS.CREDIT
     * in all payment will not show if !!!invoice_id
     */
    const isHideEdit = !title
        ? isLink && !!invoice_id && method_name === PAYMENT_METHODS.CREDIT
        : isLink && !!!invoice_id;
    const finalTitle = title || 'edit';

    return (
        <div className={style}>
            {isEmpty
                ? null
                : !isHideEdit && (
                      <Link
                          title={t(finalTitle)}
                          to={addBranchPath(`/customers/account/${item.id}`)}
                          onClick={_handleClick}
                          className="v2-btn-default --purple px-1"
                      >
                          {t(finalTitle)}
                      </Link>
                  )}
        </div>
    );
};
export const ColumnCustomerPaymentFor = ({ item, style, handleClick }) => {
    const { key = 1, invoice_number = '' } = item || {};

    if (!invoice_number) {
        return (
            <div className={style}>
                <span className="col-label">{TYPE_PAYMENT_FOR[key]}</span>
            </div>
        );
    }

    return (
        <div
            className={style}
            onClick={() => {
                handleClick(item);
            }}
        >
            <span className="col-label">{TYPE_PAYMENT_FOR[key]}</span>
            <span className="name ml-1">{invoice_number}</span>
        </div>
    );
};
export const ColumnTwoRowText = ({ item, style, firstKey = '', secondKey = '' }) => {
    const firstKeyValue = resolveValueObject(firstKey, item);
    const secondKeyValue = resolveValueObject(secondKey, item);
    return (
        <div className={style}>
            <div className="col-label" title={firstKeyValue}>
                {firstKeyValue}
            </div>
            <div className="col-label" title={secondKeyValue}>
                {secondKeyValue}
            </div>
        </div>
    );
};
export const ColumnText = ({ item, style, keyGetValue }) => {
    return (
        <div className={style}>
            <div className="txt-ellipsis">{resolveValueObject(keyGetValue, item)}</div>
        </div>
    );
};
export const ColumnInvoice = ({ style, item, onOpenInvoice = () => {} }) => {
    return (
        <div className={style}>
            <p className="col-label" title={item.number} onClick={() => onOpenInvoice(item)}>
                {item.number}
            </p>
        </div>
    );
};

export const ColumnJob = ({ item, style, noneLocation = false, onOpenJob = () => {} }) => {
    const { name = '', location = '' } = item;
    return (
        <div className={style}>
            <div className="btn-modal col-label" onClick={() => onOpenJob(item.id)} title={name}>
                {name}
            </div>
            {!noneLocation && (
                <div className="word-break" title={location}>
                    {location}
                </div>
            )}
        </div>
    );
};
export const ColumnJobFrequency = ({ item, style }) => {
    const { t } = useTranslation('report');
    const itemJobFrequency = item?.job_frequency;
    const { frequency = '', exception_text = '', repeat_text = '' } = item?.job_recurrence || itemJobFrequency || {};

    return (
        <div className={style}>
            {!!itemJobFrequency ? (
                <ItemLabel label={`${repeat_text} ${exception_text}`} />
            ) : (
                <Fragment>
                    {!!item?.has_linked_job ? (
                        <div className="linked tooltip">
                            <IconLink />
                            <p className="tooltiptext top">{t('tooltip_linked_job')}</p>
                        </div>
                    ) : null}
                    <ItemLabel label={frequency} />
                    <ItemLabel label={repeat_text} />
                    <ItemLabel label={exception_text} defaultClassLabel="col-label red-default" />
                </Fragment>
            )}
        </div>
    );
};

export const ColumnJobStatusTab = ({ value, style }) => {
    const { t } = useTranslation(['report']);
    const statusFinal = JOB_STATUS_OPTION_TAB[value] || 'active';
    return (
        <div className={style}>
            <div className={`status-btn ${statusFinal}`}>{t(`${statusFinal}`)}</div>
        </div>
    );
};

export const ColumnServiceFrequency = ({ item }) => {
    const { recurrence, repeat_on, except } = item.frequency || {};

    return (
        <div className="col --frequence">
            <div className="col__customer">
                <span className="col-label">{recurrence}</span>
                <span className="detail col-label" title={repeat_on}>
                    {repeat_on}
                </span>
                <span className="detail col-label red-default" title={except}>
                    {except}
                </span>
            </div>
        </div>
    );
};

export const ColumnInvoiceFrequency = ({ item, style }) => {
    return (
        <div className={style}>
            <p className="col-label fw-600">{item?.frequency?.frequency}</p>
            <p className="col-label">{item?.frequency?.repeat_text}</p>
        </div>
    );
};

export const ColumnServiceColor = ({ style, item, idRow, idCol, isDelete }) => {
    const cellId = `${idRow}_${idCol}`;
    const setSessionStorage = window.sessionStorage.getItem(KEY_COLOR_SERVICE) === cellId;
    const [state, dispatchState] = useReducer(reducer, {
        isVisible: setSessionStorage,
        data: item
    });
    const finalIsVisible = state.isVisible;
    const colorCode = state.data.color_code || '';
    const refDropdown = useRef(null);
    const refColor = useRef(colorCode);

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById(cellId);
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeColorPicker();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById(cellId);
        if (
            refDropdown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropdown.current.contains(event.target)
        ) {
            _closeColorPicker();
        }
    }

    function _closeColorPicker() {
        finalIsVisible &&
            dispatchState({
                isVisible: false
            });
    }

    const _handleUpdateColor = () => {
        if (isDelete) return;
        dispatchState({ isVisible: !finalIsVisible });
    };

    function _handleChangeColor(color) {
        const colorSelected = color.hex || '';
        if (colorSelected !== refColor.current) {
            clientQuery(
                updateColorSettingService(item.id),
                { data: { color: colorSelected }, method: 'PUT' },
                _handleChangeColorSuccess(colorSelected)
            );
        }
    }

    const _handleChangeColorSuccess = (data) => {
        refColor.current = data;
        refColor.current = data;
        dispatchState({ data: { ...state.data, color_code: data } });
    };

    const _handleChange = (color_code) => {
        dispatchState({ data: { ...state.data, color_code } });
    };

    function _renderContent() {
        if (!colorCode) return null;
        return (
            <Manager>
                <div id={cellId} ref={refDropdown} className="flexcenter">
                    <Reference>
                        {({ ref }) => (
                            <div ref={ref}>
                                <div
                                    className={classNames('color', { 'cursor-not-allowed': isDelete })}
                                    onClick={() => _handleUpdateColor(item)}
                                    style={{ background: colorCode }}
                                    ref={ref}
                                />
                            </div>
                        )}
                    </Reference>

                    <div className="code" style={{ color: colorCode }}>
                        {colorCode}
                    </div>
                    {finalIsVisible && (
                        <Popper
                            placement={'bottom-end'}
                            strategy="fixed"
                            modifiers={[{ name: 'offset', options: { offset: [0, 5] } }]}
                        >
                            {({ ref, style, placement }) => (
                                <div ref={ref} style={style} data-placement={placement} className="dropdown-color">
                                    <div className="details details-color">
                                        <div className="choose-color">
                                            <div className="color-picker color-picker__inactive">
                                                <SketchPicker
                                                    disableAlpha
                                                    color={colorCode}
                                                    onChangeComplete={_handleChangeColor}
                                                    onChange={({ hex }) => {
                                                        _handleChange(hex);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Popper>
                    )}
                </div>
            </Manager>
        );
    }

    return <div className={style}>{_renderContent()}</div>;
};

export const ColumnServiceName = ({ style, item, onClickItem, isDelete }) => {
    const name = item.name;

    return (
        <div className={style}>
            {isDelete ? (
                <p title={name}>{name}</p>
            ) : (
                <p className="col-label" title={name} onClick={() => onClickItem(item)}>
                    {name}
                </p>
            )}
        </div>
    );
};

export const ColumnListAssignee = ({ style, item }) => {
    return (
        <div className={style}>
            {item?.assignees?.map((user, index) => {
                const { user_id = '', id, name = '' } = user;
                if (!name && !user_id) return null;
                if (user_id) return <ColumnUser key={`${id}+${index}`} {...user} />;
                return (
                    <div key={`${id}+${index}`} className="col__customer">
                        <div className="avt fs-11">{name.substring(0, 2)}</div>
                        <span className="col-label" title={name}>
                            {name}
                        </span>
                    </div>
                );
            })}
        </div>
    );
};
export const ColumnUser = ({ user_id, name }) => {
    const users = useSelector(({ companyUsers }) => companyUsers.users);
    const user = users.find((item) => item.id === user_id);

    const _renderAvatar = (avatar) => {
        return avatar ? (
            <img src={avatar} width={24} height={24} alt="" />
        ) : (
            <div className="avt">{name.substring(0, 2)}</div>
        );
    };

    return (
        <div className="col__customer">
            <div className="avt-img">{_renderAvatar(user?.avatar || null)}</div>
            <span className="col-label" title={name}>
                {name}
            </span>
        </div>
    );
};

export const ColumnEditRecurring = ({ style, item, handleClick = () => {} }) => {
    const { t } = useTranslation(['report']);
    const refDropdown = useRef(null);

    const _onClick = (mode) => {
        handleClick({ mode, item });
    };

    const _renderPopper = () => {
        return (
            <>
                <ul>
                    <li onClick={() => _onClick('view')}>
                        <div className="items btn-modal">{t('report:view')} </div>
                    </li>
                    <li onClick={() => _onClick('edit')}>
                        <div className="items btn-modal">{t('report:edit')}</div>
                    </li>
                    <li onClick={() => _onClick('delete')}>
                        <div className="items btn-modal">{t('report:delete')}</div>
                    </li>
                </ul>
            </>
        );
    };

    return (
        <div className={style}>
            <DropdownPopper
                ref={refDropdown}
                isCheckHidden
                wrapperClassName="v2-dropdown"
                wrapperListClass="v2-dropdown__menu scrolls"
                dropdownIcon={<IconThreeDots />}
                buttonClassName="dropbtn v2-btn-default --icon-sm --transparent"
                isUseToggle
            >
                {_renderPopper()}
            </DropdownPopper>
        </div>
    );
};

export const ColumnEditSubscription = ({
    style,
    item,
    onEditSubscription = () => {},
    onCancelSubscription = () => {},
    typeSubscription,
    idRow,
    strategy = 'absolute'
}) => {
    const { t } = useTranslation(['report']);
    const refDropdown = useRef(null);

    const _renderListOptions = () => {
        const isSquare = typeSubscription === PAYMENT_CARD_CUSTOMER.SQUARE;
        return (
            <ul>
                <li onClick={() => onEditSubscription(item)}>
                    <div className="items btn-modal">{t('report:edit_subscription')} </div>
                </li>
                <li onClick={() => onCancelSubscription(item)}>
                    <div className="items btn-modal">{t('report:cancel_subscription')}</div>
                </li>
                <li>
                    <a
                        href={isSquare ? urlOpenSquare(item.subscription_id) : urlOpenStripe(item.subscription_id)}
                        target="_blank"
                        className="items btn-modal"
                        rel="noreferrer"
                    >
                        {isSquare ? t('report:open_in_square') : t('report:open_in_stripe')}
                    </a>
                </li>
            </ul>
        );
    };

    return (
        <div className={style}>
            {item.status !== COMMON.CANCELED && (
                <DropdownPopper
                    ref={refDropdown}
                    isCheckHidden
                    wrapperClassName="v2-dropdown"
                    wrapperListClass="v2-dropdown__menu scrolls"
                    dropdownIcon={<IconThreeDots />}
                    onOpen={() => changeStyleRowsDropdown(idRow, true)}
                    onClose={() => changeStyleRowsDropdown(idRow, false)}
                    strategy={strategy}
                    modifiers={[
                        { name: 'offset', options: { offset: [0, 5] } },
                        {
                            name: 'preventOverflow',
                            options: {
                                mainAxis: false,
                                boundary: document.querySelector('.tables-list.--hastotal')
                            }
                        }
                    ]}
                    buttonClassName="dropbtn v2-btn-default --icon-sm --transparent"
                    isUseToggle
                    isUseMouseDownOutSide
                >
                    {_renderListOptions()}
                </DropdownPopper>
            )}
        </div>
    );
};

export const ColumnAssigneeSelect = ({ style, item, typeSubscription }) => {
    const { id: customer_id } = useParams();
    const users = useSelector(({ companyUsers }) => companyUsers.users);
    users.sort((a, b) => a.full_name.localeCompare(b.full_name));
    const [selected, setSelected] = useState(users.find((user) => user.id === item.user_id) || null);
    const refDropdown = useRef(null);
    const { t } = useTranslation();

    const _handleSelect = (id) => {
        const newSelected = id ? users.find((user) => user.id === id) : null;
        setSelected(newSelected);
        _handleUpdateAssignTo(newSelected);
        refDropdown.current._close();
    };

    const _handleUpdateAssignTo = (selected) => {
        if (!customer_id) return;
        clientQuery(getUrlSubscriptionAssign(customer_id), {
            data: {
                subscription_id: item.subscription_id,
                item_id: selected?.id || '',
                type: COMMON.USER,
                merchant: typeSubscription === 'square' ? 2 : 1
            },
            method: 'PUT'
        });
    };

    const _renderOptions = () => {
        return (
            <ul>
                <li className={classNames('items', { active: !selected })} onClick={() => _handleSelect(null)}>
                    <div className="txt-ellipsis">{t('setting:select_user')}</div>
                </li>
                {users.map((option) => (
                    <li
                        key={option.id}
                        className={classNames('items', { active: option?.id === selected?.id })}
                        onClick={() => _handleSelect(option.id)}
                    >
                        <div className="txt-ellipsis">
                            {option.first_name} {option.last_name}
                        </div>
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <div className={style}>
            <DropdownPopper
                ref={refDropdown}
                isCheckHidden
                wrapperClassName="v2-dropdown"
                buttonClassName="dropbtn items bg-white"
                dropdownIcon={
                    <>
                        <div className="txt-ellipsis">
                            {selected ? `${selected?.first_name} ${selected?.last_name}` : t('setting:select_user')}
                        </div>
                        <div className="arrow">
                            <IconArrowDown width={16} height={16} />
                        </div>
                    </>
                }
                wrapperListClass="v2-dropdown__menu scrolls"
                isUseToggle
            >
                {_renderOptions()}
            </DropdownPopper>
        </div>
    );
};

export const ColumnLocationSelect = ({ style, item, locations = [], typeSubscription }) => {
    const { id: customer_id } = useParams();
    const [selected, setSelected] = useState(
        locations.find((location) => parseInt(location.id) === parseInt(item.location_id))
    );
    const refDropdown = useRef(null);
    const { t } = useTranslation();

    const _handleSelect = (id) => {
        const newSelected = locations.find((location) => parseInt(location.id) === parseInt(id)) || null;
        setSelected(newSelected);
        _handleUpdateLocation(newSelected);
        refDropdown.current._close();
    };

    const _handleUpdateLocation = (selected) => {
        if (!customer_id) return;
        clientQuery(getUrlSubscriptionAssign(customer_id), {
            data: {
                subscription_id: item.subscription_id,
                item_id: selected?.id || '',
                type: COMMON.LOCATION,
                merchant: typeSubscription === 'square' ? 2 : 1
            },
            method: 'PUT'
        });
    };

    const _renderOptions = () => {
        return (
            <ul>
                <li className={classNames('items', { active: !selected })} onClick={() => _handleSelect(null)}>
                    <div className="txt-ellipsis">{t('report:select_location')}</div>
                </li>
                {locations.map((option) => (
                    <li
                        key={option.id}
                        className={classNames('items', { active: parseInt(option?.id) === parseInt(selected?.id) })}
                        onClick={() => _handleSelect(option.id)}
                    >
                        <div className="txt-ellipsis">
                            {option.name} {option.street1 && `| ${option.street1}`}
                        </div>
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <div className={style}>
            <DropdownPopper
                ref={refDropdown}
                isCheckHidden
                wrapperClassName="v2-dropdown"
                buttonClassName="dropbtn items bg-white"
                dropdownIcon={
                    <>
                        <div className="txt-ellipsis">{selected ? selected?.name : t('report:select_location')}</div>
                        <div className="arrow">
                            <IconArrowDown width={16} height={16} />
                        </div>
                    </>
                }
                wrapperListClass="v2-dropdown__menu scrolls"
                isUseToggle
            >
                {_renderOptions()}
            </DropdownPopper>
        </div>
    );
};

export const ColumnSubscription = ({ style, item, onOpenSubscription }) => {
    return (
        <div className={style}>
            <p className="col-label" title={item.name} onClick={() => onOpenSubscription(item)}>
                {item.name}
            </p>
        </div>
    );
};

export const ColumnLocationAddress = ({ item, style, onOpenLocation = () => {}, isDelete = false }) => {
    const locationName = item.location_name || '';

    return (
        <div className={style}>
            {isDelete ? (
                <p className="name">{locationName}</p>
            ) : (
                <p className="name" onClick={() => onOpenLocation(item)}>
                    {locationName}
                </p>
            )}
        </div>
    );
};

export const ColumnLocationTaxes = ({ item, style }) => {
    return (
        <div className={style}>
            {item.taxes.map((taxes, index) => (
                <div key={index} className="col-label">
                    {taxes.name} ({taxes.rate}%)
                </div>
            ))}
        </div>
    );
};

export const ColumnNickName = ({ item, style, onChangeInput = () => {} }) => {
    return (
        <div className={style}>
            <input
                className="field-input"
                onChange={(e) => onChangeInput(e, item)}
                type="text"
                name="value-commissions"
                defaultValue={item.nickname}
                spellCheck
            />
        </div>
    );
};

export const ColumnRatePercent = ({ value, style }) => {
    return (
        <div className={style}>
            <div className="col-label">{value}%</div>
        </div>
    );
};

export const ColumnLocationTags = ({ item, style, idRow, typeTag = TYPE_TAGS.TAG_CUSTOMER, onHandleSaveTags }) => {
    const [state, dispatchState] = useReducer(reducer, { isAddTags: false });
    const isAddTags = state.isAddTags;
    const refTags = useRef(null);
    const tags = item.tags || [];
    const isConversationTag = typeTag === TYPE_TAGS.TAG_CONVERSATION;
    const finalId = `list_tags_${isConversationTag ? COMMON.CONVERSATIONS : COMMON.LOCATIONS}${idRow}`;

    useEffect(() => {
        if (!isAddTags) {
            document.addEventListener('click', handleClickOutside, true);
            _handleClose();
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            _handleAddTags();
            refTags.current.onEdit();
        }

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    const _handleAddTags = () => {
        dispatchState({ isAddTags: true });
    };

    const _handleClose = () => {
        dispatchState({ isAddTags: false });
        _handleChangeStyleRow(false);
    };

    const handleClickOutside = (event) => {
        const elPrevent = document.getElementById(finalId);
        const listTags = refTags.current?.getValue() || [];

        if (elPrevent && !elPrevent.contains(event.target)) {
            _handleClose();
            if (tags.length !== listTags.length) {
                onHandleSaveTags(listTags, item.id);
            }
        }
    };

    const _handleChangeStyleRow = (isOpen) => {
        changeStyleRowsDropdown(idRow, isOpen);
    };

    return (
        <div className={style}>
            {!isAddTags || item.deleted ? (
                <>
                    <TagsDisplay
                        data={tags.map((item) => (isConversationTag ? item : item.name))}
                        isTagsSideMenu
                        classRow="tags__info flexcenter flex-wrap"
                        classWrapper="tags"
                    />
                    <div
                        className={classNames('v2-btn-default --icon-sm btn-plus', { 'is-disable': item.deleted })}
                        onClick={_handleAddTags}
                    >
                        <IconPlus />
                    </div>
                </>
            ) : (
                <div id={finalId}>
                    <GdTags
                        ref={refTags}
                        defaultTags={tags.map((item) => ({
                            id: isConversationTag ? item : item.name,
                            name: isConversationTag ? item : item.name
                        }))}
                        isTagsSideMenu
                        classWrapper="tags__info flexcenter flex-wrap"
                        autoFocus
                        isCheckHidden
                        onOpen={_handleChangeStyleRow}
                        deleteWithId
                        type={typeTag}
                    />
                </div>
            )}
        </div>
    );
};

export const ColumnSchedulesAddress = ({ item, style, onSelect = () => {} }) => {
    const _handleEmpty = (placeName) => {
        onSelect(placeName, { name: '' }, item);
    };

    const _handleBlur = ({ name, value }) => {
        onSelect(name, { name: value }, item);
    };

    return (
        <div className={style}>
            <div className="start relative">
                <IconLocation />
                <GdPlaces
                    withClassName={`field-input`}
                    name={'start_address'}
                    onSelect={(name, value) => {
                        onSelect(name, value, item);
                    }}
                    onBlur={_handleBlur}
                    defaultValue={item?.start_address}
                    onEmpty={_handleEmpty}
                />
            </div>
            <div className="end relative">
                <IconLocation />
                <GdPlaces
                    withClassName={`field-input`}
                    name={'end_address'}
                    onBlur={_handleBlur}
                    onSelect={(name, value) => {
                        onSelect(name, value, item);
                    }}
                    defaultValue={item?.end_address}
                    onEmpty={_handleEmpty}
                />
            </div>
        </div>
    );
};

export const ColumnLocationActiveService = ({ item, style }) => {
    return (
        <div className={style}>
            {item.active_service.map((service) => (
                <div key={service.id} className="details">
                    <span className="tooltip has-content svg-tooltip-sm">
                        <IconTooltip width={12} height={12} />
                        <span className="tooltiptext tooltip-content top-arrow">{service.date}</span>
                    </span>
                    <div className="col-label">{service.name}</div>
                </div>
            ))}
        </div>
    );
};

export const ColumnLocationUnits = ({ item, style }) => {
    return (
        <div className={style}>
            <span className={classNames('col-label', { disable: !item.units })}>{item.units}</span>
        </div>
    );
};

export const ColumnLocationAddressContact = ({ item, style }) => {
    const address_contact = item?.address_contact;

    return (
        <div className={style}>
            <div className="details">
                <div className="col-label">{address_contact?.address_to || ''}</div>
            </div>
            <div className="details">
                <div className="col-label">{address_contact?.bill_to || ''}</div>
            </div>
        </div>
    );
};
export const ColumnSchedulesAssign = ({ item, style, companyUsers, onSelectAssign = () => {}, idRow }) => {
    const newCompanyUsers = [
        {
            avatar: '',
            id: '-1',
            last_name: ''
        },
        ...companyUsers
    ];
    const reducer = (state, action) => {
        if (typeof action !== 'object') {
            return false;
        }
        return { ...state, ...action };
    };
    const { t } = useTranslation(['setting']);
    const idAssignee = item.assignee;
    const defaultSelected = newCompanyUsers.find((item) => item.id === idAssignee);
    const [state, setState] = useReducer(reducer, {
        isVisible: false,
        selected: defaultSelected || null
    });
    const { selected: finalSelected } = state;
    const fullNameSelected = finalSelected?.first_name?.concat(' ', finalSelected?.last_name);
    const refPopper = useRef(null);

    const _handleSelect = (id) => {
        const newSelected = newCompanyUsers.find((item) => item.id === id) || null;
        setState({ selected: newSelected });
        onSelectAssign(newSelected.id, item);
        refPopper.current._close();
    };

    const _renderOptions = () => {
        return (
            <ul>
                {newCompanyUsers.map((option) => {
                    return (
                        <li
                            key={option.id}
                            className={classNames(`items ${finalSelected?.id === option?.id ? 'active' : ''}`)}
                            onClick={() => _handleSelect(option.id)}
                        >
                            <div className="user-name">
                                {!!option.avatar && (
                                    <div className="avt-img">
                                        <GdAvatar
                                            src={option.avatar || ''}
                                            alt={option.first_name || ''}
                                            width="24"
                                            height="24"
                                        />
                                    </div>
                                )}
                                <span className="txt-ellipsis">{`${option.first_name || ''} ${
                                    option.last_name || ''
                                }`}</span>
                            </div>
                        </li>
                    );
                })}
            </ul>
        );
    };

    return (
        <div className={style}>
            <DropdownPopper
                ref={refPopper}
                wrapperClassName="v2-dropdown dropdown-assign"
                buttonClassName="dropbtn items"
                dropdownIcon={
                    !finalSelected ? (
                        <>
                            <div className="txt-ellipsis">{fullNameSelected || t('setting:select_user')}</div>
                            <div className="arrow">
                                <IconDropUpDown width={16} height={16} />
                            </div>
                        </>
                    ) : (
                        <>
                            {finalSelected.avatar && (
                                <span className="avt-img">
                                    <GdAvatar
                                        src={finalSelected?.avatar}
                                        alt={fullNameSelected}
                                        width="24"
                                        height="24"
                                    />
                                </span>
                            )}

                            <div className="txt-ellipsis">{fullNameSelected || t('setting:select_user')}</div>
                            <div className="arrow">
                                <IconDropUpDown width={16} height={16} />
                            </div>
                        </>
                    )
                }
                wrapperListClass="v2-dropdown__menu content-full content-user scrolls"
                isUseToggle
                onOpen={() => changeStyleRowsDropdown(idRow, true)}
                onClose={() => changeStyleRowsDropdown(idRow, false)}
            >
                {_renderOptions()}
            </DropdownPopper>
        </div>
    );
};

export const ColumnCustomerEstimate = ({ style, item, handleClick = () => {}, styleText = '', isDeleted = false }) => {
    const number = item.number || '';

    const _handleClick = () => {
        if (!isDeleted) handleClick(item);
    };

    return (
        <div className={style}>
            <p className={classNames(styleText, { 'col-label': !!!styleText })} title={number} onClick={_handleClick}>
                {number}
            </p>
        </div>
    );
};

export const ColumnViewEstimateDelete = ({ style, item, handleClick = () => {} }) => {
    return (
        <div className={style}>
            <p className="col-label" title="View" onClick={() => handleClick(item)}>
                View
            </p>
        </div>
    );
};

export const ColumnEstimateJob = ({ style, item }) => {
    return (
        <div className={style}>
            <div className="col-label">{item.job}</div>
            <div className="col-label">{item.location}</div>
        </div>
    );
};

export const ColumnStatusSystem = ({ style, item }) => {
    const { t } = useTranslation(['common']);

    return (
        <div className={style}>
            {!item.alway_active && (
                <span className={classNames('status-btn', `${item.active || item.status ? 'green active' : 'void'}`)}>
                    {item.active || item.status ? t('common:active') : t('common:disabled')}
                </span>
            )}
        </div>
    );
};

export const ColumnPlanSystem = ({ style, item }) => {
    const { t } = useTranslation(['common']);

    return (
        <div className={style}>
            {!!item.plan && (
                <div className="v2-btn-default has-icon --purple svg-purple">
                    <IconUpgrade /> {t('common:upgrade_pro')}
                </div>
            )}
        </div>
    );
};

export const ColumnTypeCustom = ({ style, item }) => {
    const { t } = useTranslation(['common']);

    return (
        <div className={style}>
            <div className="col-label">
                {item.type === TYPE_BUTTON_ACTIONS.ACTIVE ? t('common:reminder') : t('common:follow_up')}
            </div>
        </div>
    );
};

export const ColumnNameClick = ({ style, value, item, onClick = () => {} }) => {
    return (
        <div className={style}>
            <div className="col-label" onClick={() => onClick(item)}>
                {value}
            </div>
        </div>
    );
};

export const ColumnServiceAddress = ({ style, item }) => {
    let serviceRow = '';
    const serviceAddress = item.service_address;
    if (item.service_address_city) {
        serviceRow += `${item.service_address_city}, `;
    }
    if (item.service_address_state) {
        serviceRow += `${item.service_address_state}`;
    }
    if (item.service_address_zip) {
        serviceRow += ` ${item.service_address_zip}`;
    }

    return (
        <div className={style}>
            <div className="col-label" title={serviceAddress}>
                {serviceAddress}
            </div>
            <div className="col-label" title={serviceRow}>
                {serviceRow}
            </div>
        </div>
    );
};

export const ColumnTypeCommissionTracking = ({ style, item }) => {
    return (
        <div className={style}>
            <div className="col-label">{item.commission?.type}</div>
        </div>
    );
};
export const ColumnServiceType = ({ style, item, handleClick, styleText, id }) => {
    const serviceType = item?.service_type || item?.service_name;

    return (
        <div className={style}>
            {item?.is_recurrence || !!!item?.customer?.id ? (
                <p className="col-label" title={serviceType}>
                    {serviceType}
                </p>
            ) : (
                <p
                    className={styleText}
                    title={serviceType}
                    style={linkStyle}
                    onClick={() => handleClick({ key: id, row: item })}
                >
                    {serviceType}
                </p>
            )}
        </div>
    );
};
export const ColumnCurrencySymbol = ({ style, value, currency, isLink = false, item, handleClick, styleText, id }) => {
    const _handleClick = () => {
        isLink && handleClick({ key: id, row: item });
    };
    return (
        <div className={style} onClick={_handleClick}>
            <p className={`col-label ${styleText}`}>{transformToCurrency(parseFloat(value), currency)}</p>
        </div>
    );
};

export const ColumnTotalCredit = ({ style, item }) => {
    const { t } = useTranslation(['report']);
    const currency = useSelector(({ auth }) => auth.user.settings.currency);
    const paymentStatus = item.payment_status || 0;
    const paymentFee = item.payment_fee || 0;
    const total = item.total || transformToCurrency(item.amount, currency);
    const titleToolTip = paymentStatus !== PAYMENT_STATUS_FAIL.FAIL ? total : null;

    function _renderStatus() {
        if (paymentStatus === PAYMENT_STATUS_FAIL.FAIL) {
            return <span className="credit-stt red-default">({t('report:fail')})</span>;
        }

        if (paymentStatus === PAYMENT_STATUS_FAIL.PENDING) {
            return <span className="credit-stt__label">({t('report:pending')})</span>;
        }

        return null;
    }

    return (
        <div className={style}>
            <span
                className="col-label"
                title={
                    !!paymentFee && !!paymentStatus
                        ? t('report:transaction_fee', {
                              paymentFee: transformToCurrency(paymentFee, currency)
                          })
                        : titleToolTip
                }
            >
                {total}
            </span>
            {_renderStatus()}
        </div>
    );
};

export const ColumnMethodRevenue = ({ style, item }) => {
    const method = item.method.replace(METHOD_API, METHOD_CONVERT);

    return (
        <div className={style}>
            <p className="col-label" title={method}>
                {method}
            </p>
        </div>
    );
};

export const ColumnAssignedTo = ({ style, value, companyUsers }) => {
    const assignedTo = companyUsers.find((user) => user.id === String(value));
    const fullName = assignedTo?.full_name || '';

    return (
        <div className={style}>
            <div className="col__customer">
                {fullName && <div className="avt-img">{_renderAvatar(assignedTo?.avatar, fullName)}</div>}
                <span className="col-label" title={fullName}>
                    {fullName}
                </span>
            </div>
        </div>
    );
};
export const ColumnWeather = ({ style, value }) => {
    const { wind_speed = '', wind_direction = '', temperature = '' } = value || {};
    return (
        <div className={style}>
            {wind_speed && <div className="col-label">Wind Speed: {wind_speed}</div>}
            {wind_direction && <div className="col-label">Wind Direction: {wind_direction}</div>}
            {temperature && <div className="col-label">Temperature: {temperature}°</div>}
        </div>
    );
};
export const ColumnAddressQb = ({ style, item }) => {
    const addressQb = _formatAddressQBReport(item) || {};

    return (
        <div className={style}>
            <div className="col-label" title={addressQb}>
                {addressQb.street || ''}
            </div>
            <div className="col-label" title={addressQb}>
                {addressQb.fullLocation || ''}
            </div>
        </div>
    );
};

export const ColumnCustomersFullAddress = ({ style, item }) => {
    const { line1, line2, city, state, zip } = item;
    let addressRow = '';

    if (city) addressRow += `${city}, `;
    if (state) addressRow += `${state}`;
    if (zip) addressRow += ` ${zip}`;

    return (
        <div className={style}>
            {line1 && (
                <div className="col-label" title={line1}>
                    {line1}
                </div>
            )}
            {line2 && (
                <div className="col-label" title={line2}>
                    {line2}
                </div>
            )}
            <div className="col-label" title={addressRow}>
                {addressRow}
            </div>
        </div>
    );
};

export const ColumnAssignBooking = ({ style, item, assignBookingPopup = {} }) => {
    return item?.assignable ? (
        <GridAssignBooking
            urlAPI={assignBookingPopup.urlAPI || ''}
            callBackAfterAssign={assignBookingPopup.callBackAfterAssign}
            listSuggestion={item?.customer_list}
            rowId={item.id}
            rowIndex={item.id}
            style={style}
            handleAssignSuccess={assignBookingPopup.handleAssignSuccess}
            handleAssignFailed={assignBookingPopup.handleAssignFailed}
            isInbound={assignBookingPopup.isInbound || false}
        />
    ) : (
        <div className={style}></div>
    );
};

export const ColumnJobDocument = ({ item, style }) => {
    const { job } = item;

    return (
        <div className={style}>
            <div className="btn-modal col-label">{job?.name || 'n/a'}</div>
            <div className="word-break">{job?.location || ''}</div>
        </div>
    );
};

export const ColumnAddressLead = ({ style = '', item }) => {
    function _renderAddressInbound() {
        return item.locations.map((address, index) => {
            const finalAddress = `${combineString([
                address.address_to || '',
                address.street_1 || '',
                address.street_2 || '',
                address.city || '',
                address.state || ''
            ])} ${address.zip || ''}`;

            return (
                <p key={index.toString()} className="col-label" title={finalAddress}>
                    {finalAddress}
                </p>
            );
        });
    }

    function _renderAddressBooking() {
        const fullAddress = displayLocation({
            street1: item.service_address_1 || '',
            street2: item.service_address_2 || '',
            city: item.service_city || '',
            state: item.service_state || '',
            zip: item.service_zip || '',
            withoutHTML: true
        });
        const finalAddress = !!fullAddress.trim() ? fullAddress : item.address;

        return (
            <p className="col-label" title={finalAddress}>
                {finalAddress}
            </p>
        );
    }

    return <div className={style}>{!!item.locations ? _renderAddressInbound() : _renderAddressBooking()}</div>;
};

export const ColumnCost = ({ style = '', item }) => {
    const currency = useSelector(({ auth }) => auth.user.settings.currency);

    return (
        <div className={style}>
            <div className="col-label">{transformToCurrency(item.cost || '', currency)}</div>
        </div>
    );
};

export const ColumnWordBreak = ({ style, value }) => {
    return (
        <div className={style}>
            {Array.isArray(value) ? (
                value.map((item, index) => (
                    <p key={index.toString()} className="word-break">
                        {item || ''}
                    </p>
                ))
            ) : (
                <p className="word-break">{value || ''}</p>
            )}
        </div>
    );
};

export const ColumnDeleteQbInvoice = ({ style, handleClick = () => {}, isChecked = false }) => {
    const { t } = useTranslation(['report']);
    const refButtonSave = useRef(null);

    const _deleteSuccess = () => {
        refButtonSave.current.removeLoading();
    };

    return (
        <div className={classNames(`${style} justify-center`, { 'is-disable cursor-not-allowed': !isChecked })}>
            <ButtonSave
                ref={refButtonSave}
                title={t('customers:delete')}
                wrapClass="v2-btn-main"
                onSave={() => handleClick(_deleteSuccess)}
            />
        </div>
    );
};

export const ColumnBillingEmail = ({ style, value }) => {
    return (
        <div className={style}>
            {value.map((item) => {
                return (
                    <div key={item.id} className="col-label">
                        {item.email || ''}
                    </div>
                );
            })}
        </div>
    );
};
export const ColumnCustomButton = ({
    style = '',
    styleText = '',
    value = 0,
    iconActive = <IconLogout />,
    icon = <IconSignedOut />,
    positionIcon = 'left',
    handleClick = () => {},
    textActive = 'sign_out',
    text = 'signed_out',
    fileTranslation = 'report',
    item = null
}) => {
    const { t } = useTranslation([fileTranslation]);

    const _renderButton = () => {
        const iconFinal = value ? iconActive : icon;
        return (
            <>
                {positionIcon === 'left' && iconFinal}
                {value ? t(textActive) : t(text)}
                {positionIcon === 'right' && iconFinal}
            </>
        );
    };

    return (
        <div className={style}>
            <div className={classNames(styleText, { 'is-disable': !value })} onClick={() => handleClick(item)}>
                {_renderButton()}
            </div>
        </div>
    );
};

export const ColumnDevice = ({ item, style = '' }) => {
    return (
        <div className={style}>
            {!!item && (
                <div className="flex-monitor">
                    <IconDeviceSession type={item.type} />
                    <span className="flex-monitor__label">{item.platform}</span>
                </div>
            )}
        </div>
    );
};

export const ColumnTimeAgo = ({ value, format, timezone, style = '', styleText = '' }) => {
    let finalValue = value;
    if (typeof value !== 'number' && typeof value !== 'string') finalValue = value?.createdDate || null;
    return (
        <div className={style}>
            <p className={styleText}>
                {finalValue && moment(finalValue).isValid() ? caculateTimeAgo(finalValue, format, timezone) : ''}
            </p>
        </div>
    );
};

export const StatementService = ({ item, style = '' }) => {
    const { service, address, locationName } = item;

    return (
        <div className={style} title={`${service} ${locationName} ${address}`}>
            <p className="col-label">{service}</p>
            <p className="col-label">{locationName}</p>
            <p className="col-label">{address}</p>
        </div>
    );
};

export const ColUserActiveVOIP = ({ item, style }) => {
    const { t } = useTranslation('addons');
    const { avatar, full_name, active_call } = item;
    const isOnCall = !!active_call;
    const { customer } = active_call || {};

    const _handleClickCustomer = (e) => {
        !customer.id && e.preventDefault();
    };

    return (
        <div className={style}>
            <div className="flexcenter">
                <div className="call-bar__controll">
                    <div className={`avt-has-micro ${isOnCall ? 'relative' : '--normal'} mr-2`}>
                        <img className="avt-img --lg" src={avatar} />
                        {isOnCall && (
                            <span className="avt-has-micro__icon absolute cursor-default">
                                <IconCall isOnCall />
                            </span>
                        )}
                    </div>
                </div>
                <div className="flex-column gap-4 txt-name">
                    <p className="fw-600 txt-ellipsis">{full_name}</p>
                    {isOnCall && (
                        <div>
                            <span className="grey-generic mr-1">{t('on_a_call_with')}</span>
                            <Link
                                to={addBranchPath(`/customers/${customer?.id}`)}
                                className="is-link fw-500"
                                onClick={_handleClickCustomer}
                            >
                                {customer?.name || formatPhoneNumberVoip(customer?.phone_number)}
                            </Link>
                            <span className="dots is-link fw-500 dp-hide">{t('listen')}</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export const ColPersonalNumberVoip = ({ item, style }) => {
    return (
        <div className={style}>
            <DropdownPersonalNumber item={item} />
        </div>
    );
};

export const ColGroupNumberVoip = ({ item, style }) => {
    const refSwitch = useRef(null);

    const { groupNumber } = useContext(ActiveUsersContext);
    if (!groupNumber.length) return null;

    const { id: itemId, group_number, onGroupNumber = () => {} } = item;

    const _handleChange = ({ status, phoneGroupId, phoneNumber }) => {
        onGroupNumber({ id: itemId, phoneGroupId, status, phoneNumber });
    };

    return (
        <div className={style}>
            {groupNumber.map((item) => {
                const { phone_number: phoneNumber, id: phoneGroupId } = item;
                const phoneText = formatPhoneNumberVoip(phoneNumber);
                return (
                    <SwitchCheckbox
                        key={phoneGroupId}
                        ref={refSwitch}
                        id={`group_number_${itemId}_${phoneGroupId}`}
                        textPosition="right"
                        textChecked={phoneText}
                        textUnChecked={phoneText}
                        defaultChecked={group_number.some((item) => item.id === phoneGroupId)}
                        isEffectDefaultChecked
                        onChangeImmediately={(status) => _handleChange({ status, phoneGroupId, phoneNumber })}
                    />
                );
            })}
        </div>
    );
};

export const ColCallOptionsVoip = ({ item, style, title, keyGetValue }) => {
    const { t } = useTranslation(['addons']);
    const refSwitch = useRef(null);
    const { id, [keyGetValue]: value, onChangeOption = () => {} } = item;
    const text = t(`addons:${title}`);
    const idElm = `toggle_voip_options_${keyGetValue}_${id}`;

    const _handleChange = (status) => {
        onChangeOption({ id, status, keyword: keyGetValue });
    };

    return (
        <div className={style}>
            <SwitchCheckbox
                ref={refSwitch}
                id={idElm}
                textPosition="right"
                textChecked={text}
                textUnChecked={text}
                defaultChecked={!!value}
                onChangeImmediately={_handleChange}
                isEffectDefaultChecked
            />
        </div>
    );
};

export const ColEditActiveUserVoip = ({ item, style }) => {
    return (
        <div className={style}>
            <ActiveVoipUsersEdit item={item} />
        </div>
    );
};

export const ColumnDigits = ({ item, style = '' }) => {
    return (
        <div className={style}>
            <DigitsAutoAttendant item={item} />
        </div>
    );
};

export const ColumnEditAutoAttendant = ({ item, style = '' }) => {
    return (
        <div className={style}>
            <EditAutoAttendant item={item} />
        </div>
    );
};

export const ColumnForwardAutoAttendant = ({ item, style = '' }) => {
    return (
        <div className={style}>
            <ForwardAutoAttendant item={item} />
        </div>
    );
};

export const ColumnTimeHMS = ({ value, style = '' }) => {
    const time = formatTimeToHMS(value);
    return (
        <div className={classNames(style, { '--empty': !value })}>
            <p className="col-label txt-ellipsis" title={time}>
                {time}
            </p>
        </div>
    );
};
export const ColumnStatus = ({ value, style = '' }) => {
    const status = value.label;
    return (
        <div className={style}>
            <div className={`status-btn ${status ? status.toLowerCase() : 'na'}`}>{status || 'n/a'}</div>
        </div>
    );
};
export const ColumnSource = ({ value, style = '' }) => {
    return (
        <div className={style}>
            <div className="col-label">{value?.name}</div>
        </div>
    );
};
export const ColumnCustomerName = ({ value, style = '' }) => {
    const { id, name: finalName } = value || {};
    return (
        <div className={style}>
            {!!id ? (
                <div className="col__customer">
                    <Link to={addBranchPath(`${CUSTOMERS}/${value.id}`)} className="name">
                        {finalName}
                    </Link>
                </div>
            ) : (
                <p>{finalName}</p>
            )}
        </div>
    );
};

export const ColumnDate = ({
    timezone = '',
    isShowEmpty = false,
    value = isShowEmpty ? '' : new Date(),
    style = '',
    format = DATE_FORMAT,
    styleText = 'col-label'
}) => {
    const isValidTime = !!value && moment(value).isValid();
    if (timezone) {
        return (
            <div className={style}>
                <p className={styleText}>{isValidTime ? moment.tz(value, timezone).format(format) : ''}</p>
            </div>
        );
    }
    return (
        <div className={style}>
            <p className={styleText}>
                {isValidTime ? (isShowEmpty ? moment(value).utc().format(format) : moment(value).format(format)) : ''}
            </p>
        </div>
    );
};
export const ColumnNextTask = ({ value = {}, style = '', styleText = 'col-label' }) => {
    return (
        <div className={style}>
            <p className={styleText}>{value?.description || ''}</p>
        </div>
    );
};
export const ColumnNextTaskDate = ({ value = {}, style = '', styleText = 'col-label', format = DATE_FORMAT }) => {
    return (
        <div className={style}>
            <p className={styleText}>
                {value?.date && moment(value?.date).isValid() ? moment(value?.date).utc().format(format) : ''}
            </p>
        </div>
    );
};

export const ColumnAddress = ({ value = [], style = '', isBillingAddress = false }) => {
    let streetCity = '';
    let stateZip = '';

    const {
        addressLine1 = '',
        addressLine2 = '',
        city = '',
        state = '',
        zip = '',
        billingAddressLine1,
        billingAddressLine2,
        billingCity,
        billingState,
        billingZip
    } = value || {};

    streetCity += `${isBillingAddress ? billingAddressLine1 || '' : addressLine1 || ''} ${
        isBillingAddress ? billingAddressLine2 || '' : addressLine2 || ''
    } ${isBillingAddress ? billingCity || '' : city || ''}`;
    if (state) stateZip += `${isBillingAddress ? billingState || '' : state || ''}`;
    if (zip) stateZip += ` ${isBillingAddress ? billingZip || '' : zip || ''}`;
    let finalString = `${streetCity}`;
    if (!!stateZip && finalString.trim()) finalString += `, ${stateZip}`;
    return (
        <div className={style}>
            <p className="col-label" title={finalString}>
                {finalString}
            </p>
        </div>
    );
};

export const ColumnSMS = ({ value, style = '', styleText = '' }) => {
    return (
        <div className={style}>
            <p className={styleText}>{value?.message}</p>
        </div>
    );
};

export const ColumnOppValue = ({ value, style = '', styleText = '', typeValue = '' }) => {
    const textContent = value?.[typeValue] || '';
    return (
        <div className={style} title={textContent}>
            <p className={styleText}>{textContent}</p>
        </div>
    );
};

export const ColumnExpand = ({ style, value, fileTranslation }) => {
    const { t } = useTranslation(fileTranslation);
    const containerDescRef = useRef(null);
    const contentRef = useRef(null);
    const showMoreRef = useRef(null);

    useEffect(() => {
        const contentHeight = contentRef.current?.clientHeight;
        const showMoreText = showMoreRef.current;
        if (contentHeight && showMoreText) {
            showMoreText.style.display = contentHeight >= REPORT_LIMIT_HEIGHT_COLUMN ? 'inline' : 'none';
        }
    }, [value]);

    const _toggleIsMore = () => {
        containerDescRef.current && containerDescRef.current.classList.toggle('is-more');
        const showMoreText = showMoreRef.current;
        if (showMoreText) {
            showMoreText.innerHTML = t(`report:${_checkContainClassIsMore('show_less', 'show_more')}`);
        }
    };

    const _checkContainClassIsMore = (correctText = '', wrongText = '') => {
        return containerDescRef.current && containerDescRef.current.classList.contains('is-more')
            ? correctText
            : wrongText;
    };

    return (
        <div className={style} ref={containerDescRef}>
            <div
                ref={contentRef}
                className={classNames('col-label content', _checkContainClassIsMore('is-more', ''))}
                title={typeof value === 'string' ? value : ''}
            >
                {Array.isArray(value) ? (
                    value.map((item, index) => (
                        <p key={index.toString()} className="word-break">
                            {convertLineBreaks(item || '')}
                        </p>
                    ))
                ) : (
                    <p className="word-break">{convertLineBreaks(value || '')}</p>
                )}
            </div>
            <span
                ref={showMoreRef}
                className="purple-default is-link"
                style={{ display: 'none' }}
                onClick={_toggleIsMore}
            >
                {t(`report:${_checkContainClassIsMore('show_less', 'show_more')}`)}
            </span>
        </div>
    );
};

export const ColAvatarMultipleBranch = ({ item, style }) => {
    const { avatar, name, color } = item;

    return (
        <div className={style}>
            {!!avatar ? (
                <img className="avt-img --large" src={avatar} alt="Avatar" />
            ) : (
                <span className="avt --large" style={{ '--branch-color': getAvtColorBranch({ color }) }}>
                    {getAvatarCustomer(name)}
                </span>
            )}
        </div>
    );
};

export const ColPhoneMultipleBranch = ({ value, style }) => {
    return <div className={style}>{formatPhoneNumberVoip(value)}</div>;
};

export const ColPlanMultipleBranch = ({ value = {}, style }) => {
    return <div className={style}>{`${value.name}/${value.type}`}</div>;
};

export const ColAssignedUsersMultipleBranch = ({ item, style }) => {
    const { users, status } = item;
    const companyUsers = useSelector(({ companyUsers }) => companyUsers.users);

    const _renderUsers = () => {
        const _renderAvatars = () => {
            return [...companyUsers.filter((user) => users.includes(+user.id))].splice(0, 6).map((assignee) => {
                const { id, full_name, avatar } = assignee;
                return (
                    <span key={id} className="avt-img tooltip">
                        <img src={avatar} alt={full_name} width={22} height={22} />
                        <span className="tooltiptext top">{full_name}</span>
                    </span>
                );
            });
        };

        const lengthListUser = users.length;

        return (
            <div className="wrap-member-group flexcenter">
                {_renderAvatars()}
                {lengthListUser > 6 && <span className="avt-img avt-more fs-10 fw-500">+{lengthListUser - 6}</span>}
            </div>
        );
    };

    return (
        <div className={style}>
            {_renderUsers()}
            {status !== STATUS_MULTI_BRANCH.ARCHIVE && (
                <DropdownAssignedUsers item={item} companyUsers={companyUsers}>
                    <div className="dropbtn items --without-number">
                        <IconPlus />
                    </div>
                </DropdownAssignedUsers>
            )}
        </div>
    );
};

export const ColEditMultipleBranch = ({ item, style }) => {
    return (
        <div className={style}>
            <MultipleBranchEdit item={item} />
        </div>
    );
};

export const ColStageFunnel = ({ item, style }) => {
    return (
        <div className={style}>
            <div
                className={`status-btn txt-ellipsis --${
                    LIST_STATUS_PIPELINES.find((option) => option.id === item.status)?.status
                }`}
                title={item.name}
            >
                {item.name}
            </div>
        </div>
    );
};

export const ColUserHasAvt = ({ value, style }) => {
    const { avatar, full_name } = value || {};

    return (
        <div className={style}>
            <div className="col-customer flexcenter gap-4">
                {!!avatar ? (
                    <span className="avt-img">
                        <img src={avatar} alt={full_name} width={32} height={32} />
                    </span>
                ) : (
                    <div className="avt">{getAvatarCustomer(full_name)}</div>
                )}
                <span className="txt-ellipsis fw-500" title={full_name}>
                    {full_name}
                </span>
            </div>
        </div>
    );
};

export const ColRole = ({ value, style }) => {
    return (
        <div className={style}>
            <div className={classNames('tag-label', `--${value?.toLowerCase()?.replace(/\s/g, '-')}`)}>{value}</div>
        </div>
    );
};

export const ColSwitchCheckboxOppPermission = ({ item, value, style, config = {}, onChange = () => {} }) => {
    const userId = item.user.id;
    const { id: key } = config;

    const _handleChange = (value) => {
        onChange({ id: userId, value, key });
    };

    return (
        <div className={style}>
            <SwitchCheckbox
                id={`${userId}_${key}`}
                textChecked="On"
                textUnChecked="Off"
                defaultChecked={value.includes(key)}
                isEffectDefaultChecked
                textPosition="right"
                onChangeImmediately={_handleChange}
                wrapClassName={classNames('switch large', {
                    'is-disable': item.role === ACCOUNT_ROLE.SUPERADMIN_SETTING
                })}
            />
        </div>
    );
};
export const ColRatePerHour = ({ item, currency, style }) => {
    return (
        <div className={style}>
            <p className="col-label">{transformToCurrency(parseFloat(item?.rate || 0), currency)}/h</p>
        </div>
    );
};
export const ColInOutTime = ({ item, style }) => {
    const timezone = useSelector(({ auth }) => auth.user.settings.timezone);
    return (
        <div className={style}>
            <p className="col-label">
                {formatUnixToTime(item?.start || 0, timezone)} - {formatUnixToTime(item?.end || 0, timezone)}
            </p>
        </div>
    );
};
export const ColDuration = ({ item, style }) => {
    return (
        <div className={classNames(style, { '--overlap': item.has_overlap })}>
            <p className="col-label">{formatTimeUnix(item.duration || 0)}</p>
        </div>
    );
};
export const ColTagsDisplay = ({ item = [], style = 'col --tags', config = {} }) => {
    return (
        <div className={style}>
            <TagsDisplay
                data={item[config.keyGetValue].map((item) => item)}
                isTagsSideMenu
                classRow="tags__info flexcenter flex-wrap"
                classWrapper="tags"
            />
        </div>
    );
};

export const ColCallActivity = ({ item = {}, style = '', action = '', value = 0, handleClick = () => {} }) => {
    const _handleClick = () => {
        handleClick({ action, id: item.user.id });
    };

    return (
        <div className={classNames(style, { '--empty': !value })}>
            <p className="col-label txt-ellipsis purple-default cursor-pointer btn-modal" onClick={_handleClick}>
                {value || 0}
            </p>
        </div>
    );
};

export const ColStatusItem = ({ style = '', value = null, listStatus = [] }) => {
    const {
        classNameStatus = '',
        title = '',
        name: finalName = '',
        icon = null
    } = listStatus.find((item) => item.id === value) || {};

    return (
        <div className={style}>
            <div className={classNames('status-btn', classNameStatus)}>
                {icon}
                {title || finalName}
            </div>
        </div>
    );
};
