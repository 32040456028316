import { DEFAULT_ALL, COMMON, YEAR_CURRENT } from 'app/const/App';
import { REPORT_TYPE } from 'app/const/report/ReportTypeContent';
import { PAYMENT_CARD_CUSTOMER } from 'app/const/Customers';

import { getListStaffFilter } from 'common/redux/actions/reports/revenueByStaffAction';
import { getListSettingService } from 'common/redux/actions/settings/serviceAction';
import { getListPaymentMethods } from 'common/redux/actions/settings/paymentMethodsAction';
import { getListCustomerCharacter, getListSearchCustomer } from 'common/redux/actions/customers/customerAction';
import { getListTypeFilterRequest } from 'common/redux/actions/reports/documentAction';
import { getListServiceFilterRevenueByItemRequest } from 'common/redux/actions/reports/revenueByItemAction';
import { getListSettingSource } from 'common/redux/actions/reports/revenueBySourceAction';
import { getListMethodFilterRequest } from 'common/redux/actions/reports/paymentAction';
import {
    getListPlansSubscriptionStripeFilter,
    getListPlansSubscriptionSquareFilter
} from 'common/redux/actions/reports/subscriptionAction';
import { getListCountyMaterialUseFilter } from 'common/redux/actions/reports/materialUseAction';
import { getListLocationCity, getListLocationZip } from 'common/redux/actions/locationAction';
import { getListConversationTags, getListSimpleTags } from 'common/redux/actions/settings/tagsAction';
import { getListLocation } from 'common/redux/actions/customers/locationAction';
import { getListYearRevenue } from 'common/redux/actions/reports/revenueByClientAction';
import IconDone from 'assets/icon/IconDone';
import { getListStaffFilterPaymentsCollected } from 'common/redux/actions/reports/paymentsCollectedAction';
import { getListUserReview } from 'common/redux/actions/reviews/serviceAction';
import {
    getListScheduleServiceLookupRequest,
    getListServiceTypeRequest
} from 'common/redux/actions/reports/serviceLookupAction';
import i18n from 'assets/i18n';
import IconProfile from 'assets/icon/IconProfile';
import { JOB_COMPLETED, JOB_CONFIRMED, JOB_PENDING_CONFIRMATION, JOB_RESCHEDULE, JOB_UNCONFIRMED } from '../Job';
import { REPORT_STATUS_OPTIONS, STATUS_OPTIONS, STATUS_TRAIN_OPTIONS } from 'app/modules/addons/chatbot/constants';

export const TYPE_SELECT = {
    MULTI: 'select_multi',
    SINGLE: 'select_single',
    CUSTOMERS: 'customer',
    CLIENTS: 'clients',
    SORT: 'sort',
    SELECT_WITH_SEARCH: 'select_with_search',
    MULTI_SELECT_RATING: 'select_multi_rating'
};

const COLUMNS = 'columns';
const COLUMNS_NY = 'columns_ny';
const STATUS = 'status';
const SIGN_STATUS = 'sign_status';
const SERVICE = 'service';
const TYPES = 'types';
const METHOD = 'method';
const METHODS = 'methods';
const SORT_BY = 'sort_by';
const CHARACTER = 'character';
const PLAN = 'plan';
const PLANS = 'plans';
const SQUARE_PLANS = 'square_plans';
const YEAR = 'year';
const CLIENT = 'client';
const CLIENTS = 'clients';
const ITEM = 'item';
const ITEMS = 'items';
const SALE = 'sale';
const SALES = 'sales';
const PAYMENT_METHODS = 'payment_methods';
const SERVICES = 'services';
const STAFF = 'staff';
const STAFFS = 'staffs';
const TAG_TYPE = 'tag_type';
const SOURCE = 'source';
const SOURCES = 'sources';
const FIRST_NAME = 'first_name';
const LAST_NAME = 'last_name';
const LOCATION_TAGS = 'location_tags';
const CUSTOMER_TAGS = 'customer_tags';
const TOTAL_COLLECTED = 'total_collected';
const TOTAL_BILLDED = 'total_billed';
const REPORT = 'report';
const REPORT_SOURCE = 'report_source';
const PAYMENT_FOR = 'payment_for';
const COUNTY = 'county';
const CUSTOMER_ID = 'customer_id';
const ALL_CUSTOMERS = 'all_customer';
const ALL_CLIENTS = 'all_clients';
const SERVICE_TYPE = 'service_type';
const SERVICE_TYPE_SINGLE = 'service_type_single';
const JOB_STATUS = 'job_status';
const ASSIGNED_TO = 'assigned_to';
const CITY = 'city';
const ZIPCODE = 'zipcode';
const TAGS = 'tags';
const CONVERSATION_TAGS = 'conversation_tags';
const REVENUE = 'revenue';
const EMAIL = 'email';
const FILTERS = 'filters';
const DISPLAY_BY = 'display_by';
const COMMISSION = 'commission';
const INVOICE_STATUS = 'invoice_status';
const INVOICE_STATEMENT = 'type';
const STATEMENT_TYPE = 'statement_type';
const SEARCH_LOCATION = 'location_id';
const USERS = 'users';
const ITEMS_LIKELY = 'items_likely';
const TEAMMATE = 'teammate';
const ALL_RATING = 'all_rating';
const SOURCE_INBOUND = 'source_inbound';
const SOURCE_ONLINE_BOOKING = 'source_online_booking';
const SOURCE_NEW_JOB = 'report_source';

// 1. LIST FILTER DROPDOWN - each item is the same with params keys of APIs
// COLUMNS, STATUS, SIGN_STATUS,
export const INVOICE_LIST_FILTER = [COLUMNS, STATUS, SIGN_STATUS, SERVICE];
export const REVIEW_SERVICE_LIST_FILTER = [USERS, ITEMS];
export const REVIEW_LIKELY_LIST_FILTER = [ITEMS_LIKELY];
export const ESTIMATE_LIST_FILTER = [COLUMNS, STATUS, SIGN_STATUS];
export const DOCUMENT_LIST_FILTER = [COLUMNS, STATUS, TYPES];
export const CUSTOMER_LIST_FILTER = [COLUMNS, STATUS, TAGS, SORT_BY];
export const PAYMENT_LIST_FILTER = [COLUMNS, PAYMENT_METHODS];
export const CREDIT_LIST_FILTER = [COLUMNS];
export const RECURRING_INVOICE_LIST_FILTER = [COLUMNS];
export const SUBSCRIPTION_LIST_FILTER = {
    [PAYMENT_CARD_CUSTOMER.STRIPE]: [COLUMNS, PLANS, STAFFS],
    [PAYMENT_CARD_CUSTOMER.SQUARE]: [COLUMNS, SQUARE_PLANS, STAFFS]
};
export const LOCATIONS_WO_ACTIVE_JOBS_LIST_FILTER = [STATUS, SORT_BY];
export const CUSTOMERS_WO_ACTIVE_JOBS_LIST_FILTER = [STATUS, SERVICE_TYPE_SINGLE, SORT_BY];
export const NEW_CUSTOMERS_LIST_FILTER = [REPORT_SOURCE, SORT_BY];
export const PAYMENTS_COLLECTED_LIST_FILTER = [CUSTOMER_ID, STAFF, PAYMENT_FOR, PAYMENT_METHODS, SORT_BY];
export const TOTAL_SALES_LIST_FILTER = [CUSTOMER_ID, SORT_BY];

export const SALES_FORECAST_LIST_FILTER = [YEAR, SORT_BY];
export const CHATBOT_TRAIN_AI_FILTER = [STATUS];
export const CHATBOT_REPORT_FILTER = [STATUS, CONVERSATION_TAGS];
export const CHATBOT_CUSTOM_ANSWER_FILTER = [STATUS];
export const CHATBOT_CONTENT_FILTER = [STATUS];
export const SALES_TAX_SUMMARY_LIST_FILTER = {
    ROW_1: [REVENUE, PAYMENT_METHODS],
    ROW_2: [SORT_BY]
};

export const REVENUE_BY_CLIENT_LIST_FILER = {
    ROW_1: [YEAR, CLIENTS, SALE, PAYMENT_METHODS],
    ROW_2: [SORT_BY]
};

export const REVENUE_BY_SERVICE_LIST_FILER = [YEAR, SERVICE_TYPE_SINGLE, SALE, PAYMENT_METHODS];
export const REVENUE_BY_ITEM_LIST_FILER = [YEAR, ITEM, SALE, PAYMENT_METHODS];
export const REVENUE_BY_STAFF_LIST_FILER = [YEAR, STAFF, SALE, PAYMENT_METHODS];
export const REVENUE_BY_SOURCE_LIST_FILER = [YEAR, SOURCE, SALE, PAYMENT_METHODS];
export const REVENUE_BY_TAGS_LIST_FILER = [YEAR, TAG_TYPE, SALE, PAYMENT_METHODS];

export const MATERIAL_USE_LIST_FILTER = [COUNTY];
export const MATERIAL_USE_DETAIL_LIST_FILTER = [COLUMNS];
export const MATERIAL_NEW_YORK_LIST_FILTER = [COLUMNS_NY];
export const EMAIL_HISTORY_LIST_FILTER = [STATUS, CUSTOMER_ID, SORT_BY];
export const SERVICE_LOOKUP_LIST_FILTER = [COLUMNS, SERVICE, JOB_STATUS, ASSIGNED_TO, CITY, ZIPCODE, TAGS, SORT_BY];
export const NEW_JOB_LIST_FILTER = [SOURCE_NEW_JOB, ASSIGNED_TO, JOB_STATUS, SERVICE, COLUMNS];

export const ACCOUNTS_AGING_LIST_FILTER = [FILTERS, EMAIL, SORT_BY, SERVICE];
export const ACTIVITY_COMPARISON_LIST_FILTER = [STAFFS, COLUMNS];
export const OPPORTUNITIES_PIPELINE_LIST_FILTER = [STAFFS];
export const SALES_FUNNEL_LIST_FILTER = [STAFFS];
export const STATUS_CHANGES_LIST_FILTER = [STAFFS];
export const TRACKER_LIST_FILTER = [STAFFS];

export const LEADS_LIST_FILTER = {
    [REPORT_TYPE.LEADS.inbound_leads]: [SOURCE_INBOUND, COLUMNS, SORT_BY],
    [REPORT_TYPE.LEADS.online_bookings]: [SOURCE_ONLINE_BOOKING, COLUMNS, SORT_BY]
};

export const COMMISSIONS_LIST_FILTER = [DISPLAY_BY, COMMISSION, STAFFS, INVOICE_STATUS, JOB_STATUS, PAYMENT_METHODS];
export const STATEMENT_LIST_FILTER = [INVOICE_STATEMENT, COLUMNS, STATEMENT_TYPE];
export const LOCATION_CUSTOMER_LIST = [COLUMNS];
export const VALUE_1 = 1;
export const VALUE_2 = 2;
export const VALUE_3 = 3;
export const VALUE_4 = 4;
// 2. OPTIONS FILTER
// Invoices
const INVOICE_COLUMNS_OPTIONS = [
    { id: 'account_number', name: 'account_number_#', value: 'account_number' },
    { id: 'invoice_number', name: 'invoice_#', value: 'invoice_number' },
    { id: 'customer', name: 'customer_name', value: 'customer' },
    { id: 'job_name', name: 'job_name', value: 'job_name' },
    { id: 'frequency', name: 'frequency', value: 'frequency' },
    { id: 'billing_email', name: 'billing_email', value: 'billing_email' },
    { id: 'date', name: 'date', value: 'date' },
    { id: 'total_format', name: 'total', value: 'total_format' },
    { id: 'status', name: 'status', value: 'status' },
    {
        id: 'signature_status',
        name: 'signature_status',
        value: 'signature_status'
    }
];

const INVOICE_COLUMNS_DEFAULT_SELECTED = [
    'account_number',
    'invoice_number',
    'customer',
    'job_name',
    'frequency',
    'billing_email',
    'date',
    'total_format',
    'status',
    'signature_status'
];

const STATEMENT_COLUMNS_OPTIONS = [
    { id: 'date', name: 'date', value: 'date' },
    { id: 'invoice', name: 'invoice', value: 'invoice' },
    { id: 'number', name: 'po_number', value: 'number' },
    { id: 'service', name: 'service', value: 'service' },
    { id: 'payment_date', name: 'payment_date', value: 'payment_date' },
    { id: 'amount', name: 'amount', value: 'amount' },
    { id: 'paid', name: 'paid', value: 'paid' },
    {
        id: 'balance',
        name: 'balance',
        value: 'balance'
    }
];

const STATEMENT_COLUMNS_DEFAULT_SELECTED = ['date', 'invoice', 'service', 'amount', 'paid', 'balance'];

const LOCATION_COLUMNS_OPTIONS = [
    { id: 'location_name', name: 'location', value: 'location_name' },
    { id: 'address_to', name: 'address_to', value: 'address_to' },
    { id: 'service_address', name: 'service_address', value: 'service_address' },
    { id: 'bill_to', name: 'bill_to', value: 'bill_to' },
    { id: 'billing_email', name: 'billing_email', value: 'billing_email' },
    { id: 'billing_email_cc', name: 'billing_email_cc', value: 'billing_email_cc' },
    { id: 'notes', name: 'notes', value: 'notes' },
    { id: 'units', name: 'units', value: 'units' },
    { id: 'sms', name: 'sms', value: 'sms' },
    { id: 'email', name: 'email', value: 'email' },
    {
        id: 'tags',
        name: 'tags',
        value: 'tags'
    },
    { id: 'taxes', name: 'tax', value: 'taxes' },
    { id: 'active_services', name: 'active_service', value: 'active_services' }
];

const LOCATION_COLUMNS_DEFAULT_SELECTED = [
    'location_name',
    'address_to',
    'service_address',
    'bill_to',
    'billing_email',
    'billing_email_cc',
    'notes',
    'units',
    'sms',
    'email',
    'tags',
    'taxes',
    'active_services'
];

const INVOICE_STATEMENT_OPTIONS = [
    { id: 'all', name: 'all', value: DEFAULT_ALL },
    { id: 'billed_invoices', name: 'billed_invoices', value: 2 },
    { id: 'zero_invoices', name: 'zero_invoices', value: 3 }
];

const STATEMENT_TYPE_OPTIONS = [
    { id: 'open_items', name: 'open_items', value: 1 },
    { id: 'transaction_statements', name: 'transaction_statements', value: 2 },
    { id: 'forward_balance', name: 'forward_balance', value: 3 }
];

const INVOICE_STATUS_OPTIONS = [
    { id: 'draft', name: 'draft', value: 'draft' },
    { id: 'void', name: 'void', value: 'void' },
    { id: 'partial', name: 'partial', value: 'partial' },
    { id: 'partial_paid', name: 'partial_paid', value: 'partial_paid' },
    { id: 'sent', name: 'sent', value: 'sent' },
    { id: 'paid', name: 'paid', value: 'paid' },
    { id: 'write_off', name: 'write_off', value: 'write_off' },
    { id: 'failed', name: 'failed', value: 'failed' }
];

const INVOICE_SIGN_STATUS_OPTIONS = [
    { id: 'sign_draft', name: 'draft', value: 'draft' },
    { id: 'emailed', name: 'sent', value: 'emailed' },
    { id: 'pending_sig', name: 'pending_sig', value: 'pending_sig' },
    { id: 'signed', name: 'signed', value: 'signed' }
];

//// Estimates
const ESTIMATE_COLUMNS_OPTIONS = [
    {
        id: 'estimate_number',
        name: 'estimate_number',
        value: 'estimate_number'
    },
    { id: 'account_number', name: 'account_number_#', value: 'account_number' },
    { id: 'customer', name: 'customer_name', value: 'customer' },
    { id: 'phones', name: 'phones', value: 'phones' },
    { id: 'billing_email', name: 'billing_email', value: 'billing_email' },
    { id: 'created_by', name: 'created_by', value: 'created_by' },
    { id: 'date', name: 'date', value: 'date' },
    { id: 'total', name: 'total', value: 'total' },
    { id: 'status', name: 'status', value: 'status' },
    {
        id: 'signature_status',
        name: 'signature_status',
        value: 'signature_status'
    }
];

const ESTIMATE_COLUMNS_DEFAULT_SELECTED = [
    'estimate_number',
    'account_number',
    'customer',
    'phones',
    'billing_email',
    'created_by',
    'date',
    'total',
    'status'
];

const ESTIMATE_STATUS_OPTIONS = [
    {
        id: 'draft',
        name: 'draft',
        value: 'draft',
        icon: { style: 'icon outline-star' }
    },
    { id: 'pending', name: 'pending', value: 'pending' },
    { id: 'won', name: 'won', value: 'won' },
    { id: 'invoiced', name: 'won_invoiced', value: 'invoiced' },
    { id: 'lost', name: 'lost', value: 'lost' }
];

const ESTIMATE_SIGN_STATUS_OPTIONS = [
    { id: 'sign_draft', name: 'draft', value: 'draft' },
    { id: 'emailed', name: 'sent', value: 'emailed' },
    { id: 'pending_sig', name: 'pending_sig', value: 'pending_sig' },
    { id: 'signed', name: 'signed', value: 'signed' }
];

// Document
const DOCUMENT_COLUMNS_OPTIONS = [
    {
        id: 'document_number',
        name: 'document_number_#',
        value: 'document_number'
    },
    { id: 'document_type', name: 'document_type', value: 'document_type' },
    { id: 'account_number', name: 'account_number_#', value: 'account_number' },
    { id: 'customer', name: 'customer', value: 'customer' },
    { id: 'created_by', name: 'created_by', value: 'created_by' },
    { id: 'date_created', name: 'date_created', value: 'date_created' },
    { id: 'status', name: 'status', value: 'status' }
];

const DOCUMENT_COLUMNS_DEFAULT_SELECTED = [
    'document_number',
    'document_type',
    'account_number',
    'customer',
    'created_by',
    'date_created',
    'status'
];

const DOCUMENT_SIGN_STATUS_OPTIONS = [
    { id: 0, name: 'draft', value: 0 },
    { id: 1, name: 'sent', value: 1 },
    { id: 2, name: 'pending_sig', value: 2 },
    { id: 3, name: 'signed', value: 3 }
];

const CUSTOMERS_STATUS_OPTIONS = [
    { id: 1, name: 'active', value: 1 },
    { id: 0, name: 'inactive', value: 0 },
    { id: 2, name: 'leads', value: 2 }
];

// CUSTOMERS
const CUSTOMERS_COLUMNS_OPTIONS = [
    { id: 'status', name: 'status_column', value: 'status' },
    { id: 'account_number', name: 'account_number_#', value: 'account_number' },
    { id: 'customer', name: 'customer', value: 'customer' },
    { id: 'title', name: 'title', value: 'title' },
    { id: 'first_name', name: 'first_name', value: 'first_name' },
    { id: 'last_name', name: 'last_name', value: 'last_name' },
    { id: 'phones', name: 'phones', value: 'phones' },
    { id: 'phones_office', name: 'phones_office', value: 'phones_office' },
    { id: 'phones_mobile', name: 'phones_mobile', value: 'phones_mobile' },
    { id: 'phones_home', name: 'phones_home', value: 'phones_home' },
    { id: 'emails', name: 'email', value: 'emails' },
    { id: 'company', name: 'company', value: 'company' },
    { id: 'service_address', name: 'service_address', value: 'service_address' },
    { id: 'service_address_1', name: 'service_address_1', value: 'service_address_1' },
    { id: 'service_address_2', name: 'service_address_2', value: 'service_address_2' },
    { id: 'service_address_city', name: 'service_address_city', value: 'service_address_city' },
    { id: 'service_address_state', name: 'service_address_state', value: 'service_address_state' },
    { id: 'service_address_zip', name: 'service_address_zip', value: 'service_address_zip' },
    { id: 'billing_address', name: 'billing_address', value: 'billing_address' },
    { id: 'billing_address_1', name: 'billing_address_1', value: 'billing_address_1' },
    { id: 'billing_address_city', name: 'billing_address_city', value: 'billing_address_city' },
    { id: 'billing_address_state', name: 'billing_address_state', value: 'billing_address_state' },
    { id: 'billing_address_zip', name: 'billing_address_zip', value: 'billing_address_zip' },
    { id: 'credit', name: 'credit_card', value: 'credit' },
    { id: 'bank_accounts', name: 'bank_ach', value: 'bank_accounts' },
    { id: 'balance', name: 'balance', value: 'balance' },
    { id: 'added_by', name: 'added_by', value: 'added_by' },
    { id: 'created_at', name: 'date_added', value: 'created_at' },
    { id: 'source', name: 'source', value: 'source' },
    { id: 'qb_sync', name: 'qb_sync', value: 'qb_sync' },
    { id: 'qb_id', name: 'qb_id', value: 'qb_id' }
];

export const CUSTOMERS_COLUMNS_OPTIONS_ALL = CUSTOMERS_COLUMNS_OPTIONS.map((item) => item.value);

const CUSTOMERS_COLUMNS_DEFAULT_SELECTED = ['customer', 'emails', 'company', 'balance', 'qb_sync', 'qb_id'];

// Payment
const PAYMENT_COLUMNS_OPTIONS = [
    { id: 'account_number', name: 'account_number_#', value: 'account_number' },
    { id: 'payment_for', name: 'payment_for', value: 'payment_for' },
    { id: 'customer', name: 'customer', value: 'customer' },
    { id: 'method', name: 'method', value: 'method' },
    { id: 'date', name: 'date', value: 'date' },
    { id: 'amount', name: 'amount', value: 'amount' },
    { id: 'status', name: 'status', value: 'status' }
];

export const ALL_COLUMNS_PAYMENT = [
    'account_number',
    'payment_for',
    'customer',
    'method',
    'date',
    'amount',
    'status',
    'view'
];

const PAYMENT_COLUMNS_DEFAULT_SELECTED = [
    'account_number',
    'payment_for',
    'customer',
    'method',
    'date',
    'amount',
    'status'
];

//// Credit
export const CREDIT_COLUMNS_SELECT_OPTION = [
    'account_number',
    'customer',
    'method',
    'date',
    'total',
    'available_credit',
    'status'
];

const CREDIT_COLUMNS_OPTIONS = [
    { id: 'account_number', name: 'account_number_#', value: 'account_number' },
    { id: 'customer', name: 'customer', value: 'customer' },
    { id: 'method', name: 'method', value: 'method' },
    { id: 'date', name: 'date', value: 'date' },
    { id: 'total', name: 'total', value: 'total' },
    {
        id: 'available_credit',
        name: 'available_credit',
        value: 'available_credit'
    },
    { id: 'status', name: 'status', value: 'status' }
];
const CREDIT_COLUMNS_DEFAULT_SELECTED = [
    'account_number',
    'customer',
    'method',
    'date',
    'total',
    'available_credit',
    'status'
];

//// Recurring Invoice

export const ALL_COLUMNS_RECURRING_INVOICE = [
    'account_number',
    'customer',
    'job_name',
    'repeat_text',
    'action',
    'status',
    'total_value',
    'started',
    'end_date',
    'next_invoice'
];

const RECURRING_INVOICE_COLUMNS_OPTIONS = [
    { id: 'account_number', name: 'account_number_#', value: 'account_number' },
    { id: 'customer', name: 'customer', value: 'customer' },
    { id: 'job_name', name: 'job_name', value: 'job_name' },
    { id: 'repeat_text', name: 'repeats', value: 'repeat_text' },
    { id: 'action', name: 'action', value: 'action' },
    { id: 'status', name: 'status', value: 'status' },
    { id: 'total_value', name: 'total_value', value: 'total_value' },
    { id: 'started', name: 'started', value: 'started' },
    { id: 'end_date', name: 'end_date', value: 'end_date' },
    { id: 'next_invoice', name: 'next_invoice', value: 'next_invoice' }
];
const RECURRING_INVOICE_COLUMNS_DEFAULT_SELECTED = [
    'account_number',
    'customer',
    'job_name',
    'repeat_text',
    'action',
    'status',
    'total_value',
    'started',
    'end_date',
    'next_invoice'
];

//// Subscription

export const ALL_SUBSCRIPTION_COLUMNS = [
    'account_number',
    'customer',
    'email',
    'subscription_id',
    'payment_card',
    'user_id',
    'status',
    'plan',
    'total_value',
    'started',
    'end_date',
    'next_invoice',
    'edit'
];

const SUBSCRIPTION_COLUMNS_OPTIONS = [
    { id: 'account_number', name: 'account_number_#', value: 'account_number' },
    { id: 'email', name: 'stripe_email', value: 'email' },
    { id: 'subscription_id', name: 'stripe_id', value: 'subscription_id' },
    { id: 'payment_card', name: 'payment_method', value: 'payment_card' },
    { id: 'user_id', name: 'assigned_to', value: 'user_id' },
    { id: 'status', name: 'status', value: 'status' },
    { id: 'plan', name: 'plan', value: 'plan' },
    { id: 'total_value', name: 'total_value', value: 'total_value' },
    { id: 'started', name: 'started', value: 'started' },
    { id: 'end_date', name: 'end_date', value: 'end_date' },
    { id: 'next_invoice', name: 'next_invoice', value: 'next_invoice' }
];
const SUBSCRIPTION_COLUMNS_DEFAULT_SELECTED = [
    'account_number',
    'email',
    'subscription_id',
    'payment_card',
    'user_id',
    'status',
    'plan',
    'total_value',
    'started',
    'end_date',
    'next_invoice'
];

//// Square Subscription
const SQUARE_SUBSCRIPTION_COLUMNS_OPTIONS = [
    { id: 'account_number', name: 'account_number_#', value: 'account_number' },
    { id: 'email', name: 'square_email', value: 'email' },
    { id: 'subscription_id', name: 'square_id', value: 'subscription_id' },
    { id: 'payment_card', name: 'payment_method', value: 'payment_card' },
    { id: 'user_id', name: 'assigned_to', value: 'user_id' },
    { id: 'status', name: 'status', value: 'status' },
    { id: 'plan', name: 'plan', value: 'plan' },
    { id: 'total_value', name: 'total_value', value: 'total_value' },
    { id: 'started', name: 'started', value: 'started' },
    { id: 'end_date', name: 'end_date', value: 'end_date' },
    { id: 'next_invoice', name: 'next_invoice', value: 'next_invoice' }
];
const SQUARE_SUBSCRIPTION_COLUMNS_DEFAULT_SELECTED = [
    'account_number',
    'email',
    'subscription_id',
    'payment_card',
    'user_id',
    'status',
    'plan',
    'total_value',
    'started',
    'end_date',
    'next_invoice'
];

//// Location Wo Active Job
const WO_ACTIVE_JOBS_STATUS_OPTIONS = [
    { id: DEFAULT_ALL, name: 'all_status', value: DEFAULT_ALL },
    { id: 1, name: 'active', value: 1 },
    { id: 0, name: 'inactive', value: 0 }
];
const LOCATIONS_WO_ACTIVE_JOBS_COLUMNS_OPTIONS = [
    { id: 1, name: 'status', value: 'status' },
    { id: 2, name: 'account_number_#', value: 'account_number' },
    { id: 3, name: 'customer', value: 'customer' },
    { id: 4, name: 'mobile', value: 'mobile' },
    { id: 5, name: 'service_address', value: 'service_address' },
    { id: 6, name: 'service_address_city', value: 'service_address_city' },
    { id: 7, name: 'service_address_zip', value: 'service_address_zip' },
    { id: 8, name: 'last_service_type', value: 'last_service_type' },
    { id: 9, name: 'job_status', value: 'job_status' },
    { id: 10, name: 'last_service_date', value: 'last_service_date' },
    { id: 11, name: 'left_time', value: 'left_time' }
];
const LOCATIONS_WO_ACTIVE_JOBS_COLUMNS_DEFAULT_SELECT = [
    'status',
    'account_number',
    'customer',
    'mobile',
    'service_address',
    'service_address_city',
    'service_address_zip',
    'last_service_type',
    'job_status',
    'last_service_date',
    'left_time'
];
const LOCATIONS_WO_ACTIVE_JOBS_STATUS_OPTIONS = WO_ACTIVE_JOBS_STATUS_OPTIONS;
const LOCATIONS_WO_ACTIVE_JOBS_STATUS_DEFAULT_SELECT = DEFAULT_ALL;

//// Customers Wo Active Job
const CUSTOMERS_WO_ACTIVE_JOBS_COLUMNS_OPTIONS = [
    { id: 1, name: 'status', value: 'status' },
    { id: 3, name: 'customer_name', value: 'customer_name' },
    { id: 5, name: 'service_address', value: 'service_address' },
    { id: 8, name: 'last_service_type', value: 'last_service_type' },
    { id: 9, name: 'job_status', value: 'job_status' },
    { id: 10, name: 'last_service_date', value: 'last_service_date' }
];
const CUSTOMERS_WO_ACTIVE_JOBS_COLUMNS_DEFAULT_SELECT = [
    'status',
    'customer_name',
    'service_address',
    'last_service_type',
    'job_status',
    'last_service_date'
];
const CUSTOMERS_WO_ACTIVE_JOBS_STATUS_OPTIONS = WO_ACTIVE_JOBS_STATUS_OPTIONS;
const CUSTOMERS_WO_ACTIVE_JOBS_STATUS_DEFAULT_SELECT = DEFAULT_ALL;

//// New Customers
const NEW_CUSTOMERS_COLUMNS_OPTIONS = [
    { id: 1, name: 'customer_name', value: 'customer_name' },
    { id: 2, name: 'date_added', value: 'date_added' },
    { id: 3, name: 'source', value: 'source' },
    { id: 4, name: 'phones', value: 'phones' },
    { id: 5, name: 'email', value: 'email' },
    { id: 6, name: 'service_address', value: 'service_address' },
    { id: 7, name: 'active_job_schedules', value: 'active_job_schedules' }
];
const NEW_CUSTOMERS_COLUMNS_DEFAULT_SELECT = [
    'customer_name',
    'date_added',
    'source',
    'phones',
    'email',
    'service_address',
    'active_job_schedules'
];

//// Payments Collected
const PAYMENTS_COLLECTED_COLUMNS_OPTIONS = [
    { id: 1, name: 'payment_for', value: 'payment_for' },
    { id: 2, name: 'customer_name', value: 'customer_name' },
    { id: 3, name: 'method', value: 'method' },
    { id: 4, name: 'service_state', value: 'service_state' },
    { id: 5, name: 'staff', value: 'staff_name' },
    { id: 6, name: 'date', value: 'date' },
    { id: 7, name: 'amount', value: 'amount' },
    { id: 8, name: 'w_o_tax', value: 'w_o_tax' }
];
const PAYMENTS_COLLECTED_COLUMNS_DEFAULT_SELECT = [
    'payment_for',
    'customer_name',
    'method',
    'service_state',
    'staff',
    'date',
    'amount',
    'w_o_tax'
];
const PAYMENTS_COLLECTED_PAYMENT_FOR_OPTIONS = [
    { id: -1, name: 'both', value: DEFAULT_ALL },
    { id: 'Invoice', name: 'invoices', value: 'Invoice' },
    { id: 'Credit', name: 'credit', value: 'Credit' }
];

export const PAYMENTS_COLLECTED_PAYMENT_FOR_DEFAULT_SELECT = 'Invoice';

//// Total Sales

const TOTAL_SALES_COLUMNS_OPTIONS = [
    { id: 1, name: 'invoice', value: 'invoice' },
    { id: 2, name: 'customer_name', value: 'customer_name' },
    { id: 3, name: 'date', value: 'date' },
    { id: 4, name: 'status', value: 'status' },
    { id: 6, name: 'amount', value: 'amount' },
    { id: 7, name: 'w_o_tax', value: 'w_o_tax' }
];
const TOTAL_SALES_COLUMNS_DEFAULT_SELECT = ['invoice', 'customer_name', 'date', 'status', 'amount', 'w_o_tax'];

//// Sales Forecast
const SALES_FORECAST_COLUMNS_OPTIONS = [
    { id: 'customer', name: 'customer', value: 'customer' },
    { id: 'jan', name: 'jan', value: 'jan' },
    { id: 'feb', name: 'feb', value: 'feb' },
    { id: 'mar', name: 'mar', value: 'mar' },
    { id: 'apr', name: 'apr', value: 'apr' },
    { id: 'may', name: 'may', value: 'may' },
    { id: 'jun', name: 'jun', value: 'jun' },
    { id: 'jul', name: 'jul', value: 'jul' },
    { id: 'aug', name: 'aug', value: 'aug' },
    { id: 'sep', name: 'sep', value: 'sep' },
    { id: 'oct', name: 'oct', value: 'oct' },
    { id: 'nov', name: 'nov', value: 'nov' },
    { id: 'dec', name: 'dec', value: 'dec' },
    { id: 'total', name: 'total', value: 'total' }
];
const SALES_FORECAST_COLUMNS_DEFAULT_SELECT = [
    'customer',
    'jan',
    'feb',
    'mar',
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec',
    'total'
];

//// Material Use Detail
const MATERIAL_USE_DETAIL_COLUMNS_OPTIONS = [
    { id: 1, name: 'customer_name', value: 'customer_name' },
    { id: 2, name: 'service_type', value: 'service_type' },
    { id: 3, name: 'check_in', value: 'check_in' },
    { id: 4, name: 'check_out', value: 'check_out' },
    { id: 5, name: 'weather', value: 'weather' },
    { id: 6, name: 'epa', value: 'epa' },
    { id: 7, name: 'material', value: 'material' },
    { id: 8, name: 'qty', value: 'qty' },
    { id: 9, name: 'units', value: 'units' },
    { id: 10, name: 'date', value: 'date' },
    { id: 11, name: 'county', value: 'county' },
    { id: 12, name: 'address_1', value: 'address_1' },
    { id: 13, name: 'address_2', value: 'address_2' },
    { id: 14, name: 'city', value: 'city' },
    { id: 15, name: 'state', value: 'state' },
    { id: 16, name: 'zip', value: 'zip' },
    { id: 17, name: 'dilution', value: 'dilution' },
    { id: 18, name: 'method', value: 'method' },
    { id: 19, name: 'target', value: 'target' },
    { id: 20, name: 'location', value: 'location' },
    { id: 21, name: 'area', value: 'area' },
    { id: 22, name: 'assigned_to', value: 'assigned_to' }
];
const MATERIAL_USE_DETAIL_COLUMNS_DEFAULT_SELECT = [
    'customer_name',
    'service_type',
    'check_in',
    'check_out',
    'weather',
    'epa',
    'material',
    'qty',
    'units',
    'date',
    'county',
    'address_1',
    'address_2',
    'city',
    'state',
    'zip',
    'dilution',
    'method',
    'target',
    'location',
    'area',
    'assigned_to'
];

//// Email History
const EMAIL_HISTORY_COLUMNS_OPTIONS = [
    { id: 1, name: 'customer_name', value: 'customer_name' },
    { id: 2, name: 'email', value: 'email' },
    { id: 3, name: 'email_type', value: 'email_type' },
    { id: 4, name: 'status', value: 'status' },
    { id: 5, name: 'time_stamp', value: 'time_stamp' }
];
const EMAIL_HISTORY_COLUMNS_DEFAULT_SELECT = ['customer_name', 'email', 'email_type', 'status', 'time_stamp'];
const EMAIL_HISTORY_STATUS_OPTIONS = [
    { id: DEFAULT_ALL, name: 'all_status', value: DEFAULT_ALL },
    { id: 1, name: 'sending', value: 1 },
    { id: 2, name: 'sent', value: 2 },
    { id: 3, name: 'opened', value: 3 },
    { id: 4, name: 'clicked', value: 4 },
    { id: 5, name: 'bounced', value: 5 },
    { id: 6, name: 'failed', value: 6 },
    { id: -2, name: 'rejected', value: -2 }
];

//// Service Lookup
const SERVICE_LOOKUP_COLUMNS_OPTIONS = [
    { id: 'account_number', name: 'account_number_#', value: 'account_number' },
    { id: 'service_type', name: 'service_type', value: 'service_type' },
    { id: 'phones', name: 'phones', value: 'phones' },
    { id: 'email', name: 'email', value: 'email' },
    {
        id: 'service_address',
        name: 'service_address',
        value: 'service_address'
    },
    {
        id: 'service_address_1',
        name: 'service_address_1',
        value: 'service_address_1'
    },
    {
        id: 'service_address_2',
        name: 'service_address_2',
        value: 'service_address_2'
    },
    {
        id: 'service_address_city',
        name: 'service_address_city',
        value: 'service_address_city'
    },
    {
        id: 'service_address_state',
        name: 'service_address_state',
        value: 'service_address_state'
    },
    {
        id: 'service_address_zip',
        name: 'service_address_zip',
        value: 'service_address_zip'
    },
    { id: 'county', name: 'county', value: 'county' },
    {
        id: 'billing_address',
        name: 'billing_address',
        value: 'billing_address'
    },
    { id: 'source', name: 'source', value: 'source' },
    { id: 'invoice_number', name: 'invoice_#', value: 'invoice_number' },
    { id: 'invoice_value', name: 'invoice_value', value: 'invoice_value' },
    { id: 'w_o_tax', name: 'w_o_tax', value: 'w_o_tax' },
    { id: 'balance', name: 'balance', value: 'balance' },
    { id: 'invoice_status', name: 'invoice_status', value: 'invoice_status' },
    { id: 'scheduled_date', name: 'scheduled_date', value: 'scheduled_date' },
    { id: 'job_status', name: 'job_status', value: 'job_status' },
    { id: 'assigned_to', name: 'assigned_to', value: 'assigned_to' },
    { id: 'created_by', name: 'created_by', value: 'created_by' },
    {
        id: 'last_service_date',
        name: 'last_service_date',
        value: 'last_service_date'
    },
    { id: 'tag', name: 'tag', value: 'tag' },
    { id: 'service_no', name: 'service_no', value: 'service_no' },
    { id: 'check_in_time', name: 'check_in_time', value: 'check_in_time' },
    { id: 'check_out_time', name: 'check_out_time', value: 'check_out_time' },
    { id: 'location_name', name: 'location_name', value: 'location_name' },
    { id: 'job_frequency', name: 'job_frequency', value: 'job_frequency' }
];
export const SERVICE_LOOKUP_COLUMNS_SELECT_ALL = [
    'customer',
    'account_number',
    'service_type',
    'phones',
    'email',
    'service_address',
    'service_address_1',
    'service_address_2',
    'service_address_city',
    'service_address_state',
    'service_address_zip',
    'county',
    'billing_address',
    'source',
    'invoice_number',
    'invoice_value',
    'w_o_tax',
    'balance',
    'invoice_status',
    'scheduled_date',
    'job_status',
    'assigned_to',
    'created_by',
    'last_service_date',
    'tag',
    'service_no',
    'check_in_time',
    'check_out_time',
    'location_name',
    'job_frequency'
];
const SERVICE_LOOKUP_COLUMNS_DEFAULT_SELECT = [
    'service_type',
    'phones',
    'service_address',
    'service_address_city',
    'service_address_zip',
    'county',
    'invoice_value',
    'w_o_tax',
    'scheduled_date',
    'job_status',
    'assigned_to',
    'location_name'
];

const NEW_JOB_COLUMNS_OPTIONS = [
    { id: 'account_number', name: 'account_number_#', value: 'account_number' },
    { id: 'service_type', name: 'service_type', value: 'service_type' },
    { id: 'phones', name: 'phones', value: 'phones' },
    { id: 'email', name: 'email', value: 'email' },
    {
        id: 'service_address',
        name: 'service_address',
        value: 'service_address'
    },
    {
        id: 'service_address_1',
        name: 'service_address_1',
        value: 'service_address_1'
    },
    {
        id: 'service_address_2',
        name: 'service_address_2',
        value: 'service_address_2'
    },
    {
        id: 'service_address_city',
        name: 'service_address_city',
        value: 'service_address_city'
    },
    {
        id: 'service_address_state',
        name: 'service_address_state',
        value: 'service_address_state'
    },
    {
        id: 'service_address_zip',
        name: 'service_address_zip',
        value: 'service_address_zip'
    },
    { id: 'county', name: 'county', value: 'county' },
    {
        id: 'billing_address',
        name: 'billing_address',
        value: 'billing_address'
    },
    { id: 'source', name: 'source', value: 'source' },
    { id: 'invoice_number', name: 'invoice_#', value: 'invoice_number' },
    { id: 'invoice_value', name: 'invoice_value', value: 'invoice_value' },
    { id: 'w_o_tax', name: 'w_o_tax', value: 'w_o_tax' },
    { id: 'balance', name: 'balance', value: 'balance' },
    { id: 'invoice_status', name: 'invoice_status', value: 'invoice_status' },
    { id: 'scheduled_date', name: 'scheduled_date', value: 'scheduled_date' },
    { id: 'job_created_date', name: 'job_created_date', value: 'job_created_date' },
    { id: 'job_status', name: 'job_status', value: 'job_status' },
    { id: 'assigned_to', name: 'assigned_to', value: 'assigned_to' },
    { id: 'created_by', name: 'created_by', value: 'created_by' },
    {
        id: 'last_service_date',
        name: 'last_service_date',
        value: 'last_service_date'
    },
    { id: 'tag', name: 'tag', value: 'tag' },
    { id: 'service_no', name: 'service_no', value: 'service_no' },
    { id: 'check_in_time', name: 'check_in_time', value: 'check_in_time' },
    { id: 'check_out_time', name: 'check_out_time', value: 'check_out_time' },
    { id: 'location_name', name: 'location_name', value: 'location_name' }
];
export const NEW_JOB_COLUMNS_SELECT_ALL = [
    'customer',
    'account_number',
    'service_type',
    'phones',
    'email',
    'service_address',
    'service_address_1',
    'service_address_2',
    'service_address_city',
    'service_address_state',
    'service_address_zip',
    'county',
    'billing_address',
    'source',
    'invoice_number',
    'invoice_value',
    'w_o_tax',
    'balance',
    'invoice_status',
    'scheduled_date',
    'job_created_date',
    'job_status',
    'assigned_to',
    'created_by',
    'last_service_date',
    'tag',
    'service_no',
    'check_in_time',
    'check_out_time',
    'location_name'
];
const NEW_JOB_COLUMNS_DEFAULT_SELECT = [
    'source',
    'service_type',
    'phones',
    'service_address',
    'job_created_date',
    'job_status',
    'invoice_value'
];

export const INBOUND_LEADS_COLUMNS_SELECT_ALL = [
    'account_id',
    'account_number',
    'customer',
    'source',
    'email',
    'phone',
    'company',
    'service_address',
    'top_note',
    'note',
    'contacts',
    'locations',
    'status_label',
    'created_by',
    'date_created',
    'date_added',
    'active_jobs',
    'completed_jobs'
];
export const SUBSCRIPTION_COLUMNS_SELECT_ALL = [
    'account_number',
    'customer',
    'email',
    'subscription_id',
    'payment_card',
    'user_id',
    'status',
    'plan',
    'total_value',
    'started',
    'end_date',
    'next_invoice'
];

export const SQUARE_SUBSCRIPTION_COLUMNS_SELECT_ALL = [
    'account_number',
    'customer',
    'email',
    'subscription_id',
    'payment_card',
    'user_id',
    'status',
    'plan',
    'total_value',
    'started',
    'end_date',
    'next_invoice'
];

const SERVICE_LOOKUP_QUEST_LOG_JOB_STATUS_INCOMPLETE = [
    JOB_UNCONFIRMED,
    JOB_CONFIRMED,
    JOB_RESCHEDULE,
    JOB_PENDING_CONFIRMATION
];
const SERVICE_LOOKUP_QUEST_LOG_JOB_STATUS_COMPLETE = [JOB_COMPLETED];

export const SERVICE_LOOKUP_TABS = [
    {
        id: 1,
        name: 'active',
        value: COMMON.ACTIVE
    },
    {
        id: 2,
        name: 'deleted',
        value: COMMON.DELETED
    }
];

//// SALES TAX SUMMARY
const SALES_TAX_SUMMARY_COLUMNS_OPTIONS = {
    [REPORT_TYPE.SALES_TAX_SUMMARY_SALES_TAX_TAB]: [
        { id: 1, name: 'sales_tax', value: 'sales_tax' },
        { id: 2, name: 'taxable_sales', value: 'taxable_sales' },
        { id: 3, name: 'tax_rate', value: 'tax_rate' },
        { id: 4, name: 'tax_collected', value: 'tax_collected' },
        { id: 5, name: 'surcharge_tax', value: 'surcharge_tax' }
    ],
    [REPORT_TYPE.SALES_TAX_SUMMARY_TAX_SUMMARY_TAB]: [
        { id: 1, name: 'tax_rate', value: 'tax_rate' },
        { id: 2, name: 'date', value: 'date' },
        { id: 3, name: 'invoice_number', value: 'invoice_number' },
        { id: 4, name: 'status', value: 'status' },
        { id: 5, name: 'method', value: 'method' },
        { id: 6, name: 'customer', value: 'customer' },
        { id: 7, name: 'invoice_total', value: 'invoice_total' },
        { id: 8, name: 'taxable', value: 'taxable' },
        { id: 9, name: 'tax_collected', value: 'tax_collected' },
        { id: 10, name: 'surcharge_tax', value: 'surcharge_tax' }
    ]
};

const SALES_TAX_SUMMARY_COLUMNS_DEFAULT_SELECT = {
    [REPORT_TYPE.SALES_TAX_SUMMARY_SALES_TAX_TAB]: [
        'sales_tax',
        'taxable_sales',
        'tax_rate',
        'tax_collected',
        'surcharge_tax'
    ],
    [REPORT_TYPE.SALES_TAX_SUMMARY_TAX_SUMMARY_TAB]: [
        'tax_rate',
        'date',
        'invoice_number',
        'status',
        'method',
        'customer',
        'invoice_total',
        'taxable',
        'tax_collected',
        'surcharge_tax'
    ]
};

export const SALES_TAX_SUMMARY_TABS = [
    {
        id: 1,
        name: 'sales_tax',
        value: REPORT_TYPE.SALES_TAX_SUMMARY_SALES_TAX_TAB
    },
    {
        id: 2,
        name: 'tax_rate',
        value: REPORT_TYPE.SALES_TAX_SUMMARY_TAX_SUMMARY_TAB
    }
];

//// ACCOUNT AGING
const ACCOUNTS_AGING_COLUMNS_OPTIONS = [
    { id: 1, name: 'customer', value: 'customer' },
    { id: 3, name: 'phone', value: 'phone' },
    { id: 4, name: 'billing_email', value: 'billing_email' },
    { id: 5, name: 'payment_card', value: 'payment_card' },
    { id: 6, name: '0_30_days', value: '0_30_days' },
    { id: 7, name: '31_60_days', value: '31_60_days' },
    { id: 8, name: '61_90_days', value: '61_90_days' },
    { id: 9, name: 'over_90_days', value: 'over_90_days' },
    { id: 10, name: 'total', value: 'total' },
    { id: 11, name: 'available_credit', value: 'available_credit' }
];

const ACCOUNTS_AGING_COLUMNS_DEFAULT_SELECT = [
    'customer',
    'phone',
    'billing_email',
    'payment_card',
    '0_30_days',
    '31_60_days',
    '61_90_days',
    'over_90_days',
    'total',
    'available_credit'
];

const ACCOUNTS_AGING_FILTERS_AMOUNT_DAY_OPTIONS = [
    { id: 1, name: '0_30_days', value: VALUE_1 },
    { id: 2, name: '31_60_days', value: VALUE_2 },
    { id: 3, name: '61_90_days', value: VALUE_3 },
    { id: 4, name: 'over_90_days', value: VALUE_4 }
];

const ACCOUNTS_AGING_EMAIL_OPTIONS = [
    { id: 2, name: 'with_email', value: 2 },
    { id: 1, name: 'without_email', value: 1 }
];

//// Leads
export const LEADS_TABS = [
    { id: 1, name: 'inbound_leads', value: REPORT_TYPE.LEADS.inbound_leads },
    { id: 2, name: 'online_bookings', value: REPORT_TYPE.LEADS.online_bookings }
];

const LEADS_COLUMNS_OPTIONS = {
    [REPORT_TYPE.LEADS.inbound_leads]: [
        { id: 'account_id', name: 'account_id', value: 'account_id' },
        { id: 'account_number', name: 'account_no', value: 'account_number' },
        { id: 'customer', name: 'customer', value: 'customer' },
        { id: 'source', name: 'source', value: 'source' },
        { id: 'email', name: 'email', value: 'email' },
        { id: 'phone', name: 'phones', value: 'phone' },
        { id: 'company', name: 'company', value: 'company' },
        { id: 'service_address', name: 'address', value: 'service_address' },
        { id: 'top_note', name: 'top_note', value: 'top_note' },
        { id: 'note', name: 'notes', value: 'note' },
        { id: 'contacts', name: 'contacts', value: 'contacts' },
        { id: 'locations', name: 'locations', value: 'locations' },
        { id: 'status_label', name: 'status_label', value: 'status_label' },
        { id: 'created_by', name: 'created_by', value: 'created_by' },
        { id: 'date_created', name: 'date_created', value: 'date_created' },
        { id: 'date_added', name: 'date_added', value: 'date_added' },
        { id: 'active_jobs', name: 'active_job_schedule', value: 'active_jobs' },
        { id: 'completed_jobs', name: 'completed_jobs', value: 'completed_jobs' }
    ],
    [REPORT_TYPE.LEADS.online_bookings]: [
        { id: 'customer', name: 'customer', value: 'customer' },
        { id: 'date', name: 'date', value: 'date' },
        { id: 'time', name: 'time', value: 'time' },
        { id: 'service_name', name: 'service', value: 'service_name' },
        { id: 'email', name: 'email', value: 'email' },
        { id: 'phones', name: 'phones', value: 'phones' },
        { id: 'address', name: 'address', value: 'address' },
        { id: 'notes', name: 'notes', value: 'notes' },
        { id: 'status', name: 'status', value: 'status' }
    ]
};

const RATING_OPTIONS = [
    { id: 1, name: 'rating_great', value: 5 },
    { id: 2, name: 'rating_good', value: 4 },
    { id: 3, name: 'rating_average', value: 3 },
    { id: 4, name: 'rating_poor', value: 2 },
    { id: 5, name: 'rating_terrible', value: 1 }
];

const RATING_LIKELY_OPTIONS = [
    { id: 10, name: '10 (Very likely)', value: 10 },
    { id: 9, name: '9', value: 9 },
    { id: 8, name: '8', value: 8 },
    { id: 7, name: '7', value: 7 },
    { id: 6, name: '6', value: 6 },
    { id: 5, name: '5', value: 5 },
    { id: 4, name: '4', value: 4 },
    { id: 3, name: '3', value: 3 },
    { id: 2, name: '2', value: 2 },
    { id: 1, name: '1', value: 1 },
    { id: 0, name: '0 (Not likely)', value: 0 }
];

const SOURCE_INBOUND_OPTIONS = [
    { id: DEFAULT_ALL, name: 'all_source', value: DEFAULT_ALL },
    { id: 1, name: 'contact_us', value: 1 },
    { id: 2, name: 'online_bookings', value: 2 },
    { id: 3, name: 'power_cart', value: 3 },
    { id: 4, name: 'api', value: 4 },
    { id: 5, name: 'gorillaDesk', value: 5 }
];

const SOURCE_ONLINE_BOOKING_OPTIONS = [
    { id: DEFAULT_ALL, name: 'all_source', value: DEFAULT_ALL },
    { id: 1, name: 'online_bookings', value: -2 },
    { id: 2, name: 'power_cart', value: -3 }
];

const SOURCE_NEW_JOB_OPTIONS = [
    { id: DEFAULT_ALL, name: 'all_source', value: DEFAULT_ALL },
    { id: 1, name: 'source_created', value: 1 },
    { id: 2, name: 'source_recurring', value: 2 },
    { id: 3, name: 'source_online_booking', value: 3 },
    { id: 4, name: 'source_zapier', value: 4 },
    { id: 5, name: 'source_api', value: 5 }
];

const LEADS_COLUMNS_DEFAULT_SELECT = {
    [REPORT_TYPE.LEADS.inbound_leads]: ['customer', 'source', 'email', 'phone', 'note', 'date_added', 'active_jobs'],
    [REPORT_TYPE.LEADS.online_bookings]: [
        'customer',
        'date',
        'time',
        'service_name',
        'email',
        'phones',
        'address',
        'notes',
        'status'
    ]
};

const MATERIAL_USE_COLUMNS_OPTIONS = [
    {
        id: 'customer',
        name: 'customer',
        value: 'customer'
    },
    {
        id: 'service_name',
        name: 'service_name',
        value: 'service_name'
    },
    {
        id: 'check_in',
        name: 'check_in',
        value: 'check_in'
    },
    {
        id: 'check_out',
        name: 'check_out',
        value: 'check_out'
    },
    {
        id: 'weather',
        name: 'weather',
        value: 'weather'
    },
    {
        id: 'epa',
        name: 'epa',
        value: 'epa'
    },
    {
        id: 'material',
        name: 'material',
        value: 'material'
    },
    {
        id: 'qty',
        name: 'qty',
        value: 'qty'
    },
    {
        id: 'units',
        name: 'units',
        value: 'units'
    },
    {
        id: 'date',
        name: 'date',
        value: 'date'
    },
    {
        id: 'county',
        name: 'county',
        value: 'county'
    },
    {
        id: 'address_1',
        name: 'address_line_1',
        value: 'address_1'
    },
    {
        id: 'address_2',
        name: 'address_line_2',
        value: 'address_2'
    },
    {
        id: 'service_city',
        name: 'service_city',
        value: 'service_city'
    },
    {
        id: 'service_state',
        name: 'service_state',
        value: 'service_state'
    },
    {
        id: 'service_zip',
        name: 'service_zip',
        value: 'service_zip'
    },
    {
        id: 'dilution',
        name: 'dilution',
        value: 'dilution'
    },
    {
        id: 'method',
        name: 'method',
        value: 'method'
    },
    {
        id: 'target',
        name: 'target',
        value: 'target'
    },
    {
        id: 'location',
        name: 'location',
        value: 'location'
    },
    {
        id: 'area',
        name: 'area',
        value: 'area'
    },
    {
        id: 'assigned_to',
        name: 'assigned_to',
        value: 'assigned_to'
    }
];

const MATERIAL_USE_COLUMNS_DEFAULT_SELECT = [
    'customer',
    'service_name',
    'check_in',
    'check_out',
    'weather',
    'epa',
    'material',
    'qty',
    'units',
    'date',
    'county',
    'address_1',
    'address_2',
    'service_city',
    'service_state',
    'service_zip',
    'dilution',
    'method',
    'target',
    'location',
    'area',
    'assigned_to'
];

export const MATERIAL_NEW_YORK_COLUMNS_OPTIONS = [
    {
        id: 'epa',
        name: 'epa',
        value: 'epa'
    },
    {
        id: 'material',
        name: 'material',
        value: 'material'
    },
    {
        id: 'qty',
        name: 'qty',
        value: 'qty'
    },
    {
        id: 'units',
        name: 'units',
        value: 'units'
    },
    {
        id: 'date',
        name: 'date',
        value: 'date'
    },
    {
        id: 'county',
        name: 'county_code',
        value: 'county'
    },
    {
        id: 'address_1',
        name: 'address',
        value: 'address_1'
    },
    {
        id: 'service_city',
        name: 'city',
        value: 'service_city'
    },
    {
        id: 'service_zip',
        name: 'zip_code',
        value: 'service_zip'
    }
];

export const MATERIAL_NEW_YORK_COLUMNS_DEFAULT_SELECT = [
    'epa',
    'material',
    'qty',
    'units',
    'date',
    'county',
    'address_1',
    'service_city',
    'service_zip'
];

const DISPLAY_BY_OPTIONS = [
    { id: 0, name: 'invoice_date', value: 0 },
    { id: 1, name: 'payment_date', value: 1 }
];
const COMMISSION_OPTIONS = [
    { id: 1, name: 'standard', value: 1 },
    { id: 2, name: 'excluding_tax', value: 2 },
    { id: 3, name: 'excluding_discounts', value: 3 },
    {
        id: 4,
        name: 'excluding_tax_and_discounts',
        value: 4
    }
];

const COMMISSION_INVOICE_STATUS_OPTIONS = [
    { id: 'partial', name: 'partial', value: 'partial' },
    { id: 'partial_paid', name: 'partial_paid', value: 'partial_paid' },
    { id: 'sent', name: 'sent', value: 'sent' },
    { id: 'paid', name: 'paid', value: 'paid' },
    { id: 'failed', name: 'failed', value: 'failed' }
];

const CHARACTER_OPTIONS = [{ id: DEFAULT_ALL, name: 'A-Z', value: '' }];

//// Revenue Pages
const MONTH = 'month';
const DETAILS = 'details';

export const REVENUE_BY_CLIENT_MONTH_TAB_SELECTED = [
    'account_number',
    'customer',
    'jan',
    'feb',
    'mar',
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec',
    'total'
];
export const REVENUE_BY_CLIENT_DETAIL_TAB_SELECTED = [
    'account_number',
    'invoice_number',
    'customer',
    'method',
    'service_state',
    'date',
    'amount',
    'w_o_tax'
];
export const REVENUE_BY_CLIENT_TABS = [
    {
        id: 1,
        name: MONTH,
        value: REPORT_TYPE.REVENUE_BY_CLIENT_MONTH_TAB
    },
    {
        id: 2,
        name: DETAILS,
        value: REPORT_TYPE.REVENUE_BY_CLIENT_DETAIL_TAB
    }
];

export const REVENUE_BY_SERVICE_MONTH_TAB_SELECTED = [
    'service_name',
    'jan',
    'feb',
    'mar',
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec',
    'total'
];
export const REVENUE_BY_SERVICE_DETAIL_TAB_SELECTED = [
    'account_number',
    'service_name',
    'invoice_number',
    'customer',
    'method',
    'service_state',
    'date',
    'amount',
    'w_o_tax'
];
export const REVENUE_BY_SERVICE_TABS = [
    {
        id: 1,
        name: MONTH,
        value: REPORT_TYPE.REVENUE_BY_SERVICE_MONTH_TAB
    },
    {
        id: 2,
        name: DETAILS,
        value: REPORT_TYPE.REVENUE_BY_SERVICE_DETAIL_TAB
    }
];

export const REVENUE_BY_TAGS_MONTH_TAB_SELECTED = [
    'tag_name',
    'jan',
    'feb',
    'mar',
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec',
    'total'
];
export const REVENUE_BY_TAGS_DETAIL_TAB_SELECTED = [
    'account_number',
    'tag_name',
    'invoice_number',
    'customer',
    'method',
    'service_state',
    'date',
    'amount',
    'w_o_tax'
];
export const REVENUE_BY_TAGS_TABS = [
    {
        id: 1,
        name: MONTH,
        value: REPORT_TYPE.REVENUE_BY_TAGS_MONTH_TAB
    },
    {
        id: 2,
        name: DETAILS,
        value: REPORT_TYPE.REVENUE_BY_TAGS_DETAIL_TAB
    }
];

export const REVENUE_BY_ITEM_MONTH_TAB_SELECTED = [
    'item_name',
    'jan',
    'feb',
    'mar',
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec',
    'total'
];
export const REVENUE_BY_ITEM_DETAIL_TAB_SELECTED = [
    'account_number',
    'item_name',
    'invoice_number',
    'customer',
    'method',
    'service_state',
    'date',
    'assigned_to',
    'amount',
    'w_o_tax'
];
export const REVENUE_BY_ITEM_TABS = [
    {
        id: 1,
        name: MONTH,
        value: REPORT_TYPE.REVENUE_BY_ITEM_MONTH_TAB
    },
    {
        id: 2,
        name: DETAILS,
        value: REPORT_TYPE.REVENUE_BY_ITEM_DETAIL_TAB
    }
];

export const REVENUE_BY_STAFF_MONTH_TAB_SELECTED = [
    'user_name',
    'jan',
    'feb',
    'mar',
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec',
    'total'
];
export const REVENUE_BY_STAFF_DETAIL_TAB_SELECTED = [
    'account_number',
    'staff_name',
    'invoice_number',
    'customer',
    'method',
    'service_state',
    'date',
    'invoice_date',
    'amount',
    'w_o_tax',
    'subtotal_w_o_tax'
];
export const REVENUE_BY_STAFF_TABS = [
    { id: 1, name: MONTH, value: REPORT_TYPE.REVENUE_BY_STAFF_MONTH_TAB },
    { id: 2, name: DETAILS, value: REPORT_TYPE.REVENUE_BY_STAFF_DETAIL_TAB }
];

export const REVENUE_BY_SOURCE_MONTH_TAB_SELECTED = [
    'source_name',
    'jan',
    'feb',
    'mar',
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec',
    'total'
];
export const REVENUE_BY_SOURCE_DETAIL_TAB_SELECTED = [
    'account_number',
    'source_name',
    'invoice_number',
    'customer',
    'method',
    'service_state',
    'date',
    'amount',
    'w_o_tax'
];
export const REPORT_ACTIVITY_COMPARISON_SELECTED = [
    'outbound_call',
    'inbound_call',
    'total_duration_call',
    'avg_duration_call'
];
const REPORT_ACTIVITY_COMPARISON_OPTIONS = [
    { id: 'outbound_call', name: 'outbound_call', value: 'outbound_call' },
    { id: 'inbound_call', name: 'inbound_call', value: 'inbound_call' },
    { id: 'total_duration_call', name: 'total_duration_call', value: 'total_duration_call' },
    { id: 'avg_duration_call', name: 'avg_duration_call', value: 'avg_duration_call' }
];
export const REVENUE_BY_SOURCE_TABS = [
    {
        id: 1,
        name: MONTH,
        value: REPORT_TYPE.REVENUE_BY_SOURCE_MONTH_TAB
    },
    {
        id: 2,
        name: DETAILS,
        value: REPORT_TYPE.REVENUE_BY_SOURCE_DETAIL_TAB
    }
];

const LIST_REVENUE = [
    { id: 1, name: 'collected_cash_based', value: 'cash' },
    { id: 2, name: 'not_collected_accrual_based', value: 'accrual' }
];

////
const REVENUE_SALE_OPTIONS = [
    { id: 1, name: TOTAL_COLLECTED, value: TOTAL_COLLECTED },
    { id: 2, name: TOTAL_BILLDED, value: TOTAL_BILLDED }
];
const REVENUE_SALE_SELECTED = TOTAL_COLLECTED;

// Define Filter default for each type
const FILTER_COLLUMN = {
    name: COLUMNS,
    title: COLUMNS,
    options: INVOICE_COLUMNS_OPTIONS,
    selected: [],
    fileTranslation: REPORT,
    titleSelectAllOption: 'all_columns',
    type: TYPE_SELECT.MULTI
};

const FILTER_STATUS = {
    name: STATUS,
    title: STATUS,
    options: INVOICE_STATUS_OPTIONS,
    selected: DEFAULT_ALL,
    fileTranslation: REPORT,
    titleSelectAllOption: 'all_status',
    type: TYPE_SELECT.MULTI
};

const FILTER_LEAD_SOURCE_INBOUND = {
    name: SOURCE_INBOUND,
    title: SOURCE,
    options: SOURCE_INBOUND_OPTIONS,
    selected: DEFAULT_ALL,
    fileTranslation: REPORT,
    titleSelectAllOption: 'all_sources',
    type: TYPE_SELECT.SINGLE
};

const FILTER_LEAD_SOURCE_ONLINE_BOOKING = {
    ...FILTER_LEAD_SOURCE_INBOUND,
    name: SOURCE_ONLINE_BOOKING,
    options: SOURCE_ONLINE_BOOKING_OPTIONS
};

const FILTER_NEW_JOB_SOURCE = {
    name: SOURCE_NEW_JOB,
    title: SOURCE,
    options: SOURCE_NEW_JOB_OPTIONS,
    selected: DEFAULT_ALL,
    fileTranslation: REPORT,
    titleSelectAllOption: 'all_sources',
    type: TYPE_SELECT.SINGLE
};

const FILTER_SIGN_STATUS = {
    name: SIGN_STATUS,
    title: SIGN_STATUS,
    options: INVOICE_SIGN_STATUS_OPTIONS,
    selected: DEFAULT_ALL,
    fileTranslation: REPORT,
    titleSelectAllOption: 'all_statuses',
    type: TYPE_SELECT.MULTI
};

const FILTER_SERVICE = {
    name: SERVICE,
    title: SERVICE,
    options: [],
    selected: DEFAULT_ALL,
    fileTranslation: REPORT,
    titleSelectAllOption: 'all_services',
    action: getListSettingService,
    type: TYPE_SELECT.MULTI
};

const FILTER_USERS = {
    name: USERS,
    title: TEAMMATE,
    options: [],
    selected: DEFAULT_ALL,
    fileTranslation: REPORT,
    titleSelectAllOption: 'all',
    action: getListUserReview,
    type: TYPE_SELECT.MULTI
};

const FILTER_RATING = {
    name: ITEMS,
    title: ALL_RATING,
    options: RATING_OPTIONS,
    selected: DEFAULT_ALL,
    fileTranslation: REPORT,
    titleSelectAllOption: 'all_rating',
    type: TYPE_SELECT.MULTI_RATING
};

const FILTER_LIKELY_RATING = {
    name: ITEMS_LIKELY,
    title: ALL_RATING,
    options: RATING_LIKELY_OPTIONS,
    selected: DEFAULT_ALL,
    fileTranslation: REPORT,
    titleSelectAllOption: 'all_rating',
    type: TYPE_SELECT.MULTI
};

const FILTER_SERVICES = {
    ...FILTER_SERVICE,
    name: SERVICES
};

const FILTER_SERVICE_TYPE = {
    ...FILTER_SERVICE,
    name: SERVICE_TYPE,
    title: SERVICE_TYPE
};

const FILTER_SERVICE_TYPE_SINGLE = {
    name: SERVICE_TYPE_SINGLE,
    title: SERVICE_TYPE_SINGLE,
    options: [{ id: DEFAULT_ALL, name: 'all_services' }],
    selected: DEFAULT_ALL,
    fileTranslation: REPORT,
    titleSelectAllOption: 'all_services',
    action: getListSettingService,
    type: TYPE_SELECT.SINGLE
};

const FILTER_YEAR = {
    name: YEAR,
    title: YEAR,
    fileTranslation: REPORT,
    type: TYPE_SELECT.SINGLE
};

const FILTER_CLIENTS = {
    name: CLIENTS,
    title: CLIENTS,
    options: [],
    selected: [],
    fileTranslation: REPORT,
    titleSelectAllOption: CLIENT,
    action: getListSearchCustomer,
    type: TYPE_SELECT.CLIENTS
};

const FILTER_ITEM = {
    name: ITEM,
    title: ITEM,
    options: [{ id: DEFAULT_ALL, name: 'all_items' }],
    selected: DEFAULT_ALL,
    fileTranslation: REPORT,
    titleSelectAllOption: 'all_items',
    action: getListServiceFilterRevenueByItemRequest,
    type: TYPE_SELECT.SINGLE
};

const FILTER_ITEMS = {
    ...FILTER_ITEM,
    name: ITEMS,
    title: ITEMS,
    options: [],
    type: TYPE_SELECT.MULTI
};

const FILTER_SALE = {
    name: SALE,
    title: SALES,
    options: REVENUE_SALE_OPTIONS,
    selected: REVENUE_SALE_SELECTED,
    fileTranslation: REPORT,
    titleSelectAllOption: SALES,
    type: TYPE_SELECT.SINGLE
};

const FILTER_PAYMENT_METHOD = {
    name: PAYMENT_METHODS,
    title: METHODS,
    options: [],
    selected: DEFAULT_ALL,
    fileTranslation: REPORT,
    titleSelectAllOption: 'all',
    action: getListPaymentMethods,
    type: TYPE_SELECT.MULTI
};

const FILTER_SORT_BY = {
    name: SORT_BY,
    title: SORT_BY,
    options: [
        { id: 1, name: FIRST_NAME, value: FIRST_NAME },
        { id: 2, name: LAST_NAME, value: LAST_NAME }
    ],
    selected: FIRST_NAME,
    fileTranslation: REPORT,
    titleSelectAllOption: SORT_BY,
    type: TYPE_SELECT.SORT
};

const FILTER_TYPES = {
    name: TYPES,
    title: TYPES,
    options: [],
    selected: DEFAULT_ALL,
    fileTranslation: REPORT,
    titleSelectAllOption: 'all_types',
    action: getListTypeFilterRequest,
    type: TYPE_SELECT.MULTI
};

const FILTER_STAFF = {
    name: STAFF,
    title: STAFF,
    options: [{ id: DEFAULT_ALL, name: 'all_staff', value: DEFAULT_ALL }],
    selected: DEFAULT_ALL,
    fileTranslation: REPORT,
    titleSelectAllOption: 'all_staff',
    type: TYPE_SELECT.SINGLE
};

const FILTER_STAFFS = { ...FILTER_STAFF, options: [], name: STAFFS, type: TYPE_SELECT.MULTI };

const FILTER_ASSIGNED_TO = {
    ...FILTER_STAFF,
    name: ASSIGNED_TO,
    title: ASSIGNED_TO,
    titleSelectAllOption: 'all_assigned_to',
    type: TYPE_SELECT.MULTI
};

const FILTER_METHODS = {
    name: METHODS,
    title: METHOD,
    options: [],
    selected: DEFAULT_ALL,
    fileTranslation: REPORT,
    titleSelectAllOption: 'all_methods',
    action: getListMethodFilterRequest,
    type: TYPE_SELECT.MULTI
};

const FILTER_SOURCE = {
    name: SOURCE,
    title: SOURCE,
    options: [{ id: DEFAULT_ALL, name: 'all_source' }],
    selected: DEFAULT_ALL,
    fileTranslation: REPORT,
    titleSelectAllOption: 'all_sources',
    action: getListSettingSource,
    type: TYPE_SELECT.SINGLE
};
const FILTER_SOURCES = { ...FILTER_SOURCE, name: SOURCES, title: SOURCES, type: TYPE_SELECT.MULTI };
const FILTER_REPORT_SOURCE = { ...FILTER_SOURCE, name: REPORT_SOURCE };

const LIST_TAG_TYPE = [
    { id: 4, name: LOCATION_TAGS, value: 4 },
    { id: 3, name: CUSTOMER_TAGS, value: 3 }
];
const SELECTED_TAG = 3;

const FILTER_TAG_TYPE = {
    name: TAG_TYPE,
    title: LOCATION_TAGS,
    options: [],
    selected: SELECTED_TAG,
    fileTranslation: REPORT,
    type: TYPE_SELECT.SINGLE
};

const FILTER_PLANS = {
    name: PLANS,
    title: PLAN,
    options: [],
    selected: DEFAULT_ALL,
    fileTranslation: REPORT,
    titleSelectAllOption: 'all_plans',
    action: getListPlansSubscriptionStripeFilter,
    type: TYPE_SELECT.MULTI
};

const FILTER_SQUARE_PLANS = {
    name: SQUARE_PLANS,
    title: PLAN,
    options: [],
    selected: DEFAULT_ALL,
    fileTranslation: REPORT,
    titleSelectAllOption: 'all_plans',
    action: getListPlansSubscriptionSquareFilter,
    type: TYPE_SELECT.MULTI
};

const FILTER_PAYMENT_FOR = {
    name: PAYMENT_FOR,
    title: PAYMENT_FOR,
    options: PAYMENTS_COLLECTED_PAYMENT_FOR_OPTIONS,
    selected: DEFAULT_ALL,
    fileTranslation: REPORT,
    type: TYPE_SELECT.SINGLE
};

const FILTER_COUNTY = {
    name: COUNTY,
    title: COUNTY,
    options: [],
    selected: 'all',
    fileTranslation: REPORT,
    type: TYPE_SELECT.SINGLE
};

const FILTER_CUSTOMER_ID = {
    ...FILTER_CLIENTS,
    name: CUSTOMER_ID,
    title: ALL_CUSTOMERS,
    type: TYPE_SELECT.CUSTOMERS
};

const FILTER_JOB_STATUS = {
    name: JOB_STATUS,
    title: JOB_STATUS,
    options: [],
    selected: DEFAULT_ALL,
    fileTranslation: REPORT,
    titleSelectAllOption: 'all_status',
    type: TYPE_SELECT.MULTI,
    className: 'list-job-status --no-bg'
};

const FILTER_CITY = {
    name: CITY,
    title: 'city',
    options: [],
    selected: DEFAULT_ALL,
    fileTranslation: REPORT,
    titleSelectAllOption: 'all_cities',
    action: getListLocationCity,
    type: TYPE_SELECT.MULTI
};

const FILTER_ZIPCODE = {
    name: ZIPCODE,
    title: 'zip',
    options: [],
    selected: DEFAULT_ALL,
    fileTranslation: REPORT,
    titleSelectAllOption: 'all_zipcodes',
    action: getListLocationZip,
    type: TYPE_SELECT.MULTI
};

const FILTER_TAGS = {
    name: TAGS,
    title: 'tag',
    options: [],
    selected: DEFAULT_ALL,
    fileTranslation: REPORT,
    titleSelectAllOption: 'all_tags',
    action: getListSimpleTags,
    type: TYPE_SELECT.MULTI
};

const FILTER_CONVERSATION_TAGS = {
    name: CONVERSATION_TAGS,
    title: 'conversation_tag',
    options: [],
    selected: DEFAULT_ALL,
    fileTranslation: REPORT,
    titleSelectAllOption: 'all_conversation_tags',
    action: getListConversationTags,
    type: TYPE_SELECT.MULTI
};

const FILTER_REVENUE = {
    name: REVENUE,
    title: REVENUE,
    options: LIST_REVENUE,
    selected: 'cash',
    fileTranslation: REPORT,
    type: TYPE_SELECT.SINGLE
};

const FILTER_AMOUNT_DATE = {
    name: 'filters',
    title: 'days',
    options: [],
    selected: [],
    fileTranslation: REPORT,
    titleSelectAllOption: 'all',
    type: TYPE_SELECT.MULTI
};

const FILTER_EMAIL = {
    name: EMAIL,
    title: EMAIL,
    options: [],
    selected: [],
    fileTranslation: REPORT,
    titleSelectAllOption: 'both',
    type: TYPE_SELECT.MULTI
};

const FILTER_DISPLAY_BY = {
    name: DISPLAY_BY,
    title: DISPLAY_BY,
    options: DISPLAY_BY_OPTIONS,
    selected: 0,
    fileTranslation: REPORT,
    type: TYPE_SELECT.SINGLE
};

const FILTER_COMMISSION = {
    name: COMMISSION,
    title: COMMISSION,
    options: COMMISSION_OPTIONS,
    selected: 1,
    fileTranslation: REPORT,
    type: TYPE_SELECT.SINGLE
};

const FILTER_COMMISSION_INVOICE_STATUS = {
    name: INVOICE_STATUS,
    title: INVOICE_STATUS,
    options: COMMISSION_INVOICE_STATUS_OPTIONS,
    selected: DEFAULT_ALL,
    fileTranslation: REPORT,
    titleSelectAllOption: 'all',
    type: TYPE_SELECT.MULTI
};

const FILTER_CHARACTER = {
    name: CHARACTER,
    title: CHARACTER,
    options: CHARACTER_OPTIONS,
    selected: DEFAULT_ALL,
    fileTranslation: REPORT,
    titleSelectAllOption: SORT_BY,
    action: getListCustomerCharacter,
    type: TYPE_SELECT.SORT,
    isDisplaySortText: false,
    isSortCharacter: true
};
const FILTER_INVOICE_STATEMENT = {
    name: INVOICE_STATEMENT,
    title: INVOICE_STATEMENT,
    options: INVOICE_STATEMENT_OPTIONS,
    selected: DEFAULT_ALL,
    fileTranslation: REPORT,
    titleSelectAllOption: 'All',
    type: TYPE_SELECT.SINGLE
};
const FILTER_STATEMENT_TYPE = {
    name: STATEMENT_TYPE,
    title: STATEMENT_TYPE,
    options: STATEMENT_TYPE_OPTIONS,
    selected: 2,
    fileTranslation: REPORT,
    type: TYPE_SELECT.SINGLE
};
const FILTER_SEARCH_LOCATION = {
    name: SEARCH_LOCATION,
    title: SEARCH_LOCATION,
    options: [],
    action: getListLocation,
    fileTranslation: REPORT,
    type: TYPE_SELECT.SELECT_WITH_SEARCH,
    titleSelectAllOption: 'All Location',
    selected: DEFAULT_ALL
};
// Define Object Filter Default for all Type
const getFilterParams = {
    [COLUMNS]: FILTER_COLLUMN,
    [COLUMNS_NY]: FILTER_COLLUMN,
    [STATUS]: FILTER_STATUS,
    [SIGN_STATUS]: FILTER_SIGN_STATUS,
    [INVOICE_STATEMENT]: FILTER_INVOICE_STATEMENT,
    [STATEMENT_TYPE]: FILTER_STATEMENT_TYPE,
    [SERVICE]: FILTER_SERVICE,
    [SERVICES]: FILTER_SERVICES,
    [YEAR]: FILTER_YEAR,
    [ITEM]: FILTER_ITEM,
    [ITEMS]: FILTER_ITEMS,
    [CLIENTS]: FILTER_CLIENTS,
    [SALE]: FILTER_SALE,
    [PAYMENT_METHODS]: FILTER_PAYMENT_METHOD,
    [SORT_BY]: FILTER_SORT_BY,
    [TYPES]: FILTER_TYPES,
    [STAFF]: FILTER_STAFF,
    [STAFFS]: FILTER_STAFFS,
    [SOURCE]: FILTER_SOURCE,
    [SOURCES]: FILTER_SOURCES,
    [TAG_TYPE]: FILTER_TAG_TYPE,
    [METHODS]: FILTER_METHODS,
    [PLANS]: FILTER_PLANS,
    [SQUARE_PLANS]: FILTER_SQUARE_PLANS,
    [REPORT_SOURCE]: FILTER_REPORT_SOURCE,
    [PAYMENT_FOR]: FILTER_PAYMENT_FOR,
    [COUNTY]: FILTER_COUNTY,
    [CUSTOMER_ID]: FILTER_CUSTOMER_ID,
    [SERVICE_TYPE]: FILTER_SERVICE_TYPE,
    [SERVICE_TYPE_SINGLE]: FILTER_SERVICE_TYPE_SINGLE,
    [JOB_STATUS]: FILTER_JOB_STATUS,
    [ASSIGNED_TO]: FILTER_ASSIGNED_TO,
    [CITY]: FILTER_CITY,
    [ZIPCODE]: FILTER_ZIPCODE,
    [TAGS]: FILTER_TAGS,
    [REVENUE]: FILTER_REVENUE,
    [FILTERS]: FILTER_AMOUNT_DATE,
    [EMAIL]: FILTER_EMAIL,
    [DISPLAY_BY]: FILTER_DISPLAY_BY,
    [COMMISSION]: FILTER_COMMISSION,
    [INVOICE_STATUS]: FILTER_COMMISSION_INVOICE_STATUS,
    [CHARACTER]: FILTER_CHARACTER,
    [SEARCH_LOCATION]: FILTER_SEARCH_LOCATION,
    [USERS]: FILTER_USERS,
    [ITEMS]: FILTER_RATING,
    [ITEMS_LIKELY]: FILTER_LIKELY_RATING,
    [SOURCE_INBOUND]: FILTER_LEAD_SOURCE_INBOUND,
    [SOURCE_ONLINE_BOOKING]: FILTER_LEAD_SOURCE_ONLINE_BOOKING,
    [SOURCE_NEW_JOB]: FILTER_NEW_JOB_SOURCE,
    [CONVERSATION_TAGS]: FILTER_CONVERSATION_TAGS
};
////////////////////////////////

// Get Column Options
const getColumnOptions = {
    [REPORT_TYPE.INVOICE]: {
        options: INVOICE_COLUMNS_OPTIONS,
        selected: INVOICE_COLUMNS_DEFAULT_SELECTED
    },
    [REPORT_TYPE.ESTIMATE]: {
        options: ESTIMATE_COLUMNS_OPTIONS,
        selected: ESTIMATE_COLUMNS_DEFAULT_SELECTED
    },
    [REPORT_TYPE.DOCUMENT]: {
        options: DOCUMENT_COLUMNS_OPTIONS,
        selected: DOCUMENT_COLUMNS_DEFAULT_SELECTED
    },
    [REPORT_TYPE.DOCUMENT_CUSTOMER_LIST]: {
        options: DOCUMENT_COLUMNS_OPTIONS,
        selected: DOCUMENT_COLUMNS_DEFAULT_SELECTED
    },
    [REPORT_TYPE.CUSTOMERS]: {
        options: CUSTOMERS_COLUMNS_OPTIONS,
        selected: CUSTOMERS_COLUMNS_DEFAULT_SELECTED
    },
    [REPORT_TYPE.PAYMENT]: {
        options: PAYMENT_COLUMNS_OPTIONS,
        selected: PAYMENT_COLUMNS_DEFAULT_SELECTED
    },
    [REPORT_TYPE.CREDIT]: {
        options: CREDIT_COLUMNS_OPTIONS,
        selected: CREDIT_COLUMNS_DEFAULT_SELECTED
    },
    [REPORT_TYPE.RECURRING_INVOICE]: {
        options: RECURRING_INVOICE_COLUMNS_OPTIONS,
        selected: RECURRING_INVOICE_COLUMNS_DEFAULT_SELECTED
    },
    [REPORT_TYPE.SUBSCRIPTION[PAYMENT_CARD_CUSTOMER.STRIPE]]: {
        options: SUBSCRIPTION_COLUMNS_OPTIONS,
        selected: SUBSCRIPTION_COLUMNS_DEFAULT_SELECTED
    },
    [REPORT_TYPE.SUBSCRIPTION[PAYMENT_CARD_CUSTOMER.SQUARE]]: {
        options: SQUARE_SUBSCRIPTION_COLUMNS_OPTIONS,
        selected: SQUARE_SUBSCRIPTION_COLUMNS_DEFAULT_SELECTED
    },
    [REPORT_TYPE.LOCATIONS_WO_ACTIVE_JOBS]: {
        options: LOCATIONS_WO_ACTIVE_JOBS_COLUMNS_OPTIONS,
        selected: LOCATIONS_WO_ACTIVE_JOBS_COLUMNS_DEFAULT_SELECT
    },
    [REPORT_TYPE.CUSTOMERS_WO_ACTIVE_JOBS]: {
        options: CUSTOMERS_WO_ACTIVE_JOBS_COLUMNS_OPTIONS,
        selected: CUSTOMERS_WO_ACTIVE_JOBS_COLUMNS_DEFAULT_SELECT
    },
    [REPORT_TYPE.NEW_CUSTOMERS]: {
        options: NEW_CUSTOMERS_COLUMNS_OPTIONS,
        selected: NEW_CUSTOMERS_COLUMNS_DEFAULT_SELECT
    },
    [REPORT_TYPE.PAYMENTS_COLLECTED]: {
        options: PAYMENTS_COLLECTED_COLUMNS_OPTIONS,
        selected: PAYMENTS_COLLECTED_COLUMNS_DEFAULT_SELECT
    },
    [REPORT_TYPE.TOTAL_SALES]: {
        options: TOTAL_SALES_COLUMNS_OPTIONS,
        selected: TOTAL_SALES_COLUMNS_DEFAULT_SELECT
    },
    [REPORT_TYPE.SALES_FORECAST]: {
        options: SALES_FORECAST_COLUMNS_OPTIONS,
        selected: SALES_FORECAST_COLUMNS_DEFAULT_SELECT
    },
    [REPORT_TYPE.MATERIAL_USE_DETAIL]: {
        options: MATERIAL_USE_DETAIL_COLUMNS_OPTIONS,
        selected: MATERIAL_USE_DETAIL_COLUMNS_DEFAULT_SELECT
    },
    [REPORT_TYPE.EMAIL_HISTORY]: {
        options: EMAIL_HISTORY_COLUMNS_OPTIONS,
        selected: EMAIL_HISTORY_COLUMNS_DEFAULT_SELECT
    },
    [REPORT_TYPE.SERVICE_LOOKUP]: {
        options: SERVICE_LOOKUP_COLUMNS_OPTIONS,
        selected: SERVICE_LOOKUP_COLUMNS_DEFAULT_SELECT,
        selectedAll: SERVICE_LOOKUP_COLUMNS_SELECT_ALL
    },
    [REPORT_TYPE.SALES_TAX_SUMMARY_SALES_TAX_TAB]: {
        options: SALES_TAX_SUMMARY_COLUMNS_OPTIONS[REPORT_TYPE.SALES_TAX_SUMMARY_SALES_TAX_TAB],
        selected: SALES_TAX_SUMMARY_COLUMNS_DEFAULT_SELECT[REPORT_TYPE.SALES_TAX_SUMMARY_SALES_TAX_TAB]
    },
    [REPORT_TYPE.SALES_TAX_SUMMARY_TAX_SUMMARY_TAB]: {
        options: SALES_TAX_SUMMARY_COLUMNS_OPTIONS[REPORT_TYPE.SALES_TAX_SUMMARY_TAX_SUMMARY_TAB],
        selected: SALES_TAX_SUMMARY_COLUMNS_DEFAULT_SELECT[REPORT_TYPE.SALES_TAX_SUMMARY_TAX_SUMMARY_TAB]
    },
    [REPORT_TYPE.REVENUE_BY_CLIENT_MONTH_TAB]: {
        selected: REVENUE_BY_CLIENT_MONTH_TAB_SELECTED
    },
    [REPORT_TYPE.REVENUE_BY_CLIENT_DETAIL_TAB]: {
        selected: REVENUE_BY_CLIENT_DETAIL_TAB_SELECTED
    },

    [REPORT_TYPE.REVENUE_BY_SERVICE_MONTH_TAB]: {
        selected: REVENUE_BY_SERVICE_MONTH_TAB_SELECTED
    },
    [REPORT_TYPE.REVENUE_BY_SERVICE_DETAIL_TAB]: {
        selected: REVENUE_BY_SERVICE_DETAIL_TAB_SELECTED
    },
    [REPORT_TYPE.REVENUE_BY_ITEM_MONTH_TAB]: {
        selected: REVENUE_BY_ITEM_MONTH_TAB_SELECTED
    },
    [REPORT_TYPE.REVENUE_BY_ITEM_DETAIL_TAB]: {
        selected: REVENUE_BY_ITEM_DETAIL_TAB_SELECTED
    },

    [REPORT_TYPE.REVENUE_BY_STAFF_MONTH_TAB]: {
        selected: REVENUE_BY_STAFF_MONTH_TAB_SELECTED
    },
    [REPORT_TYPE.REVENUE_BY_STAFF_DETAIL_TAB]: {
        selected: REVENUE_BY_STAFF_DETAIL_TAB_SELECTED
    },

    [REPORT_TYPE.REVENUE_BY_SOURCE_MONTH_TAB]: {
        selected: REVENUE_BY_SOURCE_MONTH_TAB_SELECTED
    },
    [REPORT_TYPE.REVENUE_BY_SOURCE_DETAIL_TAB]: {
        selected: REVENUE_BY_SOURCE_DETAIL_TAB_SELECTED
    },

    [REPORT_TYPE.REVENUE_BY_TAGS_MONTH_TAB]: {
        selected: REVENUE_BY_TAGS_MONTH_TAB_SELECTED
    },
    [REPORT_TYPE.REVENUE_BY_TAGS_DETAIL_TAB]: {
        selected: REVENUE_BY_TAGS_DETAIL_TAB_SELECTED
    },

    [REPORT_TYPE.ACCOUNTS_AGING]: {
        options: ACCOUNTS_AGING_COLUMNS_OPTIONS,
        selected: ACCOUNTS_AGING_COLUMNS_DEFAULT_SELECT
    },
    [REPORT_TYPE.LEADS.inbound_leads]: {
        options: LEADS_COLUMNS_OPTIONS[REPORT_TYPE.LEADS.inbound_leads],
        selected: LEADS_COLUMNS_DEFAULT_SELECT[REPORT_TYPE.LEADS.inbound_leads]
    },
    [REPORT_TYPE.LEADS.online_bookings]: {
        options: LEADS_COLUMNS_OPTIONS[REPORT_TYPE.LEADS.online_bookings],
        selected: LEADS_COLUMNS_DEFAULT_SELECT[REPORT_TYPE.LEADS.online_bookings]
    },
    [REPORT_TYPE.MATERIAL_USE]: {
        options: MATERIAL_USE_COLUMNS_OPTIONS,
        selected: MATERIAL_USE_COLUMNS_DEFAULT_SELECT
    },
    [REPORT_TYPE.MATERIAL_NEW_YORK]: {
        options: MATERIAL_NEW_YORK_COLUMNS_OPTIONS,
        selected: MATERIAL_NEW_YORK_COLUMNS_DEFAULT_SELECT
    },
    [REPORT_TYPE.STATEMENT_CUSTOMER_LIST]: {
        options: STATEMENT_COLUMNS_OPTIONS,
        selected: STATEMENT_COLUMNS_DEFAULT_SELECTED
    },
    [REPORT_TYPE.LOCATION_CUSTOMER_LIST]: {
        options: LOCATION_COLUMNS_OPTIONS,
        selected: LOCATION_COLUMNS_DEFAULT_SELECTED,
        isPopper: true
    },
    [REPORT_TYPE.ACTIVITY_COMPARISON]: {
        options: REPORT_ACTIVITY_COMPARISON_OPTIONS,
        selected: REPORT_ACTIVITY_COMPARISON_SELECTED
    },
    [REPORT_TYPE.NEW_JOB]: {
        options: NEW_JOB_COLUMNS_OPTIONS,
        selected: NEW_JOB_COLUMNS_DEFAULT_SELECT,
        selectedAll: NEW_JOB_COLUMNS_SELECT_ALL
    }
};

const getColumnsNy = {
    [REPORT_TYPE.MATERIAL_USE]: {
        options: MATERIAL_NEW_YORK_COLUMNS_OPTIONS,
        selected: MATERIAL_NEW_YORK_COLUMNS_DEFAULT_SELECT,
        name: 'columns_ny',
        title: 'columns',
        fileTranslation: REPORT,
        titleSelectAllOption: 'all_columns',
        type: TYPE_SELECT.MULTI
    }
};

// Get status Options
const getStatusOptions = {
    [REPORT_TYPE.INVOICE]: {
        options: INVOICE_STATUS_OPTIONS,
        selected: DEFAULT_ALL
    },

    [REPORT_TYPE.ESTIMATE]: {
        options: ESTIMATE_STATUS_OPTIONS,
        selected: DEFAULT_ALL
    },
    [REPORT_TYPE.DOCUMENT]: {
        options: DOCUMENT_SIGN_STATUS_OPTIONS,
        selected: DEFAULT_ALL
    },
    [REPORT_TYPE.DOCUMENT_CUSTOMER_LIST]: {
        options: DOCUMENT_SIGN_STATUS_OPTIONS,
        selected: DEFAULT_ALL
    },
    [REPORT_TYPE.CUSTOMERS]: {
        options: CUSTOMERS_STATUS_OPTIONS,
        selected: [0, 1, 2]
    },
    [REPORT_TYPE.LOCATIONS_WO_ACTIVE_JOBS]: {
        options: LOCATIONS_WO_ACTIVE_JOBS_STATUS_OPTIONS,
        selected: LOCATIONS_WO_ACTIVE_JOBS_STATUS_DEFAULT_SELECT,
        type: TYPE_SELECT.SINGLE
    },
    [REPORT_TYPE.CUSTOMERS_WO_ACTIVE_JOBS]: {
        options: CUSTOMERS_WO_ACTIVE_JOBS_STATUS_OPTIONS,
        selected: CUSTOMERS_WO_ACTIVE_JOBS_STATUS_DEFAULT_SELECT,
        type: TYPE_SELECT.SINGLE
    },
    [REPORT_TYPE.EMAIL_HISTORY]: {
        options: EMAIL_HISTORY_STATUS_OPTIONS,
        selected: DEFAULT_ALL,
        type: TYPE_SELECT.SINGLE
    },
    [REPORT_TYPE.CHATBOT_TRAIN_AI]: {
        options: STATUS_TRAIN_OPTIONS,
        selected: DEFAULT_ALL,
        nullIsAll: true,
        titleSelectAllOption: 'all_status'
    },
    [REPORT_TYPE.CHATBOT_CUSTOM_ANSWER]: {
        options: STATUS_OPTIONS,
        selected: DEFAULT_ALL,
        nullIsAll: true,
        titleSelectAllOption: 'all_status'
    },
    [REPORT_TYPE.CHATBOT_CONTENT]: {
        options: STATUS_OPTIONS,
        selected: DEFAULT_ALL,
        nullIsAll: true,
        titleSelectAllOption: 'all_status'
    },
    [REPORT_TYPE.CHATBOT_REPORT]: {
        options: REPORT_STATUS_OPTIONS,
        selected: DEFAULT_ALL,
        nullIsAll: true,
        titleSelectAllOption: 'all_status'
    }
};

const getInvoiceOption = {
    [REPORT_TYPE.STATEMENT_CUSTOMER_LIST]: {
        options: INVOICE_STATEMENT_OPTIONS,
        selected: DEFAULT_ALL,
        title: 'All',
        icon: IconDone
    }
};

const getStatementTypeOptions = {
    [REPORT_TYPE.STATEMENT_CUSTOMER_LIST]: {
        options: STATEMENT_TYPE_OPTIONS,
        selected: 2
    }
};

// get status options
const getSignStatusOptions = {
    [REPORT_TYPE.INVOICE]: {
        options: INVOICE_SIGN_STATUS_OPTIONS,
        selected: DEFAULT_ALL
    },
    [REPORT_TYPE.ESTIMATE]: {
        options: ESTIMATE_SIGN_STATUS_OPTIONS,
        selected: DEFAULT_ALL
    }
};

// get Service Options
const getServiceOptions = {
    [REPORT_TYPE.INVOICE]: {
        options: [],
        selected: DEFAULT_ALL
    },
    [REPORT_TYPE.CUSTOMERS]: {
        options: [{ id: DEFAULT_ALL, name: 'all_services' }],
        selected: DEFAULT_ALL,
        action: getListSettingService,
        title: 'active_service',
        icon: IconDone
    },
    [REPORT_TYPE.REVENUE_BY_SERVICE]: {
        options: [],
        selected: DEFAULT_ALL
    },
    [REPORT_TYPE.CUSTOMERS_WO_ACTIVE_JOBS]: {
        options: [{ id: DEFAULT_ALL, name: 'all_services' }],
        selected: DEFAULT_ALL,
        action: getListSettingService,
        title: 'service_type'
    },

    [REPORT_TYPE.SERVICE_LOOKUP]: {
        options: [],
        selected: DEFAULT_ALL,
        title: 'service_type',
        action: getListServiceTypeRequest
    },
    [REPORT_TYPE.REVENUE_BY_SERVICE]: {
        options: [{ id: DEFAULT_ALL, name: 'all_services' }],
        selected: DEFAULT_ALL,
        action: getListSettingService,
        title: 'service_type'
    },
    [REPORT_TYPE.ACCOUNTS_AGING]: {
        options: [],
        selected: DEFAULT_ALL,
        title: 'service_type',
        action: getListServiceTypeRequest
    },
    [REPORT_TYPE.NEW_JOB]: {
        options: [],
        selected: DEFAULT_ALL,
        title: 'service_type',
        action: getListServiceTypeRequest
    }
};

const getUsersOptions = {
    [REPORT_TYPE.REVIEW_PAGE_PARAMS_SERVICE]: {
        options: [],
        selected: DEFAULT_ALL
    },
    [REPORT_TYPE.WORK_LOG]: {
        options: [],
        selected: DEFAULT_ALL
    }
};

const getRatingOptions = {
    [REPORT_TYPE.REVIEW_PAGE_PARAMS_SERVICE]: {
        options: RATING_OPTIONS,
        selected: DEFAULT_ALL
    }
};

const getRatingLikelyOptions = {
    [REPORT_TYPE.REVIEW_PAGE_PARAMS_LIKELY]: {
        options: RATING_LIKELY_OPTIONS,
        selected: DEFAULT_ALL
    }
};

const LIST_YEAR_FORECAST = [
    { id: 1, name: YEAR_CURRENT, value: YEAR_CURRENT },
    { id: 2, name: YEAR_CURRENT + 1, value: YEAR_CURRENT + 1 }
];

const getYearOptions = {
    [REPORT_TYPE.REVENUE_BY_CLIENT]: {
        options: [],
        action: getListYearRevenue,
        selected: YEAR_CURRENT
    },
    [REPORT_TYPE.REVENUE_BY_SERVICE]: {
        options: [],
        action: getListYearRevenue,
        selected: YEAR_CURRENT
    },
    [REPORT_TYPE.REVENUE_BY_ITEM]: {
        options: [],
        action: getListYearRevenue,
        selected: YEAR_CURRENT
    },
    [REPORT_TYPE.REVENUE_BY_STAFF]: {
        options: [],
        action: getListYearRevenue,
        selected: YEAR_CURRENT
    },
    [REPORT_TYPE.REVENUE_BY_SOURCE]: {
        options: [],
        action: getListYearRevenue,
        selected: YEAR_CURRENT
    },
    [REPORT_TYPE.REVENUE_BY_TAGS]: {
        options: [],
        action: getListYearRevenue,
        selected: YEAR_CURRENT
    },
    [REPORT_TYPE.SALES_FORECAST]: {
        options: LIST_YEAR_FORECAST,
        selected: YEAR_CURRENT
    }
};

// get client
const getClientOptions = {
    [REPORT_TYPE.REVENUE_BY_CLIENT]: {
        options: [{ id: DEFAULT_ALL, name: 'all_items' }],
        selected: DEFAULT_ALL,
        action: getListServiceFilterRevenueByItemRequest,
        title: ALL_CLIENTS
    },
    [REPORT_TYPE.REVENUE_BY_ITEM]: {
        options: [{ id: DEFAULT_ALL, name: 'all_items' }],
        selected: DEFAULT_ALL,
        action: getListServiceFilterRevenueByItemRequest,
        title: 'all_items'
    },
    [REPORT_TYPE.PAYMENTS_COLLECTED]: {
        options: [{ id: DEFAULT_ALL, name: ALL_CLIENTS }],
        selected: DEFAULT_ALL,
        title: ALL_CLIENTS
    },
    [REPORT_TYPE.TOTAL_SALES]: {
        options: [],
        selected: DEFAULT_ALL,
        title: ALL_CLIENTS
    },
    [REPORT_TYPE.EMAIL_HISTORY]: {
        options: [],
        selected: []
    }
};

const getItemsOptions = {};

// get sale
const getSaleOptions = {
    [REPORT_TYPE.REVENUE_BY_CLIENT]: {
        options: REVENUE_SALE_OPTIONS,
        selected: REVENUE_SALE_SELECTED
    },
    [REPORT_TYPE.REVENUE_BY_SERVICE]: {
        options: REVENUE_SALE_OPTIONS,
        selected: REVENUE_SALE_SELECTED
    },
    [REPORT_TYPE.REVENUE_BY_ITEM]: {
        options: REVENUE_SALE_OPTIONS,
        selected: REVENUE_SALE_SELECTED
    },
    [REPORT_TYPE.REVENUE_BY_STAFF]: {
        options: REVENUE_SALE_OPTIONS,
        selected: REVENUE_SALE_SELECTED
    },
    [REPORT_TYPE.REVENUE_BY_SOURCE]: {
        options: REVENUE_SALE_OPTIONS,
        selected: REVENUE_SALE_SELECTED
    },
    [REPORT_TYPE.REVENUE_BY_TAGS]: {
        options: REVENUE_SALE_OPTIONS,
        selected: REVENUE_SALE_SELECTED
    }
};

// get payment methods
const getPaymentMethodsOptions = {
    [REPORT_TYPE.REVENUE_BY_CLIENT]: {
        options: [],
        selected: DEFAULT_ALL
    },
    [REPORT_TYPE.REVENUE_BY_SERVICE]: {
        options: [],
        selected: DEFAULT_ALL
    },
    [REPORT_TYPE.REVENUE_BY_ITEM]: {
        options: [],
        selected: DEFAULT_ALL
    },
    [REPORT_TYPE.REVENUE_BY_STAFF]: {
        options: [],
        selected: DEFAULT_ALL
    },
    [REPORT_TYPE.REVENUE_BY_SOURCE]: {
        options: [],
        selected: DEFAULT_ALL
    },
    [REPORT_TYPE.REVENUE_BY_TAGS]: {
        options: [],
        selected: DEFAULT_ALL
    },
    [REPORT_TYPE.SALES_TAX_SUMMARY]: {
        options: [],
        selected: DEFAULT_ALL
    },
    [REPORT_TYPE.COMMISSIONS]: {
        options: [],
        selected: DEFAULT_ALL
    },
    [REPORT_TYPE.PAYMENT]: {
        options: [],
        selected: DEFAULT_ALL
    },
    [REPORT_TYPE.PAYMENTS_COLLECTED]: {
        options: [],
        selected: DEFAULT_ALL
    }
};

// get SortBy
const LIST_SORT = [
    { id: 1, name: FIRST_NAME, value: FIRST_NAME },
    { id: 2, name: LAST_NAME, value: LAST_NAME }
];
const SELECTED_SORT = FIRST_NAME;

const getSortByOptions = {
    [REPORT_TYPE.REVENUE_BY_STAFF]: {
        options: LIST_SORT,
        selected: SELECTED_SORT
    },

    [REPORT_TYPE.REVENUE_BY_SOURCE]: {
        options: LIST_SORT,
        selected: SELECTED_SORT
    },

    [REPORT_TYPE.REVENUE_BY_TAGS]: {
        options: LIST_SORT,
        selected: SELECTED_SORT
    },

    [REPORT_TYPE.RECURRING_INVOICE]: {
        options: LIST_SORT,
        selected: SELECTED_SORT
    },
    [REPORT_TYPE.SUBSCRIPTION[PAYMENT_CARD_CUSTOMER.STRIPE]]: {
        options: LIST_SORT,
        selected: SELECTED_SORT
    },
    [REPORT_TYPE.LOCATIONS_WO_ACTIVE_JOBS]: {
        options: LIST_SORT,
        selected: SELECTED_SORT
    },
    [REPORT_TYPE.CUSTOMERS_WO_ACTIVE_JOBS]: {
        options: LIST_SORT,
        selected: SELECTED_SORT
    },
    [REPORT_TYPE.NEW_CUSTOMERS]: {
        options: LIST_SORT,
        selected: SELECTED_SORT
    },
    [REPORT_TYPE.PAYMENTS_COLLECTED]: {
        options: LIST_SORT,
        selected: SELECTED_SORT
    },
    [REPORT_TYPE.TOTAL_SALES]: {
        options: LIST_SORT,
        selected: SELECTED_SORT
    },
    [REPORT_TYPE.EMAIL_HISTORY]: {
        options: LIST_SORT,
        selected: SELECTED_SORT
    },
    [REPORT_TYPE.SERVICE_LOOKUP]: {
        options: LIST_SORT,
        selected: SELECTED_SORT
    },
    [REPORT_TYPE.SALES_TAX_SUMMARY]: {
        options: LIST_SORT,
        selected: FIRST_NAME
    },
    [REPORT_TYPE.REVENUE_BY_CLIENT]: {
        options: LIST_SORT,
        selected: FIRST_NAME
    },
    [REPORT_TYPE.ACCOUNTS_AGING]: {
        options: LIST_SORT,
        selected: SELECTED_SORT
    },
    [REPORT_TYPE.LEADS.inbound_leads]: {
        options: LIST_SORT,
        selected: SELECTED_SORT
    },
    [REPORT_TYPE.LEADS.online_bookings]: {
        options: LIST_SORT,
        selected: SELECTED_SORT
    }
};

// get Types
const getTypesOptions = {
    [REPORT_TYPE.DOCUMENT]: {
        options: [],
        selected: DEFAULT_ALL
    }
};

const getStaffOptions = {
    [REPORT_TYPE.REVENUE_BY_STAFF]: {
        options: [{ id: DEFAULT_ALL, name: 'all_staff', value: DEFAULT_ALL }],
        action: getListStaffFilter,
        selected: DEFAULT_ALL,
        title: 'all_staff'
    },
    [REPORT_TYPE.PAYMENTS_COLLECTED]: {
        options: [{ id: DEFAULT_ALL, name: 'all_staff', value: DEFAULT_ALL }],
        action: getListStaffFilterPaymentsCollected,
        selected: DEFAULT_ALL,
        title: 'all_staff'
    },
    [REPORT_TYPE.SERVICE_LOOKUP]: {
        options: [],
        selected: DEFAULT_ALL,
        titleSelectAllOption: 'all_schedules',
        action: getListScheduleServiceLookupRequest,
        title: 'assigned_to'
    },
    [REPORT_TYPE.COMMISSIONS]: {
        options: [],
        selected: DEFAULT_ALL
    },
    [REPORT_TYPE.SUBSCRIPTION[PAYMENT_CARD_CUSTOMER.STRIPE]]: {
        options: [],
        selected: DEFAULT_ALL,
        titleSelectAllOption: 'all_techs',
        title: 'techs'
    },
    [REPORT_TYPE.SUBSCRIPTION[PAYMENT_CARD_CUSTOMER.SQUARE]]: {
        options: [],
        selected: DEFAULT_ALL,
        titleSelectAllOption: 'all_techs',
        title: 'techs'
    },
    [REPORT_TYPE.ACTIVITY_COMPARISON]: {
        options: [],
        selected: DEFAULT_ALL,
        titleSelectAllOption: 'all_users',
        title: 'user',
        titlePlural: 'users',
        icon: () => <IconProfile />
    },
    [REPORT_TYPE.OPPORTUNITIES_PIPELINE]: {
        options: [],
        selected: DEFAULT_ALL,
        titleSelectAllOption: 'all_users',
        title: 'user',
        titlePlural: 'users',
        nullIsAll: true,
        icon: () => <IconProfile />
    },
    [REPORT_TYPE.SALES_FUNNEL]: {
        options: [],
        selected: DEFAULT_ALL,
        titleSelectAllOption: 'all_users',
        title: 'user',
        titlePlural: 'users',
        nullIsAll: true,
        icon: () => <IconProfile />
    },
    [REPORT_TYPE.STATUS_CHANGES]: {
        options: [],
        selected: DEFAULT_ALL,
        titleSelectAllOption: 'all_users',
        title: 'user',
        titlePlural: 'users',
        nullIsAll: true,
        icon: () => <IconProfile />
    },
    [REPORT_TYPE.WORK_LOG]: {
        options: [],
        selected: DEFAULT_ALL,
        titleSelectAllOption: 'all_users',
        title: 'user',
        titlePlural: 'users',
        nullIsAll: true
    },
    [REPORT_TYPE.NEW_JOB]: {
        options: [],
        selected: DEFAULT_ALL,
        titleSelectAllOption: 'all_schedules',
        action: getListScheduleServiceLookupRequest,
        title: 'assigned_to'
    }
};

const getSourceOptions = {
    [REPORT_TYPE.REVENUE_BY_SOURCE]: {
        options: [{ id: DEFAULT_ALL, name: 'all_source' }],
        selected: DEFAULT_ALL,
        action: getListSettingSource,
        title: 'source'
    },
    [REPORT_TYPE.CUSTOMERS]: {
        options: [],
        selected: DEFAULT_ALL,
        action: getListSettingSource,
        title: 'source'
    },
    [REPORT_TYPE.REVENUE_BY_SOURCE]: {
        options: [{ id: DEFAULT_ALL, name: 'all_source' }],
        selected: DEFAULT_ALL,
        action: getListSettingSource,
        title: 'source'
    },
    [REPORT_TYPE.NEW_CUSTOMERS]: {
        options: [{ id: DEFAULT_ALL, name: 'all_source' }],
        selected: DEFAULT_ALL,
        action: getListSettingSource,
        title: 'source'
    },
    [REPORT_TYPE.LEADS.inbound_leads]: {
        options: SOURCE_INBOUND_OPTIONS,
        selected: DEFAULT_ALL,
        title: 'all_source'
    },
    [REPORT_TYPE.LEADS.online_bookings]: {
        options: SOURCE_ONLINE_BOOKING_OPTIONS,
        selected: DEFAULT_ALL,
        title: 'all_source'
    },
    [REPORT_TYPE.NEW_JOB]: {
        options: SOURCE_NEW_JOB_OPTIONS,
        selected: DEFAULT_ALL,
        title: 'all_source'
    }
};

const getTagTypeOptions = {
    [REPORT_TYPE.REVENUE_BY_TAGS]: {
        options: LIST_TAG_TYPE,
        selected: SELECTED_TAG
    },
    [REPORT_TYPE.CUSTOMERS]: {
        options: LIST_TAG_TYPE,
        selected: SELECTED_TAG
    }
};

const getMethodsOptions = {
    [REPORT_TYPE.PAYMENT]: {
        options: [],
        seleted: DEFAULT_ALL
    },
    [REPORT_TYPE.PAYMENTS_COLLECTED]: {
        options: [],
        selected: DEFAULT_ALL
    }
};

const getPlansOptions = {
    [REPORT_TYPE.SUBSCRIPTION[PAYMENT_CARD_CUSTOMER.STRIPE]]: {
        options: [],
        selected: DEFAULT_ALL
    }
};

const getSquarePlansOptions = {
    [REPORT_TYPE.SUBSCRIPTION[PAYMENT_CARD_CUSTOMER.SQUARE]]: {
        options: [],
        selected: DEFAULT_ALL
    }
};

const getPaymentForOptions = {
    [REPORT_TYPE.PAYMENTS_COLLECTED]: {
        options: PAYMENTS_COLLECTED_PAYMENT_FOR_OPTIONS,
        selected: PAYMENTS_COLLECTED_PAYMENT_FOR_DEFAULT_SELECT
    }
};

const getCountyOptions = {
    [REPORT_TYPE.MATERIAL_USE]: {
        options: [{ id: -1, name: 'All' }],
        selected: DEFAULT_ALL,
        action: getListCountyMaterialUseFilter
    },
    [REPORT_TYPE.MATERIAL_USE_DETAIL]: {
        options: [],
        selected: '',
        action: getListCountyMaterialUseFilter
    }
};

const getJobStatusOptions = {
    [REPORT_TYPE.SERVICE_LOOKUP]: {
        options: [],
        selected: DEFAULT_ALL,
        titleSelectAllOption: 'all_statuses',
        nullIsAll: true
    },
    [REPORT_TYPE.COMMISSIONS]: {
        options: [],
        selected: DEFAULT_ALL,
        titleSelectAllOption: 'all_statuses',
        nullIsAll: true
    },
    [REPORT_TYPE.NEW_JOB]: {
        options: [],
        selected: DEFAULT_ALL,
        titleSelectAllOption: 'all_statuses',
        nullIsAll: true
    }
};

const getCityOptions = {
    [REPORT_TYPE.SERVICE_LOOKUP]: {
        options: [],
        selected: DEFAULT_ALL
    }
};

const getZipcodeOptions = {
    [REPORT_TYPE.SERVICE_LOOKUP]: {
        options: [],
        selected: DEFAULT_ALL
    }
};

const getTagsOptions = {
    [REPORT_TYPE.SERVICE_LOOKUP]: {
        options: [],
        selected: DEFAULT_ALL,
        title: 'tags'
    },
    [REPORT_TYPE.CUSTOMERS]: {
        options: [],
        selected: DEFAULT_ALL,
        title: 'tags'
    },
    [REPORT_TYPE.CHATBOT_REPORT]: {
        options: [],
        selected: DEFAULT_ALL,
        title: 'conversation_tags'
    }
};

const getRevenueOptions = {
    [REPORT_TYPE.SALES_TAX_SUMMARY]: {
        options: LIST_REVENUE,
        selected: COMMON.CASH
    }
};

const getFilterAmountDate = {
    [REPORT_TYPE.ACCOUNTS_AGING]: {
        options: ACCOUNTS_AGING_FILTERS_AMOUNT_DAY_OPTIONS,
        selected: DEFAULT_ALL
    }
};

const getEmailOptions = {
    [REPORT_TYPE.ACCOUNTS_AGING]: {
        options: ACCOUNTS_AGING_EMAIL_OPTIONS,
        selected: DEFAULT_ALL
    }
};

const getDisplayByOptions = {
    [REPORT_TYPE.COMMISSIONS]: {
        options: DISPLAY_BY_OPTIONS,
        selected: 0
    }
};

const getCommissionOptions = {
    [REPORT_TYPE.COMMISSIONS]: {
        options: COMMISSION_OPTIONS,
        selected: 1
    }
};

const getInvoiceStatusOptions = {
    [REPORT_TYPE.COMMISSIONS]: {
        options: COMMISSION_INVOICE_STATUS_OPTIONS,
        selected: DEFAULT_ALL
    }
};

const getCharacterOptions = {
    [REPORT_TYPE.CUSTOMERS]: {
        options: CHARACTER_OPTIONS,
        selected: DEFAULT_ALL
    }
};
const getSearchLocationOptions = {
    statement_customer_list: {
        options: [],
        selected: DEFAULT_ALL,
        action: getListLocation
    }
};

const getFilterOptions = {
    [COLUMNS]: getColumnOptions,
    [COLUMNS_NY]: getColumnsNy,
    [STATUS]: getStatusOptions,
    [SIGN_STATUS]: getSignStatusOptions,
    [INVOICE_STATEMENT]: getInvoiceOption,
    [STATEMENT_TYPE]: getStatementTypeOptions,
    [SERVICE]: getServiceOptions,
    [SERVICES]: getServiceOptions,
    [YEAR]: getYearOptions,
    [ITEM]: getClientOptions,
    [ITEMS]: getItemsOptions,
    [CLIENTS]: getClientOptions,
    [SALE]: getSaleOptions,
    [PAYMENT_METHODS]: getPaymentMethodsOptions,
    [SORT_BY]: getSortByOptions,
    [TYPES]: getTypesOptions,
    [STAFF]: getStaffOptions,
    [STAFFS]: getStaffOptions,
    [SOURCE]: getSourceOptions,
    [SOURCES]: getSourceOptions,
    [TAG_TYPE]: getTagTypeOptions,
    [METHODS]: getMethodsOptions,
    [PLANS]: getPlansOptions,
    [SQUARE_PLANS]: getSquarePlansOptions,
    [REPORT_SOURCE]: getSourceOptions,
    [PAYMENT_FOR]: getPaymentForOptions,
    [COUNTY]: getCountyOptions,
    [CUSTOMER_ID]: getClientOptions,
    [SERVICE_TYPE]: getServiceOptions,
    [SERVICE_TYPE_SINGLE]: getServiceOptions,
    [JOB_STATUS]: getJobStatusOptions,
    [ASSIGNED_TO]: getStaffOptions,
    [CITY]: getCityOptions,
    [ZIPCODE]: getZipcodeOptions,
    [TAGS]: getTagsOptions,
    [REVENUE]: getRevenueOptions,
    [FILTERS]: getFilterAmountDate,
    [EMAIL]: getEmailOptions,
    [DISPLAY_BY]: getDisplayByOptions,
    [COMMISSION]: getCommissionOptions,
    [INVOICE_STATUS]: getInvoiceStatusOptions,
    [CHARACTER]: getCharacterOptions,
    [SEARCH_LOCATION]: getSearchLocationOptions,
    [USERS]: getUsersOptions,
    [ITEMS]: getRatingOptions,
    [ITEMS_LIKELY]: getRatingLikelyOptions,
    [SOURCE_INBOUND]: getSourceOptions,
    [SOURCE_ONLINE_BOOKING]: getSourceOptions,
    [SOURCE_NEW_JOB]: getSourceOptions,
    [CONVERSATION_TAGS]: getTagsOptions
};

// Fn get Data Filter
export const getDataFilter = (reportType, typeCP) => {
    let objectFilter = getFilterParams[typeCP];
    objectFilter = { ...objectFilter, ...getFilterOptions[typeCP][reportType] };
    return objectFilter;
};

// Fn return List Columns
export const getColumnsParams = (type) => {
    return [...getColumnOptions[type]['selected']];
};

export const getAllOptionsFilter = (reportType, typeCP) => {
    const objTemp = getFilterOptions[typeCP][reportType];
    return objTemp.selectedAll ? objTemp.selectedAll : objTemp.selected;
};

// TABS FILTER
export const TABS_FILTER = {
    ACTIVE: { id: 1, name: i18n.t('active'), value: '{"archived":0,"deleted":0}' },
    ARCHIVED: { id: 2, name: 'archived', value: '{"archived":1,"deleted":0}' },
    DELETED: { id: 3, name: i18n.t('deleted'), value: '{"archived":0,"deleted":1}' },

    ALL: { id: 4, name: 'all', value: '{"status":-1}' },
    ACTIVE_1: { id: 5, name: 'active', value: '{"status":1}' },
    FAILED: { id: 6, name: 'failed', value: '{"status":2}' },
    CANCELED: { id: 7, name: 'canceled', value: '{"status":3}' },

    DATA: { id: 8, name: 'data', value: '{"data":1,"setup":0}' },
    SETUP: { id: 9, name: 'setup', value: '{"data":0,"setup":1}' },

    ACTIVE_CUSTOMER_DOCUMENT: { id: 10, name: 'active', value: 1 },
    DELETE_CUSTOMER_DOCUMENT: { id: 11, name: 'deleted', value: 2 },

    ACTIVE_CUSTOMER_ESTIMATE: { id: 12, name: 'active', value: 1 },
    ARCHIVE_CUSTOMER_ESTIMATE: { id: 13, name: 'archived', value: 2 },
    DELETE_CUSTOMER_ESTIMATE: { id: 14, name: 'deleted', value: 3 },

    ACTIVE_CUSTOMER_LOCATION: { id: 15, name: 'active', value: 1 },
    DELETE_CUSTOMER_LOCATION: { id: 16, name: 'deleted', value: 2 },

    ACTIVE_MANAGE_MULTIPLE_BRANCH: { id: 17, name: 'active', value: 1 },
    ARCHIVE_MANAGE_MULTIPLE_BRANCH: { id: 18, name: 'archive', value: 2 }
};

export const LIST_TABS_FILTER = {
    [REPORT_TYPE.INVOICE]: [TABS_FILTER.ACTIVE, TABS_FILTER.ARCHIVED, TABS_FILTER.DELETED],
    [REPORT_TYPE.ESTIMATE]: [TABS_FILTER.ACTIVE, TABS_FILTER.ARCHIVED, TABS_FILTER.DELETED],
    [REPORT_TYPE.ESTIMATE_CUSTOMER_LIST]: [
        TABS_FILTER.ACTIVE_CUSTOMER_ESTIMATE,
        TABS_FILTER.ARCHIVE_CUSTOMER_ESTIMATE,
        TABS_FILTER.DELETE_CUSTOMER_ESTIMATE
    ],
    [REPORT_TYPE.DOCUMENT]: [TABS_FILTER.ACTIVE, TABS_FILTER.DELETED],
    [REPORT_TYPE.DOCUMENT_CUSTOMER_LIST]: [TABS_FILTER.ACTIVE_CUSTOMER_DOCUMENT, TABS_FILTER.DELETE_CUSTOMER_DOCUMENT],
    [REPORT_TYPE.SUBSCRIPTION[PAYMENT_CARD_CUSTOMER.STRIPE]]: [
        TABS_FILTER.ALL,
        TABS_FILTER.ACTIVE_1,
        TABS_FILTER.FAILED,
        TABS_FILTER.CANCELED
    ],
    [REPORT_TYPE.SUBSCRIPTION[PAYMENT_CARD_CUSTOMER.SQUARE]]: [
        TABS_FILTER.ALL,
        TABS_FILTER.ACTIVE_1,
        TABS_FILTER.FAILED,
        TABS_FILTER.CANCELED
    ],
    [REPORT_TYPE.RECURRING_INVOICE]: [TABS_FILTER.ALL, TABS_FILTER.ACTIVE_1, TABS_FILTER.FAILED],
    [REPORT_TYPE.LOCATION_CUSTOMER_LIST]: [TABS_FILTER.ACTIVE_CUSTOMER_LOCATION, TABS_FILTER.DELETE_CUSTOMER_LOCATION],
    [REPORT_TYPE.SERVICE_LOOKUP]: [TABS_FILTER.ACTIVE, TABS_FILTER.DELETED]
};

export const TABS_FILTER_BOTTOM = {
    [REPORT_TYPE.CUSTOMER_INVOICES]: [TABS_FILTER.ACTIVE, TABS_FILTER.ARCHIVED, TABS_FILTER.DELETED],
    [REPORT_TYPE.CUSTOMER_JOBS]: [TABS_FILTER.ACTIVE, TABS_FILTER.DELETED],
    [REPORT_TYPE.LOCATION_CUSTOMER_LIST]: [TABS_FILTER.ACTIVE_CUSTOMER_LOCATION, TABS_FILTER.DELETE_CUSTOMER_LOCATION],
    [REPORT_TYPE.ESTIMATE_CUSTOMER_LIST]: [
        TABS_FILTER.ACTIVE_CUSTOMER_ESTIMATE,
        TABS_FILTER.ARCHIVE_CUSTOMER_ESTIMATE,
        TABS_FILTER.DELETE_CUSTOMER_ESTIMATE
    ],
    [REPORT_TYPE.DOCUMENT_CUSTOMER_LIST]: [TABS_FILTER.ACTIVE_CUSTOMER_DOCUMENT, TABS_FILTER.DELETE_CUSTOMER_DOCUMENT],
    [REPORT_TYPE.MANAGE_MULTIPLE_BRANCH]: [
        TABS_FILTER.ACTIVE_MANAGE_MULTIPLE_BRANCH,
        TABS_FILTER.ARCHIVE_MANAGE_MULTIPLE_BRANCH
    ]
};

export const CUSTOMERS_LIST_FILTER = {
    ROW_1: [STATUS],
    ROW_2: [SORT_BY]
};

export {
    SORT_BY,
    SALE,
    YEAR,
    PAYMENT_METHODS,
    REVENUE,
    COUNTY,
    COLUMNS,
    FILTER_PAYMENT_METHOD,
    TOTAL_COLLECTED,
    ITEM,
    SERVICE_LOOKUP_QUEST_LOG_JOB_STATUS_INCOMPLETE,
    SERVICE_LOOKUP_QUEST_LOG_JOB_STATUS_COMPLETE,
    MATERIAL_USE_COLUMNS_OPTIONS,
    COLUMNS_NY,
    JOB_STATUS,
    SOURCE_NEW_JOB,
    CONVERSATION_TAGS
};
