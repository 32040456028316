import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SETTINGS_PLANS } from 'app/config/routes';
import IconUpgrade from 'assets/icon/IconUpgrade';
import classNames from 'classnames';
import IconStar from 'assets/icon/IconStar';
import { addBranchPath } from 'app/const/Branch';

const RoutingAddonsOptions = ({ data = [] }) => {
    const { t } = useTranslation(['addons']);
    return (
        <>
            {data.map((item) => (
                <div
                    key={item.keyword}
                    className={classNames('boxs boxs--power-up', {
                        active: item.value
                    })}
                >
                    <div className="boxs__header">
                        <div className="header-title">
                            <div className="title">{item.name}</div>
                            <div className="dots powerups">
                                <IconUpgrade isFillOrange />
                                {t('power_up')}
                            </div>
                        </div>
                        {!!!item.upgrade ? (
                            <div className="switch large">
                                <p className="switch__label --active">{t('addons:enabled')}</p>
                            </div>
                        ) : (
                            <Link to={addBranchPath(SETTINGS_PLANS)} className="btn-upgrage svg-star-white">
                                <IconStar />
                                <span className="line">{t('addons:upgrade')}</span>
                            </Link>
                        )}
                    </div>
                    <div className="boxs__contents">
                        <p className="description">{item.description}</p>
                    </div>
                </div>
            ))}
        </>
    );
};

export default RoutingAddonsOptions;
