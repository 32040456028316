import classNames from 'classnames';
import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonSave from 'app/components/button/ButtonSave';
import SwitchCheckbox from 'app/components/checkbox/SwitchCheckbox';
import GDStatusBar from 'app/components/status/statusbar';
import { DEFAULT_WEEKS } from 'app/const/addons';
import { GET_SETTING_CHATBOT } from 'app/const/api/V2';
import { AVATAR_DEFAULT, COMMON } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import TimePicker from 'app/modules/jobdetail/components/time/picker';
import WeeklyRepeat from 'app/modules/jobdetail/tabs/settingschedule/repeat/WeeklyRepeat';
import { clientQuery } from 'common/utils/ApiUtils';
import { convertMinuteToTime } from 'common/utils/DateUtils';
import { showStatusBar } from 'common/utils/FunctionUtils';
import { messageActionsAlert } from 'common/utils/StringUtils';
import { convertTimeToNumber } from 'common/utils/TimeUtils';
import { LIST_ACTION_MESSAGE } from '../../constants';
import FieldUploadAvatar from '../../multipleBranch/activePlan/FieldUploadAvatar';
import { CHATBOT_SETTING_INFO } from '../constants';
import LoadingSettings from './LoadingSettings';
import SettingReplyBehavior from './SettingReplyBehavior';

const ChatbotSettings = () => {
    const { t } = useTranslation(['addons', 'report']);
    const { NAME_AI, OFFICE_HOURS_GREETING, AFTER_HOURS_GREETING } = CHATBOT_SETTING_INFO;
    const [state, dispatchState] = useReducer(reducer, {
        isLoading: true,
        all_time: 0,
        active_day: DEFAULT_WEEKS,
        office_hours: '',
        after_hours: '',
        start: 0,
        end: 0,
        name: '',
        avatar: '',
        [OFFICE_HOURS_GREETING]: '',
        [AFTER_HOURS_GREETING]: ''
    });
    const {
        all_time: isAllTime,
        office_hours: officeReply,
        after_hours: afterReply,
        name: finalName,
        start: timeStart,
        end: timeEnd,
        active_day: activeDay,
        isLoading: finalIsLoading,
        avatar: finalAvt,
        [OFFICE_HOURS_GREETING]: finalOfficeHoursGreeting,
        [AFTER_HOURS_GREETING]: finalAfterHoursGreeting
    } = state;

    const refAvatar = useRef(null);
    const refFromDate = useRef(null);
    const refToDate = useRef(null);
    const refName = useRef(null);
    const refButtonSave = useRef(null);
    const refAlert = useRef(null);
    const refOfficeHours = useRef(null);
    const refAfterHours = useRef(null);
    const refOfficeHoursGreeting = useRef(null);
    const refAfterHoursGreeting = useRef(null);

    useEffect(() => {
        _getDetail();
    }, []);

    const _getDetail = () => {
        const _handleSuccess = ({ data = {} }) => {
            dispatchState((prev) => ({
                ...prev,
                ...(data || {}),
                isLoading: false,
                avatar: !!data?.avatar_id ? AVATAR_DEFAULT : data?.avatar
            }));
        };

        const _handleFail = (err) => {
            _showAlert(err);
            dispatchState((prev) => ({ ...prev, isLoading: false }));
        };

        clientQuery(GET_SETTING_CHATBOT, { method: 'GET', data: {} }, _handleSuccess, _handleFail);
    };

    const _getErrorMessage = ({ name = '' }) => {
        return t('customers:cannot_be_blank', { name: t(`addons:${name}`) });
    };

    const _handleSubmit = () => {
        const nameBot = refName.current.value.trim();
        const officeHoursGreetingBot = refOfficeHoursGreeting.current.value.trim();
        const afterHoursGreetingBot = refAfterHoursGreeting.current.value.trim();
        const { avtObject, url } = refAvatar.current?.getValue() || {};

        const msgErrors = [];
        if (!nameBot) msgErrors.push(_getErrorMessage({ name: NAME_AI }));
        if (!officeHoursGreetingBot) msgErrors.push(_getErrorMessage({ name: OFFICE_HOURS_GREETING }));
        if (!afterHoursGreetingBot) msgErrors.push(_getErrorMessage({ name: AFTER_HOURS_GREETING }));
        if (msgErrors.length) {
            _showAlert({ message: msgErrors });
            refButtonSave.current?.removeLoading();
            return;
        }

        const data = {
            avatar: avtObject || (url !== AVATAR_DEFAULT ? url : ''),
            name: nameBot,
            start: convertTimeToNumber(...Object.values(refFromDate.current._getTime())),
            end: convertTimeToNumber(...Object.values(refToDate.current._getTime())),
            all_time: isAllTime,
            active_day: activeDay,
            office_hours: refOfficeHours.current.getValue().id,
            after_hours: refAfterHours.current.getValue().id,
            [OFFICE_HOURS_GREETING]: officeHoursGreetingBot,
            [AFTER_HOURS_GREETING]: afterHoursGreetingBot
        };

        const _handleSuccess = ({ message }) => {
            _showAlert({
                message: messageActionsAlert({ message, type: t('settings'), action: LIST_ACTION_MESSAGE.UPDATED }),
                success: true
            });
        };

        const _handleFinally = () => {
            refButtonSave.current?.removeLoading();
        };

        clientQuery(
            GET_SETTING_CHATBOT,
            { method: 'PUT', data, toFormData: false },
            _handleSuccess,
            _showAlert,
            _handleFinally
        );
    };

    const _handleChangeToggle = (checked) => {
        dispatchState({ all_time: checked ? 1 : 0 });
    };

    const _handleActiveDay = (days) => {
        dispatchState({ active_day: days });
    };

    const _showAlert = ({ message, success }) => {
        showStatusBar({ id: 'chatbot-setting', message, success, refAlert });
    };

    const _handleDisableSave = (value = false) => {
        refButtonSave.current?.setDisable(value);
    };

    return (
        <div className="wrapper-chatbot --setting">
            <div className="flex-column gap-16">
                <h3 className="fs-18 fw-600 black">{t('addons:settings')}</h3>
                <GDStatusBar ref={refAlert} />
                {finalIsLoading ? (
                    <LoadingSettings />
                ) : (
                    <div className="box-setting">
                        <div className="flex-column gap-16">
                            <FieldUploadAvatar
                                ref={refAvatar}
                                isNotDivider
                                urlImage={finalAvt}
                                nameBranch={finalName}
                                titleButton={t('upload_new_picture')}
                                typePresign={COMMON.AVATAR}
                                avatarDefault={AVATAR_DEFAULT}
                                onUpFile={() => _handleDisableSave(true)}
                                onUpFileSuccess={() => _handleDisableSave(false)}
                            />
                            <div className="flex-column gap-2">
                                <div className="fw-500 black-darker3">{t(`addons:${NAME_AI}`)}</div>
                                <input
                                    ref={refName}
                                    className="field-input"
                                    defaultValue={finalName}
                                    placeholder={t('name')}
                                />
                            </div>
                            <div className="flex-column gap-2">
                                <div className="fw-500 black-darker3">{t(`addons:${OFFICE_HOURS_GREETING}`)}</div>
                                <input
                                    ref={refOfficeHoursGreeting}
                                    className="field-input"
                                    defaultValue={finalOfficeHoursGreeting}
                                    placeholder={t(`addons:${OFFICE_HOURS_GREETING}`)}
                                />
                            </div>
                            <div className="flex-column gap-2">
                                <div className="fw-500 black-darker3">{t(`addons:${AFTER_HOURS_GREETING}`)}</div>
                                <input
                                    ref={refAfterHoursGreeting}
                                    className="field-input"
                                    defaultValue={finalAfterHoursGreeting}
                                    placeholder={t(`addons:${AFTER_HOURS_GREETING}`)}
                                />
                            </div>
                        </div>
                        <div className="flex-column gap-10 box-setting__container">
                            <div className="fw-700 black-darker3">{t('addons:hour_ai_operate')}</div>
                            <div className="flex-betweenitems">
                                <p className="fw-500 black-darker3">{t('addons:set_time_operate')}</p>
                                <div className="switch large">
                                    <span className="switch__label">{t('report:all_time_date_picker')}</span>
                                    <SwitchCheckbox
                                        defaultChecked={isAllTime}
                                        id="toggle_setting_time"
                                        onChangeImmediately={_handleChangeToggle}
                                    />
                                </div>
                            </div>
                            <div className={classNames('flex-betweenitems gap-2', { 'dp-hide': isAllTime })}>
                                <div className="flex-column gap-2">
                                    <div className="grey-very-dark fs-13">{t('addons:from')}</div>
                                    <TimePicker
                                        ref={refFromDate}
                                        timeSelected={convertMinuteToTime(timeStart)}
                                        isCloseOnSelected
                                    />
                                </div>
                                <div className="flex-column gap-2">
                                    <div className="grey-very-dark fs-13">{t('addons:to')}</div>
                                    <TimePicker
                                        ref={refToDate}
                                        timeSelected={convertMinuteToTime(timeEnd)}
                                        isCloseOnSelected
                                    />
                                </div>
                            </div>
                            <WeeklyRepeat
                                shortDate={false}
                                shouldCheckHaveSelect={false}
                                title={{ className: 'grey-very-dark fs-13', title: 'Active days:' }}
                                className={classNames('flex-betweenitems', { 'dp-hide': isAllTime })}
                                classWrapper="btn-item m-0 border-none"
                                {...activeDay}
                                onChange={_handleActiveDay}
                            />
                            <div className="flex-betweenitems gap-16">
                                <SettingReplyBehavior
                                    ref={refOfficeHours}
                                    title={t('addons:office_hours')}
                                    defaultValue={officeReply}
                                />
                                <SettingReplyBehavior
                                    ref={refAfterHours}
                                    title={t('addons:after_hours')}
                                    defaultValue={afterReply}
                                    hidden={isAllTime}
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-end">
                            <ButtonSave
                                ref={refButtonSave}
                                title={t('save')}
                                wrapClass="v2-btn-main"
                                onSave={_handleSubmit}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ChatbotSettings;
