import classNames from 'classnames';
import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import ButtonSave from 'app/components/button/ButtonSave';
import { DELAY_CLICK, ENTER_KEY_CODE, SRC_LOGO_GORILLADESK } from 'app/const/App';
import { ADD_COMPANY_DETAILS, ADD_PERSONAL_DETAILS, SIGN_UP_TRACKING_INFO } from 'app/const/Mixpanel';
import { reducer } from 'app/const/Reducer';
import { LIST_CODE_BY_COUNTRY } from 'app/const/Settings';
import AuthError from 'app/modules/auth/components/AuthError';
import { mixpanelSignup } from 'app/modules/mixpanel/MixpanelSignup';
import { clientQuery } from 'common/utils/ApiUtils';
import { detectKeyPress } from 'common/utils/FunctionUtils';
import { handleTrackingEvent } from 'common/utils/MixpanelUtils';
import { capitalizeEachWord } from 'common/utils/StringUtils';
import TrialStepOne from './TrialStepOne';
import TrialStepThree from './TrialStepThree';
import TrialStepTwo from './TrialStepTwo';
import {
    COMPANY_NAME,
    COMPANY_PHONE,
    COUNTRY_CODE,
    FIRST_NAME,
    INDUSTRY,
    LAST_NAME,
    NAME,
    ONE,
    PHONE,
    PHONE_NUMBER_LENGTH,
    STEP,
    TEAMSIZE,
    THREE,
    TWO,
    VALIDATE_PHONE_BY_CODE
} from './const';

const FreeTrial = ({ submitSignup = () => {}, errorSignupForm, userId = '' }) => {
    let timer;
    const { t } = useTranslation(['common', 'auth']);
    const location = useLocation();
    const params = location?.state?.params;

    const initialState = {
        stepsActive: [STEP[ONE].value],
        stepActive: STEP[ONE].value,
        error: null,
        info: {
            [COUNTRY_CODE]: LIST_CODE_BY_COUNTRY[0].code,
            user_id: userId,
            [FIRST_NAME]: params?.first_name || '',
            [LAST_NAME]: params?.last_name || '',
            [COMPANY_PHONE]: '',
            [INDUSTRY]: '',
            [COMPANY_NAME]: '',
            [TEAMSIZE]: ''
        },
        disableForm: false,
        errorSignup: null
    };

    const [state, dispatchState] = useReducer(reducer, initialState);
    const infoTrialRef = useRef(initialState.info);

    const { stepsActive, stepActive, error: finalError, disableForm, errorSignup, isErrorCaptcha } = state;
    const refChangeInfo = useRef(false);
    const refButtonSave = useRef(null);
    const refForm = useRef(null);

    useEffect(() => {
        if (errorSignupForm) {
            dispatchState((prevState) => {
                return { ...prevState, disableForm: false, errorSignup: errorSignupForm };
            });
            refButtonSave.current?.removeLoading();
        }
    }, [errorSignupForm]);

    const _handleChangeInfo = (value) => {
        refChangeInfo.current = value;
    };

    const _updateSteps = (value) => {
        const stepsOrder = Object.values(STEP);
        const index = stepsOrder.findIndex((step) => step.value === value);

        if (index === -1) return { stepsActive: [], stepActive: null };

        const updatedSteps = stepsOrder.slice(0, index + 1).map((step) => step.value);
        const stepActive = updatedSteps[updatedSteps.length - 1];
        _handleChangeInfo(false);
        return { stepsActive: updatedSteps, stepActive };
    };

    const _handleFocus = () => {
        dispatchState((prev) => ({ ...prev, error: null }));
    };

    const _validateStepFields = (step, infoTrial) => {
        const msgError = {};
        const stepFieldMap = {
            [STEP[ONE].value]: [COMPANY_NAME, INDUSTRY, TEAMSIZE],
            [STEP[TWO].value]: [FIRST_NAME, LAST_NAME, COMPANY_PHONE]
        };

        const requiredFields = stepFieldMap[step] || [];

        requiredFields.forEach((field) => {
            const valueField = !!infoTrial?.[field] ? infoTrial[field].trim().toLowerCase() : '';
            if (!valueField) msgError[field] = t(`auth:${field}`).concat(' ', t('auth:can_not_be_blank'));
            if (
                field === COMPANY_PHONE &&
                !!VALIDATE_PHONE_BY_CODE[infoTrial?.country_code] &&
                !!valueField &&
                valueField.length < PHONE_NUMBER_LENGTH
            )
                msgError[field] = t(`auth:${field}`).concat(' ', t('auth:must_contain_10_digits'));
        });

        return msgError;
    };

    const _handleUpdateSteps = (value) => {
        dispatchState((prev) => ({ ...prev, ..._updateSteps(value), error: {} }));
    };

    const _onChangeStep = (value) => {
        if (value <= STEP[THREE].value) {
            const msgError = _validateStepFields(stepActive, infoTrialRef.current);
            if (!!Object.keys(msgError).length) {
                dispatchState((prev) => ({ ...prev, error: msgError }));
                return;
            }
        }

        if (refChangeInfo.current) {
            const stepFieldMap = {
                [STEP[TWO].value]: [COMPANY_NAME, INDUSTRY, TEAMSIZE],
                [STEP[THREE].value]: [FIRST_NAME, LAST_NAME, PHONE]
            };
            const { country_code, company_phone, user_id } = infoTrialRef.current;
            const fields = stepFieldMap[value] || [];
            const postData = { user_id };

            const fieldTransformations = {
                [PHONE]: `${
                    LIST_CODE_BY_COUNTRY.find((country) => country.code === country_code)?.dial_code || ''
                }${company_phone}`
            };

            fields.forEach((field) => {
                const transformedValue = fieldTransformations[field] || infoTrialRef.current[field];
                postData[field] = transformedValue;
            });

            handleTrackingEvent(
                mixpanelSignup({
                    profile: {
                        id: user_id,
                        name:
                            value === STEP[THREE].value
                                ? capitalizeEachWord(postData[FIRST_NAME] + ' ' + postData[LAST_NAME])
                                : undefined
                    },
                    company: { ...postData, [NAME]: capitalizeEachWord(infoTrialRef.current[COMPANY_NAME]) },
                    event: value === STEP[TWO].value ? ADD_COMPANY_DETAILS : ADD_PERSONAL_DETAILS
                })
            );

            clientQuery(SIGN_UP_TRACKING_INFO, {
                data: postData,
                toFormData: false,
                useGdBranchID: false,
                method: 'POST'
            });
        }

        _handleUpdateSteps(value);
    };

    const _handleSetInfoValue = ({ key, value }) => {
        _handleChangeInfo(true);
        infoTrialRef.current = { ...infoTrialRef.current, [key]: value };
    };

    const _onChangeInput = (e) => {
        _handleChangeInfo(true);
        const { name, value } = e.target;
        _handleSetInfoValue({ key: name, value });
    };

    const _handleSubmit = (e) => {
        if (e) e.preventDefault();
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            if (!disableForm) _submitFormTrial();
        }, DELAY_CLICK);
    };

    const _handleKeypress = (e) => {
        if (detectKeyPress(e, ENTER_KEY_CODE)) _submitFormTrial();
    };

    const renderStepContent = () => {
        const getCommonProps = () => ({
            error: finalError,
            fileTranslation: 'auth',
            infoTrial: infoTrialRef.current || {},
            handleFocus: _handleFocus,
            handleSetValue: _handleSetInfoValue,
            onChangeInput: _onChangeInput,
            onKeyPress: _handleKeypress
        });

        switch (stepActive) {
            case STEP[ONE]['value']:
                return <TrialStepOne {...getCommonProps()} />;
            case STEP[TWO]['value']:
                return <TrialStepTwo {...getCommonProps()} />;
            case STEP[THREE]['value']:
                return <TrialStepThree {...getCommonProps()} isErrorCaptcha={isErrorCaptcha} />;
            default:
                return null;
        }
    };

    const _submitFormTrial = () => {
        const onSubmitForm = () => {
            const captchaInput = refForm.current['g-recaptcha-response'];
            const isErrorCaptcha = !captchaInput || !captchaInput.value.trim();
            dispatchState((prevState) => {
                const newState = { ...prevState, isErrorCaptcha };
                if (isErrorCaptcha) newState['disableForm'] = false;
                return newState;
            });
            if (isErrorCaptcha) {
                refButtonSave.current?.removeLoading();
                return;
            }

            const msgError = _validateStepFields(stepActive, infoTrialRef.current);
            if (!!Object.keys(msgError).length) {
                dispatchState((prev) => ({ ...prev, error: msgError }));
                return;
            }
            const {
                first_name,
                last_name,
                country_code,
                company_phone,
                industry,
                company_name,
                team_size,
                estimate,
                source,
                user_id: finalUserId
            } = infoTrialRef.current;

            const postData = {
                company: {
                    phone: `${
                        LIST_CODE_BY_COUNTRY.find((country) => country.code === country_code)?.dial_code || ''
                    }${company_phone}`,
                    address: '',
                    city: '',
                    state: '',
                    zipcode: '',
                    industry,
                    team_size,
                    estimate,
                    source,
                    email: ''
                },
                profile: {
                    user_id: finalUserId,
                    company_name: company_name,
                    email: '',
                    first_name,
                    last_name,
                    password: '',
                    username: ''
                }
            };
            refButtonSave.current?.setLoading(true);
            dispatchState((prev) => ({ ...prev, disableForm: true }));
            submitSignup(postData);
        };
        return stepActive < STEP[THREE].value ? _onChangeStep(stepActive + 1) : onSubmitForm();
    };

    const _handleChangeStepBar = (value) => {
        if (stepActive === value) return;
        if (stepActive > value) {
            _handleUpdateSteps(value);
            return;
        }
        _onChangeStep(stepActive + 1);
    };
    return (
        <form ref={refForm} onSubmit={_handleSubmit} id="login-form" action="#" className="gd-form-v2 px-6">
            <AuthError text={errorSignup} />
            <h1 className="logo-text-gorilla">
                <img src={SRC_LOGO_GORILLADESK} width={377} height={90} />
            </h1>
            <div className="multi-step">
                <StepsBar stepsActive={stepsActive} onChange={_handleChangeStepBar} />
            </div>
            <div className="container-tabs">
                <StepsTitle stepActive={stepActive} />
                <div className="has-form flex-column gap-16">{renderStepContent()}</div>
            </div>
            <ButtonSave
                ref={refButtonSave}
                wrapClass={classNames('gd-getstarted v2-btn-main gd-btn-getstarted fw-500 mb-7', {
                    disable: disableForm
                })}
                title={t(`auth:${stepActive === STEP[THREE]['value'] ? 'get_started' : 'next'}`)}
                disable={disableForm}
                isNotLoading={!disableForm}
                onSave={_handleSubmit}
            />
        </form>
    );
};

const StepsTitle = ({ stepActive }) => {
    const { t } = useTranslation(['common', 'auth']);
    const dataStep = Object.values(STEP).find((step) => step.value === stepActive) || {};
    return (
        <Fragment>
            <h3 className="fs-20 black mb-2">{t(`auth:${dataStep.title}`)}</h3>
            <p className="fs-14 grey-very-dark mb-5">{t(`auth:${dataStep.subTitle}`)}</p>
        </Fragment>
    );
};

const StepsBar = ({ stepsActive, onChange = () => {} }) => {
    return Object.values(STEP).map(({ id, value }) => (
        <div
            key={id}
            className={classNames('step', { active: stepsActive.includes(value) })}
            onClick={() => onChange(value)}
        />
    ));
};

export default FreeTrial;
