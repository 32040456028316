import classNames from 'classnames';
import React, { Fragment, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import Input from 'app/components/input/Input';
import { reducer } from 'app/const/Reducer';
import { LIST_CODE_BY_COUNTRY } from 'app/const/Settings';
import IconArrowDown from 'assets/icon/IconArrowDown';
import { formatPhoneTrial } from 'common/utils/PhoneUtils';
import { CODE, COUNTRY_CODE } from './const';

const TrialStepTwo = ({
    fileTranslation = 'auth',
    infoTrial = {},
    error = {},
    handleFocus = () => {},
    onChangeInput = () => {},
    onKeyPress = () => {},
    handleSetValue = () => {}
}) => {
    const { t } = useTranslation(fileTranslation);

    const [state, dispatchState] = useReducer(reducer, {
        code: infoTrial?.country_code || '',
        phone: formatPhoneTrial(infoTrial?.company_phone || '')
    });
    const { code: finalCode, phone: finalPhone } = state;

    const refDropdownCodeByCountry = useRef(null);

    const _onChangePhone = (event) => {
        const finalValue = event.target.value.replace(/\D+/g, '');
        event.target.value = finalValue;
        dispatchState({ phone: formatPhoneTrial(finalValue) });
        onChangeInput(event);
    };

    const _renderListCodeByCountry = () => {
        return (
            <ul>
                {LIST_CODE_BY_COUNTRY.map(({ dial_code, code }) => {
                    const onClickItem = () => {
                        handleSetValue({ key: COUNTRY_CODE, value: code });
                        dispatchState((prev) => ({ ...prev, [CODE]: code }));
                        refDropdownCodeByCountry.current?._close();
                    };

                    return (
                        <li
                            key={code}
                            className={classNames('items', { active: code === finalCode })}
                            onClick={onClickItem}
                        >
                            {dial_code} ({code.toUpperCase()})
                        </li>
                    );
                })}
            </ul>
        );
    };

    const _renderButtonDropdownCodeByCountry = () => {
        const countrySelected = LIST_CODE_BY_COUNTRY.find((country) => country.code === finalCode) || {};
        return (
            <Fragment>
                <div className="txt-ellipsis">
                    {countrySelected.dial_code} ({countrySelected.code ? countrySelected.code : ''})
                </div>
                <div className="arrow">
                    <IconArrowDown />
                </div>
            </Fragment>
        );
    };

    return (
        <Fragment>
            <div className="box-input">
                <Input
                    autoFocus
                    placeholder={t('auth:first_name')}
                    type="text"
                    name="first_name"
                    classWrapper="user-box rows"
                    initValue={infoTrial.first_name}
                    error={error?.first_name}
                    isShowIconError={false}
                    onFocus={handleFocus}
                    onChange={onChangeInput}
                    handleKeypress={onKeyPress}
                />
            </div>
            <div className="box-input">
                <Input
                    placeholder={t('auth:last_name')}
                    type="text"
                    name="last_name"
                    classWrapper="user-box rows"
                    initValue={infoTrial.last_name}
                    error={error?.last_name}
                    isShowIconError={false}
                    onFocus={handleFocus}
                    onChange={onChangeInput}
                    handleKeypress={onKeyPress}
                />
            </div>
            <div className="rows d-flex">
                <div className="first-field">
                    <DropdownPopper
                        ref={refDropdownCodeByCountry}
                        wrapperClassName="v2-dropdown --area-code"
                        wrapperListClass="v2-dropdown__menu scrolls"
                        buttonClassName="dropbtn items"
                        isUseToggle
                        isCalculateWidth
                        customStyle={{ width: 'auto', maxWidth: 'auto' }}
                        customButton={_renderButtonDropdownCodeByCountry()}
                    >
                        {_renderListCodeByCountry()}
                    </DropdownPopper>
                </div>
                <div className="second-field flex-1">
                    <Input
                        placeholder="000-000-0000"
                        type="text"
                        name="company_phone"
                        classWrapper="user-box rows"
                        value={finalPhone}
                        error={error?.company_phone}
                        maxLength={10}
                        isShowIconError={false}
                        onFocus={handleFocus}
                        onChange={_onChangePhone}
                        handleKeypress={onKeyPress}
                    />
                </div>
            </div>
        </Fragment>
    );
};

export default TrialStepTwo;
