import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';

import { ACCOUNT_ROLE, COMMON } from 'app/const/App';
import { addPrefixPath } from 'app/const/Branch';
import { PERMISSIONS } from 'app/const/Permissions';
import { reducer } from 'app/const/Reducer';
import { LIST_SETTING_ACCESS_GROWTH } from 'app/const/Settings';
import { listActivePath, listAllAddOns, SETTING_MENU_OPTIONS } from 'app/const/StaticLinks';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconDropUpDown from 'assets/icon/IconDropUpDown';
import { checkPermission, getPlanUser, isActiveFeature } from 'common/utils/PermissionUtils';
import AddonHeaderExpand from './AddonHeaderExpand';
import AddonHeaderTitle from './AddonHeaderTitle';
import AddonsHeaderTabs from './AddonsHeaderTabs';
import MainFeatureHeader from './MainFeatureHeader';
import PinMenus from './PinMenus';
import { ADDONS_AI_AGENTS } from 'app/const/addons';

const AddonHeader = forwardRef((props, ref) => {
    const { t } = useTranslation(['addons']);
    const location = useLocation();
    const [state, dispatchState] = useReducer(reducer, {
        addons: {
            [COMMON.ACTIVE]: { data: [], total: 0 },
            [COMMON.INACTIVE]: { data: [], total: 0 },
            [COMMON.SETTINGS]: { data: [], total: 0 }
        },
        isSupperAdm: false,
        canEditSetting: false,
        typeAccount: {
            isTrial: true,
            isProPlan: false,
            isBasicPlan: false,
            isGrowthPlan: false
        }
    });

    const user = useSelector((state) => state.auth.user);
    const { addons, isSupperAdm, typeAccount, canEditSetting, isAccessAddons } = state;

    const refDropdown = useRef(null);
    const refPin = useRef(null);
    const isHavePermissionSetting = checkPermission(user?.permissions?.enabled || [], PERMISSIONS.accessSettingsTab);

    useImperativeHandle(ref, () => ({ setActive: (value) => refPin.current?.setActive(value) }));

    useEffect(() => {
        const SETTINGS_MENU = [];
        const userAddons = user?.settings?.addons || {};
        const userCountry = user.company.country;
        const isSupportSMS = user.company.support_sms;
        const isCountryHasSquare = user.settings.square_countries.includes(userCountry);
        const isSupperAdm = user.profile.role === ACCOUNT_ROLE.SUPERADMIN;
        const isHavePermissionAccount = checkPermission(user?.permissions?.enabled || [], PERMISSIONS.accessAccount);
        const keyWordAiAgents = ADDONS_AI_AGENTS;

        const newTypeAccount = getPlanUser(user?.profile);

        const newAddons = {
            [COMMON.ACTIVE]: { data: [], total: 0 },
            [COMMON.INACTIVE]: { data: [], total: 0 },
            [COMMON.SETTINGS]: { data: [], total: 0 }
        };
        listAllAddOns.forEach((item) => {
            if (item.isHidden) return null;
            for (const key in userAddons) {
                if (Object.hasOwnProperty.call(userAddons, key)) {
                    const element = userAddons[key];
                    const value = parseInt(element);

                    if (item.id === 'square' && !isCountryHasSquare) return;
                    if (item.id === 'sms' && !isSupportSMS) return;
                    if (item.id === 'commission_tracking' && !isSupperAdm) return;
                    if (item.id === 'multiple_branch' && !!user.company.is_branch) return;
                    if (item.id === keyWordAiAgents && !isActiveFeature(keyWordAiAgents.toUpperCase())) return;

                    const isInActiveMultiBranch = item.id === 'multiple_branch' && newTypeAccount.isTrial;
                    if (item.id === key || (item.id === 'sms' && key === 'smsplivo')) {
                        if (value === 0 || isInActiveMultiBranch) {
                            newAddons[COMMON.INACTIVE] = { data: [...newAddons[COMMON.INACTIVE].data, item] };
                        } else {
                            newAddons[COMMON.ACTIVE] = { data: [...newAddons[COMMON.ACTIVE].data, item] };
                        }
                    }
                }
            }
        });

        newAddons[COMMON.INACTIVE]['total'] = newAddons[COMMON.INACTIVE].data.length;
        newAddons[COMMON.ACTIVE]['total'] = newAddons[COMMON.ACTIVE].data.length;

        SETTING_MENU_OPTIONS.forEach((item) => {
            if (
                LIST_SETTING_ACCESS_GROWTH.includes(item.value) &&
                !newTypeAccount?.isGrowthPlan &&
                !newTypeAccount?.isTrial
            )
                return;
            if (
                (item.isPermissionAccount && !isHavePermissionAccount) ||
                (!item.isPermissionAccount && !isHavePermissionSetting)
            ) {
                return;
            }
            if (item.icon) {
                SETTINGS_MENU.push({
                    id: item.value,
                    title: item.value,
                    icon: item.icon,
                    url: item.link,
                    class: item.class.includes('svg-noeffect')
                        ? item.class.replace('svg-noeffect', '--purple')
                        : `${item.class} --purple`,
                    isBadge: false,
                    badgeTitle: '',
                    isActive: false
                });
            }
        });

        newAddons[COMMON.SETTINGS]['data'] = SETTINGS_MENU;
        dispatchState({
            addons: newAddons,
            isSupperAdm,
            typeAccount: newTypeAccount,
            canEditSetting: checkPermission(user?.permissions?.enabled || [], PERMISSIONS.accessSettingsTab) || false,
            isAccessAddons: checkPermission(user?.permissions?.enabled || [], PERMISSIONS.accessAddonsTab) || false
        });
    }, [user, user.settings.addons]);

    if (!user) return null;
    const selectedModules = listActivePath.find(
        (item) => item.url && matchPath(location.pathname, { path: addPrefixPath(item.url) })
    );
    const finalCanEditSetting =
        canEditSetting || checkPermission(user?.permissions?.enabled || [], PERMISSIONS.accessAccount);

    const _handleCloseDropdown = () => refDropdown.current._closeDropdown();

    const _handleCheckGoToPlans = (e) => {
        if (isSupperAdm) return e.preventDefault();
        _handleCloseDropdown();
    };

    const _handleLinkClick = () => _handleCloseDropdown();

    return (
        <>
            <CalendarDropdown
                ref={refDropdown}
                id="addons_header_dropdown_layout"
                wrapperClassName="wrap-nav nav-menu"
                buttonClassName="dropbtn v2-btn-default nav"
                wrapperListClass="v2-dropdown__menu"
                customDropButton={() => (
                    <>
                        {selectedModules?.iconActive}
                        <p className="nav__label is-nav-items">
                            {t(`header:${selectedModules?.titleActive || selectedModules?.title}`)}
                        </p>
                        <span className="arrow">
                            <IconDropUpDown />
                        </span>
                    </>
                )}
            >
                <div className="wrap-nav-list">
                    <div className="d-flex">
                        <div className="nav-boxs --list-menu">
                            <AddonHeaderTitle typeAccount={typeAccount} onCheckGoToPlan={_handleCheckGoToPlans} />
                            <MainFeatureHeader
                                canEditSetting={finalCanEditSetting}
                                addons={addons}
                                typeAccount={typeAccount}
                                onLinkClick={_handleLinkClick}
                                onCheckGoToPlan={_handleCheckGoToPlans}
                            />
                        </div>

                        {(canEditSetting || isAccessAddons) && (
                            <AddonsHeaderTabs
                                addons={addons}
                                typeAccount={typeAccount}
                                onLinkClick={_handleLinkClick}
                                shouldRemoveSettings={!isHavePermissionSetting}
                            />
                        )}

                        <AddonHeaderExpand
                            isProPlan={typeAccount?.isProPlan}
                            isGrowthPlan={typeAccount?.isGrowthPlan}
                            onCloseDropdown={_handleCloseDropdown}
                        />
                    </div>
                </div>
            </CalendarDropdown>
            <PinMenus
                ref={refPin}
                addons={addons}
                typeAccount={typeAccount}
                canEditSetting={finalCanEditSetting}
                onLinkClick={_handleLinkClick}
            />
        </>
    );
});

export default AddonHeader;
