import classNames from 'classnames';
import React, { forwardRef, useImperativeHandle, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { TYPE_TAGS } from 'app/const/Customers';
import { reducer } from 'app/const/Reducer';
import IconLoading from 'assets/icon/IconLoading';
import { getListSimpleTags, updateSimpleTag } from 'common/redux/actions/settings/tagsAction';
import { TYPES_TAG } from 'common/redux/reducers/settings/tags';

const ListTags = (
    {
        onHandleSelect = () => {},
        type,
        isTagsSideMenu = false,
        isTagsAccount = false,
        defaultData = null,
        update = () => {}
    },
    ref
) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const dataTags = useSelector(({ tags }) => tags);
    const tagName = TYPES_TAG[type];
    const { data: tagsValue = [], isFirstTime } = defaultData || dataTags[tagName] || {};
    const isConversationTag = type === TYPE_TAGS.TAG_CONVERSATION;
    const finalTagValue = isConversationTag ? tagsValue.map((item) => ({ ...item, id: item?.name || '' })) : tagsValue;
    const [state, dispatchState] = useReducer(reducer, {
        tags: finalTagValue.sort((a, b) => a.name.localeCompare(b.name)) || [],
        isLoading: isFirstTime
    });
    const tags = state.tags;

    React.useEffect(() => {
        update();
    }, [tags]);

    useImperativeHandle(ref, () => ({
        onHandleSearch: _handleSearch,
        onHandleAddTag: _handleAddTag,
        onHandleGetList: _handleGetList,
        onHandleRemove: _handleRemove,
        onHandleFetch: _handleGetListTags,
        onHandleSelect: _handleSelect
    }));

    const _handleGetListTags = () => {
        if (!isFirstTime) return;
        dispatch(getListSimpleTags({ type }, _handleGetSuccess));
    };

    const _handleGetSuccess = ({ data = [] }) => {
        let finalData = [...data];
        if (type === TYPE_TAGS.TAG_CONVERSATION) {
            finalData = finalData.map((item) => ({ ...item, id: item?.name || '' }));
        }
        dispatchState((prev) => ({ ...prev, tags: finalData, isLoading: false }));
    };

    const _handleGetList = () => {
        return tags || [];
    };

    const _handleRemove = (idTag) => {
        const newListTags = [...tags].filter((item) => !(item.isNewTag && item.id === idTag));
        dispatchState((prev) => ({ ...prev, tags: newListTags }));
        dispatch(updateSimpleTag({ type, data: newListTags }));
    };

    const _handleAddTag = (newTag) => {
        const newListTags = [...tagsValue, newTag];

        if (!tagsValue.some((item) => item.id === newTag.id)) {
            dispatchState((prev) => ({ ...prev, tags: newListTags }));
            dispatch(updateSimpleTag({ type, data: newListTags }));
        }
    };

    const _handleSearch = (value = '') => {
        dispatchState((prev) => ({
            ...prev,
            tags: tagsValue.filter((item) => item.name.toLowerCase().includes(value.toLowerCase()))
        }));
    };

    const _handleSelect = (newTag) => {
        dispatchState((prev) => ({ ...prev, tags: [...new Set([...tagsValue, newTag])] }));
    };

    if (state.isLoading)
        return (
            <div className="items justify-center">
                <div className="loading -ajaxbar">
                    <IconLoading />
                </div>
            </div>
        );

    if (!tags.length) return <div className="result-empty">{t('common:there_is_no_data')}</div>;
    return tags.map((tag) => (
        <li
            key={tag.id}
            className={classNames('items', { tag: isTagsSideMenu })}
            onClick={() => onHandleSelect(tag)}
            tabIndex="0"
        >
            <div
                data-tag-label={`tag-label_${type}_${tag.id}`}
                className={classNames(
                    'txt-ellipsis',
                    { 'tag-label': !isTagsSideMenu },
                    { 'tag-label__ellipsis': isTagsAccount }
                )}
            >
                {tag.name}
            </div>
        </li>
    ));
};

export default forwardRef(ListTags);
