// This should be import top of file
import FullCalendar from '@fullcalendar/react';

import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import timeGridPlugin from '@fullcalendar/timegrid';
import classNames from 'classnames';
import moment from 'moment-timezone';
import React, {
    Fragment,
    forwardRef,
    useEffect,
    useImperativeHandle,
    useLayoutEffect,
    useMemo,
    useReducer,
    useRef
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { CALENDAR_STORE, updateGetJobActive } from 'app/const/Api';
import {
    KEY_OPTIONS_CHECK_NOTIFY_TECHNICIAN_JOB_CALENDAR,
    KEY_OPTIONS_CHECK_RECURRING_JOB_CALENDAR
} from 'app/const/App';
import { JOB_EXCLUDE, JOB_PENDING_CONFIRMATION, NOTIFY_TECH } from 'app/const/Job';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import { CALENDAR_JOBS_MAGNETS } from 'app/const/api/V2';
import Services from 'app/modules/calendar/Services';
import ModalJobRecuring from 'app/modules/calendar/components/modal/JobRecuring';
import ModalReSchedule from 'app/modules/calendar/components/modal/ReSchedule';
import ModalRecurringJob from 'app/modules/calendar/components/modal/RecurringJob';
import ScheduleException from 'app/modules/calendar/components/modal/ScheduleException';
import {
    checkCalendarViewWeek,
    checkEventVisible,
    checkShowWeekends,
    convertEvents,
    convertOneEvent,
    handleCreatePopper,
    handleDisplayTimeWindow,
    handleGetCustomStyleEvent,
    handleGetDataTimeWindow,
    handleRemoveTimeWindowEl,
    handleUpdateTimeWindowEl,
    isAlwayShowWeekends,
    isEventOverDiv,
    makeEventVisibleMiddle,
    setDateTimeWindow,
    sortEventsListView,
    updateSlotHeight
} from 'app/modules/calendar/ultil/Calendar';
import { actionGetListJob, updateAgenda, updateCalendar, updateSelectDate } from 'common/redux/actions/calendar';
import { setDateInlineCalendar } from 'common/redux/actions/calendar/inlineCalendarAction';
import { actionMoveJob, actionMoveJobToWorkPool, actionResizeTimeJob } from 'common/redux/actions/calendar/jobAction';
import { actionCustomerJobList } from 'common/redux/actions/calendar/jobListAction';
import {
    actionMoveCalendarToWP,
    actionMoveJLToCalendar,
    actionMoveWPToCalendar
} from 'common/redux/actions/calendar/workPoolAction';
import { addEvent } from 'common/redux/actions/event';
import { actionOpenJobPreview, actionOpenTimeOff } from 'common/redux/actions/job';
import { actionCreateJob } from 'common/redux/actions/job/create';
import { actionOpenJobDetail } from 'common/redux/actions/job/detail';
import { clientQuery } from 'common/utils/ApiUtils';
import { getUserInfo } from 'common/utils/CompanyUserUtils';
import { convertTimeToISO, formartDateWithString } from 'common/utils/DateUtils';
import { deepCloneArray } from 'common/utils/FunctionUtils';
import { getJobStatus } from 'common/utils/JobStatusUtils';
import { getLocalStorageValue } from 'common/utils/LocalStorageUtils';
import { handleTrackingEvent } from 'common/utils/MixpanelUtils';
import { getPlanUser } from 'common/utils/PermissionUtils';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { handleAbortController } from '../customer/utils';
import { mixpanelCalendarViews } from '../mixpanel/MixpanelCalendarViews';
import { ROUTING_EVENT_TYPE } from '../routeoptimizer/const';
import RealtimeJobServices from './RealtimeJobServices';
import RealtimeServices from './RealtimeSMSServices';
import RealtimeTaskServices from './RealtimeTaskServices';
import ServiceEvent from './ServiceEvent';
import ServicesTask from './ServicesTask';
import CalendarControl from './components/CalendarControl';
import TooltipAddJob from './components/TooltipAddJob';
import { EventContent, LabelContent, ResourceContent, ResourceHeader } from './components/eventCalendar';
import DayCellContent from './components/eventCalendar/DayCellContent';
import EventContentListView from './components/eventCalendar/EventContentListView';
import HeaderContent from './components/eventCalendar/HeaderContent';
import HeaderListView from './components/listView/Header';
import { UN_MAGNET_VALUE } from './components/magnet/components/constants';
import { applyMagnetEvents, calculateDistance, modifyMagnetEvents } from './components/magnet/utils';
import AddTaskModal from './components/task/AddTaskModal';
import ListTaskModal from './components/task/ListTaskModal';
import TasksSchedule from './components/task/TasksSchedule';
import UpdateTaskModal from './components/task/UpdateTaskModal';
import CalendarTooltip from './components/tooltip';
import TooltipAllDaySlot from './components/tooltip/TooltipAllDaySlot';
import {
    ACTIONS_JOB,
    CALENDAR_EVENT_TYPE,
    CALENDAR_LIST_VIEWS_MODE,
    CALENDAR_MODE,
    CALENDAR_MODES,
    CALENDAR_MODES_CLASS,
    CALENDAR_MODE_CLASS,
    CALENDAR_MODE_VIEW,
    CALENDAR_TIMELINE_MODE_VIEW,
    DAYS_OF_WEEK,
    DEFAULT_HEIGHT_OF_ROW,
    DEFAULT_WIDTH_TIMELINE,
    ID_TIME_WINDOW_FRAME,
    JOB_MAGNET_ABILITY,
    TIME_LINE_MODES,
    TIME_LINE_MODE_VIEW_CLASS,
    TYPE_RESOURCE_HORIZONTAL,
    VIEW_OPTIONS_AGENDAR
} from './const';
import { COLUMNS_LIST_VIEW } from './const/Columns';
import { FULL_CALENDAR_KEY } from './const/SettingCalendar';
import BoxDragCalendar from './map/components/BoxDragCalendar';

function MainCalendar({ onUpdateEvents = () => {}, onDisplayPopup = () => {} }, ref) {
    const { t } = useTranslation(['calendar']);
    const dispatch = useDispatch();
    const history = useHistory();

    const calendarReducer = useSelector(({ calendar }) => calendar);
    const isFirstTimeSchedules = useSelector(({ schedules }) => schedules.isFirstTime);

    const {
        company: companySettings,
        profile: profileUser,
        settings: userSettings
    } = useSelector(({ auth }) => auth.user);
    const magnetAddon = userSettings?.addons?.routing?.job_magnet || null;

    const {
        date: finalSelectedDate,
        view: finalCalendarView,
        color: finalServiceColor,
        schedules: finalSchedules,
        heightofrow: finalHeightOfRow,
        widthofcolumn: finalWidthOfColumn,
        mode: finalCalendarMode,
        horizontal_type
    } = calendarReducer || {};
    const finalTimeLineMode = TIME_LINE_MODES[horizontal_type?.toString()];

    const finalViews = useMemo(() => {
        return CALENDAR_MODE.HORIZONTAL === finalCalendarMode ? CALENDAR_TIMELINE_MODE_VIEW : CALENDAR_MODE_VIEW;
    }, [finalCalendarMode]);

    const finalInitViews = useMemo(() => {
        return finalViews[finalCalendarView];
    }, [finalCalendarMode, finalCalendarView]);

    useEffect(() => {
        !isFirstTimeSchedules && addParamsToUrl({ schedules: finalSchedules.map((item) => item.id).join(',') });
    }, [finalSchedules]);

    const addParamsToUrl = (params) => {
        const urlSearchParams = new URLSearchParams(location.search);

        Object.entries(params).forEach(([key, value]) => {
            urlSearchParams.set(key, value);
        });

        history.replace(`${location.pathname}?${urlSearchParams.toString()}`);
    };

    const columnsListView = useMemo(() => {
        const result = [];
        const additionalColumns = calendarReducer.columns || '';
        const arrColumns = additionalColumns.split(',');
        arrColumns.forEach((item) => {
            const column = COLUMNS_LIST_VIEW.find((column) => column.key === item);
            if (column) result.push(column);
        });
        return result;
    }, [calendarReducer.columns]);

    const [state, dispatchState] = useReducer(reducer, {
        events: [],
        loading: true,
        activatedMagnet: false,
        calendarView: finalCalendarMode || CALENDAR_MODE.VERTICAL,
        heightOfRow: DEFAULT_HEIGHT_OF_ROW,
        settingsCalendar: {
            timeZone: 'UTC',
            firstDay: 0,
            lazyFetching: true,
            allDaySlot: true,
            editable: true,
            datesAboveResources: true,
            slotDuration: '00:15',
            initialView: finalInitViews || 'resourceTimeGridDay',
            views: VIEW_OPTIONS_AGENDAR,
            resources: [],
            slotMinWidth: DEFAULT_WIDTH_TIMELINE,
            headerToolbar: false
        }
    });
    const { activatedMagnet } = state;

    const { business_hours: businessHours = {} } = companySettings || {};

    useImperativeHandle(ref, () => ({
        onGoToDate: _goTodate,
        onNext: _nextAgenda,
        onPrev: _prevAgenda,
        onToday: _onToday,
        onGetTitle: _getTitle,
        onChangeView: _changeView,
        onResizeCalendar: _updateCalendarSize,
        onGetRangeDate: _getCurrentRange,
        onServiceClick: _doubleClickEvent,
        onSelectMarker: _handleSelectMarker,
        onRefetch: _fetchListJob,
        reloadEventsOptimized: _reloadEventsOptimized,
        onActivateMagnet: handleActivateMagnet
    }));

    const finalDayMaxEvent = finalCalendarView !== CALENDAR_MODES.DAY_GRID_MONTH ? 3 : false;

    const refCalendar = useRef(null);
    const refMapView = useRef(null);
    const refModalRecurring = useRef(null);
    const refViewCalendar = useRef(null);
    const refModalReSchedule = useRef(null);
    const refAddTask = useRef(null);
    const refModalScheduleError = useRef(null);
    const refTaskModal = useRef(null);
    const refTaskListModal = useRef(null);
    const refTooltip = useRef(null);
    const refTooltipAllDaySlot = useRef(null);
    const refPrevTimeWindow = useRef(null);
    const timeoutRef = useRef(null);

    // This ref for handle tooltip add job
    const refSelect = useRef(null);

    const refModalRecurringJob = useRef(null);
    const refScheduleTasks = useRef(null);
    const refTimer = useRef(null);
    const refDataAdd = useRef(null);

    // Refs for handle drag scroll in view month
    const refDragging = useRef(false);
    const refAnimatedId = useRef(null);
    const refScrollDiv = useRef(null);
    const refDivDragPos = useRef(null);
    const refPrevTimeAnimated = useRef(null);

    // This refs for handle list view calendar
    const refElements = useRef([]);
    const refCurrentSticky = useRef(null);

    // This refs for handle magnet
    const refCurrentMagnetJobs = useRef([]);
    const refStoreMagnet = useRef([]);

    // Handle toggle map or div resized.
    const refObserver = useRef(
        new ResizeObserver(() => {
            _updateCalendarSize();
        })
    );

    useEffect(() => {
        const mainPageCalendar = document.getElementById('main_page_full_calendar');
        if (mainPageCalendar) refObserver.current.observe(mainPageCalendar);
        _handleTrackingCalendarView();
    }, []);

    const { calendarView: currentCalendarView, events: finalEvents, loading: isLoading, settingsCalendar } = state;

    const { isProPlan, isGrowthPlan, isTrial } = getPlanUser(profileUser);
    const isVerticalMode = CALENDAR_MODE.VERTICAL === currentCalendarView;

    const viewCalendar = isVerticalMode ? CALENDAR_MODE_VIEW : CALENDAR_TIMELINE_MODE_VIEW;
    const slotLabelInterval = viewCalendar[finalCalendarView] !== 'resourceTimelineMonth' ? '01:00' : null;
    const isMonthView = finalCalendarView === CALENDAR_MODES.DAY_GRID_MONTH;
    const isTwoWeekView = finalCalendarView === CALENDAR_MODES.AGENDA_2_WEEK;
    const isListView = CALENDAR_LIST_VIEWS_MODE.includes(finalCalendarView);
    const finalDisplayControl = isVerticalMode && !isMonthView && !isListView;
    const classTimeLine = !isVerticalMode ? TIME_LINE_MODE_VIEW_CLASS[finalTimeLineMode] : '';

    const listTask = useMemo(() => {
        return finalEvents.filter((e) => e.type === 'task');
    }, [finalEvents]);

    useEffect(() => {
        const fullcalendarDiv = document.getElementById('view-calendar');
        // Add a scroll event listener to the #fullCalendar div
        fullcalendarDiv.addEventListener('scroll', _handleStickyListView);
        return () => {
            fullcalendarDiv.removeEventListener('scroll', _handleStickyListView);
        };
    }, []);

    useEffect(() => {
        const draggableEl = document.getElementById('external-events-work-pool');
        const draggableJLEl = document.getElementById('external-events-job-list');

        draggableEl &&
            new Draggable(draggableEl, {
                itemSelector: '.fc-event',
                eventData: _onDragToCalendar
            });

        draggableJLEl &&
            new Draggable(draggableJLEl, {
                itemSelector: '.fc-event',
                eventData: _onDragToCalendar
            });
    }, []);

    useLayoutEffect(() => {
        const div = document.querySelector(`tbody[role="presentation"] tr`);

        if (div && refTooltipAllDaySlot.current) {
            refTooltipAllDaySlot.current?.createInstance();
            div.addEventListener('mousemove', (event) => {
                const { clientX: x, clientY: y } = event;
                const hasTask = event.toElement.closest('.event-task');
                refTooltipAllDaySlot.current?.updateInstance(
                    x,
                    y,
                    !!hasTask ? t('calendar:open_a_task') : t('calendar:add_a_task')
                );
            });

            div.addEventListener('mouseleave', (e) => {
                if (e?.toElement?.id !== 'tooltip-all-day-slot') refTooltipAllDaySlot.current?.removeInstance();
            });
        }
    }, [state.events, isVerticalMode]);

    function _onDragToCalendar(eventEl) {
        eventEl.classList.add('has-drag');
        return JSON.parse(eventEl.getAttribute('data'));
    }

    useLayoutEffect(() => {
        const divTime = document.getElementById('title-calendar-time');
        if (divTime) divTime.innerText = _getTitle();
    }, [state]);

    // Resize the map view when the current calendar view changes
    useEffect(() => {
        refMapView.current && refMapView.current._resizeMapView();
    }, [currentCalendarView]);

    useEffect(() => {
        queueMicrotask(() => {
            _goTodate(finalSelectedDate);
        });
    }, [finalSelectedDate, finalCalendarView]);

    /**
     * Will get next props of, date, view, color, schedule
     * Will call api when have any change.
     */
    useEffect(() => {
        if (!refCalendar.current.getApi || isFirstTimeSchedules) return;
        queueMicrotask(() => {
            refCalendar.current.getApi().removeAllEvents();
        });

        dispatchState((prevState) => {
            return {
                calendarView: finalCalendarMode,
                settingsCalendar: {
                    ...settingsCalendar,
                    resources: finalSchedules.map((item) => ({ ...item, sortId: +item.id }))
                },
                loading: true,
                events: [],
                // If change view to job magnet ability views, will keep activated magnet
                activatedMagnet: JOB_MAGNET_ABILITY.includes(finalCalendarView) ? prevState.activatedMagnet : false
            };
        });

        setTimeout(() => {
            if (!refCalendar.current) return;
            refCalendar.current.getApi().changeView(finalInitViews);
            _fetchListJob();
        }, 0);
    }, [
        finalServiceColor,
        finalSchedules,
        finalSelectedDate,
        calendarReducer.weekends,
        finalCalendarView,
        currentCalendarView
    ]);

    // Update slotMinWidth in calendar timeline mode
    useEffect(() => {
        if (settingsCalendar.slotMinWidth !== finalWidthOfColumn) {
            dispatchState({
                events: [...finalEvents],
                settingsCalendar: { ...settingsCalendar, resources: finalSchedules, slotMinWidth: finalWidthOfColumn }
            });
        }
    }, [settingsCalendar, finalWidthOfColumn]);

    useEffect(() => {
        updateSlotHeight(finalHeightOfRow);
        if (finalEvents.length > 0) {
            const calendarApi = refCalendar.current.getApi();
            calendarApi.refetchEvents();
            dispatchState({ heightOfRow: finalHeightOfRow });
        }
    }, [finalEvents, finalCalendarView, isVerticalMode, finalHeightOfRow]);

    // Handle update calendar scroll when state change in month view
    useLayoutEffect(() => {
        _handleUpdateSizeMonthView();
    }, [finalEvents, finalSchedules, isVerticalMode, isMonthView]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            _handleUpdateTimeWindow({});
        }, 0);
        return () => {
            clearTimeout(timeout);
        };
    }, [finalHeightOfRow, finalWidthOfColumn]);

    /* Adding a class to the calendar container div. */
    useEffect(() => {
        const containerDiv = document.getElementById('displayView');
        if (containerDiv) containerDiv.classList = `calendar-container ${CALENDAR_MODE_CLASS[currentCalendarView]}`;
    }, [currentCalendarView]);

    useLayoutEffect(() => {
        if (!refPrevTimeWindow.current) return;

        let timer = null;
        const prevId = refPrevTimeWindow.current.jobId;
        const dataTimeWindow = finalEvents.find((item) => item.jobIdTimeWindow === prevId) || null;

        if (dataTimeWindow) {
            timer = setTimeout(() => {
                const element = document.getElementById(prevId);
                if (element)
                    _handleDisplayTimeWindow({
                        element,
                        jobId: prevId,
                        time_window: dataTimeWindow.time_window,
                        eventStartDay: dataTimeWindow.event.start
                    });
            }, 0);
        } else {
            refPrevTimeWindow.current = null;
            handleRemoveTimeWindowEl();
        }

        return () => {
            if (timer) clearTimeout(timer);
        };
    }, [finalEvents]);

    function _fetchListJob(shouldRemoveOldEvents = false) {
        // Clear magnet jobs
        refStoreMagnet.current = [];

        handleAbortController(abortMagnetController);
        shouldRemoveOldEvents && _removeOldEvents();
        const { activeEnd, activeStart } = _getCurrentRange();
        const start = convertTimeToISO(moment(activeStart).utc().startOf('day'));
        const end = convertTimeToISO(moment(activeEnd).utc().subtract(1, 'day').endOf('day'));

        dispatch(setDateInlineCalendar({ start: activeStart, end: activeEnd }));

        const params = {
            start,
            end,
            agenda: finalCalendarView,
            schedule: finalSchedules.map((item) => item.id).toString(),
            color_id: finalServiceColor
        };

        if (!isListView) params['inc'] = 'task';
        if (isMonthView) {
            const currentDate = refCalendar.current.getApi().getDate();
            params['active_date'] = convertTimeToISO(moment(currentDate).utc().startOf('day'));
        }

        dispatch(actionGetListJob(params, _fetchListJobSuccess, _fetchListJobFailed));
    }

    function _fetchListJobSuccess(response) {
        const events = convertEvents(response.data || [], 'move');
        dispatchState({ events, loading: false });
        if (activatedMagnet && magnetAddon) handleGetMagnetJobs();
    }

    function _fetchListJobFailed() {
        dispatchState({ events: [], loading: false });
    }

    useLayoutEffect(() => {
        if (!isVerticalMode) {
            timeoutRef.current = setTimeout(() => {
                _handleScrollToToday(moment(finalSelectedDate).utc().startOf('day'));
            }, 300);
        }
        return () => {
            timeoutRef.current && clearTimeout(timeoutRef.current);
        };
    }, [finalSelectedDate]);

    /** Change view vertical or horizontal **/
    const _changeView = () => {
        const calendarView =
            CALENDAR_MODE.HORIZONTAL === currentCalendarView ? CALENDAR_MODE.VERTICAL : CALENDAR_MODE.HORIZONTAL;
        const finalViewsAfterChange =
            calendarView === CALENDAR_MODE.HORIZONTAL ? CALENDAR_TIMELINE_MODE_VIEW : CALENDAR_MODE_VIEW;
        refViewCalendar.current = calendarView;
        refCalendar.current.getApi().changeView(finalViewsAfterChange[finalCalendarView]);
        dispatchState({ calendarView });
        dispatch(updateCalendar({ mode: calendarView }));
        _storeCalendar(10, calendarView);
    };

    const _updateHeightRow = (value) => {
        dispatch(updateCalendar({ heightofrow: value }));
        _storeCalendar(7, value);
        refCalendar.current.render();
    };

    const _updateCalendarSize = () => {
        refCalendar.current && refCalendar.current.getApi().updateSize();
    };

    const _nextAgenda = () => {
        refCalendar.current.getApi().next();
        _onChangeDate();
    };

    const _prevAgenda = () => {
        refCalendar.current.getApi().prev();
        _onChangeDate();
    };

    const _onToday = (shouldFetch = true) => {
        if (isVerticalMode) {
            if (shouldFetch) {
                refCalendar.current.getApi().today();
                _onChangeDate();
            } else {
                const timeBusinessStart = moment(businessHours.start, 'HH:mm');
                refCalendar.current.getApi().scrollToTime({
                    minute: timeBusinessStart.minutes(),
                    hours: timeBusinessStart.hours()
                });
            }
        } else {
            if (shouldFetch) {
                refCalendar.current.getApi().today();
                _onChangeDate(_handleScrollToToday);
            } else {
                _handleScrollToToday();
            }
        }
    };

    const _handleScrollToToday = (time = moment().startOf('date')) => {
        if (!isVerticalMode) {
            const { activeStart } = _getCurrentRange();
            const timeBusinessStart = moment(businessHours.start, 'HH:mm');
            const todayUtc = time.add(timeBusinessStart.hours(), 'hours').add(timeBusinessStart.minutes(), 'minutes');
            const durationTime = moment(convertTimeToISO(todayUtc)).utc() - moment(activeStart).utc();
            refCalendar.current.getApi().scrollToTime({ millisecond: durationTime });
        }
    };

    function _getTitle() {
        if (!refCalendar.current?.getApi()) return '';
        const view = refCalendar.current?.getApi().view;
        return [
            CALENDAR_MODE_VIEW[CALENDAR_MODES.AGENDA_DAY],
            CALENDAR_TIMELINE_MODE_VIEW[CALENDAR_MODES.AGENDA_DAY]
        ].includes(view.type)
            ? moment(view.currentStart).utc().format('dddd, MMM D, YYYY')
            : view.title;
    }

    function _isShowWeekends() {
        const isMultipleWeek = [
            CALENDAR_MODES.LIST_DAY,
            CALENDAR_MODES.AGENDA_2_WEEK,
            CALENDAR_MODES.AGENDA_3_WEEK,
            CALENDAR_MODES.AGENDA_4_WEEK
        ].includes(finalCalendarView);
        if (isMultipleWeek) return true;
        return !!calendarReducer.weekends && checkShowWeekends(finalCalendarView);
    }

    const _reloadEventsOptimized = () => {
        dispatchState({ loading: true });
        _fetchListJob(true);
    };

    const abortMagnetController = useRef(null);

    useEffect(() => {
        return () => handleAbortController(abortMagnetController);
    }, []);

    const handleGetMagnetJobs = (cbFinish = () => {}) => {
        handleAbortController(abortMagnetController);
        if (!JOB_MAGNET_ABILITY.includes(finalCalendarView) || !magnetAddon) return;

        abortMagnetController.current = new AbortController();
        const handleGetMagnetSuccess = ({ data = [] }) => {
            dispatchState((prevState) => {
                const eventsWithMagnet = applyMagnetEvents(prevState.events, data);
                refStoreMagnet.current = deepCloneArray(eventsWithMagnet);
                return { ...prevState, events: eventsWithMagnet };
            });
            cbFinish({ type: LIST_STATUS.SUCCESS });
        };
        const handleGetMagnetFail = ({ isAborted, message, statusCode }) => {
            if (isAborted) return;
            refStoreMagnet.current = [];
            cbFinish({ type: LIST_STATUS.ERROR, message: message || t('you_do_not_have_permission'), statusCode });
        };

        const { activeEnd, activeStart } = _getCurrentRange();
        const paramsQuery = {
            agenda: finalCalendarView,
            start: convertTimeToISO(moment(activeStart).utc().startOf('day')),
            end: convertTimeToISO(moment(activeEnd).utc().subtract(1, 'day').endOf('day')),
            schedules: finalSchedules.map((item) => item.id).toString()
        };

        clientQuery(
            CALENDAR_JOBS_MAGNETS,
            { data: paramsQuery, method: 'GET', toFormData: false, abortController: abortMagnetController.current },
            handleGetMagnetSuccess,
            handleGetMagnetFail
        );
    };

    const handleResetMagnetJobs = () => {
        activatedMagnet ? handleGetMagnetJobs() : handleStoreMagnet();
    };

    const handleActivateMagnet = (activateMagnet = false, cbFinish = () => {}) => {
        let finalActivateMagnet = activateMagnet;
        if (activateMagnet === null) finalActivateMagnet = !state.activatedMagnet;
        handleAbortController(abortMagnetController);

        // Turn off magnet
        if (!finalActivateMagnet) {
            cbFinish();
            dispatchState((prevState) => ({
                ...prevState,
                activatedMagnet: false,
                events: prevState.events.map((item) => ({
                    ...item,
                    is_magnet: false,
                    magnet_jobs: [],
                    ...UN_MAGNET_VALUE
                }))
            }));
        } else {
            const haveStoreMagnet = refStoreMagnet.current.length > 0;
            dispatchState((prevState) => {
                const newState = { ...prevState, activatedMagnet: true };
                if (haveStoreMagnet) newState['events'] = refStoreMagnet.current;
                return newState;
            });
            !haveStoreMagnet ? handleGetMagnetJobs(cbFinish) : cbFinish();
        }
    };

    const _removeOldEvents = () => {
        refCalendar.current?.getApi()?.removeAllEvents();
    };

    /**
     * Go to date with full calendar
     * Check week or 2 week will start with sunday
     * @param {*} date
     */
    function _goTodate(date) {
        let finalDate = moment(date).utc().format('YYYY-MM-DD');
        if (checkCalendarViewWeek(finalCalendarView)) {
            finalDate = moment(date).utc().startOf('week').format('YYYY-MM-DD');
        }
        refCalendar.current.getApi().gotoDate(finalDate);
    }

    function _onChangeDate(callback = () => {}) {
        const getDate = refCalendar.current.getApi().getDate();
        const { activeEnd, activeStart } = _getCurrentRange();

        dispatch(setDateInlineCalendar({ start: activeStart, end: activeEnd }));
        dispatch(updateSelectDate({ date: moment(getDate).toISOString() }));

        setTimeout(() => {
            callback();
        }, 0);
    }

    function _onCheckReizeMoveJob(infoEvent, callback, actionType) {
        // Remove animated on drag scroll
        _handleRemoveAnimated();

        refCalendar.current.getApi().refetchResources();
        const { extendedProps = {} } = infoEvent?.event || {};
        if (getJobStatus(extendedProps.status)?.type === JOB_PENDING_CONFIRMATION) {
            refModalReSchedule.current &&
                refModalReSchedule.current._showOptions(infoEvent, _onEventResizeMoveJob, callback);
        } else {
            _onEventResizeMoveJob(infoEvent, true, callback, actionType);
        }
    }

    function _onEventResizeMoveJob(infoEvent, reSchedule, callback, actionType) {
        const optionStorage = getLocalStorageValue(KEY_OPTIONS_CHECK_RECURRING_JOB_CALENDAR);
        const { extendedProps } = infoEvent?.event || {};
        const isActiveJob = extendedProps?.active_job === 1;
        const isRepeatJob = extendedProps?.repeat === 1;
        const eventStartStr = infoEvent?.event?.startStr;
        const isToday = moment.utc(infoEvent.event.startStr).isSame(moment.utc(), 'day');
        const isMoveJobActiveToToday = isActiveJob && eventStartStr ? isToday : false;

        if (
            (isMoveJobActiveToToday || isRepeatJob) &&
            reSchedule &&
            getJobStatus(extendedProps.status)?.type !== JOB_PENDING_CONFIRMATION
        ) {
            if (typeof optionStorage === 'string' && (optionStorage === '1' || optionStorage === '0')) {
                callback(
                    parseInt(optionStorage),
                    infoEvent,
                    isToday ? parseInt(getLocalStorageValue(KEY_OPTIONS_CHECK_NOTIFY_TECHNICIAN_JOB_CALENDAR)) : false
                );
                return;
            }
            refModalRecurring.current?._showOptions(
                infoEvent,
                callback,
                () => refCalendar.current.getApi().refetchResources(),
                CALENDAR_EVENT_TYPE[extendedProps.type] || null,
                isMoveJobActiveToToday &&
                    extendedProps.type === ROUTING_EVENT_TYPE.JOB &&
                    actionType === ACTIONS_JOB.MOVE,
                isActiveJob && !isRepeatJob
            );
        } else {
            callback(0, infoEvent);
        }
    }

    const _handleTimeWindowPosition = ({ newStartTime, oldTimeWindow }) => {
        // Check time window currently active
        if (refPrevTimeWindow.current) {
            const element = document.getElementById(refPrevTimeWindow.current.jobId);
            if (element) {
                const newTimeWindow = setDateTimeWindow({ newStartTime, oldTimeWindow });
                _handleDisplayTimeWindow({
                    jobId: refPrevTimeWindow.current.jobId,
                    element,
                    time_window: newTimeWindow,
                    eventStartDay: moment(newStartTime).utc().toISOString()
                });
            }
        }
    };

    /**
     * This function move event. Use eventid for move job not jobid
     * @param {*} all flag check update all or only job seleced
     * @param {*} infoEvent data of event come from event in calendar
     */
    function _handlMoveJob(all, infoEvent, notifyTech = false) {
        const { activeStart } = _getCurrentRange();
        const { start: newStartTime, extendedProps, id, _def: oldDataEvent } = infoEvent?.event || {};
        const newScheduleId = isMonthView ? finalSchedules?.[0].id : oldDataEvent.resourceIds?.[0];

        const finalDate = !!!isMonthView
            ? moment(newStartTime).utc().toISOString()
            : convertTimeToISO(
                  moment(
                      `${moment(newStartTime).utc().format('MM-DD-YYYY')} ${moment(extendedProps.event.start)
                          .utc()
                          .format('HH:mm:ss')}`,
                      'MM-DD-YYYY HH:mm:ss'
                  )
              );

        setTimeout(() => {
            _handleTimeWindowPosition({ newStartTime: finalDate, oldTimeWindow: extendedProps.time_window });
        }, 0);

        _showLoadingEventCalendar(id, false);
        dispatch(
            actionMoveJob(
                {
                    typeEvent: infoEvent.event.extendedProps.type,
                    eventId: extendedProps.eventId,
                    start: finalDate,
                    color_id: finalServiceColor,
                    schedule: newScheduleId,
                    agenda: finalCalendarView,
                    agenda_start: activeStart,
                    all: all,
                    [NOTIFY_TECH]: notifyTech ? 1 : 0,
                    // eslint-disable-next-line no-undef
                    socket_id: global.mainCalendarSocketId
                },
                (response) => _moveResizeSuccess(response, all, infoEvent, id),
                (response) => _moveResizeFailed(response, infoEvent, id)
            )
        );
    }

    function _moveResizeSuccess(response, all, infoEvent, idLoading) {
        const finalSchedulesIds = finalSchedules.map((item) => item.id);

        let dataResponse = [...response.data];

        const {
            parent_job_id: currentParentJob,
            previously_completed: previouslyCompleted,
            job_no: currentJobNo,
            typeMove: typeAction,
            eventId: finalEventId,
            type: typeEvent
        } = infoEvent.event.extendedProps;

        const eventIds = dataResponse.map((item) => item.event.id);

        dataResponse = dataResponse.filter((item) => finalSchedulesIds.includes(item.schedule.id));
        const addEventLost = dataResponse.map((item) => {
            return convertOneEvent(item, ACTIONS_JOB.MOVE);
        });

        switch (typeAction) {
            case ACTIONS_JOB.MOVE_WP:
                infoEvent.revert();
                dispatch(actionMoveWPToCalendar({ all, currentParentJob, currentJobNo, eventIds }));
                break;
            case ACTIONS_JOB.MOVE_JL:
                dispatch(actionMoveJLToCalendar([...response.data]));
                infoEvent.revert();
                break;
            case ACTIONS_JOB.MOVE:
                if (typeEvent === 'event' || typeEvent === 'timeoff') {
                    const dataUpdate = {
                        previouslyCompleted,
                        currentParentJob,
                        finalEventId,
                        addEventLost,
                        currentJobNo,
                        eventIds,
                        all
                    };
                    onUpdateEvents(dataUpdate, ACTIONS_JOB.MOVE);
                }
                break;
            default:
                break;
        }

        dispatchState((prev) => {
            let eventTemp = [...prev.events];
            if (activatedMagnet) {
                eventTemp = eventTemp.map((item) => {
                    return { ...item, is_magnet: false, schedule_ids: [], magnet_jobs: [], ...UN_MAGNET_VALUE };
                });
            }

            if (parseInt(previouslyCompleted) === 1) {
                eventTemp = eventTemp.filter((eventDetail) => eventDetail.eventId !== finalEventId);
            } else {
                eventTemp = eventTemp.filter((eventDetail) => {
                    if (currentParentJob === eventDetail.parent_job_id && eventDetail.job_no >= currentJobNo) {
                        if (!eventIds.includes(eventDetail.eventId) && all === 0) {
                            return eventDetail;
                        }
                    } else {
                        return eventDetail;
                    }
                });
            }

            eventTemp = [...eventTemp, ...addEventLost];
            return { ...prev, events: eventTemp };
        });

        _showLoadingEventCalendar(idLoading, true);
        setTimeout(() => {
            const dataEvent = dataResponse[0];
            if (!dataEvent) return;
            _handleUpdateSizeMonthView();
            _handleTimeWindowPosition({ newStartTime: dataEvent.event.start, oldTimeWindow: dataEvent.time_window });
        }, 0);
        if (activatedMagnet) handleGetMagnetJobs();
    }

    const _handleUpdateSizeMonthView = () => {
        const divWrapper = document.getElementById('fullcalendar_wrapper');
        if (!divWrapper) return;
        if (isVerticalMode && isMonthView) {
            const wrapperDiv = document.querySelector('.fc-daygrid.fc-dayGridMonth-view.fc-view');
            if (wrapperDiv) {
                const divScrollHeight = wrapperDiv.scrollHeight || 0;
                divWrapper.style.height = `${divScrollHeight}px`;
            }
        } else {
            divWrapper.style.height = '100%';
        }
    };

    function _moveResizeFailed(response, info, id) {
        // This case check move from WP
        if (info.draggedEl) {
            info.draggedEl.style.display = 'block';
        }

        _showLoadingEventCalendar(id, true);
        info && info.revert();
        refModalScheduleError.current && refModalScheduleError.current._showOptions(response.message.toString());
    }

    /**
     * This function resize event. Use eventid for resize job not jobid
     * @param {*} all flag check update all or only job seleced
     * @param {*} infoEvent data of event come from event in calendar
     */
    function _handleResizeJob(all, infoEvent) {
        const { activeStart } = _getCurrentRange();
        const { end: newEndTime, extendedProps, id } = infoEvent?.event || {};
        const { end: oldEndTime } = infoEvent.oldEvent;

        _showLoadingEventCalendar(id, false);

        const params = {
            typeEvent: infoEvent.event.extendedProps.type,
            eventId: extendedProps.eventId,
            from: moment(oldEndTime).toISOString(),
            to: moment(newEndTime).toISOString(),
            color_id: finalServiceColor,
            schedule: extendedProps.schedule.id,
            agenda: finalCalendarView,
            agenda_start: activeStart,
            all: all,
            // eslint-disable-next-line no-undef
            socket_id: global.mainCalendarSocketId
        };

        dispatch(
            actionResizeTimeJob(
                params,
                (response) => _moveResizeSuccess(response, all, infoEvent, id),
                (response) => _moveResizeFailed(response, infoEvent, id)
            )
        );
    }

    /**
     * Start active we will check with workpool we will hide job with job list will check remove before job same id
     * With WP will will hide it on work pool list.
     * With Job list. We don't delete job in job list. We just hide the current moving job in the calendar.
     * We will call function move job like move job on calendar
     */
    function _handleMoveFromSideBar(all, infoEvent, notifyTech = false) {
        if (infoEvent.event.extendedProps.typeMove === ACTIONS_JOB.MOVE_WP) {
            infoEvent.draggedEl.style.display = 'none';
        } else {
            const events = refCalendar.current.getApi().getEvents();
            events.forEach((item) => {
                if (
                    infoEvent.event.id === item.id &&
                    moment(item.start).format('x') !== moment(infoEvent.event.start).format('x')
                ) {
                    item.setProp('display', 'none');
                }
            });
        }

        _handlMoveJob(all, infoEvent, notifyTech);
    }

    function _handleMoveToWP(all, infoEvent) {
        const eventType = infoEvent?._def?.extendedProps?.type;
        if (eventType === 'timeoff' || eventType === 'event') return;
        const params = {
            color_id: finalServiceColor,
            all: all,
            jobId: infoEvent._def.extendedProps.event.id
        };

        dispatch(
            actionMoveJobToWorkPool(
                params,
                (response) => _handleMoveToWPSuccess(response, all, infoEvent),
                (response) => _handleMoveToWPFailed(response, infoEvent)
            )
        );
    }

    function _handleMoveToWPSuccess(response, all, infoEvent) {
        let eventTemp = [...finalEvents];
        let eventRemove = [];
        const { extendedProps = {}, publicId: jobId } = infoEvent._def;

        const {
            parent_job_id: currentParentJob,
            job_no: currentJobNo,
            previously_completed: previouslyCompleted
        } = extendedProps;

        if (parseInt(previouslyCompleted) === 1) {
            eventTemp = eventTemp.filter((eventDetail) => {
                if (jobId === eventDetail.id) {
                    eventRemove = eventRemove.concat(eventDetail);
                } else {
                    return eventDetail;
                }
            });
        } else {
            eventTemp = eventTemp.filter((eventDetail) => {
                if (currentParentJob === eventDetail.parent_job_id && eventDetail.job_no >= currentJobNo) {
                    if (jobId === eventDetail.id) {
                        const newColor = response.data.color;
                        if (newColor) {
                            eventDetail = {
                                ...eventDetail,
                                colorEvent: newColor,
                                color: newColor
                            };
                        }
                    }
                    if (all === 1 || jobId === eventDetail.id) {
                        eventRemove = eventRemove.concat(eventDetail);
                    } else {
                        return eventDetail;
                    }
                } else {
                    return eventDetail;
                }
            });
        }

        dispatch(actionMoveCalendarToWP(eventRemove));
        dispatchState({ events: eventTemp });
        onUpdateEvents(
            eventRemove.map((item) => item.id),
            ACTIONS_JOB.MOVE_WP
        );
        _toggleJobMoveToSideBar(true);
    }

    function _handleMoveToWPFailed(response, infoEvent) {
        infoEvent.revert();
    }

    function _eventDragStop(infoEvent) {
        // Remove animated on drag scroll
        _handleRemoveAnimated();

        if (refPrevTimeWindow.current) refPrevTimeWindow.current.jobId = infoEvent.event.id;
        const { event, jsEvent } = infoEvent;
        if (
            isEventOverDiv(
                jsEvent.clientX ?? jsEvent.changedTouches[0].clientX,
                jsEvent.clientY ?? jsEvent.changedTouches[0].clientY
            )
        ) {
            const { status, repeat, type } = event.extendedProps;
            const { type: typeStatus } = getJobStatus(status) || {};
            if (!JOB_EXCLUDE.MOVE_JOB.some((item) => item === typeStatus)) {
                if (repeat) {
                    refModalRecurring.current &&
                        refModalRecurring.current._showOptions(
                            { ...event, revert: () => _toggleJobMoveToSideBar(true) },
                            _handleMoveToWP,
                            null,
                            null,
                            type === ROUTING_EVENT_TYPE.JOB
                        );
                } else {
                    _handleMoveToWP(0, { ...event, revert: () => _toggleJobMoveToSideBar(true) });
                }
            } else {
                _toggleJobMoveToSideBar(true);
            }
        } else {
            _toggleEventsRelation(event, false);
        }
    }

    const _handleDragMove = () => {
        const divDragging = document.querySelector('.fc-event-dragging');
        if (!divDragging || !refDragging.current) return;
        refScrollDiv.current.addEventListener('mousemove', handleScroll);
    };

    function _eventDragStart({ event }) {
        if (isMonthView && isVerticalMode) {
            refDragging.current = true;
            refScrollDiv.current = document.getElementById('fullCalendar');
            setTimeout(() => {
                _handleDragMove(event);
            }, 0);
        }
        dispatch(actionOpenJobPreview(null));
        dispatch(actionOpenTimeOff(null));

        _toggleEventsRelation(event, true);
    }

    function _onEventResizeStart({ event }) {
        dispatch(actionOpenTimeOff(null));
        _toggleEventsRelation(event, true);
    }

    const handleScroll = (event) => {
        const { clientY } = event;
        refDivDragPos.current = clientY;
        const divFullcalendar = refScrollDiv.current;
        const scrollSpeed = 10;

        const scrollLoop = (timeStamp) => {
            if (refDragging.current) {
                if (!refPrevTimeAnimated.current) {
                    refPrevTimeAnimated.current = timeStamp;
                }

                const delta = timeStamp - refPrevTimeAnimated.current;
                refPrevTimeAnimated.current = timeStamp;

                if (delta > 0) {
                    const speed = (scrollSpeed * delta) / 16.67;
                    const divRect = divFullcalendar.getBoundingClientRect();

                    if (refDivDragPos.current >= divRect.bottom - 20) {
                        divFullcalendar.scrollBy(0, speed);
                    } else if (refDivDragPos.current <= divRect.top - 20) {
                        divFullcalendar.scrollBy(0, -speed);
                    } else {
                        cancelAnimationFrame(refAnimatedId.current);
                    }
                }
            }

            refAnimatedId.current = requestAnimationFrame(scrollLoop);
        };

        refAnimatedId.current = requestAnimationFrame(scrollLoop);
    };

    // Removes the event listener that listens for mouse movement and cancels the animation frame
    const _handleRemoveAnimated = () => {
        refDragging.current = false;
        refPrevTimeAnimated.current = null;
        cancelAnimationFrame(refAnimatedId.current);
        refScrollDiv.current && refScrollDiv.current.removeEventListener('mousemove', handleScroll);
    };

    function _onEventResizeStop({ event }) {
        _toggleEventsRelation(event, false);
        _handleRemoveAnimated();
    }

    function _removeAllEventActive() {
        const allEvents = document.getElementsByClassName('fc-event');

        Object.keys(allEvents).forEach((idx) => {
            const itemIdx = allEvents[idx];
            if (itemIdx) {
                itemIdx.style.boxShadow = 'none';
            }
        });
    }

    function _addEventActive(elm) {
        if (elm) {
            elm.style.boxShadow = '3px 3px 0px 1px #333';
        }
    }

    const _handleDisplayTimeWindow = ({ element, time_window, eventStartDay, jobId = null }) => {
        // Remove old time window
        handleRemoveTimeWindowEl();

        // Don't display in month view
        if (isMonthView || (!isProPlan && !isGrowthPlan && !isTrial) || !userSettings.addons.time_window) return;
        refPrevTimeWindow.current = { jobId, time_window, eventStartDay };

        const infoCalendar = {
            widthColumn: finalWidthOfColumn,
            heightRow: finalHeightOfRow,
            isTwoWeekView:
                isTwoWeekView ||
                finalCalendarView === CALENDAR_MODES.AGENDA_3_WEEK ||
                finalCalendarView === CALENDAR_MODES.AGENDA_4_WEEK,
            isMonthView,
            isVerticalMode
        };

        const dataToCalculate = handleGetDataTimeWindow(_handleDataCalculateTimeWindow({ time_window, eventStartDay }));
        const dataToCalculatePosition = handleGetDataTimeWindow(
            _handleDataCalculateTimeWindow({ time_window, eventStartDay }, true)
        );
        handleDisplayTimeWindow(
            element,
            handleGetCustomStyleEvent(dataToCalculate),
            handleGetCustomStyleEvent(dataToCalculatePosition),
            infoCalendar,
            { startDay: eventStartDay, time_window }
        );
    };

    const _handleUpdateTimeWindow = ({ time_window }) => {
        const divTimeWindow = document.getElementById(ID_TIME_WINDOW_FRAME);
        if (!divTimeWindow) return;
        let finalTimeWindow = time_window;

        // For update time window when not retrieve new time_window
        // eslint-disable-next-line eqeqeq
        if (finalTimeWindow == undefined) {
            const oldTimeStart = divTimeWindow.getAttribute('data-timewindow-time-start');
            const oldTimeEnd = divTimeWindow.getAttribute('data-timewindow-time-end');

            if (oldTimeStart === 'null' && oldTimeEnd === 'null') {
                finalTimeWindow = null;
            } else {
                finalTimeWindow = { start: oldTimeStart, end: oldTimeEnd };
            }
        }

        const eventStartDay = divTimeWindow.getAttribute('data-timewindow-start-job');
        const dataToCalculate = handleGetDataTimeWindow(
            _handleDataCalculateTimeWindow({ time_window: finalTimeWindow, eventStartDay })
        );
        const dataToCalculatePosition = handleGetDataTimeWindow(
            _handleDataCalculateTimeWindow({ time_window: finalTimeWindow, eventStartDay }, true)
        );

        handleUpdateTimeWindowEl(
            handleGetCustomStyleEvent(dataToCalculate),
            handleGetCustomStyleEvent(dataToCalculatePosition),
            finalTimeWindow
        );
    };

    const _handleDataCalculateTimeWindow = ({ time_window, eventStartDay }, isGetPosition = false) => {
        const infoCalendar = {
            widthColumn:
                document.querySelector('.fc-timeline-slot.fc-timeline-slot-lane')?.clientWidth || finalWidthOfColumn,
            heightRow: document.querySelector('.fc-timegrid-slot')?.clientHeight || finalHeightOfRow,
            isTwoWeekView:
                isTwoWeekView ||
                finalCalendarView === CALENDAR_MODES.AGENDA_3_WEEK ||
                finalCalendarView === CALENDAR_MODES.AGENDA_4_WEEK,
            isMonthView,
            isVerticalMode
        };
        const infoCalculate = {
            time_window,
            eventStartDay,
            business_hours: businessHours,
            infoCalendar
        };

        if (isGetPosition) {
            const { activeStart } = _getCurrentRange();
            return { ...infoCalculate, isVerticalMode, activeStart, isGetPosition: true };
        }
        return infoCalculate;
    };

    function _onClickEvent({ event, el }) {
        _openJobPreView(event);
        if (magnetAddon) handleMagnet(event._def.extendedProps.magnet_jobs);

        // eslint-disable-next-line no-undef
        global.jobPreviewId = event.id;
        const { location = {}, customer, event: eventJob } = event.extendedProps;
        if (location && location.lng && location.lat) onDisplayPopup({ eventJob, location, customer, event });

        if (event.extendedProps.type === 'job') {
            const { time_window, event: eventJob } = event.extendedProps;
            _handleDisplayTimeWindow({ element: el, time_window, eventStartDay: eventJob.start, jobId: event.id });
        } else {
            handleRemoveTimeWindowEl();
            refPrevTimeWindow.current = null;
        }

        _removeAllEventActive();
        _addEventActive(el);
    }

    const toggleMagnetEvents = ({ events = [], activate = false }) => {
        const calendarApi = refCalendar.current.getApi();
        if (!events || !calendarApi) return;
        dispatchState((prevState) => {
            const newEvents = modifyMagnetEvents({ events: prevState.events || [], magnets: events, activate });
            return { ...prevState, events: newEvents };
        });
    };

    const handleMagnet = (events) => {
        // Remove old highlighted magnet
        toggleMagnetEvents({ events: refCurrentMagnetJobs.current || [], activate: false });
        const distanceValue = magnetAddon?.distance?.value || 0;
        // Highlight new magnet
        toggleMagnetEvents({ events: calculateDistance(events || [], distanceValue), activate: true });
        refCurrentMagnetJobs.current = events || [];
    };

    function _doubleClickEvent(event) {
        const { extendedProps } = event;
        const jobId = extendedProps.jobId;

        if (!extendedProps?.is_workpool) _openJobPreView(event);
        if (event.extendedProps.type === 'job') {
            if (extendedProps?.recurrence?.length > 0) {
                clientQuery(updateGetJobActive(jobId), { method: 'GET' }, _openModalRecurringJob);
                return false;
            }
            _openJobDetail(jobId);
        }
    }

    function _openModalRecurringJob(finalJobData) {
        refModalRecurringJob.current &&
            refModalRecurringJob.current._showOptions(finalJobData.data, () => _openJobDetail(finalJobData.data.id));
    }

    function _openJobDetail(jobId) {
        dispatch(actionOpenJobDetail({ id: jobId || 0 }));
    }

    function _handleSelectMarker(info) {
        const jobId = info?.id;
        const scheduleId = info?.schedule?.id;

        if (!jobId || !scheduleId) return;
        _removeAllEventActive();
        if (info?.isWorkPool) return;
        const calendarAPI = refCalendar.current.getApi();
        const events = calendarAPI.getEvents();
        const findItem = events.find((item) => jobId === item.id && scheduleId === item.extendedProps.schedule.id);

        // Trigger event click to display magnet jobs
        const eventTest = calendarAPI.getEventById(jobId);
        if (eventTest) calendarAPI.trigger('eventClick', { event: eventTest });

        // Scroll horizontal view to event
        if (!isVerticalMode) {
            if (!checkEventVisible(jobId)) makeEventVisibleMiddle(jobId);
        }

        _removeAllEventActive();

        const currentEvent = document.querySelectorAll(`[id="${jobId}"]`);
        if (currentEvent) {
            currentEvent.forEach((elm) => {
                _addEventActive(elm.closest('a'));
            });
        }

        if (findItem) _openJobPreView(findItem);
    }

    function _openJobPreView(event) {
        const typeEvent = event.extendedProps.type;

        if (typeEvent === 'task') {
            return refTaskModal.current._openTask({
                ...event._def.extendedProps,
                dateData: moment(event._def.extendedProps.dateData).toISOString(),
                title: event.title,
                // id task different with job id
                id: event.id
            });
        }

        if (typeEvent === 'job') {
            const job = {
                id: event.extendedProps.jobId,
                ...event.extendedProps,
                schedule: { ...event.extendedProps.schedule }
            };
            const { customer = {} } = job;
            dispatch(actionOpenJobPreview(job));
            dispatch(
                actionCustomerJobList({
                    ...customer,
                    label: customer.full_name,
                    location: job.location
                })
            );
        }

        if (typeEvent === 'timeoff' || typeEvent === 'event') {
            dispatch(
                actionOpenTimeOff({
                    id: event.extendedProps.jobId,
                    content: event?.extendedProps?.tile?.content || [],
                    start: event.start,
                    end: event.end,
                    schedules: [],
                    description: event.extendedProps.tile.content[0],
                    isRecurring: event?.extendedProps?.repeat || false,
                    location: null,
                    type: typeEvent,
                    onEdit: _fetchListJob,
                    // only for custom event
                    color: event?.extendedProps?.colorEvent?.background || '',
                    name: event?.extendedProps?.tile.header || '',
                    parent_job_id: event?.extendedProps?.parent_job_id || '',
                    job_no: event?.extendedProps?.job_no || ''
                })
            );
        }
    }

    const _onDayClick = (info) => {
        if (info?.jsEvent?.srcElement?.closest('.fc-daygrid-day-top')) return;
        const isDayGridMonth = finalCalendarView === CALENDAR_MODES.DAY_GRID_MONTH;

        if (info.allDay && !isDayGridMonth) {
            refAddTask.current._open({
                defaultDate: moment(info.date).toISOString(),
                defaultUseId: info.resource.extendedProps.user_id
            });
            return;
        }

        if (isDayGridMonth) {
            const date = moment(info.date).toISOString();
            _goTodate(date);
            dispatch(updateSelectDate({ date }));
            dispatch(updateAgenda(CALENDAR_MODES.AGENDA_DAY));
        } else {
            _handleOpenAddPopup(info);
        }
    };

    const _handleOpenAddPopup = (info) => {
        /* Checking to see if the event is an all day event. If it is, it will return. */
        if (info.allDay) return;

        if (isVerticalMode) {
            // Get row and column index of the clicked cell
            const element = document
                .getElementById('displayView')
                .querySelector(`td[data-time="${info.dateStr.slice(11, 19)}"]`);
            if (element)
                handleCreatePopper(info.dayEl, element.nextSibling, {
                    start: moment.utc(info.date).format('hh:mm'),
                    end: moment.utc(info.date).add(15, 'minutes').format('hh:mm')
                });
        } else {
            const viewsSlotNormal = [
                CALENDAR_TIMELINE_MODE_VIEW[CALENDAR_MODES.AGENDA_DAY],
                CALENDAR_TIMELINE_MODE_VIEW[CALENDAR_MODES.AGENDA_WEEK],
                CALENDAR_TIMELINE_MODE_VIEW[CALENDAR_MODES.AGENDA_3_DAY],
                CALENDAR_TIMELINE_MODE_VIEW[CALENDAR_MODES.AGENDA_4_DAY]
            ];
            // Get row and column index of the clicked cell
            const calendarDiv = document.getElementById('displayView');
            const elementColumn = calendarDiv.querySelector(`td[data-date="${info.dateStr.slice(0, 19)}"]`);
            const resourceDiv = calendarDiv.querySelector(`td[data-resource-id="${info.resource.id}"]`);

            const { x, y, height } = resourceDiv.getBoundingClientRect();
            const fakeDiv = document.createElement('div');
            fakeDiv.style.cssText = `position:fixed;z-index:9999999;top:${y}px;left:${x}px;height:${height}px;width:100%;`;
            document.body.appendChild(fakeDiv);

            if (elementColumn) {
                handleCreatePopper(elementColumn, fakeDiv, {
                    start: moment.utc(info.date).format('HH:mm'),
                    end: viewsSlotNormal.includes(viewCalendar[finalCalendarView])
                        ? moment.utc(info.date).add(15, 'minutes').format('HH:mm')
                        : moment.utc(info.date).add(1, 'hours').format('HH:mm')
                });
                fakeDiv.remove();
            }
        }

        const scheduleInfo = info.resource._resource;
        refDataAdd.current = {
            start: info.dateStr,
            schedule: {
                id: scheduleInfo.id,
                name: scheduleInfo.extendedProps.name,
                user_id: scheduleInfo.extendedProps.user_id,
                is_primary: 1
            }
        };
    };

    const _handleCloseToolTip = () => {
        const divTooltip = document.getElementById('tooltip-add-job');
        const divTooltipBg = document.getElementById('tooltip-add-job-bg');
        if (divTooltip) divTooltip.style.display = 'none';
        if (divTooltipBg) divTooltipBg.style.display = 'none';
    };

    const _handleClearData = () => {
        refDataAdd.current = null;
        if (refSelect.current) refSelect.current.style.zIndex = 2;
    };

    const _openAddTimeoff = (type = 'timeoff') => {
        if (refDataAdd.current) {
            dispatch(addEvent({ start: refDataAdd.current.start, schedule: refDataAdd.current.schedule, type }));
            _handleCloseToolTip();
        }
    };

    function _handleCreateJob() {
        if (refDataAdd.current) {
            const { schedule: scheduleSelected, start } = refDataAdd.current;
            dispatch(actionCreateJob({ start, schedule: scheduleSelected, customer: null, customer_id: '' }));
            _handleCloseToolTip();
        }
    }

    // Check user currently dragging select or not
    // Just allow user select in one moment
    const _alowSelect = ({ end, start, allDay }) => {
        if (allDay) return true;
        const duration = moment.duration(moment(end).diff(start)).asMinutes();

        if (duration <= 15 || finalCalendarView === CALENDAR_MODES.AGENDA_2_WEEK) return true;
        if (isMonthView) return false;
    };

    const _handleUpdateRealtime = (events) => {
        dispatchState({ events });
    };

    const handleStoreMagnet = (events = []) => {
        refStoreMagnet.current = activatedMagnet ? deepCloneArray(events) : [];
    };

    function _handleReloadEvents(eventTemp) {
        refStoreMagnet.current = deepCloneArray(eventTemp);
        dispatchState((prev) => {
            return { ...prev, events: [...eventTemp] };
        });
    }

    function _toggleEventsRelation(event, hide) {
        const events = refCalendar.current.getApi().getEvents();
        const eventExtendedProps = event.extendedProps || {};
        events.forEach((item) => {
            const itemExtendedProps = item.extendedProps || {};
            if (
                eventExtendedProps.jobId === itemExtendedProps.jobId &&
                eventExtendedProps.schedule.id !== itemExtendedProps.schedule.id
            ) {
                item.setProp('display', hide ? 'none' : 'block');
            }
        });
    }

    /**
     * Show hide job virtual when move job to work pool
     */
    function _toggleJobMoveToSideBar(hide) {
        document.getElementById('work_pool_job_move').style.display = hide ? 'none' : 'block';
    }

    function _showLoadingEventCalendar(id, hide = true) {
        const element = document.getElementsByClassName('job_fullcalendar_iconloading_'.concat(id));

        if (element) {
            for (var i = 0; i < element.length; i++) {
                const currentElm = element[i];
                currentElm.classList.toggle('preloader-small__items', !hide);
                currentElm.style.display = hide ? 'none' : 'block';
            }
        }
    }

    function _eventWillUnmount() {
        refElements.current.forEach((element) => element.remove());
        refElements.current = [];
    }

    function _eventDidMount(infoEvent) {
        const extendedProps = infoEvent.event.extendedProps || {};
        infoEvent.el.addEventListener('dblclick', () => {
            _doubleClickEvent(infoEvent.event);
        });

        // TODO - Improve this code
        if (isListView) {
            const nextSibling = infoEvent.el?.nextElementSibling;
            const nextScheduleId = nextSibling?.querySelector('[data-schedule-id]')?.getAttribute('data-schedule-id');

            if (
                nextScheduleId &&
                +extendedProps.schedule?.id !== +nextScheduleId &&
                !nextSibling?.getAttribute('data-date')
            ) {
                const nextScheduleInfo = finalSchedules.find((item) => +item.id === +nextScheduleId) || {};

                const user = getUserInfo(nextScheduleInfo?.user_id);

                const dateUtc = moment.utc(infoEvent.event.startStr);
                const isToday = dateUtc.isSame(moment.utc(), 'day');
                const dateNumber = dateUtc.format('DD');
                const formattedDate = dateUtc.format('MMM, ddd');

                // Create a new element
                const newElement = document.createElement('tr');
                refElements.current.push(newElement);
                newElement.id = `schedule-${nextScheduleId}`;
                newElement.className = 'fc-list-day fc-day';
                newElement.innerHTML = `
                <th scope="colgroup">
                <div class="fc-list-day-cushion fc-cell-shaded">
                <div class="rows --title${isToday ? ' active bg-white' : ''}">
                    <div class="flexcenter">
                        <div class="title-date flexcenter gap-6" style="visibility: hidden"><span class="date">${dateNumber}</span><span class="col-label">${formattedDate}</span></div>
                        <div class="schedule-user">
                            <div class="tech-name">
                                    ${
                                        user
                                            ? `<div class="avt-img"><img src="${user?.avatar}" width="24" height="24" /></div>`
                                            : `<div class="avt">${nextScheduleInfo?.name?.substring(0, 2)}</div>`
                                    }
                                <span class="txt-ellipsis">${nextScheduleInfo?.name}</span>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                </th>
                `;
                // Insert the new element before infoEvent.el
                infoEvent.el.parentNode.insertBefore(newElement, infoEvent.el.nextElementSibling);
            }
        }
    }

    const _handleStickyListView = () => {
        const fullcalendarDiv = document.getElementById('view-calendar');

        // Get the bounding rectangles of the #fullCalendar div
        const fullCalendarRect = fullcalendarDiv.getBoundingClientRect();
        if (refCurrentSticky.current) {
            const boundingRect = refCurrentSticky.current.getBoundingClientRect();
            if (boundingRect.top >= fullCalendarRect.top) {
                refCurrentSticky.current.querySelector('.title-date').style.visibility = 'hidden';
                refCurrentSticky.current = null;
            }
        }

        // Iterate over each new element
        refElements.current.forEach((newElement) => {
            // Get the bounding rectangle of newElement
            const newElementRect = newElement.getBoundingClientRect();
            // Check if newElement has scrolled to the top of the #fullCalendar div
            if (newElementRect.top - 85 <= fullCalendarRect.top) {
                if (newElement !== refCurrentSticky.current) {
                    newElement.querySelector('.title-date').style.visibility = 'visible';
                    refCurrentSticky.current = newElement;
                }
            }
        });
    };

    const _getCurrentRange = () => {
        const { currentEnd, currentStart, activeEnd, activeStart } = refCalendar.current.getApi().view;
        return {
            currentEnd: moment(currentEnd).utc().format(),
            currentStart: moment(currentStart).utc().format(),
            activeEnd: moment(activeEnd).utc().format(),
            activeStart: moment(activeStart).utc().format()
        };
    };

    const _openTasksScheduleModal = ({ tasks = [], customer = null }) => {
        if (!refScheduleTasks.current) return;
        const calendarApi = refCalendar.current.getApi();
        const viewObject = calendarApi.view;
        const isDayView = viewObject.type === 'resourceTimeOneDay';

        refScheduleTasks.current._openTask({
            tasks,
            customer,
            isDayView,
            startRange: viewObject.currentStart,
            endRange: viewObject.currentEnd
        });
    };

    // Handle zoom-in zoom-out action
    const _scaleCalendar = (value) => {
        dispatch(updateCalendar({ widthofcolumn: value }));
        _storeCalendar(9, value);
    };

    // Call api to store value of field
    const _storeCalendar = (type, value, callbackSuccess, callbackFailed) => {
        if (refTimer.current) {
            clearTimeout(refTimer.current);
            refTimer.current = null;
        }

        refTimer.current = setTimeout(() => {
            clientQuery(CALENDAR_STORE, { data: { type, value }, method: 'PUT' }, callbackSuccess, callbackFailed);
            refTimer.current = null;
        }, 300);
    };

    const _handleHeaderClick = (date) => {
        dispatch(updateAgenda(CALENDAR_MODES.AGENDA_DAY));
        dispatch(updateSelectDate({ date: moment(date).toISOString() }));

        clientQuery(CALENDAR_STORE, {
            data: { type: 3, value: moment(date).toString() },
            method: 'PUT'
        });
    };

    const handleEvents = (events) => {
        const temp = {};
        events.forEach((e) => {
            const _defData = e._def || {};
            const extendedProps = _defData.extendedProps || {};
            const idExtended = extendedProps?.jobId;

            if (temp[`${idExtended}_${extendedProps?.type}_${_defData.resourceIds?.[0]}`]) {
                e.remove();
            } else {
                temp[`${idExtended}_${extendedProps?.type}_${_defData.resourceIds?.[0]}`] = e.extendedProps.eventId;
            }
        });
    };

    // Handle to close task edit modal.
    const _handleCloseTaskModal = () => {
        refTaskModal.current._closeTask();
    };

    const _handleUpdateModalSchedule = (data) => {
        refScheduleTasks.current && refScheduleTasks.current._updateRealtime(data);
        refTaskListModal.current && refTaskListModal.current._updateRealtime(data);
    };

    const _handleToggleTooltip = (type, data) => {
        switch (type) {
            case 'show':
                refTooltip.current.show(data);
                break;
            case 'hide':
                refTooltip.current.hide();
                break;
            default:
                break;
        }
    };

    // Render functions scope
    const renderEventContent = (eventInfo) => {
        if (isListView && !_isShowWeekends()) {
            const dayNumber = eventInfo.event.start.getDay();
            // Return an empty element to prevent rendering the event
            if (dayNumber === 0 || dayNumber === 6) return { domNodes: [] };
        }
        const event = eventInfo.event;
        let headerTitle = event.extendedProps?.tile?.header;

        const isAllDayEvent = event.allDay;
        const customStyle = handleGetCustomStyleEvent({
            startStr: event.startStr,
            endStr: event.endStr,
            widthColumn: finalWidthOfColumn,
            heightRow: finalHeightOfRow,
            isMonthView,
            isTwoWeekView:
                isTwoWeekView ||
                finalCalendarView === CALENDAR_MODES.AGENDA_3_WEEK ||
                finalCalendarView === CALENDAR_MODES.AGENDA_4_WEEK,
            isVerticalMode,
            isPastEvent: eventInfo.isPast && event.extendedProps.type === 'event'
        });

        // Handle title when dragging and resizing to show the time range
        if (!isListView) {
            if (eventInfo.isDragging || eventInfo.isResizing) {
                if (isVerticalMode) {
                    headerTitle = eventInfo.timeText;
                } else {
                    const { start, end } = event._instance.range;
                    headerTitle = `  ${moment(start).utc().format('h:mm')}-${moment(end).utc().format('h:mm')}`;
                }
            }
        }
        if (isListView) {
            return <EventContentListView event={event} additionalCells={columnsListView || []} />;
        }
        if (event.extendedProps.typeDragging === 'box-drag')
            return <BoxDragCalendar jobsLength={event.extendedProps?.jobs?.length || 0} />;
        return (
            <EventContent
                event={event}
                magnet={event.extendedProps?.magnet || null}
                isVerticalMode={isVerticalMode}
                customStyle={customStyle}
                isAllDayEvent={isAllDayEvent}
                headerTitle={headerTitle}
                onToggleTooltip={_handleToggleTooltip}
                classTimeLine={classTimeLine}
                isMonthView={isMonthView}
            />
        );
    };

    const renderDayHeaderContent = ({ date }) => {
        if (isListView && !_isShowWeekends() && (date.getDay() === 0 || date.getDay() === 6)) return null;

        let eventsInRange = {};

        if (isListView) {
            const calendarApi = refCalendar.current?.getApi();
            const start = moment(date).utc().startOf('day');
            const end = moment(date).utc().endOf('day');
            eventsInRange = calendarApi
                ?.getEvents()
                ?.find((event) => moment(event.start).isBetween(start, end, null, '[]'));

            // Check if the date is a weekend
        }

        const isWeekend = date.getDay() === 0 || date.getDay() === 6;

        return (
            <HeaderContent
                date={date}
                schedule={eventsInRange?._def?.extendedProps?.schedule}
                isDayGridMonth={isMonthView}
                isListView={isListView}
                onClick={_handleHeaderClick}
                isWeekend={isWeekend} // Pass the isWeekend flag to the HeaderContent component
            />
        );
    };

    const renderDayCellContent = (data) => {
        if (!isMonthView && !isListView) return null;

        const tasks = !!listTask.length
            ? listTask.filter((item) => {
                  return moment.utc(item.date).isSame(data.date, 'day');
              })
            : [];

        const _openListTask = (element) => {
            if (element) {
                refTaskListModal.current._open({
                    element,
                    date: data.date,
                    data: tasks,
                    onOpenTask: refTaskModal.current._openTask
                });
            }
        };

        return (
            <DayCellContent
                onOpenList={_openListTask}
                total={tasks.length}
                // onUpdateColor={_handleUpdateColor}
                {...data}
            />
        );
    };

    const renderResourceHeader = () => {
        return (
            <ResourceHeader
                onScale={_scaleCalendar}
                defaultValue={settingsCalendar.slotMinWidth}
                timelineModeView={finalTimeLineMode}
            />
        );
    };

    const renderLabelHeader = (data) => {
        const isMonthTimeline = viewCalendar[finalCalendarView] === 'resourceTimelineMonth';
        const isDayTimeline = viewCalendar[finalCalendarView] === 'resourceTimeOneDay';
        return (
            <LabelContent
                isVerticalMode={isVerticalMode}
                isDayTimeline={isDayTimeline}
                isMonthTimeline={isMonthTimeline}
                {...data}
            />
        );
    };

    const renderEmptyContent = () => {
        if (isLoading) return <div className="dp-hide" />;
        return (
            <div className={classNames('content-empty', { 'dp-hide': isLoading })}>
                {t('calendar:calendar_empty_job')}
            </div>
        );
    };

    const renderResourceContent = (info) => {
        const typeView = info.view.type;
        const resource = info.resource;
        const isHorizontal = TYPE_RESOURCE_HORIZONTAL.includes(typeView);
        const isDayResource = typeView === CALENDAR_MODE_VIEW[CALENDAR_MODES.AGENDA_DAY];
        const resouceId = resource?._resource?.id?.toString();
        const tasks = !!listTask.length ? listTask.filter((el) => el?.schedule?.id?.toString() === resouceId) : [];

        return (
            <ResourceContent
                tasks={tasks}
                resource={resource}
                isDayResource={isDayResource}
                isHorizontal={isHorizontal}
                onOpenTasks={_openTasksScheduleModal}
                // onUpdateColor={_handleUpdateColor}
                {...info}
            />
        );
    };

    const now = useMemo(() => {
        return formartDateWithString(moment(convertTimeToISO(moment())).format('YYYY-MM-DD HH:mm:ss'));
    }, []);

    const getSettingsCalendar = () => {
        if (isVerticalMode && isMonthView) return { ...settingsCalendar, contentHeight: 'auto' };
        return settingsCalendar;
    };

    const _handleTrackingCalendarView = () => {
        handleTrackingEvent(mixpanelCalendarViews({ profile: profileUser, viewType: finalCalendarView }));
    };

    return (
        <Fragment>
            <TooltipAddJob
                onClearData={_handleClearData}
                onCreateJob={_handleCreateJob}
                onCreateTimeoff={_openAddTimeoff}
            />

            {/* Task modals actions */}
            <AddTaskModal ref={refAddTask} />
            <UpdateTaskModal ref={refTaskModal} />
            {isMonthView ? <ListTaskModal ref={refTaskListModal} /> : null}
            {/* End task modals actions */}

            <ModalJobRecuring ref={refModalRecurring} />

            <ModalReSchedule ref={refModalReSchedule} />

            {/* Get job active to open job detail */}
            <ModalRecurringJob ref={refModalRecurringJob} />

            <ScheduleException ref={refModalScheduleError} />

            <div id="view-calendar" className={classNames('view-calendar', CALENDAR_MODES_CLASS[finalCalendarView])}>
                {isListView ? <HeaderListView additionalCells={columnsListView} /> : null}
                <div id="fullCalendar" className={classNames('full-calendar', classTimeLine)}>
                    {!isVerticalMode && <TasksSchedule ref={refScheduleTasks} />}
                    {state.loading && (
                        <div className="preloader">
                            <div className="loader-wave">
                                <span className="loader-wave__items" />
                            </div>
                        </div>
                    )}
                    <div id="fullcalendar_wrapper" className="full-calendar__wrapper">
                        <FullCalendar
                            schedulerLicenseKey={FULL_CALENDAR_KEY}
                            ref={refCalendar}
                            eventsSet={handleEvents}
                            events={
                                isListView
                                    ? sortEventsListView(deepCloneArray([...finalEvents]))
                                    : deepCloneArray([...finalEvents])
                            }
                            droppable
                            plugins={[
                                dayGridPlugin,
                                timeGridPlugin,
                                listPlugin,
                                resourceTimelinePlugin,
                                resourceTimeGridPlugin,
                                interactionPlugin
                            ]}
                            {...(getSettingsCalendar() || {})}
                            businessHours={{
                                daysOfWeek: DAYS_OF_WEEK,
                                startTime: businessHours.start,
                                endTime: businessHours.end
                            }}
                            eventMinHeight={5}
                            scrollTime={businessHours.start}
                            eventOrder="resourceId,start"
                            resourceOrder="sortId"
                            resourceAreaWidth={240}
                            slotLabelInterval={slotLabelInterval}
                            dayMaxEvents={finalDayMaxEvent}
                            weekends={isAlwayShowWeekends(finalCalendarView) || _isShowWeekends()}
                            eventDidMount={_eventDidMount}
                            eventWillUnmount={_eventWillUnmount}
                            // Custom render props
                            eventContent={renderEventContent}
                            dayHeaderContent={renderDayHeaderContent}
                            dayCellContent={renderDayCellContent}
                            resourceAreaHeaderContent={renderResourceHeader}
                            resourceLabelContent={renderResourceContent}
                            slotLabelContent={renderLabelHeader}
                            noEventsContent={renderEmptyContent}
                            // End custom render props
                            eventResize={(info) => _onCheckReizeMoveJob(info, _handleResizeJob)}
                            eventDrop={(info) => _onCheckReizeMoveJob(info, _handlMoveJob, ACTIONS_JOB.MOVE)}
                            eventReceive={(info) =>
                                _onCheckReizeMoveJob(info, _handleMoveFromSideBar, ACTIONS_JOB.MOVE)
                            }
                            eventDragStop={_eventDragStop}
                            eventDragStart={_eventDragStart}
                            eventResizeStart={_onEventResizeStart}
                            eventResizeStop={_onEventResizeStop}
                            eventClick={_onClickEvent}
                            dateClick={_onDayClick}
                            selectAllow={_alowSelect}
                            selectable
                            now={now}
                            fixedWeekCount={false}
                            listDaySideFormat={!isListView}
                            displayEventTime={!isListView}
                            fixedMirrorParent={document.body}
                        />
                    </div>

                    {finalDisplayControl && <CalendarControl onUpdateSize={_updateHeightRow} />}
                </div>
            </div>

            {/* Realtime services */}
            <RealtimeServices events={finalEvents || []} onUpdateRealtime={_handleUpdateRealtime} />

            <RealtimeTaskServices
                events={finalEvents || []}
                onUpdateRealtime={_handleUpdateRealtime}
                onUpdateModalTaskSchedules={_handleUpdateModalSchedule}
            />

            <RealtimeJobServices
                onRefesh={_fetchListJob}
                events={finalEvents || []}
                onReloadEvents={_handleReloadEvents}
                handleUpdateState={dispatchState}
                onUpdateStoreMagnet={handleStoreMagnet}
                onRefreshMagnet={handleResetMagnetJobs}
                schedules={finalSchedules}
            />
            {/* End realtime services */}

            <Services
                events={finalEvents}
                onReloadEvents={_handleReloadEvents}
                onRefesh={_fetchListJob}
                onUpdateTimeWindow={_handleUpdateTimeWindow}
            />

            <ServicesTask
                events={finalEvents}
                schedules={finalSchedules}
                onReloadEvents={_handleReloadEvents}
                onGetCurrentRange={_getCurrentRange}
                onCloseTaskModal={_handleCloseTaskModal}
            />

            <ServiceEvent events={finalEvents} onRefresh={_fetchListJob} />

            <CalendarTooltip ref={refTooltip} />

            {/* <CalendarColorSchedule ref={refSelectColor} isMonthView={isMonthView} /> */}
            {isVerticalMode && !isMonthView && !isListView && <TooltipAllDaySlot ref={refTooltipAllDaySlot} />}
        </Fragment>
    );
}

export default forwardRef(MainCalendar);
