import { COMMON, LIST_TABLE_ITEM_TYPE } from 'app/const/App';
import { mapCurrentValueHeader } from 'common/utils/GridViewUtils';
import { REPORT_STATUS_OPTIONS } from '.';
import { TYPE_TAGS } from 'app/const/Customers';

export const getColumnReportAI = ({ actionsStatus = '' }) => {
    const HEADER_TABLE = {
        customer: {
            title: 'customer',
            style: 'col col-md-plus',
            actionType: COMMON.ORDER,
            currentValue: COMMON.DESC
        },
        summary: {
            title: 'summary_of_conversation',
            style: 'col col-xl',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        date: {
            title: 'date',
            style: 'col col-date',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        resolved: {
            title: 'resolved',
            style: 'col col-sm',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        tags: {
            title: 'tags',
            style: 'col --tags'
        },
        sms_credit: {
            title: 'sms_credit',
            style: 'col col-md'
        }
    };

    const REPORT_COLUMNS_TABLE = [
        {
            id: 'customer',
            title: 'customer',
            status: true
        },
        {
            id: 'summary',
            title: 'summary_of_conversation',
            status: true
        },
        {
            id: 'date',
            title: 'date',
            status: true
        },
        {
            id: 'resolved',
            title: 'resolved',
            status: true
        },
        {
            id: 'tags',
            title: 'tags',
            status: true
        },
        {
            id: 'sms_credit',
            title: 'sms_credit',
            status: true
        }
    ];

    const REPORT_CONTENT_TABLE = {
        customer: {
            id: 'customer',
            style: 'col col-md-plus',
            styleText: 'name',
            type: LIST_TABLE_ITEM_TYPE.CUSTOMER_INFO
        },
        summary: {
            id: 'summary',
            style: 'col col-xl',
            styleText: 'name',
            type: LIST_TABLE_ITEM_TYPE.LINK
        },
        date: {
            id: 'date',
            style: 'col col-date',
            type: LIST_TABLE_ITEM_TYPE.DEFAULT
        },
        resolved: {
            id: 'resolved',
            style: 'col col-sm d-flex',
            type: LIST_TABLE_ITEM_TYPE.STATUS_ITEM,
            listStatus: REPORT_STATUS_OPTIONS
        },
        tags: {
            id: 'tags',
            style: 'col --tags',
            type: LIST_TABLE_ITEM_TYPE.CONVERSATION_TAGS,
            typeTag: TYPE_TAGS.TAG_CONVERSATION
        },
        sms_credit: {
            id: 'sms_credit',
            style: 'col col-md',
            type: LIST_TABLE_ITEM_TYPE.DEFAULT
        }
    };

    return {
        header: mapCurrentValueHeader(actionsStatus, { ...HEADER_TABLE }),
        columns: REPORT_COLUMNS_TABLE,
        contentConfig: REPORT_CONTENT_TABLE
    };
};
