import { COMMON, LIST_TABLE_ITEM_TYPE } from 'app/const/App';
import { mapCurrentValueHeader } from 'common/utils/GridViewUtils';
import { STATUS_OPTIONS } from '.';

export const gridColumnCustomAnswer = ({ actionsStatus = '' }) => {
    const HEADER_CHATBOT_CUSTOM_ANSWER = {
        questions: {
            title: 'question',
            style: 'col col-xl',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        status: {
            title: 'status',
            style: 'col col-sm d-flex',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        answered: {
            title: 'answered',
            style: 'col col-sm',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        resolved: {
            title: 'resolved',
            style: 'col col-sm',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        }
    };

    const COLUMNS_CHATBOT_CUSTOM_ANSWER = [
        {
            id: 'questions',
            title: 'question',
            status: true
        },
        {
            id: 'status',
            title: 'status',
            status: true
        },
        {
            id: 'answered',
            title: 'answered',
            status: true
        },
        {
            id: 'resolved',
            title: 'resolved',
            status: true
        }
    ];

    const CONTENT_CHATBOT_CUSTOM_ANSWER = {
        questions: {
            id: 'questions',
            style: 'col col-xl',
            styleText: 'name',
            type: LIST_TABLE_ITEM_TYPE.LINK
        },
        status: {
            id: 'status',
            style: 'col col-sm d-flex',
            type: LIST_TABLE_ITEM_TYPE.STATUS_ITEM,
            listStatus: STATUS_OPTIONS
        },
        answered: {
            id: 'answered',
            style: 'col col-sm'
        },
        resolved: {
            id: 'resolved',
            style: 'col col-sm',
            type: LIST_TABLE_ITEM_TYPE.RATE_PERCENT
        }
    };

    return {
        header: mapCurrentValueHeader(actionsStatus, { ...HEADER_CHATBOT_CUSTOM_ANSWER }),
        columns: COLUMNS_CHATBOT_CUSTOM_ANSWER,
        contentConfig: CONTENT_CHATBOT_CUSTOM_ANSWER
    };
};
