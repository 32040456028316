import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { RouteRender } from 'app/components/routes/RouteWithSubRoutes';
import { ADDONS_AI_AGENTS, ADDONS_AI_AGENTS_OPTIONS_KEYWORDS } from 'app/const/addons';
import ErrorPage from 'app/modules/error';
import { getPlanUser, isActiveFeature } from 'common/utils/PermissionUtils';
import SideMenu from './SideMenu';

const ChatbotLayout = ({ routes = [], ...props }) => {
    const { t } = useTranslation();
    const keyWordAiAgents = ADDONS_AI_AGENTS;
    const activeFieldAddon = useSelector(({ auth }) => auth.user.settings.addons[keyWordAiAgents]);
    const userProfile = useSelector(({ auth }) => auth.user.profile);
    const { isGrowthPlan, isTrial } = getPlanUser(userProfile);
    const keyWordAiAgentSms = ADDONS_AI_AGENTS_OPTIONS_KEYWORDS.AI_AGENT_SMS;
    const havePermission =
        (isTrial || isGrowthPlan) &&
        activeFieldAddon?.[keyWordAiAgentSms] &&
        isActiveFeature(keyWordAiAgents.toUpperCase());

    if (!havePermission) return <ErrorPage errorMessage={t('page_is_unavailable')} />;
    return (
        <div className="container-chatbot">
            <SideMenu />
            <Switch>
                {routes.map((route, i) => {
                    const result = { ...route };
                    if (route.path.split(',').length > 1) {
                        result.path = route.path.split(',');
                    }

                    return <Route key={i} path={result.path} render={() => <RouteRender {...props} route={route} />} />;
                })}
            </Switch>
        </div>
    );
};

export default ChatbotLayout;
