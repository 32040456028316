import { API_VERSION, URL_EXPORT_DATA } from 'app/const/URL';
// Auth
export const LOGIN = `${API_VERSION}auth/login`;
export const SIGNUP = `${API_VERSION}signup`;
export const LOGOUT = `${API_VERSION}auth/logout`;
export const FORGOT_PASSWORD = `${API_VERSION}password/forgot`;
export const SIGNUP_SOCIAL = `${API_VERSION}signup/social`;
export const LOGIN_SOCIAL = `${API_VERSION}login/social`;
export const CHANGE_PASSWORD = `${API_VERSION}password/reset`;
export const ACTIVATE_ACCOUNT = `${API_VERSION}signup/activate`;
export const USER_RETRIEVE = `${API_VERSION}auth/retrieve`;
export const SIGNUP_SOCIAL_COMPANY = `${API_VERSION}signup/company`;
export const LOGIN_ADMIN = `${API_VERSION}login/admin`;
export const LOGIN_INTEGRATION = `${API_VERSION}login/integration`;
export const LOGIN_CLASSIC = `${API_VERSION}login/classic`;
//Global search
export const GLOBAL_SEARCH = `${API_VERSION}search/elastic`;
export const GET_LIST_SEARCH_CUSTOMER = `${API_VERSION}customers/search`;
export const MARK_AS_CUSTOMER = `${API_VERSION}customer/mark-as`;

//Account
// export const GET_USER_QUEST_LOG = `${API_VERSION}quest/list`;
export const GET_USER_QUEST_LOG = `${API_VERSION}questlogs`;

//Inbox
export const INBOX = `${API_VERSION}inbox/total`;

//Notifications
export const NOTIFICATION_COUNT = `${API_VERSION}notifications/count`;
export const GET_LIST_NOTIFICATION = `${API_VERSION}notifications`;
export const MARK_READ_AND_UNREAD_NOTIFICATION = `${API_VERSION}notifications`;
export const NOTIFICATION_SETTING_LIST = `${API_VERSION}notifications/permissions`;
export const NOTIFICATION_SETTING_UPDATE_STATUS = `${API_VERSION}notifications/permissions`;

//Export
export const REPORT_INVOICE_EXPORT = `${API_VERSION}report/invoice/export`;
export const REPORT_INVOICE_PRINT = `${API_VERSION}report/invoice/print`;
export const REPORT_ESTIMATE_EXPORT = `${API_VERSION}report/estimate/export`;
export const REPORT_ESTIMATE_PRINT = `${API_VERSION}report/estimate/print`;

// Code status
export const CODE_SUCCESS = 200;
export const CODE_FAILURE = 403;

// report
//// invoice
export const REPORT_INVOICE_GET_LIST = `${API_VERSION}reports/invoices`;
export const REPORT_INVOICE_SEARCH = `${API_VERSION}report/invoice/search`;
export const REPORT_INVOICE = `${API_VERSION}invoices`;
export const REPORT_INVOICE_ARCHIVE = `${API_VERSION}invoice/archive`;
export const REPORT_INVOICE_SEND_MAIL = `${API_VERSION}reports/invoices/email`;
export const REPORT_INVOICE_GENERATE_PDF = `${API_VERSION}reports/invoices/pdf`;
export const REPORT_INVOICE_SERVICE_FILTER = `${API_VERSION}services`;
export const REPORT_INVOICE_MARK = `${API_VERSION}reports/invoices/mark`;

export const getInvoiceLogs = (invoiceId) => {
    return `${API_VERSION}invoice/${invoiceId}/logs`;
};

export const getEstimateLogs = (estimateId) => {
    return `${API_VERSION}estimates/${estimateId}/logs`;
};

export const actionUpdateInvoice = (invoiceId) => {
    return `${API_VERSION}invoices/${invoiceId}`;
};

export const actionDeleteInvoiceRecurrence = (invoiceId) => {
    return `${API_VERSION}invoices/recurrence/${invoiceId}`;
};

//// Estimate
export const REPORT_ESTIMATE_GET_LIST = `${API_VERSION}reports/estimates`;
export const REPORT_ESTIMATE = `${API_VERSION}estimates`;
export const REPORT_ESTIMATE_TRASH = `${API_VERSION}estimates/trash`;
export const REPORT_ESTIMATE_SEND_MAIL = `${API_VERSION}reports/estimates/email`;

//// Payment
export const REPORT_PAYMENT_GET_LIST = `${API_VERSION}reports/payments`;
export const REPORT_PAYMENT_SEARCH = `${API_VERSION}report/payment/search`;
export const REPORT_PAYMENT_PRINT = `${API_VERSION}report/payment/print`;
export const REPORT_PAYMENT_DELETE = `${API_VERSION}payments`;
export const REPORT_PAYMENT_SYNCQB = `${API_VERSION}payment/payment/syncqb`;

//// Document
export const REPORT_DOCUMENT_GET_LIST = `${API_VERSION}reports/documents`;
export const REPORT_DOCUMENT_GET_LIST_TYPE = `${API_VERSION}documents/types`;
export const REPORT_DOCUMENT_DELETE = `${API_VERSION}documents`;
export const REPORT_DOCUMENT_UNDELETE = `${API_VERSION}documents/undelete`;
export const REPORT_DOCUMENT_TRASH = `${API_VERSION}documents/trash`;

//// Credit
export const REPORT_CREDIT_GET_LIST = `${API_VERSION}reports/credits`;
export const REPORT_CREDIT_DELETE = `${API_VERSION}credits`;

//// RecurringInvoice
export const REPORT_RECURRING_INVOICE_GET_LIST = `${API_VERSION}report/recurrence`;

//// Subscriptions
export const REPORT_SUBSCRIPTION_GET_LIST = `${API_VERSION}reports/subscriptions`;
export const REPORT_FILTER_PLAN_GET_LIST = `${API_VERSION}settings/plan`;

//// Service Lookup
export const REPORT_SERVICE_LOOKUP_GET_LIST = `${API_VERSION}reports/servicelookup`;
export const REPORT_SERVICE_LOOKUP_REASSIGN_JOBS = `${API_VERSION}jobs/reassign`;
export const REPORT_SERVICE_LOOKUP_SCHEDULE_LIST = `${API_VERSION}reports/servicelookup/schedules`;
export const REPORT_SERVICE_LOOKUP_SERVICE_LIST = `${API_VERSION}reports/servicelookup/services`;

//// Accounts Aging
export const REPORT_ACCOUNTS_AGING_GET_LIST = `${API_VERSION}report/accountsaging`;
export const REPORT_ACCOUNTS_AGING_PRINT_INVOICE = `${API_VERSION}report/accountsaging/printInvoices`;
export const REPORT_ACCOUNTS_AGING_GENERATE_INVOICE_PDF = `${API_VERSION}report/accountsaging/generateinvoicespdf`;
export const REPORT_ACCOUNTS_AGING_EMAIL_LATE_PAYMENT_REMINDERS = `${API_VERSION}report/accountsaging/paymentreminder`;
export const REPORT_ACCOUNTS_AGING_SMS_LATE_PAYMENT_REMINDERS = `${API_VERSION}report/accountsaging/paymentremindersms`;
export const REPORT_ACCOUNTS_AGING_EMAIL_STATEMENTS = `${API_VERSION}report/accountsaging/emailstatements`;
export const REPORT_ACCOUNTS_AGING_STATEMENTS_PRINT = `${URL_EXPORT_DATA}statements/print`;
export const REPORT_ACCOUNTS_AGING_STATEMENTS_EXPORT_PDF = `${URL_EXPORT_DATA}statements/pdf`;

//// Locations W/O Active Jobs
export const REPORT_LOCATIONS_WO_ACTIVE_JOBS_GET_LIST = `${API_VERSION}reports/locations/woactivejobs`;

//// Customers W/o Active Jobs Report
export const REPORT_CUSTOMERS_WO_ACTIVE_JOB_GET_LIST = `${API_VERSION}reports/customers/woactivejobs`;
export const REPORT_CUSTOMERS_WO_ACTIVE_JOB_EXPORT = `${URL_EXPORT_DATA}reports/customers-wo-jobs`;

//// Sales Forecast Report
export const REPORT_SALES_FORECAST_GET_LIST = `${API_VERSION}reports/sales/forecast`;

//// Sales Tax Summary
export const REPORT_SALES_TAX_GET_LIST = `${API_VERSION}reports/sales/tax`;
export const REPORT_TAX_SUMMARY_GET_LIST = `${API_VERSION}reports/sales/taxrate`;
export const REPORT_LIST_METHOD_TAX_SUMMARY = `${API_VERSION}paymentmethods`;

//// Revenue by Client
export const REPORT_REVENUE_GET_LIST_YEAR = `${API_VERSION}revenues/years`;
export const REPORT_REVENUE_BY_CLIENT_MONTH_GET_LIST = `${API_VERSION}revenues/clients`;
export const REPORT_REVENUE_BY_CLIENT_DETAIL_GET_LIST = `${API_VERSION}revenues/clients/payment`;
export const REPORT_REVENUE_CLIENT_METHOD_FILTER = `${API_VERSION}paymentmethods`;
export const REPORT_REVENUE_CLIENT_CLIENTS_FILTER = `${API_VERSION}settings/client`;

//// Revenue by Service
export const REPORT_REVENUE_BY_SERVICE_MONTH_GET_LIST = `${API_VERSION}revenues/services`;
export const REPORT_REVENUE_BY_SERVICE_DETAIL_GET_LIST = `${API_VERSION}revenues/services/payment`;
export const REPORT_REVENUE_SERVICE_EXPORT = `${API_VERSION}revenue/service/export`;
export const REPORT_REVENUE_SERVICE_PRINT = `${API_VERSION}revenue/service/print`;
export const REPORT_REVENUE_SERVICE_METHOD_FILTER = `${API_VERSION}paymentmethods`;
export const REPORT_REVENUE_SERVICE_SERVICES_FILTER = `${API_VERSION}services`;

//// Revenue by Item
export const REPORT_REVENUE_BY_ITEM_MONTH_GET_LIST = `${API_VERSION}revenues/items`;
export const REPORT_REVENUE_BY_ITEM_DETAIL_GET_LIST = `${API_VERSION}revenues/items/payment`;
export const REPORT_REVENUE_ITEM_EXPORT = `${API_VERSION}revenue/item/export`;
export const REPORT_REVENUE_ITEM_PRINT = `${API_VERSION}revenue/item/print`;
export const REPORT_REVENUE_ITEM_METHOD_FILTER = `${API_VERSION}paymentmethods`;
export const REPORT_REVENUE_ITEM_ITEMS_FILTER = `${API_VERSION}items`;

//// Revenue by Staff
export const REPORT_REVENUE_BY_STAFF_MONTH_GET_LIST = `${API_VERSION}revenues/staffs`;
export const REPORT_REVENUE_BY_STAFF_DETAIL_GET_LIST = `${API_VERSION}revenues/staffs/payment`;
export const REPORT_REVENUE_STAFF_EXPORT = `${API_VERSION}revenue/staff/export`;
export const REPORT_REVENUE_STAFF_PRINT = `${API_VERSION}revenue/staff/print`;
export const REPORT_REVENUE_STAFF_METHOD_FILTER = `${API_VERSION}paymentmethods`;

//// Revenue by Source
export const REPORT_REVENUE_BY_SOURCE = `${API_VERSION}revenue/source`;
export const REPORT_REVENUE_BY_SOURCE_PAYMENT = `${API_VERSION}revenue/source/payment`;
export const REPORT_REVENUE_SOURCE_EXPORT = `${API_VERSION}revenue/source/export`;
export const REPORT_REVENUE_SOURCE_PRINT = `${API_VERSION}revenue/source/print`;
export const REPORT_REVENUE_SOURCE_METHOD_FILTER = `${API_VERSION}paymentmethods`;
export const REPORT_REVENUE_SOURCE_SOURCES_FILTER = `${API_VERSION}settings/source`;

//// Revenue by Tags
export const REPORT_REVENUE_BY_TAGS_MONTH_GET_LIST = `${API_VERSION}revenues/tags`;
export const REPORT_REVENUE_BY_TAGS_DETAIL_GET_LIST = `${API_VERSION}revenues/tags/payment`;
export const REPORT_REVENUE_TAGS_EXPORT = `${API_VERSION}revenue/tag/export`;
export const REPORT_REVENUE_TAGS_PRINT = `${API_VERSION}revenue/tag/print`;
export const REPORT_REVENUE_TAGS_METHOD_FILTER = `${API_VERSION}paymentmethods`;

//// Email History
export const REPORT_EMAIL_HISTORY_GET_LIST = `${API_VERSION}reports/emaillogs`;
export const REPORT_EMAIL_HISTORY_EXPORT = `${API_VERSION}report/email/export`;
export const REPORT_EMAIL_HISTORY_PRINT = `${API_VERSION}report/email/print`;

//// Material Use
export const REPORT_MATERIAL_USE_GET_LIST = `${API_VERSION}reports/materials`;
export const REPORT_MATERIAL_USE_EXPORT = `${API_VERSION}report/material/export`;
export const REPORT_MATERIAL_USE_PRINT = `${API_VERSION}report/material/print`;
export const REPORT_MATERIAL_USE_W_O_COUNTY = `${API_VERSION}reports/materials/wocounty`;
export const REPORT_MATERIAL_USE_GET_LIST_JOBS_WO_COUNTY = `${API_VERSION}reports/materials/counties`;

//// Material Use Detail
export const REPORT_MATERIAL_USE_DETAIL_GET_LIST = `${API_VERSION}reports/materials/detail`;
export const REPORT_MATERIAL_USE_DETAIL_SERVICE_FILTER = `${API_VERSION}services`;

//// Total Sales
export const REPORT_TOTAL_SALES_GET_LIST = `${API_VERSION}reports/sales/total`;
export const REPORT_TOTAL_SALES_EXPORT = `${API_VERSION}report/totalsales/export`;
export const REPORT_TOTAL_SALES_PRINT = `${API_VERSION}report/totalsales/print`;
export const REPORT_TOTAL_SALES_GET_LIST_DATA_CHART = `${API_VERSION}reports/sales/total/chart`;
export const REPORT_TOTAL_SALES_GET_LIST_YEAR = `${API_VERSION}reports/sales/total/year`;

//// Payments Collected
export const REPORT_PAYMENTS_COLLECTED_GET_LIST = `${API_VERSION}reports/paymentscollected`;
export const REPORT_PAYMENTS_COLLECTED_GET_LIST_YEARS = `${API_VERSION}reports/paymentscollected/year`;
export const REPORT_PAYMENTS_COLLECTED_GET_DATA_CHART = `${API_VERSION}reports/paymentscollected/chart`;
export const REPORT_PAYMENTS_COLLECTED_METHOD_FILTER = `${API_VERSION}paymentmethods`;

// New Customers
export const REPORT_NEW_CUSTOMERS_GET_LIST = `${API_VERSION}reports/customers/new`;
export const REPORT_NEW_CUSTOMERS_EXPORT = `${API_VERSION}report/newcustomer/export`;
export const REPORT_NEW_CUSTOMERS_PRINT = `${API_VERSION}report/newcustomer/print`;
export const REPORT_NEW_CUSTOMERS_GET_LIST_DATA_CHART_LINE = `${API_VERSION}reports/customers/new/chart/monthly`;
export const REPORT_NEW_CUSTOMERS_GET_LIST_DATA_CHART_PIE = `${API_VERSION}reports/customers/new/chart/source`;
export const REPORT_NEW_CUSTOMERS_GET_LIST_YEAR = `${API_VERSION}reports/customers/new/year`;
export const REPORT_NEW_CUSTOMERS_GET_LIST_SOURCE_FILTER = `${API_VERSION}settings/source`;

// Leads
export const REPORT_CUSTOMER_LEADS_GET_LIST = `${API_VERSION}reports/customers/leads`;
export const REPORT_BOOKING_JOBS_GET_LIST = `${API_VERSION}bookings`;
export const REPORT_INBOUND_LEADS_GET_LIST_YEARS_CHART = `${API_VERSION}reports/customers/leads/year`;
export const REPORT_INBOUND_LEADS_GET_LIST_DATA_CHART_LINE = `${API_VERSION}reports/customers/leads/chart/monthly`;
export const REPORT_INBOUND_LEADS_GET_LIST_DATA_CHART_PIE = `${API_VERSION}reports/customers/leads/chart/source`;
export const REPORT_INBOUND_LEADS_ASSIGN_CUSTOMER = `${API_VERSION}customers/assign/lead`;
export const REPORT_ONLINE_BOOKING_GET_LIST_YEARS_CHART = `${API_VERSION}bookings/year`;
export const REPORT_ONLINE_BOOKING_GET_LIST_DATA_CHART_LINE = `${API_VERSION}bookings/chart`;
export const REPORT_ONLINE_BOOKING_ASSIGN_CUSTOMER = `${API_VERSION}customers/assign/booking`;
export const REPORT_ONLINE_BOOKING_EXPORT = `${API_VERSION}report/customer/export`;
export const REPORT_ONLINE_BOOKING_PRINT = `${API_VERSION}report/customer/export`;

// REVIEWS
//// Review Likely
export const REVIEW_LIKELY_GET_LIST = `${API_VERSION}rating/likely`;

//// Review Service
export const REVIEW_SERVICE_GET_LIST = `${API_VERSION}rating/service`;
export const REPORT_REVIEW_USERS_FILTER = `${API_VERSION}users`;

// COMMISSIONS
export const COMMISSIONS_GET_LIST_DATA = `${API_VERSION}commission/data`;
export const COMMISSIONS_GET_LIST_USER = `${API_VERSION}commissions/users`;
export const getUrlManageCommissionUser = (id) => `${API_VERSION}commissions/users/${id}/settings`;
export const getUrlOptionsCommmissionUser = (id) => `${API_VERSION}commissions/users/${id}/options`;
export const COMMISSIONS_APPLY_SETTING_USER = `${API_VERSION}commissions/apply`;
export const COMMISSIONS_UPDATE_SETTING_USER = `${API_VERSION}commission/settings/setup`;
export const REPORT_COMMISSIONS_EXPORT = `${API_VERSION}commission/data/export`;
export const REPORT_COMMISSIONS_PRINT = `${API_VERSION}commission/data/print`;
export const LIST_STAFF_FILTER_COMMISSIONS = `${API_VERSION}users`;
export const LIST_METHOD_FILTER_COMMISSIONS = `${API_VERSION}paymentmethods`;

//// PAYMENT METHODS
export const GET_LIST_SIMPLE_PAYMENT_METHODS = `${API_VERSION}paymentmethods`;
export const SETTINGS_PAYMENT_METHODS = `${API_VERSION}settings/paymentmethods`;
export const SETTINGS_UPDATE_STATUS_PAYMENT_METHODS = `${API_VERSION}settings/paymentmethods/alter`;
export const getUrlSettingUpdatePaymentMethod = (idMethod = '') => {
    return `${SETTINGS_PAYMENT_METHODS}/${idMethod}`;
};

//// TAGS
export const GET_LIST_SIMPLE_TAGS = `${API_VERSION}tags`;
export const SETTINGS_TAGS = `${API_VERSION}settings/tags`;
export const getUrlSettingUpdateTag = (idTag = '') => {
    return `${SETTINGS_TAGS}/${idTag}`;
};

//// SCHEDULES
export const SETTINGS_GET_LIST_SCHEDULES = `${API_VERSION}settings/schedule`;
export const SETTINGS_ADD_AND_UPDATE_SCHEDULES = `${API_VERSION}settings/schedule/post`;
export const SETTINGS_DELETE_SCHEDULES = `${API_VERSION}settings/schedule/delete`;
export const SETTINGS_REASSIGN_SCHEDULES = `${API_VERSION}settings/schedule/reassign`;

//// USERS
export const SETTING_USERS = `${API_VERSION}settings/users`;
export const getUrlSettingUsersWithId = (idUser) => {
    return `${SETTING_USERS}/${idUser}`;
};

// Session
export const getUrlGetSessionList = (userId) => `${COMPANY_USERS}/${userId}/sessions`;
export const getUrlDeleteSession = ({ userId, sessionId }) => `${COMPANY_USERS}/${userId}/sessions/${sessionId}`;

//// Line Items
export const SETTINGS_LINE_ITEMS = `${API_VERSION}settings/items`;
export const SETTINGS_UPDATE_STATUS_LINE_ITEMS = `${API_VERSION}settings/items/alter`;
export const getUrlSettingUpdateLineItem = (idLineItem = '') => {
    return `${SETTINGS_LINE_ITEMS}/${idLineItem}`;
};

//// Taxes
export const SETTINGS_TAXES = `${API_VERSION}settings/taxes`;
export const SETTINGS_UPDATE_STATUS_TAXES = `${API_VERSION}settings/taxes/alter`;
export const getUrlSettingUpdateTax = (idTax = '') => {
    return `${SETTINGS_TAXES}/${idTax}`;
};

//// Tiles
export const SETTINGS_TILES = `${API_VERSION}settings/tiles`;
export const SETTINGS_TILES_OPTIONS = `${API_VERSION}settings/tiles/fields`;

//// Settings Invoice/Estimate/Work Order
export const SETTINGS_GET_LIST_TEMPLATE = `${API_VERSION}settings/templates/list`;
export const SETTINGS_GET_DETAIL_TEMPLATE = `${API_VERSION}settings/templates`;
export const SETTINGS_UPDATE_DETAIL_TEMPLATE = `${API_VERSION}settings/templates/update`;
export const SETTINGS_PREVIEW_TEMPLATE = `${API_VERSION}settings/templates/preview`;

//// Sources
export const SETTINGS_SOURCES = `${API_VERSION}settings/sources`;
export const SETTINGS_UPDATE_STATUS_SOURCES = `${API_VERSION}settings/sources/alter`;
export const getUrlSettingUpdateSource = (idSource = '') => {
    return `${SETTINGS_SOURCES}/${idSource}`;
};

//// Company
export const SETTINGS_GET_DETAIL_COMPANY = `${API_VERSION}settings/company`;
export const SETTINGS_DELETE_LOGO_COMPANY = `${API_VERSION}settings/company/logo`;
export const SETTINGS_UPDATE_LOGO_COMPANY = `${API_VERSION}settings/company/updateLogo`;
export const SETTINGS_CREATE_AND_UPDATE_COMPANY = `${API_VERSION}settings/company`;
export const SETTINGS_GET_LIST_INDUSTRIES = `${API_VERSION}industries`;
export const SETTINGS_GET_LIST_TIMEZONE = `${API_VERSION}settings/company/timezone`;

//// Customs
export const SETTINGS_GET_LIST_CUSTOMS = `${API_VERSION}settings/item`;
export const SETTINGS_DETAIL_CUSTOMS = `${API_VERSION}settings/detail`;
export const SETTINGS_CREATE_CUSTOMS = `${API_VERSION}settings/item/create`;
export const SETTINGS_UPDATE_CUSTOMS = `${API_VERSION}settings/item/update`;
export const SETTINGS_UPDATE_STATUS_CUSTOMS = `${API_VERSION}settings/item/status`;

//// SERVICE

export const SETTINGS_GET_LIST_SERVICE = `${API_VERSION}services`;
export const getServiceDetail = (serviceId) => {
    return `${API_VERSION}services/${serviceId}`;
};
export const SETTINGS_GET_DETAIL_SERVICE = `${API_VERSION}settings/service/detail`;
export const SETTINGS_ADD_AND_UPDATE_SERVICE = `${API_VERSION}settings/source/post`;
export const SETTINGS_UPDATE_SERVICE_COLOR = `${API_VERSION}settings/service/color`;
export const SETTINGS_UPDATE_STATUS_SERVICE = {
    active: `${API_VERSION}settings/source/active`,
    archived: `${API_VERSION}settings/source/archive`,
    deleted: `${API_VERSION}settings/source/delete`
};

// COMPANY USERS
export const COMPANY_USERS = `${API_VERSION}users`;

//// Broadcast
export const SETTINGS_GET_LIST_BROADCAST_TEMPLATE = `${API_VERSION}templates/broadcasts`;
export const SETTINGS_DETAIL_BROADCAST = `${API_VERSION}settings/detail`;
export const SETTINGS_CREATE_BROADCAST = `${API_VERSION}settings/item/create`;
export const SETTINGS_UPDATE_BROADCAST = `${API_VERSION}settings/item/update`;
export const SETTINGS_UPDATE_STATUS_BROADCAST = `${API_VERSION}settings/item/status`;

// email
export const SETTINGS_GET_EMAIL_INBOX = `${API_VERSION}settings/email/detail`;
export const SETTINGS_EMAIL_CREATE = `${API_VERSION}settings/email/create`;
export const SETTINGS_EMAIL_UPDATE = `${API_VERSION}settings/email/update`;

//// Email Inbox
export const SETTINGS_GET_LIST_EMAIL_INBOX_TEMPLATE = `${API_VERSION}templates/emails`;

// Settings
//// Common
export const SETTING_GET_LIST_EMAIL_VARIABLE = `${API_VERSION}settings/email/variable`;
export const SETTING_GET_LIST_TIMEZONE = `${API_VERSION}settings/company/timezone`;
export const SETTING_SEND_TEST_EMAIL = `${API_VERSION}sending/test`;

//// Message Templates
export const DRIP_GET_LIST_MESSAGE_TEMPLATES = `${API_VERSION}drip/template`;
export const DRIP_GET_DETAIL_TEMPLATES = `${API_VERSION}drip/template/detail`;
export const DRIP_UPDATE_MESSAGE_TEMPLATE = `${API_VERSION}drip/template/update`;
export const DRIP_CREATE_MESSAGE_TEMPLATE = `${API_VERSION}drip/template/create`;
export const DRIP_SEND_TEST_EMAIL = `${API_VERSION}drip/template/send`;
export const DRIP_DELETE_MESSAGE_TEMPLATE = `${API_VERSION}drip/template/delete`;
export const DRIP_DUPLICATE_MESSAGE_TEMPLATE = `${API_VERSION}drip/template/duplicate`;
export const DRIP_GET_LIST_VARIABLE = `${API_VERSION}drip/template/variable`;

//// Unsubscribed
export const DRIP_GET_LIST_UNSUBSCRIBED = `${API_VERSION}drip/customer/search`;

//// Smart list
export const DRIP_GET_LIST_SMART_LISTS = `${API_VERSION}drip/smart-list`;
export const DRIP_DELETE_ITEM_SMART_LIST = `${API_VERSION}drip/smart-list/delete`;
export const DRIP_DUPLICATE_ITEM_SMART_LIST = `${API_VERSION}drip/smart-list/duplicate`;
export const DRIP_CREATE_SMART_LIST = `${API_VERSION}drip/smart-list/create`;
export const DRIP_UPDATE_SMART_LIST = `${API_VERSION}drip/smart-list/update`;

export const DRIP_GET_LIST_CUSTOMER = `${API_VERSION}drip/customer/search`;
export const DRIP_SMART_LIST_UPDATE_STATUS = `${API_VERSION}drip/smart-list/status`;
export const DRIP_GET_DETAIL_FILTER_ITEM_SMART_LIST = `${API_VERSION}drip/smart-list/detail`;

export const DRIP_GET_LIST_FILTERS_SMART_LIST = `${API_VERSION}drip/smart-list/filter`;
export const DRIP_GET_LIST_SMART_LIST_WITH_BASIC_INFORMATION = `${API_VERSION}drip/smart-list/list`;

//// Campaigns
export const DRIP_GET_LIST_CAMPAIGNS = `${API_VERSION}drip/campaign`;
export const DRIP_UPDATE_STATUS_CAMPAIGN = `${API_VERSION}drip/campaign/status`;
export const DRIP_DELETE_CAMPAIGN = `${API_VERSION}drip/campaign/delete`;

export const DRIP_GET_DETAIL_CAMPAIGN = `${API_VERSION}drip/campaign/detail`;
export const DRIP_CREATE_CAMPAIGN = `${API_VERSION}drip/campaign/create`;
export const DRIP_UPDATE_DETAIL_CAMPAIGN = `${API_VERSION}drip/campaign/update`;
export const DRIP_DUPLICATE_CAMPAIGN = `${API_VERSION}drip/campaign/duplicate`;

export const DRIP_GET_LIST_CATEGORIES = `${API_VERSION}drip/campaign/category`;

//// Reports
export const DRIP_REPORT_ACTIVITY = `${API_VERSION}drip/report/activity`;
export const DRIP_REPORT_SUMMARY = `${API_VERSION}drip/report/summary`;
export const DRIP_REPORT_LINK = `${API_VERSION}drip/report/link`;
export const DRIP_REPORT_DETAIL_CUSTOMER = `${API_VERSION}drip/report/customer`;

// Email & SMS
//// Systems
export const SETTINGS_GET_LIST_SYSTEMS = `${API_VERSION}settings/item`;
export const SETTINGS_DETAIL_SYSTEMS = `${API_VERSION}settings/detail`;
export const SETTINGS_UPDATE_SYSTEMS = `${API_VERSION}settings/item/update`;
export const SETTINGS_UPDATE_STATUS_SYSTEMS = `${API_VERSION}settings/item/status`;

// CUSTOMER
export const GET_LIST_MAP_CUSTOMER = `${API_VERSION}customer/list`;
export const GET_LIST_CUSTOMER = `${API_VERSION}customers`;
export const UPDATE_STATUS_CUSTOMER = `${API_VERSION}settings/item/status`;
export const CUSTOMER_GET_STATISTIC = `${API_VERSION}customer/statistic`;
export const MERGE_CUSTOMERS = `${API_VERSION}customer/merge`;
export const GET_CUSTOMER_ACCOUNT_NUMBER = `${API_VERSION}customers/init`;
export const GET_LIST_SETTING_COMPANY_PHONE_TYPE = `${API_VERSION}phone`;
export const GET_LIST_CUSTOMER_SOURCE = `${API_VERSION}settings/source`;
export const GET_LIST_CUSTOMER_TAGS = `${API_VERSION}customers/location/tags`;
export const CREATE_CUSTOMER = `${API_VERSION}customers`;
export const API_CUSTOMER = `${API_VERSION}customers`;
export const UNDELETE_CUSTOMER = `${API_VERSION}customers/undelete`;
export const PERMANENTLY_DELETE_CUSTOMER = `${API_VERSION}customers/trash`;
export const GET_LIST_CUSTOMERS_MAP = `${API_VERSION}customers/map`;
export const CUSTOMERS_LIST_TOTAL_COUNT = `${API_VERSION}customers/summary`;
export const CUSTOMERS_LIST_CHARACTERS = `${API_VERSION}customers/characters`;
export const CUSTOMERS_QB_SYNC = `${API_VERSION}quickbooks/customers/sync`;
export const CUSTOMER_IMPORT_PROGRESS = `${API_VERSION}customers/import/progress`;
export const CUSTOMER_IMPORT = `${API_VERSION}customers/import`;
export const CUSTOMER_MERGE = `${API_VERSION}customers/merge`;

export const getUpdatePhoneType = (typeId) => `${API_VERSION}phone/${typeId}`;
export const getCustomerPaymentList = (customer_id) => `${API_VERSION}customers/${customer_id}/methods`;
export const addCardMerchant = (customer_id) => `${API_VERSION}customers/${customer_id}/card`;
export const customerPortal = (customer_id) => `${API_VERSION}customers/${customer_id}/portal`;
export const customerPortalLogin = (customer_id) => `${API_VERSION}customers/${customer_id}/portal/login`;
export const customerDetail = (customer_id) => `${API_VERSION}customers/${customer_id}/detail`;
export const customerPortalSend = (customer_id) => `${API_VERSION}customers/${customer_id}/portal/send`;
export const customerSearchQB = (customer_id) => `${API_VERSION}quickbooks/customers/${customer_id}/search`;
export const customerSaveQB = (customer_id) => `${API_VERSION}quickbooks/customers/${customer_id}`;
export const customerDeleteCardMerchant = (payment_account_id) => `${API_VERSION}customers/card/${payment_account_id}`;
export const customerSetCardMerchant = (customer_id, payment_account_id) =>
    `${API_VERSION}customers/${customer_id}/card/${payment_account_id}/default`;

//CUSTOMER REREPORT
export const GET_LIST_CUSTOMER_REPORT = `${API_VERSION}customers/simplify`;

// CUSTOMER DETAIL
//// Credit
export const CUSTOMER_GET_LIST_CREDIT = `${API_VERSION}customers/credits`;
export const customerCreateCredit = (customer_id) => `${API_VERSION}customers/${customer_id}/credits`;
export const customerEditCredit = (customer_id, credit_id) =>
    `${API_VERSION}customers/${customer_id}/credits/${credit_id}`;
export const CUSTOMER_CREDIT_GET_LIST_METHOD = `${API_VERSION}customers/credits/methods`;
export const CUSTOMER_UPDATE_STATUS_CREDIT = `${API_VERSION}settings/item/status`;

// CUSTOMER DETAIL
//// Credit
export const getUrlCustomerContact = (customerId) => `${API_VERSION}customers/${customerId}/contacts`;
export const getUrlUpdateCustomerContact = (customerId, contactId) => {
    return `${API_VERSION}customers/${customerId}/contacts/${contactId}`;
};
export const CUSTOMER_CONTACT_GET_LIST_METHOD = `${API_VERSION}customers/contacts/methods`;

// CUSTOMER DETAIL
//// Estimate
export const CUSTOMER_GET_LIST_ESTIMATE = `${API_VERSION}estimates`;
export const CUSTOMER_PERMANENTLY_DELETE_ESTIMATE = `${API_VERSION}estimates/trash`;
export const CUSTOMER_CREATE_ESTIMATE = `${API_VERSION}customers/estimates/create`;
export const CUSTOMER_UPDATE_ESTIMATE = `${API_VERSION}customers/estimates/update`;
export const CUSTOMER_ESTIMATE_GET_LIST_METHOD = `${API_VERSION}customers/estimates/methods`;
export const CUSTOMER_ESTIMATE_UPDATE_STATUS = `${API_VERSION}customers/estimates/status`;

// CUSTOMER DETAIL
//// Locations
export const CUSTOMER_GET_LIST_LOCATION = `${API_VERSION}customers/location`;
export const CUSTOMER_CREATE_LOCATION = `${API_VERSION}customers/location/create`;
export const CUSTOMER_UPDATE_LOCATION = `${API_VERSION}customers/location/update`;
export const CUSTOMER_LOCATION_GET_LIST_METHOD = `${API_VERSION}customers/location/methods`;
export const CUSTOMER_LOCATION_DETAIL = `${API_VERSION}customers/location/details`;
export const CUSTOMER_LOCATION_GET_INFO_EMAIL_PHONE_ADDRESS = `${API_VERSION}customers/location/info`;
export const CUSTOMER_LOCATION_GET_LIST_TAX_RATE = `${API_VERSION}customers/location/taxrate/list`;
export const CUSTOMER_LOCATION_SEARCH_TAGS = `${API_VERSION}customers/location/tags`;

// CUSTOMER DETAIL
//// Invoices
export const CUSTOMER_GET_LIST_INVOICE = `${API_VERSION}invoice/list`;
export const CUSTOMER_CREATE_INVOICE = `${API_VERSION}invoice/action/create`;
export const CUSTOMER_UPDATE_INVOICE = `${API_VERSION}invoice/action/update`;
export const CUSTOMER_ACTIVE_INVOICE = `${API_VERSION}invoice/action/active`;
export const CUSTOMER_ARCHIVE_INVOICE = `${API_VERSION}invoice/action/archive`;
export const CUSTOMER_DELETE_INVOICE = `${API_VERSION}invoice/action/delete`;
export const CUSTOMER_INVOICE_RECURRING = `${API_VERSION}invoices/recurrence`;
export const CUSTOMER_INVOICES = `${API_VERSION}invoices`;
export const CUSTOMER_INVOICES_DELETE_PAYMENT = `${API_VERSION}invoices/payment`;
export const CUSTOMER_INVOICES_TO_CREDIT = `${API_VERSION}invoices/credit`;
export const CUSTOMER_INVOICES_PERMANENT_DELETE = `${API_VERSION}invoices/trash`;
export const QUICK_BOOK_CUSTOMER_INVOICE = `${API_VERSION}quickbooks/invoice/sync`;
export const QUICK_BOOK_CREDIT_SYNC = `${API_VERSION}quickbooks/credit/sync`;

// CUSTOMER DETAIL - Subscriptions
export const getUrlCustomerSubscription = (customer_id) => `${API_VERSION}customers/${customer_id}/subscription`;
export const getUrlCustomerSubscriptionReactive = (customer_id) =>
    `${API_VERSION}customers/${customer_id}/subscription/reactive`;
export const getUrlCustomerSubscriptionCancel = (customer_id) =>
    `${API_VERSION}customers/${customer_id}/subscription/cancel`;
export const getUrlSubscriptionRetrieve = (customer_id) =>
    `${API_VERSION}customers/${customer_id}/subscription/retrieve`;
export const getUrlSubscriptionAssign = (customer_id) => `${API_VERSION}customers/${customer_id}/subscription/assignTo`;
export const getUrlSubscriptionDetail = (customer_id) => `${API_VERSION}customers/${customer_id}/subscription/detail`;

// CUSTOMER DETAIL - Payments
export const CUSTOMER_DETAIL_PAYMENTS = `${API_VERSION}payments`;
export const QUICK_BOOK_CUSTOMER_PAYMENTS = `${API_VERSION}quickbooks/payment/sync`;

// Attachment
export const ATTACHMENT_PRESIGN = `${API_VERSION}attachments/presign`;
export const ATTACHMENT_PRESIGN_ESIGN = `/portal/request/presign`;

//Locations
export const getUrlCustomerLocation = (customerId = '') => {
    return `${API_VERSION}customers/${customerId}/locations/simplify`;
};

//Customer Jobs
export const getUrlCustomerJobsHistory = (customerId = '') => {
    return `${API_VERSION}customers/${customerId}/jobs/history`;
};
export const getUrlCustomerJobs = (customerId = '') => {
    return `${API_VERSION}customers/${customerId}/jobs`;
};
export const CUSTOMER_JOBS_UNDELETE = `${API_VERSION}jobs/undelete`;

//Customer tags
export const getUrlCustomerTags = (customerId = '') => {
    return `${API_VERSION}customers/${customerId}/tags`;
};

//Customer wook pool
export const CUSTOMER_JOB_WOOK_POOL = `${API_VERSION}workpool`;
export const COUNT_CUSTOMER_JOB_WOOK_POOL = `${API_VERSION}workpool/count`;

//Customer list task
export const CUSTOMER_LIST_TASKS = `${API_VERSION}tasks`;

export const CUSTOMER_UPDATE_TASK_STATUS = `${API_VERSION}tasks/status`;
export const updateTask = (taskId = '') => {
    return `${API_VERSION}tasks/${taskId}`;
};

//Location
export const LOCATION_GET_LIST_CITY = `${API_VERSION}location/service/city`;
export const LOCATION_GET_LIST_ZIP = `${API_VERSION}location/service/zip`;

//Calendar
export const CALENDAR_GET_SCHEDULES = `${API_VERSION}schedules`;
export const CALENDAR_GET_LIST_JOB = `${API_VERSION}jobs`;
export const CALENDAR_STORE = `${API_VERSION}calendar/store`;

//Calendar job
export const resizeTimeJob = (jobId = '') => {
    return `${API_VERSION}jobs/${jobId}/resize`;
};

export const moveJob = (jobId = '') => {
    return `${API_VERSION}jobs/${jobId}/move`;
};

export const moveJobToWorkPool = (jobId = '') => {
    return `${API_VERSION}workpool/${jobId}/move`;
};

export const jobSummary = (jobId = '') => {
    return `${API_VERSION}jobs/${jobId}/summary`;
};

export const updateJobStatus = (jobId = '') => {
    return `${API_VERSION}jobs/${jobId}/status`;
};

export const updateGetJobActive = (jobId = '') => {
    return `${API_VERSION}jobs/${jobId}/active`;
};

export const actionLocationMessagingPreferences = (customerId = '', locationId = '') => {
    return `${API_VERSION}customers/${customerId}/locations/${locationId}/preferences`;
};

export const updateLocationCustomerMessagingPreferences = (customerId = '') => {
    return `${API_VERSION}customers/${customerId}/locations/preferences`;
};

export const saveJobMeta = (jobId = '') => {
    return `${API_VERSION}jobs/${jobId}/meta`;
};

// TimeOff Calendar

export const moveTimeoff = (timeoffId = '') => {
    return `${API_VERSION}timeoffs/${timeoffId}/move`;
};
export const resizeTimeoff = (timeoffId = '') => {
    return `${API_VERSION}timeoffs/${timeoffId}/resize`;
};

export const moveEvent = (eventId = '') => {
    return `${API_VERSION}events/${eventId}/move`;
};

export const resizeEvent = (eventId = '') => {
    return `${API_VERSION}events/${eventId}/resize`;
};

export const CALENDAR_GET_LIST_JOB_EVENTS = `${API_VERSION}jobs/events`;
export const CALENDAR_BATCH_ACTION_MOVE_JOB = `${API_VERSION}jobs/move`;

export const GEO_USER_UN_LOCATION = `${API_VERSION}map/userLocation`;
export const GEO_USER_LOCATION = `${API_VERSION}map/geo`;
export const GET_LIST_TEMPLATE_REMINDER = `${API_VERSION}templates/reminder`;
export const GET_LIST_TEMPLATE_CONFIRMATION = `${API_VERSION}templates/confirmation`;
export const GET_LIST_CUSTOMERS_SENDING = `${API_VERSION}customers/sending`;
export const GET_LIST_SENDING_APPOINTMENT = `${API_VERSION}sending/appointment`;
export const SENDING_BROADCAST = `${API_VERSION}sending/broadcast`;

//Job Detail
export const GET_DATA_JOB_DETAIL = API_VERSION.concat('/jobs/print');

export const ADD_MATERIAL_STICKY = `${API_VERSION}materials/sticky`;

export const updateCheckinJob = (jobId = '') => {
    return `${API_VERSION}jobs/${jobId}/check`;
};

export const getDataJobDetail = (jobId) => {
    return `${API_VERSION}jobs/${jobId}`;
};

export const CREATE_JOB_DETAIL = `${API_VERSION}jobs`;

export const DRIVE_MATRIX = `${API_VERSION}routing/matrix`;
export const GET_LIST_EVENT_ROUTE = `${API_VERSION}routing`;
export const OPTIMIZED_EVENT_ROUTE = `${API_VERSION}routing/optimize`;
export const ACCEPT_OPTIMIZED_ROUTE = `${API_VERSION}routing/accept`;

export const getCustomerContact = (customerId) => {
    return `${API_VERSION}customers/${customerId}/contacts/simplify`;
};

export const getJobException = (jobId = '') => {
    return `${API_VERSION}jobs/${jobId}/exception`;
};

export const updateScheduleJob = (jobId = '') => {
    return `${API_VERSION}jobs/${jobId}/schedule`;
};

export const getListMaterial = (jobId) => {
    return `${API_VERSION}jobs/${jobId}/materials`;
};
export const updateMaterial = (jobId, materialId) => {
    return `${API_VERSION}jobs/${jobId}/materials/${materialId}`;
};
export const deleteMaterial = (jobId, materialId) => {
    return `${API_VERSION}jobs/${jobId}/materials/${materialId}`;
};
export const deleteMaterialService = (serviceId, materialId) => {
    return `${API_VERSION}services/${serviceId}/materials/${materialId}`;
};
export const pullFromPreviousService = (jobId) => {
    return `${API_VERSION}jobs/${jobId}/materials/pull`;
};
export const LIST_ITEM_MATERIAL = `${API_VERSION}materials/simplify`;

export const getListDocumentType = `${API_VERSION}documents/types`;
export const GET_LIST_DOCUMENTS = `${API_VERSION}documents`;

export const getDocumentContent = (documentId) => {
    return `${API_VERSION}addons/document/documents/${documentId}`;
};

export const getListStatementCustomer = (statementId) => {
    return `${API_VERSION}statements/${statementId}`;
};

export const getListLocationCustomer = (customerId) => {
    return `${API_VERSION}customers/${customerId}/locations`;
};

// "api/customers/locations/:location_id/smslogs" || Location SMS logs
export const getLocationSMSLogs = (location_id) => `${API_VERSION}customers/locations/${location_id}/smslogs`;
// "api/customers/locations/:location_ids" || Location detail
export const getDetailLocation = (location_id) => `${API_VERSION}customers/locations/${location_id}`;
// "api/customers/:customer_id/locations/:location_id" || Location Update
export const getUpdateLocation = (customer_id, location_id) =>
    `${API_VERSION}customers/${customer_id}/locations/${location_id}`;

export const geStatementLogs = `${API_VERSION}statements/logs`;

export const getListCreditCustomer = (customerId) => {
    return `${API_VERSION}customers/${customerId}/credits`;
};

export const listJobDevices = (jobId) => {
    return `${API_VERSION}jobs/${jobId}/devices`;
};
export const listLocationArea = (locationId) => {
    return `${API_VERSION}locations/${locationId}/areas`;
};
export const updateDeleteLocationArea = (locationId, areaId) => {
    return `${API_VERSION}locations/${locationId}/areas/${areaId}`;
};
export const listDeviceType = `${API_VERSION}devices/types`;
export const updateDeleteDeviceType = (deviceTypeId) => {
    return `${API_VERSION}devices/types/${deviceTypeId}`;
};
export const listDeviceStatus = `${API_VERSION}devices/statuses`;
export const updateDeleteDeviceStatus = (deviceStatusId) => {
    return `${API_VERSION}devices/statuses/${deviceStatusId}`;
};
export const listActivityLevel = `${API_VERSION}devices/activitylevels`;
export const updateDeleteActivityLevel = (activityLevelId) => {
    return `${API_VERSION}devices/activitylevels/${activityLevelId}`;
};
export const geJobLogs = (jobId) => {
    return `${API_VERSION}jobs/${jobId}/logs`;
};

export const addDeviceToJob = (jobId) => {
    return `${API_VERSION}jobs/${jobId}/devices`;
};
export const updateDeviceOnJob = (jobId, deviceId) => {
    return `${API_VERSION}jobs/${jobId}/devices/${deviceId}`;
};
export const getListMaterialByDevice = (deviceId) => {
    return `${API_VERSION}devices/${deviceId}/materials`;
};
export const updateMaterialByDevice = (deviceId, materialId) => {
    return `${API_VERSION}devices/${deviceId}/materials/${materialId}`;
};

export const changeJobService = (jobId) => {
    return `${API_VERSION}jobs/${jobId}/service`;
};

export const getInvoiNumber = `${API_VERSION}invoices/init`;
export const getListItems = `${API_VERSION}items`;
export const getListTaxes = `${API_VERSION}taxes`;
export const searchCustomer = `${API_VERSION}customers/simplify`;

export const invoiceTerms = (invoiceId) => {
    return `${API_VERSION}invoices/${invoiceId}/terms`;
};

export const estimateTerms = (invoiceId) => {
    return `${API_VERSION}estimates/${invoiceId}/terms`;
};

export const setDefaultInvoiceTerms = `${API_VERSION}settings/invoices/terms`;
export const setDefaultEstimateTerms = `${API_VERSION}settings/estimates/terms`;

export const invoiceNotes = (invoiceId) => {
    return `${API_VERSION}invoices/${invoiceId}/note`;
};

export const estimateNotes = (invoiceId) => {
    return `${API_VERSION}estimates/${invoiceId}/note`;
};

export const getInvoiceDetail = (invoiceId) => {
    return `${API_VERSION}invoice/${invoiceId}`;
};

export const updateInvoiceStatus = (invoiceId) => {
    return `${API_VERSION}invoices/${invoiceId}/status`;
};

export const updateInvoiceDetail = (invoiceId) => {
    return `${API_VERSION}invoices/${invoiceId}`;
};

export const setDefaultInvoiceNotes = `${API_VERSION}settings/invoices/note`;
export const setDefaultEstimateNotes = `${API_VERSION}settings/estimates/note`;

//Parameters location_id => required
export const listEmailsSimplify = (customerId) => {
    return `${API_VERSION}customers/${customerId}/emails`;
};
//Parameters keyword => optional
export const listCardsSimplify = (customerId) => {
    return `${API_VERSION}customers/${customerId}/cards`;
};
export const createInvoice = `${API_VERSION}invoices`;
export const settingItems = `${API_VERSION}settings/items`;
export const settingTaxes = `${API_VERSION}settings/taxes`;
export const GET_LIST_SERVICE_SETTING = `${API_VERSION}settings/services`;
export const GET_LIST_SYSTEM_SETTING = `${API_VERSION}templates/system`;
export const GET_LIST_CUSTOM_SETTING = `${API_VERSION}templates/automessages`;
export const SETTING_SERVICE_TRASH = `${API_VERSION}services/alter`;
export const SETTING_SERVICE_DELETE = `${API_VERSION}services`;
export const GET_LIST_SCHEDULES_SETTING = `${API_VERSION}settings/schedules`;
export const scheduleSettingDelete = (scheduleId) => {
    return `${API_VERSION}settings/schedules/${scheduleId}`;
};
export const scheduleSettingColor = (scheduleId) => {
    return `${API_VERSION}settings/schedules/${scheduleId}/colors`;
};

export const signatureInvoice = (invoiceId) => {
    return `${API_VERSION}invoices/${invoiceId}/signature`;
};

export const signatureEstimate = (estimateId) => {
    return `${API_VERSION}estimates/${estimateId}/signature`;
};

export const invoiceEmailSend = `${API_VERSION}invoice/email/send`;
export const invoiceListSms = `${API_VERSION}template/invoice/sms`;
export const invoiceTemplateEmail = `${API_VERSION}template/invoice/email`;
export const invoiceSmsSend = `${API_VERSION}invoice/sms/send`;

export const estimateEmailSend = `${API_VERSION}estimate/email/send`;
export const estimateListSms = `${API_VERSION}template/estimate/sms`;
export const estimateTemplateEmail = `${API_VERSION}template/estimate/email`;
export const estimateSmsSend = `${API_VERSION}estimate/sms/send`;

export const jobEmailSend = `${API_VERSION}job/email/send`;
export const jobListSms = `${API_VERSION}template/workorder/sms`;
export const jobTemplateEmail = `${API_VERSION}template/workorder/email`;
export const jobSmsSend = `${API_VERSION}job/sms/send`;

export const getEstimateDetail = (estimateId) => {
    return `${API_VERSION}estimates/${estimateId}`;
};
export const convertEstimateToInvoice = (estimateId) => {
    return `${API_VERSION}estimates/${estimateId}/convert`;
};
export const updateEstimateStatus = (estimateId) => {
    return `${API_VERSION}estimates/${estimateId}/status`;
};
export const createEstimate = `${API_VERSION}estimates`;

export const getEstimateNumber = `${API_VERSION}estimates/init`;

export const getCustomerDetail = (customerId) => {
    return `${API_VERSION}customers/${customerId}/detail`;
};

export const UPDATE_STATUS_MULTIPLE_CUSTOMERS = `${API_VERSION}customers/status`;

export const initDataCustomer = (customerId) => {
    return `${API_VERSION}customers/${customerId}/init`;
};
export const getSurchargeMethod = `${API_VERSION}payments/surcharge`;
export const getListInvoicesPayment = `${API_VERSION}payments/invoices`;
export const getListPaymentMethod = `${API_VERSION}paymentmethods`;
export const createPayment = `${API_VERSION}payments`;
export const getListCard = (customerId) => {
    return `${API_VERSION}customers/${customerId}/cards`;
};
export const GET_SURCHARGE_METHOD = `${API_VERSION}payments/surcharge`;

export const GET_LIST_PHONE_TYPES = `${API_VERSION}phone`;

export const getListContact = (customerId) => {
    return `${API_VERSION}customers/${customerId}/contacts`;
};

export const createLocation = (customerId) => {
    return `${API_VERSION}customers/${customerId}/locations`;
};

export const getCustomerTimeLine = (customerId) => {
    return `${API_VERSION}customers/${customerId}/timeline`;
};

export const GET_EMAIL_DETAIL = `${API_VERSION}inbox/email/details`;

export const customerNotes = (customerId) => {
    return `${API_VERSION}customers/${customerId}/notes`;
};

export const updateNoteSticky = (noteId) => {
    return `${API_VERSION}notes/${noteId}/sticky`;
};

export const actionNotes = (noteId) => {
    return `${API_VERSION}notes/${noteId}`;
};

export const actionNotesRecurring = (noteId) => {
    return `${API_VERSION}notes/${noteId}/recurring`;
};

export const actionNotesMarkAddRead = (noteId) => {
    return `${API_VERSION}notes/${noteId}/read`;
};

export const jobNotes = (noteId) => {
    return `${API_VERSION}jobs/${noteId}/notes`;
};

export const jobTimeLine = (jobId) => {
    return `${API_VERSION}jobs/${jobId}/timeline`;
};

export const commentOfNote = (noteId) => {
    return `${API_VERSION}notes/${noteId}/comments`;
};

export const actionComment = (commentId) => {
    return `${API_VERSION}comments/${commentId}`;
};

export const actionTopNote = (topNoteId) => {
    return `${API_VERSION}notes/${topNoteId}`;
};

export const actionAttachment = (attmentId) => {
    return `${API_VERSION}attachments/${attmentId}`;
};

export const SEND_EMAIL_INBOX = `${API_VERSION}inbox/email`;

export const SEND_EMAIL_INBOX_DEFAULT = `${API_VERSION}inbox/email/send`;

export const DELETE_EMAIL_INBOX_ATTACHMENT = `${API_VERSION}inbox/email/attachment`;

export const UNSCHEDULE_EMAIL_INBOX = `${API_VERSION}inbox/email/unschedule`;

export const ACTION_REMINDER_EMAIL_INBOX = `${API_VERSION}inbox/email/reminder`;

export const ACTION_SNOOZE_EMAIL_INBOX = `${API_VERSION}inbox/email/snooze`;

export const GET_LIST_INBOXES = `${API_VERSION}inbox`;

export const ACTION_TASK_MOVE = `${API_VERSION}inbox/task/move`;

export const ACTION_TASK_SNOOZE = `${API_VERSION}inbox/task/snooze`;

export const GET_EMAIL_THREAD = `${API_VERSION}inbox/email/thread`;

export const ACTION_EMAIL_MOVE = `${API_VERSION}inbox/email/move`;

export const ACTION_BATCH_SEND_EMAIL = `${API_VERSION}inbox/email/batchsend`;

export const GET_EMAIL_INBOX_SIGNATURE = `${API_VERSION}addons/email/signature`;

// Plans
export const getListPlan = (customer_id) => `${API_VERSION}customers/${customer_id}/plan`;

//MDU
export const getListBuildingAndUnit = (locationId) => {
    return `${API_VERSION}mdu/${locationId}`;
};

export const actionCreatebuilding = (locationId) => {
    return `${API_VERSION}mdu/${locationId}/buildings`;
};

export const actionDeleteBuilding = (buildingId) => {
    return `${API_VERSION}mdu/buildings/${buildingId}`;
};

export const actionCreateUnit = (locationId) => {
    return `${API_VERSION}mdu/${locationId}/units`;
};

export const actionDeleteUnit = (unitId) => {
    return `${API_VERSION}mdu/units/${unitId}`;
};

export const actionAssignUnit = (jobId) => {
    return `${API_VERSION}mdu/assign/${jobId}`;
};

export const actionUpdateUnitDetail = (locationId, unitid) => {
    return `${API_VERSION}mdu/${locationId}/units/${unitid}`;
};

export const getDetailJobUnit = (jobId, unitId) => {
    return `${API_VERSION}mdu/detail/${jobId}/unit/${unitId}`;
};

export const getUnitNotes = (unitId) => {
    return `${API_VERSION}mdu/note/${unitId}`;
};

export const getUnitMaterial = (unitId) => {
    return `${API_VERSION}units/${unitId}/materials`;
};

export const actionUnitMaterial = (unitId, materialId) => {
    return `${API_VERSION}units/${unitId}/materials/${materialId}`;
};

export const actionUpdateBuilding = (locationId, buildingId) => {
    return `${API_VERSION}mdu/${locationId}/buildings/${buildingId}`;
};

export const actionNoteVisible = (unitId) => {
    return `${API_VERSION}mdu/visible/${unitId}`;
};

export const actionUnitCheckTime = (jobId, unitId) => {
    return `${API_VERSION}mdu/job/${jobId}/unit/${unitId}/check`;
};

export const actionsignnatureUnit = (jobId, unitId) => {
    return `${API_VERSION}mdu/signature/${jobId}/unit/${unitId}`;
};

// Addons
export const ADDONS_CALENDAR = `${API_VERSION}addons/calendar`;
export const ADDONS_LIST = `${API_VERSION}addons`;
export const ADDONS_DOCUMENT = `${API_VERSION}addons/document`;
export const ADDONS_DOCUMENT_LIST = `${API_VERSION}addons/document/documents`;
export const ADDONS_STRIPE_DETAIL = `${API_VERSION}addons/stripe`;
export const ADDONS_STRIPE_SURCHARGE = `${API_VERSION}addons/stripe/surcharge`;
export const ADDONS_STRIPE_ACH_SURCHARGE = `${API_VERSION}addons/stripe/achSurcharge`;
export const ADDONS_SQUARE_DETAIL = `${API_VERSION}addons/square`;
export const ADDONS_SQUARE_SURCHARGE = `${API_VERSION}addons/square/surcharge`;
export const ADDONS_MERCHANT_DEFAULT = `${API_VERSION}addons/merchant`;
export const ADDONS_SMS_DETAIL = `${API_VERSION}addons/sms`;
export const ADDONS_SMS_AFTER_HOURS = `${API_VERSION}addons/sms/afterHour`;
export const ADDONS_PORTAL_DETAIL = `${API_VERSION}addons/portal`;
export const ADDONS_SMS_NUMBERS = `${API_VERSION}addons/sms/numbers`;
export const ADDONS_SMS_RENT_NUMBERS = `${API_VERSION}addons/sms/number`;
export const ADDONS_SMS_ACTIVE = `${API_VERSION}addons/sms/activate`;
export const ADDONS_SMS_DEACTIVE = `${API_VERSION}addons/sms/account`;
export const ADDONS_BOOKING = `${API_VERSION}addons/booking`;
export const ADDONS_BOOKING_SETTINGS = `${API_VERSION}addons/booking/settings`;
export const ADDONS_BOOKING_SERVICES = `${API_VERSION}addons/booking/services`;
export const ADDONS_SMS_COUNTRIES = `${API_VERSION}addons/sms/countries`;
export const ADDONS_API_KEYS = `${API_VERSION}addons/api`;
export const ADDONS_API_KEYS_LIST = `${API_VERSION}addons/api/keys`;
export const getUpdateAndDeleteAPIKey = (id) => `${API_VERSION}addons/api/keys/${id}`;
export const getSettingsAPIKey = (id) => `${API_VERSION}addons/api/keys/${id}/settings`;
export const ADDONS_VOIP_DETAIL = `${API_VERSION}addons/voip`;
export const ADDONS_MULTIPLE_BRANCH_DETAIL = `${API_VERSION}addons/branch`;
export const ADDONS_LIST_MULTIPLE_BRANCH = `${API_VERSION}addons/branch/list`;
export const ADDONS_TOGGLE_ASSIGN_USER_MULTIPLE_BRANCH = `${API_VERSION}addons/branch/assignUser`;
export const ADDONS_OPPS_DETAIL = `${API_VERSION}addons/opportunity`;
export const ADDONS_SMARTVIEW_DETAIL = `${API_VERSION}addons/smartviews`;

export const getDeleteServiceBooking = (id) => `${API_VERSION}addons/booking/services/${id}`;
export const getDeleteDocumentAddons = (id, type = 1) => `${API_VERSION}addons/document/documents/${id}?type=${type}`;
export const getUpdateBookingServiceNote = (id) => `${API_VERSION}addons/booking/services/${id}/note`;
export const getUpdateBookingServiceAssign = (id) => `${API_VERSION}addons/booking/services/${id}/assignment`;
export const ADDONS_MANAGE_DOCUMENT = `${API_VERSION}addons/document/documents`;

// Addons Update Options
export const UPDATE_ADDONS_STRIPE_OPTIONS = `${API_VERSION}addons/stripe/options`;
export const UPDATE_ADDONS_SQUARE_OPTIONS = `${API_VERSION}addons/square/options`;
export const UPDATE_ADDONS_COMMISSION_OPTIONS = `${API_VERSION}addons/commission/options`;
export const UPDATE_ADDONS_DOCUMENT_OPTIONS = `${API_VERSION}addons/document/options`;
export const UPDATE_ADDONS_PORTAL_OPTIONS = `${API_VERSION}addons/portal/options`;
export const UPDATE_ADDONS_DEVICE_TRACKING_OPTIONS = `${API_VERSION}addons/device/options`;
export const UPDATE_ADDONS_ONLINE_BOOKING_OPTIONS = `${API_VERSION}addons/booking/options`;
export const UPDATE_ADDONS_MATERIAL_OPTIONS = `${API_VERSION}addons/material/options`;
export const UPDATE_ADDONS_GPS_TRACKING_OPTIONS = `${API_VERSION}addons/gps/options`;
export const UPDATE_ADDONS_TRIGGERS_OPTIONS = `${API_VERSION}addons/trigger/options`;
export const UPDATE_ADDONS_ROUTING_OPTIONS = `${API_VERSION}addons/routing/options`;
export const UPDATE_ADDONS_INBOX_EMAIL_OPTIONS = `${API_VERSION}addons/email/options`;
export const UPDATE_ADDONS_QUICKBOOKS_SYNC = `${API_VERSION}addons/quickbooks/option`;
export const UPDATE_ADDONS_INBOX_EMAIL_SETTINGS = `${API_VERSION}addons/email/settings`;
export const UPDATE_ADDONS_REVIEW_OPTIONS = `${API_VERSION}addons/review/options`;
export const UPDATE_ADDONS_REVIEW_OPTIONS_SETTING = `${API_VERSION}addons/review/settings`;
export const UPDATE_ADDONS_RESET_KEY_ZAPIER = `${API_VERSION}addons/zapier/key`;
export const UPDATE_ADDONS_TRIGGER_OPTIONS = `${API_VERSION}addons/trigger/options`;
export const UPDATE_ADDONS_MULTI_UNIT_OPTIONS = `${API_VERSION}addons/unit/options`;
export const UPDATE_ADDONS_EMAIL_ACCOUNT = `${API_VERSION}addons/email/account`;
export const UPDATE_ADDONS_API_KEY_OPTIONS = `${API_VERSION}addons/api/options`;
export const UPDATE_ADDONS_VOIP_OPTIONS = `${API_VERSION}addons/voip/options`;
export const UPDATE_ADDONS_OPPORTUNITY_OPTIONS = `${API_VERSION}addons/opportunity/options`;
export const UPDATE_ADDONS_WORK_LOG_OPTIONS = `${API_VERSION}addons/worklog/options`;
export const UPDATE_ADDONS_SMARTVIEW_OPTIONS = `${API_VERSION}addons/smartviews/options`;
export const UPDATE_ADDONS_CUSTOM_FIELDS_OPTIONS = `${API_VERSION}addons/customfield/options`;
export const UPDATE_CUSTOM_JOB_STATUS_FIELDS_OPTIONS = `${API_VERSION}addons/jobstatus/options`;
export const UPDATE_ADDONS_ESTIMATE_OPTIONS = `${API_VERSION}addons/estimate/options`;

export const ACTIONS_DELETE_INVOICE = `${API_VERSION}invoices`;
export const STRIPE_ADDONS_DISCONNECT = `${API_VERSION}addons/stripe/disconnect`;
export const STRIPE_ADDONS_CONNECT = `${API_VERSION}addons/stripe/connect`;
export const STRIPE_ADDONS_OPTIONS = `${API_VERSION}addons/stripe/options`;
export const SQUARE_ADDONS_OPTIONS = `${API_VERSION}addons/square/options`;
export const SQUARE_ADDONS_CONNECT = `${API_VERSION}addons/square/connect`;
export const SQUARE_ADDONS_DISCONNECT = `${API_VERSION}addons/square/disconnect`;
export const SQUARE_ADDONS_LOCATIONS = `${API_VERSION}addons/square/location`;
export const SMS_ADDONS_OPTIONS = `${API_VERSION}addons/sms/options`;
export const SMS_ADDONS_OVERRIDE_GLOBAL = `${API_VERSION}addons/sms/settings`;
export const ADDONS_ENGINE_DETAIL = `${API_VERSION}addons/review`;
export const ZAPIER_ADDONS_DETAIL = `${API_VERSION}addons/zapier`;
export const ADDONS_TRIGGER_DETAIL = `${API_VERSION}addons/trigger`;
export const ADDONS_EMAIL_DETAIL = `${API_VERSION}addons/email`;
export const ADDONS_GPS_TRACKING_DETAIL = `${API_VERSION}addons/gps`;
export const ADDONS_DEVICE_TRACKING_DETAIL = `${API_VERSION}addons/device`;
export const ADDONS_MULTI_UNIT_DETAIL = `${API_VERSION}addons/unit`;
export const ADDONS_MULTI_UNIT_DETAIL_SETTING = `${API_VERSION}addons/unit/settings`;
export const ADDONS_PORTAL_SETTING = `${API_VERSION}addons/portal/settings`;
export const ADDONS_ROUTING_DETAIL = `${API_VERSION}addons/routing`;
export const ADDONS_COMMISSION_TRACKING = `${API_VERSION}addons/commission`;
export const ADDONS_VOIP_UPDATE_OPTION = `${API_VERSION}addons/voip/options`;
export const ADDONS_WORK_LOG_DETAIL = `${API_VERSION}addons/worklog`;
export const ADDONS_CUSTOM_FIELDS_DETAIL = `${API_VERSION}addons/customfield`;
export const ADDONS_CUSTOM_JOB_STATUS_DETAIL = `${API_VERSION}addons/jobstatus`;
export const ADDONS_ESTIMATE_DETAIL = `${API_VERSION}addons/estimate`;
export const CUSTOM_JOB_STATUS_LIST = `${API_VERSION}job/statuses`;
// AI chatbot
export const ADDONS_CHATBOT_AI = `${API_VERSION}addons/aiagents`;
export const UPDATE_ADDONS_CHATBOT_AI_OPTIONS = `${API_VERSION}addons/aiagents/options`;

// Addons - Advance Routing
export const ADDONS_ADVANCE_ROUTING = `${API_VERSION}addons/routing`;
export const UPDATE_ADDONS_ADVANCE_ROUTING_OPTIONS = ADDONS_ADVANCE_ROUTING + '/options';
const ADDONS_ADVANCE_ROUTING_JOB_MAGNET = ADDONS_ADVANCE_ROUTING + '/jobmagnet';
export const MAGNET_PERMISSION = ADDONS_ADVANCE_ROUTING_JOB_MAGNET + '/permissions';
export const MAGNET_SETTINGS = ADDONS_ADVANCE_ROUTING_JOB_MAGNET + '/settings';
const ADDONS_ADVANCE_ROUTING_AVAILABLE = ADDONS_ADVANCE_ROUTING + '/bestavailabletime';
export const AVAILABLE_PERMISSION = ADDONS_ADVANCE_ROUTING_AVAILABLE + '/permissions';
export const AVAILABLE_SETTINGS = ADDONS_ADVANCE_ROUTING_AVAILABLE + '/settings';

//Service

export const ACTION_SERIVES = `${API_VERSION}services`;

export const getSericeDetail = (serviceId) => {
    return `${API_VERSION}services/${serviceId}`;
};

export const updateMaterialWithService = (serviceId) => {
    return `${API_VERSION}services/${serviceId}/jobs/materials`;
};

export const updateTodosWithService = (serviceId) => {
    return `${API_VERSION}services/${serviceId}/jobs/todos`;
};

export const getDetailWorkOrder = (jobId) => {
    return `${API_VERSION}jobs/${jobId}/wo`;
};

export const actionWONoteRecurring = (jobId) => {
    return `${API_VERSION}jobs/${jobId}/wo/recurring`;
};

export const actionWorkOrderNote = (jobId) => {
    return `${API_VERSION}jobs/${jobId}/wo/note`;
};

export const signatureWorkOrder = (jobId) => {
    return `${API_VERSION}jobs/${jobId}/signature`;
};

export const REPORT_ACTIVITY_COMPARISON = `${API_VERSION}reports/activity-comparison`;
export const REPORT_CALL_ACTIVITY_COMPARISON = `${REPORT_ACTIVITY_COMPARISON}/calls`;

export const getListSmartDialer = (id) => {
    return `${API_VERSION}smartviews/${id}/calls`;
};

export const GET_WIDGET_DATA = `${API_VERSION}widget/data`;

export const actionUpdateBranch = (id) => {
    return `${API_VERSION}addons/branch/${id}`;
};

export const BLOCK_PHONE_NUMBER = `${API_VERSION}addons/blocked/numbers`;

export const OVERRIDE_INVOICE_BALANCE_CUSTOMER = `${API_VERSION}customers/override`;

export const REPORT_NEW_JOB_GET_LIST = `${API_VERSION}reports/jobs/new`;
