import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { BEST_AVAILABLE_TIME_MANAGE, JOB_MAGNET_MANAGE } from 'app/config/routes';
import { ADDONS_FOOTER_OPTIONS, LAST_MONTH, USAGE_MAXIMUM } from 'app/const/addons';
import { ADDONS_ROUTING_DETAIL, UPDATE_ADDONS_ADVANCE_ROUTING_OPTIONS } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import { clientQuery } from 'common/utils/ApiUtils';
import { onHandlePercent } from 'common/utils/NumberUtils';
import { checkAccessFail, getPlanUser } from 'common/utils/PermissionUtils';
import AddonBack from '../components/AddonBack';
import { AddonsOptionsItem } from '../components/AddonsOptions';
import RoutingAddonsContent from './components/RoutingAddonsContent';
import RoutingAddonsLoading from './components/RoutingAddonsLoading';
import RoutingAddonsOptions from './components/RoutingAddonsOptions';

const AddonsRouting = () => {
    const [state, dispatchState] = useReducer(reducer, { routing: {}, isLoading: true });
    const { t } = useTranslation(['addons', 'common']);
    const profileData = useSelector(({ auth }) => auth.user.profile);
    const { isGrowthPlan } = getPlanUser(profileData);
    const refAlert = useRef(null);
    const { routing, isLoading } = state;
    const { amount = 0, maximum = USAGE_MAXIMUM, last_month_amount = 0 } = routing.usage || {};

    useEffect(() => {
        _getDetailRoutingAddons();
    }, []);

    const _getDetailRoutingAddons = () => {
        clientQuery(ADDONS_ROUTING_DETAIL, { method: 'GET' }, _handleGetRoutingSuccess, checkAccessFail);
    };

    const _handleGetRoutingSuccess = ({ data }) => {
        dispatchState((prevState) => {
            const newState = { ...prevState, isLoading: false };
            const dataRouting = { ...data };
            dataRouting['options'] = data.options.splice(0, 1);
            dataRouting['optionsAdvance'] = data.options;
            newState['routing'] = dataRouting;
            return newState;
        });
    };

    return (
        <div className="addons-wrap__container">
            <div className="header">
                <AddonBack />
            </div>
            <AlertCustomer ref={refAlert} />
            <div className="scrolls">
                <div className="boxs-wrapper">
                    {isLoading ? (
                        <RoutingAddonsLoading />
                    ) : (
                        <>
                            <RoutingAddonsContent {...routing} isLoading={isLoading} />
                            <RoutingAddonsOptions data={routing.options} />
                            <AddonsAdvanceRoutingOptions data={routing.optionsAdvance} isUpgradeGrowthIcon />
                            <div className="is-divider --horizontal my-3" />
                            <div className="wrap-process customer-process">
                                <p className="description violet-name">
                                    {t('addons:drive_matrix_title', {
                                        connections: isGrowthPlan ? 15000 : 2500,
                                        plan_type: isGrowthPlan ? 'Growth' : 'Pro'
                                    })}
                                </p>
                                <p className="description mt-5 violet-name">{t('addons:drive_matrix_desc')}</p>
                                <p className="description mt-5 violet-name">{t('addons:drive_matrix_desc_contact')}</p>
                                <div className="rows mt-4">
                                    <div className="rows__label">{t('addons:connection_usage_this_month')}</div>
                                    <div
                                        className={`progress-bar --green per-${
                                            `${onHandlePercent(maximum)}` - `${onHandlePercent(amount)}`
                                        }`}
                                    >
                                        <span />
                                    </div>
                                    <div className="rows__number">
                                        {maximum - amount}/{maximum}
                                    </div>
                                </div>
                                <div className="rows">
                                    <div className="rows__label">
                                        {t('addons:usage_last_month', {
                                            month: LAST_MONTH
                                        })}
                                    </div>
                                    <div className={`progress-bar --black per-${onHandlePercent(last_month_amount)}`}>
                                        <span />
                                    </div>
                                    <div className="rows__number">{last_month_amount}</div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

const AddonsAdvanceRoutingOptions = ({ data = [] }) => {
    const isActivated = useSelector(({ auth }) => !!auth.user.settings.addons.routing);
    if (!data || !data.length) return null;
    return data.map((item) => {
        const keyword = item.keyword;
        const pathManage =
            keyword === ADDONS_FOOTER_OPTIONS.BEST_AVAILABLE_TIME ? BEST_AVAILABLE_TIME_MANAGE : JOB_MAGNET_MANAGE;
        return (
            <AddonsOptionsItem
                key={keyword}
                isPowerUp
                shouldCheckManage
                pathManage={pathManage}
                urlUpdate={UPDATE_ADDONS_ADVANCE_ROUTING_OPTIONS}
                status={isActivated}
                isUpgradeGrowthIcon
                {...item}
            />
        );
    });
};

export default AddonsRouting;
