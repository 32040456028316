import { isWebglSupport } from 'common/utils/FunctionUtils';
import moment from 'moment';
import { LINK_CDN_IMG } from './URL';

// KEY APP
export const ACCESS_TOKEN = 'access_token';
export const KEY_CURRENT_BRANCH = 'current_branch_id';
export const KEY_DEVICE_ID = 'KEY_DEVICE_ID';

export const SPECICAL_KEYS = [ACCESS_TOKEN, KEY_CURRENT_BRANCH, KEY_DEVICE_ID];

export const LIVE_STATUS = 'live_status';
export const ERROR_CODE = {
    EXPIRED_TOKEN: 403,
    PERMISSION_DENIED: 401,
    UNPROCESSABLE: 422
};
export const SUCCESS_CODE = 200;
export const WEB = 'web';
export const PREFIX_TITLE = 'GorillaDesk';

export const ModuleLayouts = {
    REPORT: 'report',
    CALENDAR: 'calendar',
    CUSTOMER: 'customer',
    CUSTOMER_DETAIL: 'customer_detail',
    SETTINGS: 'settings',
    REVIEW: 'reviews',
    COMMISSION: 'commission',
    DRIP: 'drip',
    LEAD: 'lead'
};

export const CUSTOMER_SEARCH_LIMIT = 20;

// Limits
export const LIMIT_PHONES_ADD_CONTACTS = 2;

// TYPES ACCOUNT USER
export const TRIAL_ACCOUNT_USER = 'Trial';
export const BASIC_ACCOUNT_USER = 'Basic';
export const ANNUAL_BASIC_ACCOUNT_USER = 'Annual_Basic';
export const PRO_ACCOUNT_USER = 'Pro';
export const ANNUAL_PRO_ACCOUNT_USER = 'Annual_Pro';
export const GROWTH_ACCOUNT_USER = 'Growth';
export const ANNUAL_GROWTH_ACCOUNT_USER = 'Annual_Growth';
export const FREEZE_ACCOUNT_USER = 'Freeze';
export const ANNUAL_FREEZE_ACCOUNT_USER = 'Annual_Freeze';
export const FREE_TRIAL_KEY = 'free_trial';

//Notifications
export const NOTIFICATION_LIMIT = 20;

export const KEY_CODE_ESCAPE = 27;
export const DELAY_TIME = 300;
export const DELAY_CLICK = 300;
// eslint-disable-next-line no-undef
export const AVATAR_DEFAULT = process.env.REACT_APP_AVATAR_DEFAULT;

// Customer
export const CUSTOMER_IMPORT_ACCEPT_FILE = '.csv';

export const AMOUNT_PAGE_PAGINATION = 5;
export const AMOUNT_POSISION_PAGINATION = 7;
export const LIST_PAGE_SIZE = [10, 15, 20, 25, 50, 100];
export const PAGE_SIZE_DEFAULT = LIST_PAGE_SIZE[1];
export const YEAR_START = 2015;
export const GD_HEADER_HEIGHT = 36;
export const GD_ROW_TITLE_HEIGHT = 64;

// Const Date Range
export const FORMAT_DATE = {
    'm/d/Y': 'MM/DD/YYYY'
};
export const DATE_RANGE = {
    SET_OPEN: 'set_open',
    CANCEL: 'cancel',
    APPLY: 'apply',
    SELECT: 'select',
    TO_DAY: 'to_day',
    YESTERDAY: 'yesterday',
    LAST_7_DAYS: 'last_7_days',
    LAST_WEEK: 'last_week',
    THIS_WEEK: 'this_week',
    MONTH_TO_DATE: 'month_to_date',
    PREVIOUS_MONTH: 'previous_month',
    YEAR_TO_DATE: 'year_to_date',
    // New range for v2 follow design
    LAST_30_DAYS: 'last_30_days',
    THIS_MONTH: 'this_month',
    LAST_MONTH: 'last_month',
    LAST_3_MONTH: 'last_3_month',
    LAST_6_MONTH: 'last_6_month',
    THIS_YEAR: 'this_year',
    LAST_YEAR: 'last_year',
    ALL_TIME: 'all_time'
};

export const ENTER_KEY_CODE = 13;
export const EMPTY_CURRENCY = '0.00';

export const DEFAULT_ALL = -1;
export const DEFAULT_ALL_STRING = '-1';
export const DEFAULT_OFFSET = 0;
export const DEFAULT_PAGE = 1;
export const DEFAULT_SORT_BY_NAME = 'first_name';
export const KEY_REPORT_LOCAL_STORAGE = 'filter_report';
export const KEY_CALENDAR_SETTINGS = 'calendar_settings';
export const KEY_OPTIONS_CHECK_RECURRING_JOB_CALENDAR = 'KEY_OPTIONS_CHECK_RECURRING_JOB_CALENDAR';
export const KEY_OPTIONS_CHECK_CHANGE_STATUS_JOB = 'KEY_OPTIONS_CHECK_CHANGE_STATUS_JOB';
export const KEY_REVIEW_LOCAL_STORAGE = 'reviews';
export const KEY_CUSTOMER_DETAIL_CREDIT_LOCAL_STORAGE = 'customer_detail_credit';
export const KEY_CUSTOMER_DETAIL_CONTACT_LOCAL_STORAGE = 'customer_detail_contact';
export const KEY_CUSTOMER_DETAIL_ESTIMATE_LOCAL_STORAGE = 'customer_detail_estimate';
export const KEY_CUSTOMER_DETAIL_LOCATION_LOCAL_STORAGE = 'customer_detail_location';
export const KEY_CUSTOMER_DETAIL_INVOICE_LOCAL_STORAGE = 'customer_detail_invoice';
export const KEY_QUESTLOG_LOCAL_STORAGE = 'questlog';
export const KEY_MAP_CALENDAR_SEARCH_STORAGE = 'map_calendar_search_location';
export const KEY_MAP_CALENDAR_STYLE = 'map_calendar_style';
export const KEY_MAP_CALENDAR_GEO_LOCATION = 'map_calendar_geo_location';
export const KEY_MAP_CUSTOMER_LIST_STYLE = 'map_customer_list_style';
export const KEY_OPTIONS_CHECK_NOTIFY_TECHNICIAN_JOB_CALENDAR = 'KEY_OPTIONS_CHECK_NOTIFY_TECHNICIAN_JOB_CALENDAR';

export const TYPE_BUTTON_ACTIONS = {
    DELETE: 4,
    ARCHIVE: 2,
    ACTIVE: 1,
    UNDELETE: 3,
    TRASH: 5,
    MARK_AS: 6,
    MERGE_ACCOUNT: 7,
    PERMANENTLY_DELETE: 8,
    SYNC_QB: 9
};

export const REPORT_BATCH_ACTION_ITEMS = {
    INVOICES: {
        EMAIL: 1,
        PRINT: 2,
        GENERATE_PDF: 3,
        SYNC_TO_QB: 4
    },
    ESTIMATES: {
        EMAIL: 1,
        PRINT: 2
    },
    SERVICE_LOOKUP: {
        REASSIGN_JOBS: 1
    },
    ACCOUNTS_AGING: {
        PRINT_INVOICE: 1,
        GENERATE_INVOICE_PDF: 2,
        PRINT_STATEMENT: 3,
        GENERATE_STATEMENT_PDF: 4,
        EMAIL_LATE_PAYMENT_REMINDER: 5,
        SMS_LATE_PAYMENT_REMINDER: 6,
        EMAIL_STATEMENT: 7
    }
};

export const LIST_TABLE_ITEM_TYPE = {
    LINK: 'link',
    LINK_PAYMENT_METHODS: 'link_payment_methods',
    AVATAR: 'avatar',
    CUSTOMER_INFO: 'customer_info',
    AVATAR_LINK: 'avatar_link',
    STATUS: 'status',
    STATUS_CREDIT: 'status_credit',
    COLOR: 'color',
    PHONE: 'phone',
    PARSE_HTML: 'parse_html',
    PAYMENT_CARD: 'payment_card',
    PAYMENT_METHOD: 'payment_method',
    EMAIL: 'email',
    EMAIL_ARRAY: 'email_array',
    PRICING: 'pricing',
    CURRENCY: 'currency',
    TEAMMATE: 'teammate',
    RATING: 'rating',
    RATING_COUNTING: 'rating_counting',
    RATING_COUNTING_TEXT: 'rating_text',
    SWITCH: 'switch',
    EDITABLE: 'editable',
    ELLIPSIS: 'ellipsis',
    CUSTOM: 'custom',
    DESCRIPTION: 'description',
    INPUT: 'input',
    CUSTOM_NEXT_INVOICE: 'custom_next_invoice',
    TAGS: 'tags',
    DEFAULT: 'default',
    ESTIMATE_STATUS: 'estimate_status',
    CREATED_BY: 'created_by',
    JOBS_STATUS: 'jobs_status',
    PAYMENT_FOR: 'payment_for',
    LIST: 'list',
    LIST_LOCATIONS: 'list_locations',
    LIST_CONTACTS: 'list_contacts',
    LIST_ACTIVE_JOBS: 'list_active_jobs',
    CUSTOMER_STATUS: 'customer_status',
    QB_ID: 'quick_book_id',
    QB_SYNC: 'quick_book_sync',
    CREDIT_CARD: 'credit_card',
    BALANCE: 'balance',
    LIST_TAGS: 'list_tags',
    TAXES: 'taxes',
    CUSTOMER_EMAIL_LIST: 'customer_email_list',
    CUSTOMER_EDIT: 'customer_edit',
    DOCUMENT: 'document',
    TYPE: 'type',
    JOB: 'job',
    DATE_CREATED: 'date_created',
    IMG_DOCUMENT: 'img_document',
    CUSTOMER_PAYMENT_FOR: 'customer_payment_for',
    TWO_ROWS: 'two_rows',
    TEXT: 'text',
    JOB_FREQUENCY: 'job_frequency',
    LIST_ASSIGNEE: 'list_assignee',
    SUBSCRIPTION_EDIT: 'subscription_edit',
    ASSIGNEES_SELECT: 'assignment_select',
    LOCATIONS_SELECT: 'locations_select',
    SUBSCRIPTION: 'subscription',
    CUSTOMER_CREDIT: 'customer_credit',
    INVOICE: 'invoice',
    RECURRING_INVOICE: 'recurring_invoice',
    SERVICE_FREQUENCY: 'service_frequency',
    SERVICE_COLOR: 'service_color',
    SERVICE_NAME: 'service_name',
    LOCATION_ADDRESS: 'location_address',
    LOCATION_ADDRESS_CONTACT: 'location_address_contact',
    LOCATION_TAXES: 'location_taxes',
    LOCATION_TAGS: 'location_tags',
    LOCATION_ACTIVE_SERVICE: 'location_active_service',
    LOCATION_UNITS: 'location_units',
    SCHEDULES_NICKNAME: 'schedules_nickname',
    SCHEDULES_ADDRESS: 'schedules_address',
    GROUPS: 'groups',
    SCHEDULES_ASSIGNEE: 'schedules_assign',
    CUSTOMER_ESTIMATE: 'customer_estimate',
    CUSTOMER_ESTIMATE_JOB: 'customer_estimate_job',
    SETTING_STATUS_SYSTEM: 'setting_status_system',
    SETTING_PLAN_SYSTEM: 'setting_plan_system',
    SETTING_TYPE_CUSTOM: 'setting_type_custom',
    NAME_CLICK: 'name_click',
    PAYMENT_CARD_WITH_ACTION: 'payment_card_with_action',
    ALL_PAYMENT_FOR: 'all_payment_for',
    TYPE_COMMISSION: 'type_commission',
    INVOICE_FREQUENCY: 'invoice_frequency',
    SERVICE_TYPE: 'service_type',
    SERVICE_ADDRESS: 'service_address',
    SERVICE_ADDRESS_1: 'service_address_1',
    SERVICE_ADDRESS_2: 'service_address_2',
    CURRENCY_SYMBOL: 'currency_format',
    TOTAL_CREDIT: 'total_credit',
    METHOD_REVENUE: 'method_revenue',
    ASSIGNED_TO: 'assigned_to',
    WEATHER: 'weather',
    QB_ADDRESS: 'qb_address',
    CUSTOMERS_ADDRESS: 'customers_address',
    ASSIGN_BOOKING: 'assign_booking',
    JOB_DOCUMENT: 'job_document',
    ADDRESS_LEAD: 'address_lead',
    UNITS: 'units',
    SMS: 'sms',
    QB_SYNC_CREDIT: 'qb_sync',
    VIEW_ESTIMATE: 'view_estimate',
    INVOICE_RECURRING_EDIT: 'invoice_recurring_edit',
    COST: 'cost',
    RATE_PERCENT: 'rate_percent',
    WORD_BREAK: 'word_break',
    DELETE_QUICK_BOOK_INVOICE: 'delete_quick_book_invoice',
    JOB_STATUS_TAB: 'job_status_tab',
    BILLING_EMAIL: 'billing_email',
    CUSTOM_BUTTON: 'custom_button',
    DEVICE: 'device',
    TIME_AGO: 'time_ago',
    STATEMENT_SERVICE: 'statement_service',
    QB_ID_COLUMN: 'qb_id',
    USER_VOIP: 'user_voip',
    PERSONAL_NUMBER_VOIP: 'personal_number',
    GROUP_NUMBER_VOIP: 'group_number',
    CALL_RECORDING_VOIP: 'call_recording',
    CALL_TRANSCRIPTION: 'transcription',
    EDIT_ACTIVE_USER_VOIP: 'edit',
    DIGITS: 'digits',
    FORWARD_TO: 'forward_to',
    EDIT_AUTO_ATTENDANT: 'edit_auto_attendant',
    TIME_HMS: 'time_hms',
    CUSTOMER_STATUS_SMART_VIEW: 'customer_status_smart_view',
    SOURCE: 'source',
    NAME_CUSTOMER: 'name_customer',
    NEXT_TASK: 'next_task',
    NEXT_TASK_DATE: 'next_task_date',
    ADDRESS: 'address',
    SMS_MESSAGE: 'sms_message',
    OPPORTUNITY_VALUE: 'opportunity_value',
    AVATAR_MULTIPLE_BRANCH: 'avatar_multiple_branch',
    EMAIL_MULTIPLE_BRANCH: 'email_multiple_branch',
    PHONE_MULTILE_BRANCH: 'phone_multiple_branch',
    PLAN_MULTILE_BRANCH: 'plan_multiple_branch',
    ASSINGED_USERS_MULTILE_BRANCH: 'assigned_users_multiple_branch',
    EDIT_MULTILE_BRANCH: 'edit_multiple_branch',
    STAGE_SALE_FUNNEL: 'stage_sale_funnel',
    RATE_PER_HOUR: 'rate_per_hour',
    IN_OUT_TIME: 'in_out_time',
    DURATION: 'duration',
    USER_HAS_AVT: 'user_has_avt',
    ROLE_USER: 'role_user',
    SWITCH_CHECKBOX_OPP_PERMISSION: 'switch_checkbox_opp_permission',
    BRANCH_COLOR: 'branch_color',
    BANK_ACH: 'bank_ach',
    TAGS_DISPLAY: 'tags_display',
    REFERRAL_STATUS: 'referral_status',
    COUNT: 'count',
    CALL_ACTIVITY: 'call_activity',
    STATUS_ITEM: 'status_item',
    CONVERSATION_TAGS: 'conversation_tags'
};

export const KEY_CURRENCY_COLUMNS = 'currency_columns';

export const LABEL_CHART_THE_MONTHS_OF_YEAR = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
];
export const TYPE_CHARTS = {
    bar: 'bar',
    line: 'line',
    pie: 'pie'
};

export const DATE_FORMAT = 'YYYY-MM-DD';
export const YEAR_CURRENT = moment().year();
export const YEAR_CURRENT_STRING = YEAR_CURRENT.toString();
export const CURRENT_MONTH = moment().format('MM');
export const TODAY = moment()._d;
export const CURRENT_DAY = moment();
export const CURRENT_MONTH_SHORT_NAME = moment().format('MMM');
export const YESTERDAY = moment().subtract(1, 'day')._d;
export const START_DATE_LAST_7_DAYS = moment().subtract(6, 'days');
export const START_DATE_ALL_TIME = moment().subtract(5, 'years');

export const START_DATE_LAST_WEEK = moment().subtract(1, 'weeks').startOf('week');
export const END_DATE_LAST_WEEK = moment().subtract(1, 'weeks').endOf('week');
export const START_DATE_THIS_WEEK = moment().startOf('week');
export const END_DATE_THIS_WEEK = moment().endOf('week');

export const START_DATE_OF_MONTH = moment().startOf('months')._d;
export const END_DATE_OF_MONTH = moment().endOf('months')._d;

export const START_DATE_PREVIOUS_MONTH = moment().subtract(1, 'months').startOf('month')._d;
export const END_DATE_PREVIOUS_MONTH = moment().subtract(1, 'months').endOf('month')._d;

export const LAST_30_DAYS = moment().subtract(29, 'days');
export const START_OF_LAST_MONTH = moment().subtract(1, 'month').startOf('month');
export const END_OF_LAST_MONTH = moment().subtract(1, 'month').endOf('month');
export const START_OF_LAST_3_MONTH = moment().subtract(3, 'month').startOf('month');
export const END_OF_LAST_3_MONTH = moment().subtract(3, 'month').endOf('month');
export const START_OF_LAST_6_MONTH = moment().subtract(6, 'month').startOf('month');
export const END_OF_LAST_6_MONTH = moment().subtract(6, 'month').endOf('month');
export const START_OF_YEAR = moment().startOf('year');
export const END_OF_YEAR = moment().endOf('year');
export const START_OF_LAST_YEAR = moment().subtract(1, 'year').startOf('year');
export const END_OF_LAST_YEAR = moment().subtract(1, 'year').endOf('year');
export const START_OF_MONTH = moment().startOf('month');
export const END_OF_MONTH = moment().endOf('month');

export const START_DATE_OF_YEAR = moment().startOf('year')._d;
export const END_DATE_OF_YEAR = moment().endOf('year')._d;

export const CHART_COLOR_TOTAL = '#fff';
export const CHART_COLOR_DEFAULT = 'rgb(241, 93, 128)';
export const CHART_LEGEND_LIMIT_NUMBER = 10;
export const CHART_COLORS = [
    '#a3e7d9',
    '#3ed0b4',
    '#029f81',
    '#01332a',
    '#94b6f1',
    '#578dec',
    '#2c70e0',
    '#0140ad',
    '#94b6f1',
    '#2f7ed8',
    '#0d233a',
    '#8bbc21',
    '#910000',
    '#1aadce',
    '#492970',
    '#f28f43',
    '#77a1e5',
    '#c42525',
    '#a6c96a',
    '#4572A7',
    '#AA4643',
    '#89A54E',
    '#80699B',
    '#3D96AE',
    '#DB843D',
    '#92A8CD',
    '#A47D7C',
    '#B5CA92'
];
export const LINE_CHART_COLORS = ['#3075EA', '#F45D29', '#FFBB00', '#1EAA5C', '#7C83A3'];
export const EMPTY_CHART_COLOR = '#F5F5F5';
export const CHART_HEIGHT_DEFAULT = 340;
export const LINE_CHART_BACKGROUND_COLOR_OPACITY = 'rgb(36,119,241,0.07)';
export const LINE_CHART_BACKGROUND_COLOR = 'rgb(36,119,241)';
export const LINE_CHART_BORDER_COLOR = 'rgb(36,119,241)';

//Setting
export const ADMIN_PERMISSION = 1;
export const TECH_PERMISSION = 2;
export const MAX_UPLOAD_FILE_SIZE = 2097152; // MAX 2Mb
export const KEY_TOKEN = '';
export const I18N_KEY = '';

export const TYPE_TARGET_LINK = {
    SELF: '_self',
    BLANK: '_blank'
};

export const REGEX_NUMBER = /^[1-9]([0-9]*$)/;
export const REGEX_EMAIL =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const REGEX_PASSWORD = /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,}.*)$/;
export const REGEX_URL = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/;
/**
 * Safari not support regex lookbehind ?<=
 */
// export const REGEX_FORMAT_NUMBER = /(?<=\d)(?=(\d\d\d)+(?!\d))/g;
export const REGEX_FORMAT_NUMBER = /(\d)(?=(\d{3})+(?!\d))/g;

export const SINGULAR_NOUN = 1;
export const DEFAULT_DELAY_DAY = 1;

export const STATUS_ACCOUNT = {
    ACTIVE: 'active',
    EXPIRED: 'expired'
};

export const ACCOUNT_ROLE = {
    SUPERADMIN: 'SuperAdmin',
    SUPERADMIN_SETTING: 'Super Admin',
    ADMIN: 'Admin',
    TECHNICIAN: 'Technician'
};

export const SOCKET_EVENTS = {
    CONNECT: 'connect',
    CONNECTED: 'connected',
    DISCONNECT: 'disconnect',
    RECONNECTING: 'reconnecting',
    CONNECT_ERROR: 'connect_error',
    CONNECT_FAILED: 'connect_failed'
};

export const ACCEPTED_ATTACHMENTS = [
    'image/jpeg',
    'image/gif',
    'image/png',
    'image/bmp',
    'audio/basic',
    'audio/l24',
    'audio/mp4',
    'audio/mpeg',
    'audio/ogg',
    'audio/vorbis',
    'audio/vnd.rn-realaudio',
    'audio/vnd.wave',
    'audio/3gpp',
    'audio/3gpp2',
    'audio/ac3',
    'audio/vnd.wave',
    'audio/webm',
    'audio/amr-nb',
    'audio/amr',
    'text/vcard',
    'text/csv',
    'text/rtf',
    'text/richtext',
    'text/calendar',
    'text/directory',
    'application/pdf'
];

export const SMS_ACCEPTED_ATTACHMENTS = [
    'image/jpeg',
    'image/gif',
    'image/png',
    'image/bmp',
    'image/jpg',
    'image/tiff',
    'audio/basic',
    'audio/l24',
    'audio/mpeg',
    'audio/ogg',
    'audio/vorbis',
    'audio/vnd.rn-realaudio',
    'audio/vnd.wave',
    'audio/3gpp2',
    'audio/ac3',
    'audio/vnd.wave',
    'audio/amr-nb',
    'audio/amr',
    'text/vcard',
    'text/x-vcard',
    'text/csv',
    'text/rtf',
    'text/richtext',
    'text/calendar',
    'text/directory',
    'application/pdf'
];

// Just support PNG, JPG, TXT, PDF
export const EMAIL_INBOX_ACCEPT_FILE = 'image/png,image/jpg,image/jpeg,application/pdf,text/plain';

export const VOIP_ACCEPTED_AUDIO = [
    'audio/mpeg',
    'audio/wav',
    'audio/wave',
    'audio/x-wav',
    'audio/aiff',
    'audio/x-aifc',
    'audio/x-aiff',
    'audio/x-gsm',
    'audio/gsm',
    'audio/ulaw'
];

export const ACCEPTED_CUSTOMER_ATTACHMENTS = [
    'image/jpeg',
    'image/gif',
    'image/png',
    'image/bmp',
    'audio/basic',
    'audio/l24',
    'audio/mp4',
    'audio/mpeg',
    'audio/ogg',
    'audio/vorbis',
    'audio/vnd.rn-realaudio',
    'audio/vnd.wave',
    'audio/3gpp',
    'audio/3gpp2',
    'audio/ac3',
    'audio/vnd.wave',
    'audio/webm',
    'audio/amr-nb',
    'audio/amr',
    'text/vcard',
    'text/csv',
    'text/rtf',
    'text/richtext',
    'text/calendar',
    'text/directory',
    'application/pdf',
    'video/x-ms-asf',
    'video/x-ms-wmv',
    'video/x-ms-wmx',
    'video/x-ms-wm',
    'video/avi',
    'video/divx',
    'video/x-flv',
    'video/quicktime',
    'video/mpeg',
    'video/mp4',
    'video/ogg',
    'video/webm',
    'video/x-matroska',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-word.document.macroEnabled.12',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
    'application/vnd.ms-word.template.macroEnabled.12',
    'application/vnd.ms-excel',
    'application/vnd.ms-access',
    'application/vnd.ms-project',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel.sheet.macroEnabled.12',
    'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
    'application/vnd.ms-excel.template.macroEnabled.12',
    'application/vnd.ms-excel.addin.macroEnabled.12'
];

export const LIST_STATUS = {
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning'
};

export const LIST_SINGLE_SELECT_CALL_API = ['service_type_single', 'item', 'staff', 'source', 'report_source'];
export const KEYS_SINGLE_SELECT_CALL_API = 'last_single_selected_filter_with_api';

// COMMON const use in App
export const COMMON = {
    AVATAR: 'avatar',
    AVATAR_ID: 'avatar_id',
    ACCOUNT_ID: 'account_id',
    ACCOUNT_NUMBER: 'account_number',
    AMOUNT: 'amount',
    ASC: 'asc',
    CASH: 'cash',
    COLUMNS: 'columns',
    COMPANY: 'company',
    CONTACTS: 'contacts',
    CREATED_BY: 'created_by',
    CURRENT_TAB: 'current_tab',
    CUSTOMER: 'customer',
    DATE: 'date',
    DATE_ADDED: 'date_added',
    DATE_CREATED: 'date_created',
    DESC: 'desc',
    EMAIL: 'email',
    EPA: 'epa',
    INVOICE_NUMBER: 'invoice_number',
    ITEM_NAME: 'item_name',
    ITEM_VALUE: 'item_value',
    LOCATION: 'location',
    LOCATIONS: 'locations',
    MATERIAL: 'material',
    METHOD: 'method',
    NOTE: 'note',
    NOT_SET: '',
    ORDER: 'order',
    PHONE: 'phone',
    SELECTED: 'selected',
    SERVICE_ADDRESS: 'service_address',
    SERVICE_NAME: 'service_name',
    SERVICE_STATE: 'service_state',
    SOURCE: 'source',
    SOURCE_NAME: 'source_name',
    STATUS_LABEL: 'status_label',
    TAG_NAME: 'tag_name',
    TOP_NOTE: 'top_note',
    USER_NAME: 'user_name',
    W_O_TAX: 'w_o_tax',
    ACTIVE: 'active',
    ARCHIVED: 'archived',
    DELETED: 'deleted',
    PARAMS: 'params',
    ERROR: 'error',
    SUCCESS: 'success',
    DESCRIPTION: 'description',
    COST: 'cost',
    TAX_1: 'tax1',
    TAX_2: 'tax2',
    NAME: 'name',
    RATE: 'rate',
    STRING: 'string',
    NUMBER: 'number',
    SUBJECT: 'subject',
    EMAIL_CONTENT: 'email_content',
    SMS_CONTENT: 'sms_content',
    CONTENT: 'content',
    TEMP_ID: 'temp_id',
    PASSWORD: 'password',
    DOCUMENTS: 'documents',
    SCHEDULES: 'schedules',
    IMAGE_TYPES: `image/png, image/jpeg, image/jpg`,
    OPERATING_START: `operating_start`,
    OPERATING_END: `operating_end`,
    LOGO: 'logo',
    HOUR: 'hour',
    MINUTE: 'minute',
    AMPM: 'ampm',
    LICENSE: 'license',
    ADDRESS: 'address',
    CITY: 'city',
    STATE: 'state',
    ZIP: 'zip',
    WEBSITE: 'website',
    FAX: 'fax',
    OBJECT_KEY: 'object_key',
    OBJECT_TAB: 'object_tag',
    URL: 'url',
    CURRENT_PAGE: 'current_page',
    ALL: 'all',
    DELETE: 'delete',
    SYNC_TO_QB: 'sync_to_qb',
    ACTIVATE: 'activate',
    PERMANENTLY_DELETE: 'permanently_delete',
    COMPLETED: 'completed',
    CANCELED: 'canceled',
    START: 'start',
    END: 'end',
    USER: 'user',
    INACTIVE: 'inactive',
    CHECK_NUMBER: 'check_number',
    CHECK_VALUE: 'check_value',
    MEMO: 'memo',
    N_A: 'n/a',
    LENGTH: 'length',
    COLOR_CODE: 'color_code',
    FREQUENCY: 'frequency',
    CHECK: 'check',
    HOURS: 'hours',
    MINUTES: 'minutes',
    UNDELETE: 'undelete',
    CSV: 'csv',
    XLS: 'xls',
    PDF: 'pdf',
    PRINT: 'print',
    SMS: 'sms',
    SCHEDULE_DATE: 'scheduled_date',
    PAYMENT_FOR: 'payment_for',
    DOCUMENT_NUMBER: 'document_number',
    DOCUMENT_TYPE: 'document_type',
    PAST_DUE: 'past_due',
    INVOICE_DATE: 'invoice_date',
    PAYMENT_DATE: 'payment_date',
    COUNTY: 'county',
    PHONES: 'phones',
    CUSTOMER_PORTAL: 'customer_portal',
    ACTIVE_JOBS: 'active_jobs',
    INVOICE_TOTAL: 'invoice_total',
    NEXT_SERVICE: 'next_service',
    COUNTRY: 'country',
    INDUSTRY_ID: 'industry_id',
    TIMEZONE: 'timezone',
    DATE_FORMAT: 'date_format',
    CURRENCY: 'currency',
    TEMPERATURE: 'temperature',
    CREATED: 'created',
    LOCATION_NAME: 'location_name',
    SETTINGS: 'settings',
    TAXABLE_SALES: 'taxable_sales',
    PAYMENT_AMOUNT: 'payment_amount',
    BANK_ACH: 'bank_accounts',
    CUSTOMER_ID: 'customer_id',
    JOB_CREATED_DATE: 'job_created_date',
    QUESTIONS: 'questions',
    LABEL: 'label',
    QUESTION: 'question',
    CONVERSATIONS: 'conversations'
};

export const MAX_NUMBER_STATUS = 3;
export const TAGS_NAME_PREVENT_CLICK = ['INPUT', 'LABEL'];

export const TYPE_TAGS_MAP = {
    1: 'Customer',
    2: 'Location'
};

// Special Value
export const TOTAL_BILLED = 'total_billed';

// Key API calendar store
export const KEY_STORE_SCHEDULE = 1; // value of schedule
export const KEY_STORE_VIEW = 2; // value of view calendar
export const KEY_STORE_DATE = 3; // value of date calendar
export const KEY_STORE_WEEK = 4; // value of week (1 or 0)
export const KEY_STORE_COLOR = 5; // value of color
export const KEY_STORE_WIDTH_MAP = 6; // value of width map
export const KEY_STORE_HEIGHT_CALENDAR = 7; // value of height calendar
export const KEY_STORE_HEIGHT_MAP = 8; // value of height map

// Map default value
export const DEFAULT_WIDTH_MAP = 312;
export const DEFAULT_HEIGHT_MAP = 204;
export const DEFAULT_COLOR_MARKER = '#89849b';

export const DOCUMENT_SEARCH_LIMIT = 20;

export const ADDONS_HOURS = [
    { name: '00', value: 0 },
    { name: '01', value: 1 },
    { name: '02', value: 2 },
    { name: '03', value: 3 },
    { name: '04', value: 4 },
    { name: '05', value: 5 },
    { name: '06', value: 6 },
    { name: '07', value: 7 },
    { name: '08', value: 8 },
    { name: '09', value: 9 },
    { name: '10', value: 10 },
    { name: '11', value: 11 },
    { name: '12', value: 12 }
];

export const TIMEOFF_HOURS = [
    { name: '00', value: 0 },
    { name: '01', value: 1 },
    { name: '02', value: 2 },
    { name: '03', value: 3 },
    { name: '04', value: 4 },
    { name: '05', value: 5 },
    { name: '06', value: 6 },
    { name: '07', value: 7 },
    { name: '08', value: 8 },
    { name: '09', value: 9 },
    { name: '10', value: 10 },
    { name: '11', value: 11 },
    { name: '12', value: 12 },
    { name: '13', value: 13 },
    { name: '14', value: 14 },
    { name: '15', value: 15 },
    { name: '16', value: 16 },
    { name: '17', value: 17 },
    { name: '18', value: 18 },
    { name: '19', value: 19 },
    { name: '20', value: 20 },
    { name: '21', value: 21 },
    { name: '22', value: 22 },
    { name: '23', value: 23 }
];

export const TIMEOFF_MINUTES = [
    { name: '00', value: 0 },
    { name: '05', value: 5 },
    { name: '10', value: 10 },
    { name: '15', value: 15 },
    { name: '20', value: 20 },
    { name: '25', value: 25 },
    { name: '30', value: 30 },
    { name: '35', value: 35 },
    { name: '40', value: 40 },
    { name: '45', value: 45 },
    { name: '50', value: 50 },
    { name: '55', value: 55 }
];
export const SUBSCRIPTION_CANCEL_IMMEDIATELY = 2;
export const MERCHANT_METHOD = 3;
export const TYPE_DELETE_SERVICES_SETTING = 5;

export const KEY_MODAL_SMS_CHARGE_FAILED = 'modal_sms_charge';

export const METHOD_API = '****';
export const METHOD_CONVERT = '••••';
export const MATERIAL_NY_PRL = 'NY PRL';
export const MATERIAL_NY_PRL_OPTION = 2;
export const MATERIAL_ALL_DETAILED_OPTION = 1;

export const MATERIAL_USE_SELECT_OPTIONS = {
    ALL: -1,
    ALL_COUNTY: 'all-by-county',
    ALL_DETAILED: -2,
    NY_PRL: -3
};

export const LIST_OPTION_MATERIAL_USE_DETAIL = [
    MATERIAL_USE_SELECT_OPTIONS.ALL_DETAILED,
    MATERIAL_USE_SELECT_OPTIONS.NY_PRL
];

export const MATERIAL_DETAIL_OPTION = {
    [MATERIAL_USE_SELECT_OPTIONS.ALL_DETAILED]: MATERIAL_ALL_DETAILED_OPTION,
    [MATERIAL_USE_SELECT_OPTIONS.NY_PRL]: MATERIAL_NY_PRL_OPTION
};

export const LOGIN_BY_TOKEN_TYPES = {
    ACCESS: 'access',
    ACCESS_CLOSEIO: 'accessCloseio',
    CLASSIC: 'classic'
};

export const ADDED_BY_SPECIAL_LIST = [
    {
        id: '-1',
        avatar: '',
        first_name: 'Portal',
        full_name: 'Portal / Online Booking',
        last_name: ' Online Booking'
    },
    { id: '-2', avatar: '', first_name: 'Zapier', full_name: 'Zapier Api', last_name: 'Api' }
];

export const INVOICES_ACTION_OPTIONS = {
    ACTIVATE: 1,
    ARCHIVED: 2,
    UNDELETE: 3
};

export const MODES_HANDLE_SETTING_USERS = {
    CREATE: 'create',
    EDIT: 'edit',
    PREVIEW: 'preview',
    SESSION: 'session'
};

export const FILE_BROKEN = 'file_broken';

export const VIEW_ESIGN_INVOICE = '2';

export const HIDE_TIME_CONFIRMATION = '1';

export const IS_WEBGL_SUPPORT = isWebglSupport();

export const KEY_LOCAL_STORAGE_HELLO_BAR = 'show_hello_bar';

export const COLOR_SUCCESS = '#24a863';
export const PRESIGN_TYPES = {
    EMAIL_INBOX: 'email_inbox'
};

export const DEFAULT_CURRENCY_SYMBOL = '$';

export const ACCEPTED_ATTACHMENTS_PDF = 'application/pdf';

export const DEFAULT_TIME_TIMEOUT = 2000;
export const LIMIT_CHARACTERS = 255;

export const DISTANCE_RANGE_OPTIONS = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: '6', value: 6 },
    { label: '7', value: 7 },
    { label: '8', value: 8 },
    { label: '9', value: 9 },
    { label: '10', value: 10 }
];
export const DAY_RANGE_OPTIONS = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: '6', value: 6 },
    { label: '7', value: 7 },
    { label: '8', value: 8 },
    { label: '9', value: 9 },
    { label: '10', value: 10 }
];
export const SRC_LOGO_GORILLADESK = `${LINK_CDN_IMG}gorilladesk.png`;
export const GD_DEVICE_ID = 'gd-device-id';
const TIME_MS = 'TIME_MS';
export const GD_COUNTDOWN_TYPE = { TIME_MS: TIME_MS };
export const LIMIT_CHARACTERS_TEXT_MISSED_CALL_VOIP = 160;
export const DIVIDER = 'divider';
