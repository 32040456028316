import GdRichText from 'app/components/richtext';
import { TYPE_FUNCTION_RICHTEXT } from 'app/components/richtext/const';
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';

const ContentNoteVisible = (
    {
        onCloseEdit = () => {},
        onUpdate = () => {},
        content = '',
        showCancel = true,
        disableSend = false,
        showSave = true,
        showRightToolbar = true,
        scrollParams = { behavior: 'smooth', block: 'center', inline: 'center' },
        onBlur = () => {},
        onTriggerImages = () => {}
    },
    ref
) => {
    const refRichtext = useRef(null);

    useEffect(() => {
        refRichtext.current?.setValue(content);
    }, [content]);

    function _handleCancelEdit() {
        onCloseEdit();
    }

    useImperativeHandle(ref, () => ({
        focus: () => {
            refRichtext.current.focus();
        },
        getContent: () => {
            if (refRichtext.current.isEmpty()) return '';
            _getValue();
        },
        resetValue: () => {
            _handleReset();
        },
        getAttachments: () => refRichtext.current.getValueWithAttach()?.attachments || [],
        getNotify: () => refRichtext.current.getNotify() || [],
        clearNotify: () => {
            refRichtext.current.clearNotify();
        },
        setValue: (newValue, isConcat) => {
            if (isConcat) {
                const newContent = (refRichtext.current?.getValue() || '').concat(newValue);
                refRichtext.current?.setValue(newContent);
                !showRightToolbar && onBlur(newContent);
            } else {
                refRichtext.current?.setValue(newValue);
            }
            refRichtext.current.scrollTo(scrollParams);
        }
    }));

    const _getValue = () => {
        return refRichtext.current.getValue();
    };

    function _handleUpdate() {
        const finalValue = refRichtext.current.getValue();
        onUpdate(finalValue === '<br/>' ? '' : finalValue);
    }

    const _handleReset = () => {
        refRichtext.current.setValue('');
    };

    const _triggerUploadImage = (data = []) => {
        onTriggerImages(data);
        if (!data.length) {
            _getValue();
        }
    };

    const _handleBlur = (content) => {
        if (!!refRichtext.current?.getUploadingImages()?.length) return;
        onBlur(content);
    };

    return (
        <GdRichText
            ref={refRichtext}
            canDropImage
            wrapperClass="wrap-content is-editing"
            defaultContent={content || ' '}
            isBreakLine
            isBlank
            toolbarConfig={{
                left: [
                    { group: 'emoji', children: [{ type: TYPE_FUNCTION_RICHTEXT.EMOJI }] },
                    {
                        group: 'inline-style',
                        children: [
                            { type: TYPE_FUNCTION_RICHTEXT.BOLD },
                            { type: TYPE_FUNCTION_RICHTEXT.ITALIC },
                            { type: TYPE_FUNCTION_RICHTEXT.UNDERLINE },
                            { type: TYPE_FUNCTION_RICHTEXT.LINK }
                        ]
                    },
                    {
                        group: 'list',
                        children: [{ type: TYPE_FUNCTION_RICHTEXT.UL }, { type: TYPE_FUNCTION_RICHTEXT.OL }]
                    }
                ],
                right: !showRightToolbar
                    ? []
                    : [
                          {
                              group: 'save-group',
                              children: [
                                  {
                                      type: TYPE_FUNCTION_RICHTEXT.SAVE_BUTTON,
                                      props: {
                                          showCancel,
                                          disableSubmit: disableSend,
                                          showSave,
                                          onSubmit: _handleUpdate,
                                          onCancel: _handleCancelEdit
                                      }
                                  }
                              ]
                          }
                      ]
            }}
            onBlur={_handleBlur}
            onTriggerUploadImage={_triggerUploadImage}
        />
    );
};

export default forwardRef(ContentNoteVisible);
