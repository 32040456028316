import { COMMON, LIST_TABLE_ITEM_TYPE } from 'app/const/App';
import { mapCurrentValueHeader } from 'common/utils/GridViewUtils';
import { STATUS_OPTIONS } from '.';

export const gridColumnContent = ({ actionsStatus = '' }) => {
    const HEADER_CHATBOT_CONTENT = {
        label: {
            title: 'label',
            style: 'col col-xl',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        status: {
            title: 'status',
            style: 'col col-sm d-flex',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        is_script: {
            title: 'is_script',
            style: 'col col-md-plus'
        },
        answered: {
            title: 'answered',
            style: 'col col-sm',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        resolved: {
            title: 'resolved',
            style: 'col col-sm',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        }
    };

    const COLUMNS_CHATBOT_CONTENT = [
        {
            id: 'label',
            title: 'label',
            status: true
        },
        {
            id: 'status',
            title: 'status',
            status: true
        },
        {
            id: 'is_script',
            title: 'is_script',
            status: true
        },
        {
            id: 'answered',
            title: 'answered',
            status: true
        },
        {
            id: 'resolved',
            title: 'resolved',
            status: true
        }
    ];

    const CONTENT_CHATBOT_CONTENT = {
        label: {
            id: 'label',
            style: 'col col-xl',
            styleText: 'name',
            type: LIST_TABLE_ITEM_TYPE.LINK
        },
        status: {
            id: 'status',
            style: 'col col-sm d-flex',
            type: LIST_TABLE_ITEM_TYPE.STATUS_ITEM,
            listStatus: STATUS_OPTIONS
        },
        is_script: {
            id: 'is_script',
            style: 'col col-md-plus',
            type: LIST_TABLE_ITEM_TYPE.STATUS_ITEM,
            listStatus: STATUS_OPTIONS
        },
        answered: {
            id: 'answered',
            style: 'col col-sm'
        },
        resolved: {
            id: 'answered',
            style: 'col col-sm',
            type: LIST_TABLE_ITEM_TYPE.RATE_PERCENT
        }
    };

    return {
        header: mapCurrentValueHeader(actionsStatus, { ...HEADER_CHATBOT_CONTENT }),
        columns: COLUMNS_CHATBOT_CONTENT,
        contentConfig: CONTENT_CHATBOT_CONTENT
    };
};
