/* eslint-disable no-unused-vars */
import { actionOpenJobPreview } from 'common/redux/actions/job';
import PropTypes from 'prop-types';
import { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    actionWPRealtimeSatus,
    actionWPRealtimeDelete,
    actionWPRealtimeMove
} from 'common/redux/actions/calendar/workPoolAction';
import { actionJobStatus, actionDeleteJob } from 'app/modules/calendar/ultil/JobAction';
import moment from 'moment';
import { SocketContext } from 'app/services/socket/SocketProvider';
import { JOB_CANCELED, JOB_COMPLETED } from 'app/const/Job';
import { getJobStatus } from 'common/utils/JobStatusUtils';
import { modifyMagnetEvents } from './components/magnet/utils';
import { UN_MAGNET_VALUE } from './components/magnet/components/constants';
import { STATUSES_EXCEPT_MAGNET } from './const';

function RealtimeJobServices({
    events = [],
    onReloadEvents = () => {},
    onRefesh = () => {},
    handleStatusMap = () => {},
    schedules = [],
    handleUpdateState = () => {},
    onUpdateStoreMagnet = () => {},
    onRefreshMagnet = () => {},
    isMap = false
}) {
    const { start, end } = useSelector(({ inlineCalendarReducer }) => inlineCalendarReducer);

    function checkTimeOverLap(startJob, endJob) {
        return moment(startJob).utc().unix() <= moment(end).utc().unix() &&
            moment(start).utc().unix() < moment(endJob).utc().unix()
            ? true
            : false;
    }

    const dispatch = useDispatch();

    const {
        jobStatus: statusResponse,
        jobDelete: deleteResponse,
        jobMove: moveResponse,
        jobResize: resizeResponse,
        jobCreate: createResponse,
        jobUpdate: updateResponse,
        jobReassign: reassignResponse,
        invoiceStatus: invoiceStatusResponse
    } = useContext(SocketContext);

    useEffect(() => {
        _setSocketId(`${Date.now()}_main_calendar`);
    }, []);

    useEffect(() => {
        statusResponse && _prepareStatus(statusResponse);
    }, [statusResponse]);

    useEffect(() => {
        deleteResponse && _jobDelete(deleteResponse);
    }, [deleteResponse]);

    useEffect(() => {
        moveResponse && _jobMoveAction(moveResponse);
    }, [moveResponse]);

    useEffect(() => {
        resizeResponse && _jobMove(resizeResponse);
    }, [resizeResponse]);

    useEffect(() => {
        createResponse && _jobMove(createResponse);
    }, [createResponse]);

    useEffect(() => {
        updateResponse && _jobMove(updateResponse);
    }, [updateResponse]);

    useEffect(() => {
        reassignResponse && _jobReassign(reassignResponse);
    }, [reassignResponse]);

    useEffect(() => {
        invoiceStatusResponse && _handleUpdateInvoiceStatus(invoiceStatusResponse);
    }, [invoiceStatusResponse]);

    const _handleUpdateInvoiceStatus = (invoiceStatusResponse) => {
        const { status: invoiceStatus, eventIds } = invoiceStatusResponse;

        handleUpdateState((prev) => {
            return {
                ...prev,
                events: prev.events.map((item) => {
                    const currentEventId = parseInt(item?.event?.id);
                    if (eventIds?.includes(currentEventId)) {
                        item.draft_invoice =
                            invoiceStatus === 'draft' && getJobStatus(item.status)?.type === JOB_COMPLETED ? 1 : 0;
                    }
                    return item;
                })
            };
        });
    };

    const _jobReassign = (response) => {
        const responseData = response.data;
        const scheduleTo = schedules.some((item) => item.id === responseData.to);
        const scheduleFrom = schedules.some((item) => item.id === responseData.from);

        if (scheduleTo || scheduleFrom) {
            lastChechRefetch(response);
        }
    };

    const _prepareStatus = (response) => {
        const {
            nextJob,
            socket_id,
            jobId,
            status,
            locked,
            previously_completed,
            draft_invoice,
            editable,
            v2: jobColor = {}
        } = response;

        // eslint-disable-next-line no-undef
        if (socket_id && (socket_id === global.socketIdJobDetail || socket_id === global.jobPreviewId)) {
            return false;
        }

        const jobData = {
            current_job: {
                job: {
                    id: jobId,
                    status: parseInt(status),
                    locked: parseInt(locked),
                    previously_completed: previously_completed
                },
                draft_invoice: draft_invoice ? 1 : 0,
                editable: editable,
                color: {
                    border: jobColor.borderColor,
                    text: jobColor.textColor || '#7A83A6',
                    background: jobColor.backgroundColor
                },
                refetchEvents: Date.now()
            }
        };

        if (nextJob) {
            const nexJobColor = nextJob.v2 || {};

            jobData['new_job'] = {
                job: {
                    id: nextJob.jobId,
                    status: parseInt(nextJob.status),
                    locked: parseInt(nextJob.locked),
                    previously_completed: parseInt(nextJob.previously_completed)
                },
                editable: nextJob.editable,
                color: {
                    border: nexJobColor.borderColor,
                    text: nexJobColor.textColor || '#7A83A6',
                    background: nexJobColor.backgroundColor
                },
                refetchEvents: Date.now()
            };
        }

        //Pass data real time status to Work pool
        dispatch(actionWPRealtimeSatus(jobData));

        _realtimeStatus(jobData);
    };

    /**
     * Funtion update status event in calendars
     * 1. If have next job we will active ( This case update canceled or completed )
     * 2. We will global.jobPreviewId for check is the event status being opened in the job preview?
     * 3. global.jobPreviewId set at click event on main calendar and clear at close job preview
     */
    const _realtimeStatus = (jobData) => {
        let jobPreviewUpdate = null;

        if (!isMap) {
            handleUpdateState((prev) => {
                // If change status to cancel or complete we will refresh magnet calendar
                const needClearMagnet = STATUSES_EXCEPT_MAGNET.includes(jobData.current_job.job.status);
                const {
                    list: newEvents,
                    jobPreview,
                    oldIsExceptMagnet
                } = actionJobStatus(jobData, events, needClearMagnet);
                jobPreviewUpdate = jobPreview;

                needClearMagnet || oldIsExceptMagnet ? onRefreshMagnet() : onUpdateStoreMagnet(newEvents);
                return { ...prev, events: newEvents };
            });

            setTimeout(() => {
                if (jobPreviewUpdate) {
                    dispatch(actionOpenJobPreview({ ...jobPreviewUpdate, schedule: { ...jobPreviewUpdate.schedule } }));
                }
            }, 1000);
        } else {
            handleStatusMap(jobData);
        }
    };

    function _jobDelete(jobData) {
        // eslint-disable-next-line no-undef
        const soketJobDetail = global.socketIdJobDetail || '';
        const responseSocketId = jobData.socket_id;

        dispatch(actionWPRealtimeDelete(jobData));

        if (responseSocketId === soketJobDetail) {
            return false;
        }

        const { list: newEvents, isJobPreview } = actionDeleteJob(jobData, events, isMap);

        onReloadEvents(newEvents);
        isJobPreview && dispatch(actionOpenJobPreview(null));
    }

    function lastChechRefetch(jobData) {
        // eslint-disable-next-line no-undef
        if (jobData && (jobData.socket_id !== global.mainCalendarSocketId || isMap)) {
            onRefesh();
        }
    }

    function _jobMoveAction(jobData) {
        const { start = null, end = null } = jobData?.data || {};
        dispatch(actionWPRealtimeMove(jobData));
        if (checkTimeOverLap(start, end || start)) {
            lastChechRefetch(jobData);
        }
    }

    function _jobMove(jobData) {
        const { start = null, end = null } = jobData?.data || {};
        if (checkTimeOverLap(start, end || start)) {
            lastChechRefetch(jobData);
        }
    }

    const _setSocketId = (socketId) => {
        // eslint-disable-next-line no-undef
        global.mainCalendarSocketId = socketId;
    };

    return null;
}

RealtimeJobServices.propTypes = { onReloadEvents: PropTypes.func };

export default RealtimeJobServices;
