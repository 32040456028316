import {
    ADDONS_CHAT_BOT_CONTENT,
    ADDONS_CHAT_BOT_CUSTOM_ANSWER,
    ADDONS_CHAT_BOT_REPORT,
    ADDONS_CHAT_BOT_SETTINGS,
    ADDONS_CHAT_BOT_TRAIN_AI
} from 'app/config/routes';
import { DIVIDER, TYPE_BUTTON_ACTIONS } from 'app/const/App';
import i18n from 'assets/i18n';
import IconCheck from 'assets/icon/IconCheck';
import IconClose from 'assets/icon/IconClose';

export const ADDONS_CHAT_BOT_MENU_OPTIONS = [
    { id: 1, value: i18n.t('addons:content'), link: ADDONS_CHAT_BOT_CONTENT },
    { id: 2, value: i18n.t('addons:custom_answers'), link: ADDONS_CHAT_BOT_CUSTOM_ANSWER },
    { id: 3, value: i18n.t('addons:train_ai'), link: ADDONS_CHAT_BOT_TRAIN_AI },
    { id: 4, value: i18n.t('addons:report'), link: ADDONS_CHAT_BOT_REPORT },
    { id: 5, value: i18n.t('addons:settings'), link: ADDONS_CHAT_BOT_SETTINGS }
];

export const STATUS_OPTIONS = [
    { id: 1, name: i18n.t('addons:active'), value: 1, classNameStatus: 'active' },
    { id: 0, name: i18n.t('addons:inactive'), value: 0, classNameStatus: 'inactive' }
];

export const REPLY_BEHAVIOR_OPTIONS = [
    { id: 0, title: i18n.t('addons:reply_immediately') },
    { id: DIVIDER },
    { id: 1, title: i18n.t('addons:reply_after_minute', { minute: 1 }) },
    { id: 2, title: i18n.t('addons:reply_after_minutes', { minute: 3 }) },
    { id: 3, title: i18n.t('addons:reply_after_minutes', { minute: 5 }) }
];
export const MODE_CHART_TRAIN_AI = {
    ALL: 'all',
    THIS_MONTH: 'this-month',
    THIS_YEAR: 'this-year',
    LAST_YEAR: 'last-year'
};
export const TAB_CHART_TRAIN_AI = [
    { value: MODE_CHART_TRAIN_AI.ALL, label: i18n.t('addons:all_time') },
    { value: MODE_CHART_TRAIN_AI.THIS_YEAR, label: i18n.t('addons:this_year') },
    { value: MODE_CHART_TRAIN_AI.LAST_YEAR, label: i18n.t('addons:last_year') },
    { value: MODE_CHART_TRAIN_AI.THIS_MONTH, label: i18n.t('addons:this_month') }
];

export const STATUS_TRAIN_OPTIONS = [
    {
        id: 1,
        name: i18n.t('addons:trained'),
        value: 1,
        classNameStatus: 'has-icon active svg-white-stroke',
        icon: <IconCheck />
    },
    {
        id: 0,
        name: i18n.t('addons:untrained'),
        value: 0,
        classNameStatus: 'has-icon error svg-white-stroke',
        icon: <IconClose isSmall />
    }
];

export const DELETE = 'DELETE';
export const ARCHIVE = 'ARCHIVE';
export const ACTIVE = 'ACTIVE';
export const INACTIVE = 'INACTIVE';
export const TYPE_DATA_CHATBOT = { UPDATE: 'UPDATE', [DELETE]: DELETE };
export const BATCH_ACTION = { CONTENT: 'content', CUSTOM_ANSWER: 'customanswers' };
export const DATA_WARNING_TYPE = { CONTENT: 'CONTENT', CUSTOM_ANSWER: 'CUSTOM_ANSWER' };
export const ACTION_BUTTON_TYPE = { [STATUS_OPTIONS[1].id]: INACTIVE, [STATUS_OPTIONS[0].id]: ACTIVE };
export const MARK_AS_OPTIONS = [
    { id: 1, name: i18n.t('addons:mark_active'), value: 1 },
    { id: 0, name: i18n.t('addons:mark_inactive'), value: 0 }
];
export const ACTION_BUTTON = {
    [MARK_AS_OPTIONS[0].id]: { label: ACTIVE.toLowerCase(), type: 1, status: MARK_AS_OPTIONS[0].id },
    [MARK_AS_OPTIONS[1].id]: { label: INACTIVE.toLowerCase(), type: 0, status: MARK_AS_OPTIONS[1].id },
    [TYPE_BUTTON_ACTIONS.DELETE]: { label: DELETE.toLowerCase(), type: 2 }
};
export const REPORT_STATUS_OPTIONS = [
    { id: 1, name: i18n.t('addons:ai_resolved'), value: 1, classNameStatus: 'resolved' },
    { id: 0, name: i18n.t('addons:not_resolved'), value: 0, classNameStatus: 'not-resolved' },
    { id: 2, name: i18n.t('addons:agent_resolved'), value: 2, classNameStatus: 'agent-resolved' }
];
export const CHATBOT_SETTING_INFO = {
    NAME_AI: 'name_ai',
    OFFICE_HOURS_GREETING: 'office_hours_greeting',
    AFTER_HOURS_GREETING: 'after_hours_greeting'
};
