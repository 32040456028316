import { LIST_TABLE_ITEM_TYPE } from 'app/const/App';
import { mapCurrentValueHeader } from 'common/utils/GridViewUtils';

export const gridColumnTrainAISource = ({ actionsStatus = '' }) => {
    const TRAIN_AI_SOURCE_HEADER_TABLE = {
        customer: { title: 'customer', style: 'col col-md' },
        question: { title: 'question', style: 'col col-xl' },
        date: { title: 'date', style: 'col col-md' }
    };

    const TRAIN_AI_SOURCE_COLUMNS_TABLE = [
        { id: 'customer', title: 'customer', status: true },
        { id: 'question', title: 'question', status: true },
        { id: 'date', title: 'date', status: true }
    ];

    const TRAIN_AI_SOURCE_CONTENT_TABLE = {
        customer: { id: 'customer', style: 'col col-md', styleText: 'name', type: LIST_TABLE_ITEM_TYPE.CUSTOMER_INFO },
        question: { id: 'question', style: 'col col-xl', styleText: 'col-label', type: LIST_TABLE_ITEM_TYPE.LINK },
        date: { id: 'date', style: 'col col-md', type: LIST_TABLE_ITEM_TYPE.DEFAULT }
    };

    return {
        header: mapCurrentValueHeader(actionsStatus, { ...TRAIN_AI_SOURCE_HEADER_TABLE }),
        columns: TRAIN_AI_SOURCE_COLUMNS_TABLE,
        contentConfig: TRAIN_AI_SOURCE_CONTENT_TABLE
    };
};
